import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {NotificationService} from "@incert/incert-core";
import {SelectItem} from "primeng/api";
import {RKSV, ViewMode} from "./account-system-rksv.interface";
import {AccountSystem} from "../../../core/models/account-system.interface";
import {AccountSystemManager} from "../account-system-manager.service";
import {AuthManager} from "../../../core/auth";

@Component({
  selector: "account-system-rksv",
  templateUrl: "./account-system-rksv.component.html",
  styleUrls: ["./account-system-rksv.component.css"],
})
export class AccountSystemRksvComponent implements OnInit {
  @Input() accountSystemId: number;
  @Input() accountSystems: Array<AccountSystem> = [];
  @Input() viewMode: ViewMode = null;

  public _rksvData: RKSV;
  public formGroup: UntypedFormGroup;
  public mode: ViewMode = "show";
  public activeStatusSI: Array<SelectItem> = [];
  public isAdminLogin: boolean;

  constructor(
    private accountSystemManager: AccountSystemManager,
    private notificationService: NotificationService,
    private authManager: AuthManager,
  ) {
    this.formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(""),
      accountSystem: new UntypedFormControl(null),
      uid: new UntypedFormControl(""),
      rksvActivated: new UntypedFormControl(""),
      webServiceUrl: new UntypedFormControl(""),
      webServiceUser: new UntypedFormControl(""),
      webServicePass: new UntypedFormControl(""),
      webServiceFonTid: new UntypedFormControl(""),
      webServiceFonUser: new UntypedFormControl(""),
      webServiceFonPass: new UntypedFormControl(""),
      aesKey: new UntypedFormControl(""),
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.viewMode) {
      this.mode = this.viewMode;
    }

    if (this.accountSystemId && this.accountSystems) {
      await this.fetchRKSVForSystem();

      this.createActiveSI();
      this.toggleEditability();
    } else {
      console.error("could not fetch RKSV Data, as no id provided");
    }

    this.isAdminLogin = await this.authManager.isAdminLoggedIn();
  }

  private async fetchRKSVForSystem() {
    this._rksvData =
      (await this.accountSystemManager.getRKSVDataForACS(
        this.accountSystemId,
      )) ?? null;

    if (Array.isArray(this._rksvData)) {
      this._rksvData = null;
    }

    if (this._rksvData) {
      const accountSystem = this.accountSystems.find(
        (s) => s.id === this._rksvData.accountSystem.id,
      );
      const {memos, ...acs} = accountSystem;

      this.formGroup.patchValue(this._rksvData);
      this.formGroup.patchValue({accountSystem: acs});
    }
  }

  private toggleEditability() {
    Object.keys(this.formGroup.controls).forEach((control) => {
      this.mode === "edit" || this.mode === "create"
        ? this.formGroup.controls[control].enable()
        : this.formGroup.controls[control].disable();
    });
  }

  public toggleMode(mode: ViewMode) {
    this.mode = mode;

    this.patchCoreRKSVData();
    this.toggleEditability();
  }

  private patchCoreRKSVData() {
    this.formGroup.patchValue({
      aesKey: "SnWmk4q9BTJm+yv5WjfMSRenVS6DE/xkXTKT/Bn/8Ps=",
    });
    this.formGroup.patchValue({
      webServiceUrl: "https://www.a-trust.at/asignrkonline/v2/",
    });
  }

  async update() {
    try {
      await this.accountSystemManager.putRKSVData(this.formGroup.value);
      this.showNotification(true);
      this.toggleMode("show");
    } catch (e) {
      this.showNotification(false);
    }
  }

  async save() {
    try {
      this.formGroup.patchValue({
        accountSystem: this.accountSystems.find(
          (a) => a.id === this.accountSystemId,
        ),
      });

      this._rksvData = await this.accountSystemManager.postRKSVData(
        this.formGroup.getRawValue(),
      );

      this.formGroup.patchValue(this._rksvData);

      this.showNotification(true);
      this.toggleMode("show");
    } catch (e) {
      this.showNotification(false);
    }
  }

  private showNotification(success: boolean) {
    if (success) {
      this.notificationService.notifySuccess(
        "Die Änderungen wurden erfolgreich gespeichert",
        "Änderungen gespeichert",
      );
    } else {
      this.notificationService.notifyError(
        "Die Änderungen konnten nicht gespeichert werden",
        "Fehlgeschlagen",
      );
    }
  }

  private createActiveSI() {
    this.activeStatusSI.push({label: "Aktiv", value: true});
    this.activeStatusSI.push({label: "Nicht aktiv", value: false});
  }
}
