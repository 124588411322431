import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { createNameof, NotificationService } from "@incert/incert-core";
import { SelectItem } from "primeng/api";
import { ContactGroup } from "../contact-group.model";
import { ContactGroupManagerService } from "../contact-group-manager.service";
import { ContactGroupComponent } from "../contact-group.component";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { AccountSystemGroup } from "../../../account-system-group/account-system-group";

@Component({
  selector: "incert-internal-tools-contact-group-add",
  templateUrl: "./contact-group-add.component.html",
})
export class ContactGroupAddComponent implements OnInit {
  nameofForm = createNameof<ContactGroup>();
  contactGroupComp: ContactGroupComponent;
  contactRoles: SelectItem[] = [];
  accountSystemGroups: AccountSystemGroup[] = [];
  reportDashboards: SelectItem[] = [];
  contactGroup: ContactGroup;
  form: UntypedFormGroup;
  typeSI: SelectItem[] = [
    { value: "general", label: "Allgemein" },
    { value: "report", label: "Report" },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private contactGroupManager: ContactGroupManagerService,
  ) {
    this.form = this.fb.group(
      modelToForm<ContactGroup>({
        id: [null],
        name: [null, [Validators.required]],
        type: [null, [Validators.required]],
        contactRoles: [null],
        accountSystemGroup: [null, [Validators.required]],
      }),
    );
  }

  async ngOnInit() {
    if (this.contactGroup) {
      this.form.patchValue(this.contactGroup);
      this.form
        .get("contactRoles")
        .patchValue(this.contactGroup.contactRoles.map((c) => c.id));
      this.form
        .get("accountSystemGroup")
        .patchValue(
          this.contactGroup.accountSystemGroup
            ? this.contactGroup.accountSystemGroup.id
            : null,
        );
    }
  }

  async upsertContactGroup() {
    markAsDirty(this.form);
    if (this.form.valid) {
      const contactGroup: ContactGroup = this.form.value;
      if (this.isType("general")) {
        contactGroup.contactRoles = this.form
          .get("contactRoles")
          .value?.map((v) => {
            return {
              id: v,
            };
          });
      } else {
        contactGroup.contactRoles = null;
      }
      contactGroup.accountSystemGroup = {
        id: this.form.get("accountSystemGroup").value,
      };

      await this.contactGroupManager.upsertContactGroup(contactGroup);
      this.notificationService.notifySuccess(
        this.contactGroup
          ? "Kontaktgruppe bearbeitet"
          : "Kontaktgruppe hinzugefügt",
      );
      await this.contactGroupComp.init();
      return true;
    }
    return false;
  }

  isType(type: string) {
    return this.form.get("type")?.value === type;
  }
}
