import { Component, Input, ContentChildren, QueryList } from "@angular/core";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { WizardStepService } from "../wizard-step.service";

@Component({
  selector: "wizard-step",
  templateUrl: "./wizard-step.component.html",
  providers: [WizardStepService],
})
export class WizardStepComponent {
  private labelContent = "";

  @Input()
  set label(val: string) {
    if (this.labelContent !== val) {
      this.labelContent = val;
      this.label$.next(val);
    }
  }

  get label() {
    return this.labelContent;
  }

  @Input()
  isActive = false;

  @Input()
  set validationForms(value: { [id: string]: UntypedFormGroup }) {
    if (value !== this.validationForms$.value) {
      this.validationForms$.next(value);
    }
  }

  get validationForms() {
    return this.validationForms$.value;
  }

  get isEveryFormValid() {
    return Object.values(this.validationForms).every((f) => f.valid);
  }

  label$ = new BehaviorSubject<string>("");
  validationForms$ = new BehaviorSubject<{ [id: string]: UntypedFormGroup }>({});

  @ContentChildren("wizardContent", { descendants: true })
  children = new QueryList<any>();

  constructor(public wizardStepService: WizardStepService) {}
}
