import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ClipboardService } from "../../../../../incert-core/src/lib/services/clipboard/clipboard.service";
import { Subscription } from "rxjs";
import { ClipboardDisplayOptions } from "../../../../../incert-core/src/lib/services/clipboard/clipboard.interface";

@Component({
  selector: "clipboard-outlet",
  templateUrl: "./clipboard-outlet.component.html",
  styleUrls: ["./clipboard-outlet.component.css"],
})
export class ClipboardOutletComponent implements OnInit, OnDestroy {
  @Input() alwaysOnTop = false;

  public outletConfig: ClipboardDisplayOptions;

  public subscription: Subscription;

  constructor(private clipboardService: ClipboardService) {
    this.subscription = this.clipboardService.notifyOutlet$.subscribe(
      (outlet) => {
        this.outletConfig = outlet;

        setTimeout(() => {
          this.outletConfig = null;
        }, outlet.duration ?? 5000);
      },
    );
  }

  ngOnInit(): void {}

  public acceptImport() {
    if (!this.outletConfig.disabledClick) {
      this.clipboardService.importAccepted$.next(true);
      this.outletConfig = null;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
