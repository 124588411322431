import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
} from "@angular/core";
import { concatMap, Subject, Subscription } from "rxjs";
import { MenuItem } from "primeng/api";
import { ConfirmService, LocalStorageDataPersistor } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";
import { WizardStepComponent } from "./wizard-step/wizard-step.component";
import {
  markAsDirty,
  MultiLanguageFormService,
  WizardStepContext,
} from "@incert/incert-gui";
import { Router } from "@angular/router";
import { debounceTime } from "rxjs/operators";

export class WizardSubmitEvent {
  steps: WizardStepComponent[];
  reset: () => Promise<void>;
}

interface WizardConfig {
  activeIndex?: number;
  highestVisitedIndex?: number;
  enableAllLanguages?: boolean;
}

@Component({
  selector: "wizard",
  templateUrl: "./wizard.component.html",
  providers: [MultiLanguageFormService],
})
export class WizardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output()
  submit = new EventEmitter<WizardSubmitEvent>();
  @Input()
  formPersistorKey: string;
  @Input()
  isEdit = false;

  @Input()
  goBackRoute = "";
  @Input()
  goBackTabName = "";

  @Input()
  displayLanguageSwitch = false;

  @ContentChildren(WizardStepComponent, { descendants: true })
  wizardSteps = new QueryList<WizardStepComponent>();
  activeIndex = 0;
  wizardStepArray: WizardStepComponent[];
  stepsItems: MenuItem[] = [];

  visibleStepComponents: any[] = [];

  private subscriptions: Subscription[] = [];

  private prevActiveIndex = 0;
  private highestVisitedIndex = 0;

  //caching variables to save data from local storage
  private initialConfig: WizardConfig = {};
  private config = new Subject<WizardConfig>();

  private _touched = false;

  private readonly defaultContext = new WizardStepContext();

  private formSubscriptions = new Subscription();

  get touched(): boolean {
    return this._touched;
  }

  constructor(
    private confirmService: ConfirmService,
    private i18n: I18nService,
    private router: Router,
    private dataPersistor: LocalStorageDataPersistor,
    public multiLanguageFormService: MultiLanguageFormService,
  ) {
    this.multiLanguageFormService.enableAllLanguages = true;
  }

  get context() {
    if (!this.wizardStepArray) {
      return this.defaultContext;
    }
    return (
      this.wizardStepArray[this.activeIndex]?.wizardStepService?.context ||
      this.defaultContext
    );
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      this.config
        .pipe(
          debounceTime(500),
          concatMap((v) =>
            this.dataPersistor.save(this.formPersistorKey + "_config", v),
          ),
        )
        .subscribe(),
    );

    //load date from persistor here because local storage gets overwritten in ngAfterViewInit()
    if (this.formPersistorKey && !this.isEdit) {
      this.initialConfig = (await this.dataPersistor.load(
        this.formPersistorKey + "_config",
      )) as WizardConfig;
    }
  }

  async ngAfterViewInit(): Promise<void> {
    await this.i18n.load("core/wizard");
    this.wizardStepArray = this.wizardSteps.toArray();
    if (this.formPersistorKey && !this.isEdit) {
      this.stepsItems = this.wizardStepArray.map((value) => {
        return {
          label: value.label,
          disabled: true,
        };
      });
    } else {
      this.stepsItems = this.wizardStepArray.map((value) => {
        return {
          label: value.label,
          disabled: false,
        };
      });
    }
    this.wizardStepArray[this.activeIndex].isActive = true;
    this.stepsItems[this.activeIndex].disabled = false;

    for (let i = 0; i < this.wizardStepArray.length; i++) {
      this.subscriptions.push(
        this.wizardStepArray[i].label$.subscribe((label) => {
          this.stepsItems[i].label = label;
          this.stepsItems = [...this.stepsItems];
        }),
      );
    }

    if (this.formPersistorKey && !this.isEdit) {
      let loadFromPersistor = false;

      //check if data is available in local storage
      if (await this.dataPersistor.has(this.formPersistorKey)) {
        loadFromPersistor = await this.confirmService.confirmInfo(
          this.i18n.instant("core.wizard.restoreData.content"),
          this.i18n.instant("core.wizard.restoreData.header"),
        );
        if (!loadFromPersistor) {
          await this.dataPersistor.remove(this.formPersistorKey);
        }
        this._touched = loadFromPersistor;
      }

      if (loadFromPersistor) {
        await this.loadFromPersistor();
      }
    }

    this.subscribeAllForms();

    for (const step of this.wizardStepArray) {
      this.subscriptions.push(
        step.validationForms$.subscribe(() => this.subscribeAllForms()),
      );
    }

    this.onActiveIndexChange();
    this.enableFollowingSteps();

    if (this.formPersistorKey && !this.isEdit) {
      this.subscriptions.push(
        this.multiLanguageFormService.enableAllLanguages$.subscribe(() =>
          this.storeConfig(),
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }

  /**
   * sets the forms values, the activeIndex and the highestVisitedIndex to the values fetched from local storage.
   * also checks if index variables are invalid (in case they were modified in local storage by the user)
   */
  private async loadFromPersistor(): Promise<void> {
    const data = await this.dataPersistor.load(this.formPersistorKey);
    for (const step of this.wizardSteps) {
      for (const id in step.validationForms) {
        if (id in data) {
          step.validationForms[id].patchValue(data[id]);
        }
      }
    }

    for (const step of this.wizardSteps) {
      for (const id of Object.keys(step.validationForms)) {
        for (const key in step.validationForms[id].controls) {
          if (step.validationForms[id].controls[key].status == "INVALID") {
            markAsDirty(step.validationForms[id].controls[key]);
          }
        }
      }
    }

    let firstInvalidIndex: number = this.wizardStepArray.findIndex(
      (step) => !step.isEveryFormValid,
    );

    if (firstInvalidIndex === -1) {
      firstInvalidIndex = this.wizardStepArray.length - 1;
    }

    let lsActiveIndex;
    if (typeof this.initialConfig?.activeIndex === "number") {
      lsActiveIndex = this.initialConfig.activeIndex;
    }

    let lsHighestVisitedIndex;
    if (typeof this.initialConfig?.highestVisitedIndex === "number") {
      lsHighestVisitedIndex = this.initialConfig.highestVisitedIndex;
    }

    if (typeof this.initialConfig?.enableAllLanguages === "boolean") {
      this.multiLanguageFormService.enableAllLanguages =
        this.initialConfig.enableAllLanguages;
    }

    if (isNaN(lsActiveIndex) || lsActiveIndex > firstInvalidIndex) {
      lsActiveIndex = firstInvalidIndex;
    }

    if (
      isNaN(lsHighestVisitedIndex) ||
      lsHighestVisitedIndex >= this.wizardStepArray.length
    ) {
      lsHighestVisitedIndex = this.wizardStepArray.length - 1;
    }

    this.activeIndex = lsActiveIndex;
    this.highestVisitedIndex = lsHighestVisitedIndex;
  }

  private async storeInPersistor() {
    const data = {};
    for (const step of this.wizardSteps) {
      for (const key in step.validationForms) {
        data[key] = step.validationForms[key].getRawValue();
      }
    }
    await this.dataPersistor.save(this.formPersistorKey, data);
  }

  /**
   * method is called everytime the active index changes - deactivates the previous active step,
   * sets the active status of the wizard steps as well as the link in the steps component
   */
  onActiveIndexChange() {
    //deactivate previous active step
    this.wizardStepArray[this.prevActiveIndex].isActive = false;

    //enable new active step component, enable link of step item
    this.wizardStepArray[this.activeIndex].isActive = true;
    this.stepsItems[this.activeIndex].disabled = false;

    //check if new active index is higher than highestVisitedIndex
    if (this.highestVisitedIndex <= this.activeIndex) {
      this.highestVisitedIndex = this.activeIndex;
    }

    this.prevActiveIndex = this.activeIndex;

    //store activeIndex and highestVisitedIndex in persistor
    if (this.formPersistorKey && !this.isEdit) {
      this.storeConfig();
    }

    this.visibleStepComponents = this.wizardStepArray
      .slice(0, this.highestVisitedIndex + 1)
      .map((value) => value.children.first);
  }

  /**
   * sets the active index to the index of the wizard step containing the provided component
   * if no such step exists (e.g. the findIndex function returns -1), active index is set to 0
   *
   * @param component - the component the target wizard step has to contain
   */
  navigateTo(component: any): void {
    let newActiveIndex: number = this.wizardStepArray.findIndex(
      (value) => value.children.first === component,
    );
    if (newActiveIndex === -1) newActiveIndex = 0;

    let firstInvalidIndex: number = this.wizardStepArray.findIndex(
      (value) => !value.isEveryFormValid,
    );
    if (firstInvalidIndex === -1)
      firstInvalidIndex = this.wizardStepArray.length - 1;

    if (this.formPersistorKey && !this.isEdit) {
      if (
        newActiveIndex <= firstInvalidIndex &&
        newActiveIndex <= this.highestVisitedIndex
      ) {
        this.activeIndex = newActiveIndex;
      } else if (newActiveIndex <= this.highestVisitedIndex) {
        this.activeIndex = firstInvalidIndex;
      } else {
        this.activeIndex = this.highestVisitedIndex;
      }
    } else {
      this.activeIndex = newActiveIndex;
    }

    this.onActiveIndexChange();
  }

  /**
   * helper method to disable all following steps if the current form becomes invalid
   */
  disableFollowingSteps(): void {
    this.stepsItems.forEach((step, index) => {
      step.disabled = index > this.activeIndex;
    });
  }

  /**
   * helper method to enable all the following, already visited steps once form on curren view becomes valid again
   * if multiple forms were invalid, only the steps until the first invalid step will be set to enabled
   */
  enableFollowingSteps(): void {
    //check whether wizardStepArray is initialized to prevent app from breaking on initialisation
    if (this.wizardStepArray) {
      let firstInvalidIndex: number = this.wizardStepArray.findIndex(
        (step) => !step.isEveryFormValid,
      );
      if (firstInvalidIndex === -1)
        firstInvalidIndex = this.highestVisitedIndex;

      this.stepsItems.forEach((step, index) => {
        if (index <= firstInvalidIndex) step.disabled = false;
      });

      this.stepsItems = [...this.stepsItems];
    }
  }

  onClickPrev(): void {
    this.activeIndex--;
    this.onActiveIndexChange();
  }

  onClickNext(): void {
    this.activeIndex++;
    this.onActiveIndexChange();
  }

  onClickSubmit(): void {
    this.submit.emit({
      steps: this.wizardStepArray,
      reset: async () => {
        if (this.formPersistorKey) {
          await this.dataPersistor.remove(this.formPersistorKey);
        }
      },
    });
  }

  /*
  Go Back to initial route
   */
  public async goBack() {
    const confirmation = await this.confirmService.confirmInfo(
      this.goBackTabName,
      this.i18n.instant("core.action.abort"),
    );
    if (confirmation) {
      await this.router.navigate([
        this.goBackRoute,
        { tab_new: false, tab_name: this.goBackTabName },
      ]);
    }
  }

  private storeConfig() {
    this.config.next({
      activeIndex: this.activeIndex,
      highestVisitedIndex: this.highestVisitedIndex,
      enableAllLanguages: this.multiLanguageFormService.enableAllLanguages,
    });
  }

  private subscribeAllForms() {
    const formSubscriptions = new Subscription();

    for (const step of this.wizardSteps) {
      if (!step.validationForms) {
        continue;
      }

      for (const key in step.validationForms) {
        const form = step.validationForms[key];

        formSubscriptions.add(
          form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            if (this.formPersistorKey && !this.isEdit) {
              if (step.isEveryFormValid) {
                this.enableFollowingSteps();
              } else {
                this.disableFollowingSteps();
              }
            }
            if (!this._touched && form.touched) {
              this._touched = true;
            }
          }),
        );

        formSubscriptions.add(
          form.valueChanges
            .pipe(
              debounceTime(500),
              concatMap(() => this.storeInPersistor()),
            )
            .subscribe(),
        );
      }
    }

    this.formSubscriptions.unsubscribe();
    this.formSubscriptions = formSubscriptions;
  }
}
