<div class="p-m-5">
  <p-tabView>
    <p-tabPanel header="Group/Level basiert">
      <data-table [config]="this.dtConfig"></data-table>
    </p-tabPanel>
    <p-tabPanel header="Interface basiert">
      <data-table [config]="this.dtConfigLegacy"></data-table>
    </p-tabPanel>
  </p-tabView>
</div>



