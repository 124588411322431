import { UntypedFormBuilder } from "@angular/forms";
import { FormLanguage } from "./form-language.model";
import { Inject, Injectable } from "@angular/core";
import { FORM_LANGUAGES } from "./language-form.token";
import { LanguageFormGroup } from "./language-form-group.class";

export interface ControlsConfig {
  [key: string]: any;
}

@Injectable({
  providedIn: "root",
})
export class LanguageFormBuilder {
  public constructor(
    private fb: UntypedFormBuilder,
    @Inject(FORM_LANGUAGES) public languages: FormLanguage[],
  ) {}

  public get defaultLanguage(): FormLanguage {
    return this.languages[0];
  }

  public group(controlsConfigCreator: () => ControlsConfig): LanguageFormGroup {
    const formGroup = new LanguageFormGroup({});

    for (const language of this.languages) {
      const control = this.fb.group(controlsConfigCreator());
      formGroup.setControl(language.id, control);
    }
    return formGroup;
  }
}
