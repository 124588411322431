import { Injectable, Pipe, PipeTransform, Inject } from "@angular/core";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

@Injectable({
  providedIn: "root",
})
@Pipe({
  name: "numberFormat",
})
export class NumberFormatPipe implements PipeTransform {
  constructor(@Inject(INCERT_GUI_I18N) private i18n: IncertGUII18n) {}

  transform(value: any, digitsInfo?: string): string {
    if (typeof value !== "string" && typeof value !== "number") {
      return "";
    }

    const number = typeof value === "string" ? parseFloat(value) : 0;
    if (isNaN(number)) {
      return "";
    }

    let options;
    if (digitsInfo) {
      const match = digitsInfo.match(/^(\d+).(\d+)-(\d+)$/);
      if (match) {
        options = {
          minimumIntegerDigits: Number(match[1]),
          minimumFractionDigits: Number(match[2]),
          maximumFractionDigits: Number(match[3]),
        };
      }
    }
    return number.toLocaleString(this.i18n.locale, options);
  }
}
