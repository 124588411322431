import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class LoadingService {
  private _running = 0;
  private _loading$ = new BehaviorSubject(false);
  public loading$ = this._loading$.asObservable();

  public async load<T>(fn: () => Promise<T>) {
    this._loading$.next(true);
    this._running++;

    try {
      return await fn();
    } finally {
      this.decrease();
    }
  }
  public decrease() {
    this._running--;
    if (this._running <= 0) {
      this._loading$.next(false);
    }
  }

  public get isLoading() {
    return this._running;
  }
}
