import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { APIService } from "./api.service";

export interface Logic {
  [date: string]: string;
}

@Injectable({
  providedIn: "root",
})
export class ConstantsManager {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getKeyValueConstants(): Promise<Array<Logic>> {
    return this.httpService.get<Array<Logic>>(
      this.settingsService.baseUrl + "const/keyvalue/logic",
    );
  }

  public getKeyValueConcats(): Promise<Array<Logic>> {
    return this.httpService.get<Array<Logic>>(
      this.settingsService.baseUrl + "const/keyvalue/concat",
    );
  }
}
