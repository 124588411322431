import { Injectable } from "@angular/core";
import { HttpService } from "../index";
import { APIService } from "../api.service";
import { Country } from "./country";

@Injectable({ providedIn: "root" })
export class CountryService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getCountries(): Promise<Array<Country>> {
    return this.httpService.get<Array<Country>>(
      this.settingsService.baseUrl + "country",
    );
  }
}
