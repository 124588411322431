import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertOutletComponent } from "./alert-outlet.component";
import { DialogModule } from "primeng/dialog";

@NgModule({
  imports: [CommonModule, DialogModule],
  declarations: [AlertOutletComponent],
  exports: [AlertOutletComponent],
})
export class AlertOutletModule {}
