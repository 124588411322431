import { HttpClient } from "@angular/common/http";
import { mergeRqlQuery, parseRqlQuery, RQLQuery } from "../../rql";
import { Injectable } from "@angular/core";

interface RqlResponse<T> {
  total: number;
  limit: number;
  data: T;
}

@Injectable({
  providedIn: "root",
})
export class RqlHttpAccess {
  private _baseHref = "";
  private _queryKey = "q";

  public constructor(protected httpClient: HttpClient) {}

  public async get<T, F>(
    path: string,
    query: RQLQuery<F>,
    merge?: RQLQuery<any>,
  ): Promise<RqlResponse<T>> {
    query = merge ? mergeRqlQuery(query, merge) : query;
    const url =
      this._baseHref +
      path +
      (path.indexOf("?") === -1 ? "?" : "&") +
      this._queryKey +
      "=" +
      parseRqlQuery(query);
    return new Promise<RqlResponse<T>>((resolve) => {
      this.httpClient.get(url, { observe: "response" }).subscribe((res) => {
        const total = res.headers.has("Pagination-Count")
          ? Number(res.headers.get("Pagination-Count"))
          : null;
        const limit = res.headers.has("Pagination-Limit")
          ? Number(res.headers.get("Pagination-Limit"))
          : null;
        const data = <T>res.body;
        resolve({
          data: data,
          limit: limit,
          total: total,
        });
      });
    });
  }

  set baseHref(value: string) {
    this._baseHref = value;
  }

  set queryKey(value: string) {}
}
