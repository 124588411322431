import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  ControlValueAccessor,
  Validator,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Icon } from "../icon/icon";
import { ConfirmService, NotificationService } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "inc-file-upload",
  templateUrl: "./inc-file-upload.component.html",
  styleUrls: ["./inc-file-upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncFileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IncFileUploadComponent),
      multi: true,
    },
  ],
})
export class IncFileUploadComponent implements ControlValueAccessor, Validator {
  @ViewChild("preview", { read: ElementRef })
  private set preview(preview: ElementRef) {
    if (preview) {
      this.previewChange.emit(preview);
    }
  }

  @Output()
  public previewChange = new EventEmitter<ElementRef>();
  @Output()
  public onDelete = new EventEmitter<any>();

  private _onChange: (_: any) => void;
  private _onTouched: (_: any) => void;
  private _selectedFiles: File[] = [];
  public selectedFileInfo: {
    isVideo: boolean;
    isImage: boolean;
    objectUrl: string;
  }[] = [];

  get selectedFiles(): File[] {
    return this._selectedFiles;
  }

  private _disabled = false;
  private _touched = false;
  public fc = new UntypedFormControl([]);
  //events for every Icon given (except delete) - file: File, type: string (icon.type)
  @Output()
  action = new EventEmitter<any>();

  //icons to be shown on selected images - each icon has an event
  @Input()
  icons: Array<Icon>;

  //icon for image delete button, has to be given separately from other icons to handle file deletion
  @Input()
  showDelete: boolean;

  //accepted filetype for upload
  @Input()
  fileType = "image/*";

  @Input()
  whitelist: string[];

  //Max Number of Images to be uploaded
  @Input()
  maxNumberOfFiles = 100;

  @Input()
  resetStyles: boolean;

  @Input()
  allowMultiple = true;

  @Input()
  maxSizeKB = 0;

  unrecognizedFileTypes: string[] = [".pem"];

  constructor(
    private confirmService: ConfirmService,
    private i18n: I18nService,
    public sanitizer: DomSanitizer,
    private ns: NotificationService,
  ) {}

  writeValue(files: any): void {
    this._selectedFiles = [];
    this.selectedFileInfo = [];
    if (Array.isArray(files)) {
      for (const file of files) {
        if (file instanceof File && this.checkFile(file, this.fileType)) {
          if (this.maxSizeKB > 0 ? file.size < this.maxSizeKB : true) {
            this._selectedFiles.push(file);
            this.selectedFileInfo.push({
              objectUrl: URL.createObjectURL(file),
              isImage: this.checkFile(file, "image/*"),
              isVideo: this.checkFile(file, "video/*"),
            });
          } else {
            this.ns.notifyError(
              this.i18n.instant("core.errorMsg.fileTooLarge") +
                " " +
                this.maxSizeKB / 1000000 +
                "MB",
              this.i18n.instant("core.notification.saveError"),
            );
          }
        }
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  private checkFile(file: File, type: string) {
    if (type === "whitelist" && this.whitelist.length > 0) {
      for (const wType of this.whitelist) {
        if (this.checkFile(file, wType)) {
          return true;
        }
      }
    }
    if (type.includes("/*")) {
      type = type.replace("/*", "");
      return file.type.includes(type);
    }
    if (this.unrecognizedFileTypes.includes(this.fileType)) {
      return "." + file.name.split(".").pop() === this.fileType;
    }
    return this.fileType === "whitelist"
      ? file.type === type
      : file.type === this.fileType;
  }

  public showSelectedFiles(event, form) {
    this.markAsTouched();
    this.writeValue([...this._selectedFiles, ...event.currentFiles]);
    this.change();
    form.clear();
  }

  async deleteFile(i: number) {
    const confirmContent =
      this.fileType === "image/*"
        ? this.i18n.instant("core.media.image.delete.confirmDetails")
        : this.i18n.instant("core.media.file.delete.confirmDetails");
    const confirmHeader =
      this.fileType === "image/*"
        ? this.i18n.instant("core.media.image.delete.confirm")
        : this.i18n.instant("core.media.file.delete.confirm");
    const confirm = await this.confirmService.confirmWarning(
      confirmContent,
      confirmHeader,
    );
    if (confirm) {
      this._selectedFiles.splice(i, 1);
      this.writeValue(this._selectedFiles);
      this.change();
      this.onDelete.emit(this._selectedFiles);
    }
  }

  /**
   * the upload component doesn't concern itself with handling events (except delete)
   * sends file with type (type of given icon) to parent
   * @param file
   * @param type
   */
  public actionFile(file: File, type: string) {
    this.action.emit({ file, type });
    this.change();
  }

  private change() {
    if (this._onChange) {
      this._onChange(this._selectedFiles);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
  }

  markAsTouched() {
    if (!this._touched) {
      if (this._onTouched) this._onTouched(this._touched);
      this._touched = true;
    }
  }
}
