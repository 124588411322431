import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
})
export class IncertGUIModule {
  static forRoot(): ModuleWithProviders<IncertGUIModule> {
    return {
      ngModule: IncertGUIModule,
      providers: [],
    };
  }
}
