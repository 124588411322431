import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";
import { ButtonType } from "@incert/incert-core";
import { IconType } from "@incert/incert-core";

//Button with predefined IconType. ONLY USE FOR USING ICON TYPE!!y
@Component({
  selector: "icon-button",
  templateUrl: "./icon-button.component.html",
  styleUrls: ["./icon-button.component.scss"],
})
export class IconButtonComponent implements OnInit {
  @Input()
  public set iconType(value: keyof typeof IconType) {
    if (this._iconType !== value) {
      this._iconType = value;
      this.updateIconClass();
    }
  }

  public get iconType() {
    return this._iconType;
  }

  @Input()
  public set buttonType(value: keyof typeof ButtonType) {
    if (this._buttonType !== value) {
      this._buttonType = value;
      this.updateIconClass();
    }
  }

  public get buttonType() {
    return this._buttonType;
  }

  @Input() public label: string;
  @Input() public displayAsLink = false;
  @Input() public fullWidth = false;
  @Input() public iconOnly = false; //displayAsButton for ICON ONLY BUTTONS
  @Input() public displayIconButtonAsButton = false;
  @Input() public autofocus = false;
  @Input() public tooltip: string;
  @Input() public tooltipPosition = "bottom";
  @Input() public displayAsLinkButtonHeight = false; //display same height as buttons would have, so they're always in the same line
  @Input() public buttonId = "";

  @Input()
  public set disabled(value: boolean) {
    //disabled
    if (value) {
      this.el.nativeElement.style["pointer-events"] = "none";
    } else {
      this.el.nativeElement.style["pointer-events"] = "auto";
    }
    this._disabled = value;
  }

  @Input("class") classList = ""; //additional classes

  @HostBinding("class") get class() {
    if (this.fullWidth) {
      return this.classList + " full-width";
    }
  }

  public get disabled() {
    return this._disabled;
  }

  private _disabled: boolean;
  private _iconType?: keyof typeof IconType;
  private _buttonType: keyof typeof ButtonType = "primary";

  public iconClass: string = null;
  public buttonClass = "";
  public iconPosition = "left";

  constructor(private el: ElementRef) {}

  private updateIconClass() {
    if (
      this.iconType &&
      (this.buttonType === "secondary" || this.buttonType === "primary")
    ) {
      this.iconClass = IconType[this.iconType];
    } else {
      this.iconClass = "";
    }
  }

  ngOnInit() {
    if (IconType[this.iconType] === IconType.goto) {
      this.iconPosition = "right";
    }
    switch (ButtonType[this.buttonType]) {
      case ButtonType.danger:
        this.buttonClass = "p-button-danger";
        break;
      case ButtonType.info:
        this.buttonClass = "p-button-info";
        break;
      case ButtonType.secondary:
        this.buttonClass = "p-button-secondary";
        break;
      case ButtonType.warning:
        this.buttonClass = "p-button-warning";
        break;
      case ButtonType.success:
        this.buttonClass = "p-button-success";
        break;
    }
    if (this.displayAsLink) {
      this.buttonClass += " reset-button-to-link ";
    }
    if (this.iconOnly) {
      this.buttonClass += " p-button-icon-only ";
    }
    if (
      this.iconOnly &&
      !this.displayAsLink &&
      this.displayIconButtonAsButton
    ) {
      this.buttonClass += " display-as-button ";
    }
    if (this.fullWidth) {
      this.buttonClass += " full-width ";
    }
    if (this.displayAsLinkButtonHeight) {
      this.buttonClass += " display-as-link-button-height ";
    }
  }
}
