import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SerializerService {
  deserialize(value) {
    if (typeof value !== "object" || value === null) {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map((i) => this.deserialize(i));
    }

    if (value.__type__ === "Date") {
      return new Date(value.value);
    }

    const result = {};
    for (const key in value) {
      result[key] = this.deserialize(value[key]);
    }
    return result;
  }

  serialize(value) {
    if (typeof value !== "object" || value === null) {
      return value;
    }

    const isFile = (file) =>
      typeof file === "object" &&
      file !== null &&
      typeof file.objectURL !== "undefined";

    if (Array.isArray(value)) {
      return value.map((i) => this.serialize(i));
    }

    if (value instanceof Date) {
      return {
        __type__: "Date",
        value: value.toISOString(),
      };
    }

    if (isFile(value)) {
      return {};
    }

    const result = {};
    for (const key in value) {
      result[key] = this.serialize(value[key]);
    }
    return result;
  }
}
