<div class="quill-container {{quillLoaded ? 'show' : ''}}" style="{{height}}">
    <div *ngIf="i18nLoaded" #toolbar>
        <select class="ql-size">
            <option value="small">
                {{'gms.frontend.quillOptions.size.small'|i18n}}
            </option>
            <option selected>
                {{'gms.frontend.quillOptions.size.normal'|i18n}}
            </option>
            <option value="large">
                {{'gms.frontend.quillOptions.size.large'|i18n}}
            </option>
            <option value="huge">
                {{'gms.frontend.quillOptions.size.huge'|i18n}}
            </option>
        </select>
        <select class="ql-header">
            <option selected>
                {{'gms.frontend.quillOptions.header.normal'|i18n}}
            </option>
            <option value="1">
                {{'gms.frontend.quillOptions.header.heading1'|i18n}}
            </option>
            <option value="2">
                {{'gms.frontend.quillOptions.header.heading2'|i18n}}
            </option>
            <option value="3">
                {{'gms.frontend.quillOptions.header.heading3'|i18n}}
            </option>
            <option value="4">
                {{'gms.frontend.quillOptions.header.heading4'|i18n}}
            </option>
            <option value="5">
                {{'gms.frontend.quillOptions.header.heading5'|i18n}}
            </option>
            <option value="6">
                {{'gms.frontend.quillOptions.header.heading6'|i18n}}
            </option>
        </select>
        <button type="button" class="ql-bold"></button>
        <button type="button" class="ql-italic"></button>
        <button type="button" class="ql-underline"></button>
        <button type="button" class="ql-strike"></button>
        <button type="button" class="ql-script" value="sub"></button>
        <button type="button" class="ql-script" value="super"></button>
        <button type="button" class="ql-list" value="ordered"></button>
        <button type="button" class="ql-list" value="bullet"></button>
        <button type="button" class="ql-color"></button>
        <button type="button" class="ql-background"></button>
        <button type="button" class="ql-clean"></button>
        <button type="button" class="ql-align" value=""></button>
        <button type="button" class="ql-align" value="center"></button>
        <button type="button" class="ql-align" value="right"></button>
        <button type="button" class="ql-align" value="justify"></button>
        <button type="button" class="ql-link"></button>
        <button type="button" class="custom-button add-new-table-btn" (click)="op.toggle($event)">
          <incert-icon iconType="table"></incert-icon>
        </button>
        <button type="button" class="custom-button view-source-btn" (click)="viewSource()">
          <incert-icon iconType="coding"></incert-icon>
        </button>
    </div>
    <div #editor></div>

</div>

<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="select-table-container">
            <label>Tabelle einfügen {{addTableState.row + 1}} x {{addTableState.column + 1}}</label>
            <div *ngFor="let row of addTableRows;" class="select-table-row">
                <div class="select-table-item"
                     *ngFor="let column of addTableColumns"
                     (click)="handleClickAddTable(row, column)"
                     (mouseover)="updateAddTableState(row, column)"
                     [class]="{active: row <= addTableState.row && column <= addTableState.column ? 'active' : ''}"
                >
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
