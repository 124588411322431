import { Component } from "@angular/core";
import { AccountSystem } from "../../../../core/models/account-system.interface";

@Component({
  selector: "incert-internal-tools-system-value-import",
  templateUrl: "./system-value-import.component.html",
  styleUrls: ["./system-value-import.component.css"],
})
export class SystemValueImportComponent {
  public accountSystems: Array<AccountSystem>;

  constructor() {}
}
