import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import {
  DataTableFilterComponent,
  DataSourceFilter,
  FILTER_CONFIG,
  FilterConfig,
} from "../../data-table";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { I18nService } from "@incert/i18n";
import { DataTableFilterService } from "../data-table-filter-service";

interface Config extends FilterConfig {
  type?: "in" | "eq";
  options: SelectItem[] | Promise<SelectItem[]>;
  filter?: boolean;
}

@Component({
  selector: "select-filter",
  templateUrl: "./select-filter.component.html",
  styleUrls: ["./select-filter.component.scss"],
})
export class SelectFilterComponent implements DataTableFilterComponent, OnInit {
  config: Config = {
    header: this.i18n.instant("core.all"),
    defaultValue: null,
    type: "eq",
    options: [],
    filter: false,
  };
  change = new EventEmitter<DataSourceFilter>();
  form: UntypedFormGroup;
  options: SelectItem[] = [];
  floatingLabel = false;
  prevValue = null;

  constructor(
    private fb: UntypedFormBuilder,
    private i18n: I18nService,
    @Inject(FILTER_CONFIG) config: Config,
    private dataTableFilterService: DataTableFilterService,
  ) {
    this.config = Object.assign(this.config, config);
    this.form = this.fb.group({ filter: [this.config.defaultValue || null] });
  }

  async ngOnInit() {
    this.options = await this.config.options;
  }

  //filter updates when called; method can get called, sending value as parameter
  async getFilter() {
    if (this.config.method) {
      await this.config.method(this.form.get("filter").value);
      return null;
    }
    return this.buildFilter(this.form.get("filter").value);
  }

  setValue(val) {
    this.form.get("filter").patchValue(val);
  }

  setFloatingLabel(val) {
    this.floatingLabel = val;
  }

  reset(): void {
    this.form.get("filter").reset();
  }

  buildFilter(v): DataSourceFilter | null {
    if (v === null || (Array.isArray(v) && v.length === 0)) {
      return this.dataTableFilterService.getDataTableFilter(this.config, null);
    }
    return this.dataTableFilterService.getDataTableFilter(this.config, v);
  }

  filterExplicit() {
    if (this.prevValue !== this.form.get("filter").value) {
      this.change.emit(this.buildFilter(this.form.get("filter").value));
    }
    this.prevValue = this.form.get("filter").value;
  }
}
