import { ArithmeticStrategy } from "../arithmetic-strategy";

export class SumOperation implements ArithmeticStrategy {
  calculate(data, property, ...args): number {
    let sum = 0;

    try {
      data.forEach((dataItem) => (sum += +dataItem[property]));
    } catch (e) {
      console.error("unable to aggregate data-table property", e);
    }

    return sum;
  }
}
