import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputWithIconComponent } from "./input-with-icon.component";
import { IconButtonModule, IconModule } from "../..";

@NgModule({
  imports: [CommonModule, IconModule, IconButtonModule],
  declarations: [InputWithIconComponent],
  exports: [InputWithIconComponent],
})
export class InputWithIconModule {}
