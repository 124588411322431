import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "incert-default-actions-container",
  templateUrl: "./default-actions-container.component.html",
  styleUrls: ["./default-actions-container.component.css"],
})
export class DefaultActionsContainerComponent implements OnInit {
  @Input() nextLabel: string;
  @Input() backLabel: string;
  @Output() nextClick = new EventEmitter();
  @Output() backClick = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  handleNextClick() {
    this.nextClick.emit();
  }
  handleBackClick() {
    this.backClick.emit();
  }
}
