export enum IconType {
  drag = "fal fa-arrows-up-down-left-right",
  arrows = "fal fa-arrows-up-down-left-right",
  delete = "fas fa-trash",
  sortUp = "fas fa-sort-up",
  sortDown = "fas fa-sort-down",
  openAccordion = "fal fa-chevron-up",
  closeAccordion = "fal fa-chevron-down",
  close = "fal fa-xmark",
  print = "fal fa-print",
  settings = "fal fa-gear",
  crossselling = "fas fa-share-all",
  buy = "fas fa-circle-dollar",
  edit = "fas fa-pen",
  check = "far fa-check",
  help = "fas fa-circle-question",
  logout = "fal fa-arrow-right-from-bracket",
  euro = "fas fa-euro-sign",
  goto = "fal fa-chevron-right",
  date = "fal fa-calendar-check",
  file = "fal fa-file-lines",
  csvFile = "fas fa-file-csv",
  xlsFile = "fas fa-file-xls",
  pdfFile = "fas fa-file-pdf",
  download = "fal fa-arrow-down-to-line",
  search = "fal fa-magnifying-glass",
  message = "fas fa-envelope",
  mail = "fal fa-envelope",
  email = "fal fa-at",
  headset = "fal fa-headset",
  support = "fal fa-circle-play",
  teamviewer = "fal fa-handshake-angle",
  shoppingcart = "fal fa-cart-shopping",
  shoppingbag = "fal fa-bags-shopping",
  plus = "fal fa-plus",
  minus = "fal fa-minus",
  reload = "fal fa-rotate-right",
  globalfilter = "fal fa-rotate-right",
  watching = "fas fa-eye",
  filter = "fas fa-filter",
  community = "fas fa-users",
  memo = "fas fa-book",
  history = "fas fa-clock-rotate-left",
  more = "fas fa-chevron-right",
  quote = "fas fa-quote-right",
  time = "fal fa-clock",
  monitor = "fal fa-desktop",
  upload = "fal fa-upload",
  import = "far fa-arrow-up-to-line",
  copy = "far fa-clone",
  searchMinus = "fal fa-magnifying-glass-minus",
  searchPlus = "fal fa-magnifying-glass-plus",
  action = "fal fa-clone",
  coding = "fas fa-code",
  asterisk = "fas fa-asterisk",
  addUser = "fas fa-user-plus",
  userTag = "fas fa-user-tag",
  notification = "fal fa-bell",
  accountSystem = "fas fa-users-gear",
  logFile = "fas fa-file-waveform",
  hidePassword = "far fa-eye-slash",
  branch = "fas fa-code-branch",
  save = "fas fa-floppy-disk",
  password = "fal fa-key",
  pinned = "fas fa-thumbtack",
  unpinned = "fal fa-thumbtack",
  move = "fas fa-circle-right",
  contextMenu = "fas fa-ellipsis",
  chevronLeft = "fal fa-chevron-left",
  chevronRight = "fal fa-chevron-right",
  success = "fas fa-check",
  successCircle = "fas fa-circle-check",
  error = "fas fa-xmark",
  errorCircle = "fas fa-circle-xmark",
  warning = "fas fa-exclamation",
  warningTriangle = "fas fa-triangle-exclamation",
  warningCircle = "fas fa-circle-exclamation",
  info = "fas fa-circle-info",
  qrcode = "fas fa-qrcode",
  images = "fal fa-images",
  ai = "fas fa-robot",
  trendUp = "fas fa-arrow-up-long",
  trendDown = "fas fa-arrow-down-long",
  externalLink = "far fa-arrow-up-right-from-square",
  cancel = "fal fa-xmark",
  play = "fas fa-play",
  stop = "fas fa-stop",
  invoice = "far fa-file-invoice-dollar",
  clipboard = "far fa-clipboard",
  sendMessage = "fal fa-paper-plane-top",
  chatBot = "fas fa-message-lines",
  folder = "far fa-folder",
  folderOpen = "far fa-folder-open",
  list = "fal fa-list",
  stack = "fas fa-layer-group",
  user = "fal fa-user",
  users = "fas fa-users",
  inactive = "fas fa-ban",
  arrowLeft = "fas fa-arrow-left-long",
  shuffle = "fal fa-shuffle",
  dasboard = "fas fa-objects-column",
  robot = "fas fa-robot",
  json = "fas fa-brackets-curly",
  bars = "fal fa-bars",
  table = "fal fa-table",
  language = "fal fa-globe",
  currency = "fal fa-money-bill",
  star = "fas fa-star",
  chart = "fal fa-chart-pie-simple",
  cube = "fal fa-cube",
  workInProgress = "fal fa-person-digging",
  colorPicker = "fas fa-palette",
  facebook = "fab fa-facebook",
  instagram = "fab fa-instagram",
  meta = "fab fa-meta",
  link = "fal fa-link",
}
