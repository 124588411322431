import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { NotificationService } from "@incert/incert-core";
import { Toast } from "primeng/toast";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";

@Component({
  selector: "toast-outlet",
  templateUrl: "./toast-outlet.html",
  providers: [MessageService],
})
export class ToastOutletComponent implements OnInit, OnDestroy {
  public messages: Message[] = [];
  maxMessages = 5;

  @ViewChild(Toast)
  toast: Toast;

  private subscription?: Subscription;

  public constructor(
    private messageService: MessageService,
    private notificationService: NotificationService,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.subscription = this.notificationService.message$.subscribe((v) => {
      if (this.toast) {
        v = { ...v };
        v.detail = this.sanitizer.bypassSecurityTrustHtml(v.detail) as string;

        this.toast.add([v]);

        if (this.toast.messages) {
          let stickyCount = 0;
          for (const m of this.toast.messages) {
            if (m.sticky) {
              stickyCount++;
            }
          }

          this.toast.messages.reduce<number>(
            (summary, element) => (element.sticky ? summary + 1 : summary),
            0,
          );

          if (stickyCount > this.maxMessages) {
            let found = false;
            this.toast.messages = this.toast.messages.filter((v) => {
              if (v.sticky && !found) {
                found = true;
                return false;
              }
              return true;
            });
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
