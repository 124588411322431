import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccessProfileService {
  constructor() {}

  public hasAccessElement(element: any, changeList: any): boolean {
    return this.isMatchElement(element, changeList);
  }

  public hasAccessElementAP(element: any, changeList: any, type: any, ap: any) {
    if (ap) {
      if (this.isMatchElement(element, type)) {
        return !this.isMatchElement(element, changeList);
      } else {
        return this.isMatchElement(element, changeList);
      }
    }
  }

  public isMatchElement(element: any, list: any) {
    if (list) {
      return !!list.find((v) => v.id === element.id);
    }
  }

  public registerChanges(accessElement: any, accessChanges: any) {
    if (accessChanges.find((v) => v.id === accessElement.id)) {
      this.removeElement(accessElement, accessChanges);
    } else {
      this.addElement(accessElement, accessChanges);
    }
  }

  addElement(accessElement: any, accessChanges: any) {
    accessChanges.push(accessElement);
  }

  removeElement(accessElement: any, accessChanges: any) {
    accessChanges.splice(
      accessChanges.indexOf(
        accessChanges.find((v) => v.id === accessElement.id),
      ),
      1,
    );
  }
}
