import { Injectable } from "@angular/core";
import { DataSourceFilter } from "@incert/incert-gui";

@Injectable({ providedIn: "root" })
export class DataTableFilterService {
  public constructor() {}

  public getDataTableFilter(config, value): DataSourceFilter | null {
    if (config.properties) {
      return {
        type: "or",
        filters: config.properties.map((property) => ({
          property,
          type: config.type,
          value: value,
        })),
      };
    } else {
      return {
        type: "or",
        filters: [
          {
            property: config.property,
            externalProperty: config.externalProperty,
            type: config.type,
            value: value,
          },
        ],
      };
    }
  }
}
