import { Component, EventEmitter, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  DataSourceFilter,
  FILTER_CONFIG,
  FilterConfig,
} from "../../data-table";
import { DataTableFilterComponent } from "../../data-table";
import { DataTableFilterService } from "../data-table-filter-service";
import { DateUtil } from "@incert/incert-core";

interface Config extends FilterConfig {
  type?: "in" | "gt" | "lt" | "eq" | "between";
  useUnixTimestamps?: boolean;
}

@Component({
  selector: "date-range-filter",
  templateUrl: "./date-filter.component.html",
  styleUrls: ["./date-filter.component.scss"],
})
export class DateFilterComponent implements DataTableFilterComponent {
  config: Config = {
    header: "",
    defaultValue: [],
    type: "gt",
  };
  change = new EventEmitter<DataSourceFilter>();
  form: UntypedFormGroup;
  yearRangeString: string = "2013:" + (new Date().getFullYear() + 10);
  floatingLabel = false;
  prevValue = null;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(FILTER_CONFIG) config: Config,
    private dataTableFilterService: DataTableFilterService,
  ) {
    this.config = Object.assign(this.config, config);
    this.form = this.fb.group({ filter: [this.config.defaultValue || null] });
    if (this.config.defaultValue.length === 0) this.reset();
  }

  //filter updates when called; method can get called, sending value as parameter
  async getFilter() {
    if (this.config.method) {
      await this.config.method(this.form.get("filter").value);
    }
    return this.buildFilter(this.form.get("filter").value);
  }

  setValue(val) {
    this.form.get("filter").patchValue(val);
  }

  setFloatingLabel(val) {
    this.floatingLabel = val;
  }

  reset(): void {
    this.form.get("filter").reset();
  }

  buildFilter(v): DataSourceFilter | null {
    let value = null;

    const dates = this.unifyDates(v);
    if (dates.length > 0) {
      switch (this.config.type) {
        case "in":
          value = dates.map((d) => new Date(d));
          break;
        case "gt":
          value = DateUtil.stripTime(dates[0]);
          break;
        case "lt":
          value = DateUtil.getEndOfDay(dates[0]);
          break;
        case "eq":
          value = DateUtil.getNoon(dates[0]);
          break;
        case "between":
          value = [
            DateUtil.stripTime(dates[0]),
            DateUtil.getEndOfDay(dates[1] ?? dates[0]),
          ];
          break;
      }
    }

    if (this.config.useUnixTimestamps && value) {
      return this.dataTableFilterService.getDataTableFilter(
        this.config,
        this.convertDatesToUnixTimestamps(value),
      );
    }

    return this.dataTableFilterService.getDataTableFilter(this.config, value);
  }

  public get selectionMode() {
    if (this.config.type === "between") {
      return "range";
    }
    if (this.config.type === "in") {
      return "multiple";
    }
    return "single";
  }

  private unifyDates(date: Date | Date[] | null | undefined): Date[] {
    if (!date) {
      return [];
    }
    if (Array.isArray(date)) {
      date = date.map((d) => (this.isString(d) ? new Date(d) : d));
    } else {
      if (this.isString(date)) {
        date = new Date(date);
      }
    }
    return Array.isArray(date) ? date.filter((d) => d) : [date];
  }

  isString(val: any): boolean {
    return typeof val === "string";
  }

  private convertDatesToUnixTimestamps(dates: Date[]): string[] {
    const res = [];
    for (const date of dates) {
      res.push(date.getTime() / 1000);
    }
    return res;
  }

  filterExplicit() {
    if (this.prevValue !== this.form.get("filter").value) {
      this.change.emit(this.buildFilter(this.form.get("filter").value));
    }
    this.prevValue = this.form.get("filter").value;
  }
}
