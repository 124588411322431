import { DateDataConverter } from "../types/date-data-converter";
import { NumberDataConverter } from "../types/number-data-converter";
import { ObjectOrArrayType } from "../object-or-array-type.type";

export class DataConverter {
  private static typeBackConversion(
    options: any,
    value: any,
    strict: boolean,
  ): any {
    if (value === undefined) {
      return undefined;
    }
    if (options["type"] === Date) {
      return DateDataConverter.encode(value);
    }

    const prototype: any = options["type"].prototype
      ? options["type"].prototype
      : options["type"].__proto__;
    if (prototype.__dataConversionEnabled) {
      return this.convertBack(value, strict);
    }

    if (options.type === Array) {
      if (!options.innerArrayType) {
        console.log(
          "Warning! No Array Type defined for " +
            options.name +
            " (" +
            options.type +
            ")!",
        );
      } else {
        for (let i = 0; i < value.length; i++) {
          value[i] = this.convertBack(value[i], strict);
        }
      }
    }
    return value;
  }

  private static async typeConversion(options: any, value: any): Promise<any> {
    if (options.type === Date || options["isDate"] === true) {
      return DateDataConverter.decode(value);
    }
    if (options.type === Number) {
      return NumberDataConverter.decode(value, options);
    }

    if (options.innerArrayType && !options.innerArrayTypeResolved) {
      options.innerArrayTypeResolved = await options.innerArrayType();
    }

    if (options.innerObjectType && !options.innerObjectTypeResolved) {
      options.innerObjectTypeResolved = await options.innerObjectType();
    }

    const prototype: any = options.type.prototype
      ? options.type.prototype
      : options.type.__proto__;
    if (prototype && prototype.__dataConversionEnabled) {
      return await this.convertObject(options.type, value);
    }

    if (options.innerArrayTypeResolved) {
      for (let i = 0; i < value.length; i++) {
        value[i] = await this.convertObject(
          options.innerArrayTypeResolved,
          value[i],
        );
      }
    }
    if (options.innerObjectTypeResolved) {
      for (const key of Object.keys(value)) {
        value[key] = await this.convertObject(
          options.innerObjectTypeResolved,
          value[key],
        );
      }
    }
    return value;
  }

  private static validate(options: any, value: any): boolean {
    if (options.validators) {
      for (const validator of options.validators) {
        if (validator.prototype.validate) {
          if (validator.prototype.validate(value, options) === false) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public static async convert<T extends any>(
    type: ObjectOrArrayType<T>,
    source: object[] | object,
  ): Promise<T> {
    if (Array.isArray(source) === true) {
      const result = [];
      for (const data of <object[]>source) {
        result.push(await this.convertObject(type, data));
      }
      return <any>result;
    } else {
      return await this.convertObject(type, source);
    }
  }

  private static async convertObject(type: any, source: any): Promise<any> {
    const instance: any = new type();

    let prototype: any = type.prototype;
    if (!prototype) {
      prototype = type.__proto__;
    }

    if (prototype.__dataConversionEnabled) {
      const mapping: any[] = prototype.__dataConversionMapping;

      for (const element in mapping) {
        if (mapping.hasOwnProperty(element)) {
          const options: any = mapping[element];

          // value is not required
          if (!options.required) {
            const isTypNumberAndValid = (value: any): boolean =>
              typeof value === "number" && !isNaN(value);
            const valueIsNullOrUndefined = (value: any): boolean =>
              !value && value !== false;

            if (
              valueIsNullOrUndefined(source[options.name]) &&
              !isTypNumberAndValid(source[options.name])
            ) {
              // next element in the loop
              continue;
            }
          } else {
            if (!source.hasOwnProperty(options.name)) {
              console.error(
                "The conversion couldn't be performed because required parameter " +
                  options.name +
                  " is not part of source \n" +
                  type,
              );

              throw new Error(
                "The conversion couldn't be performed because required parameter " +
                  options.name +
                  " is not part of source \n" +
                  type,
              );
            }
          }

          instance[options.name] = await this.typeConversion(
            options,
            source[options.name],
          );

          if (!this.validate(options, instance[options.name])) {
            console.error(
              "Property " + options.name + " couldn't be validated! \n" + type,
            );
            throw new Error(
              "Property " + options.name + " couldn't be validated! \n" + type,
            );
          }
        } // end-if
      } // end-for
    } else {
      return null;
    }

    return instance;
  }

  public static convertBack(source: any, strict = true): object | object[] {
    if (Array.isArray(source)) {
      const result = [];
      for (const el of source) {
        result.push(this.convertBackObject(el, strict));
      }
      return result;
    } else {
      return this.convertBackObject(source, strict);
    }
  }

  private static convertBackObject(source: any, strict = true): object {
    if (source === undefined) {
      return undefined;
    }

    let prototype: any = source.prototype;

    if (!prototype) {
      prototype = source.__proto__;
    }

    if (prototype.__dataConversionEnabled) {
      const convertedObject: any = {};

      const mapping: any[] = prototype.__dataConversionMapping;

      for (const element in mapping) {
        if (mapping.hasOwnProperty(element)) {
          const options: any = mapping[element];

          if (strict) {
            if (options.required && !source[options.name]) {
              console.error(
                "Required property " +
                  options.name +
                  " is not set for given instance!",
              );
              throw new Error(
                "Required property " +
                  options.name +
                  " is not set for given instance!",
              );
            }
          }
          convertedObject[options.name] = this.typeBackConversion(
            options,
            source[options.name],
            strict,
          );

          if (strict) {
            if (!this.validate(options, convertedObject[options.name])) {
              console.error("Couldn't validate the property " + options.name);
              throw new Error("Couldn't validate the property " + options.name);
            }
          }
        }
      }

      return convertedObject;
    } else {
      return source;
    }
  }
}
