import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Directive({ selector: "[ifControlEnabled]" })
export class IfControllEnabledDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  private hasView = false;

  @Input()
  set ifControlEnabled(control: AbstractControl) {
    this.changeState(control.enabled);
    control.statusChanges.subscribe(() => {
      this.changeState(control.enabled);
    });
  }

  private changeState(valid: boolean) {
    if (valid === true && this.hasView === false) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (valid === false) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
