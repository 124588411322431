import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ProcessedDataTableFilterDefinition} from "../../interfaces/processed-data-table-filter-definition.interface";
import {
  ArrayDataSource, DataTableConfig, markAsDirty
} from "@incert/incert-gui";
import {I18nService} from "@incert/i18n";
import {DataTableFilterProfile} from "../../../shared/interfaces/data-table-persistence.interface";

@Component({
  selector: 'incert-data-table-insert-filter-profile-overlay',
  templateUrl: './data-table-insert-filter-profile-overlay.component.html',
  styleUrls: ['./data-table-insert-filter-profile-overlay.component.css']
})
export class DataTableInsertFilterProfileOverlayComponent implements OnInit {
  processedFilters: ProcessedDataTableFilterDefinition[] = []
  activeProcessedFilters: ProcessedDataTableFilterDefinition[] = []
  formGroup: UntypedFormGroup
  dtConfig: DataTableConfig<ProcessedDataTableFilterDefinition> = null;
  arrayDataSource: ArrayDataSource<ProcessedDataTableFilterDefinition> = null;

  constructor(private i18n: I18nService) {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl("", Validators.required)
    });
  }

  ngOnInit(): void {
    this.activeProcessedFilters = this.processedFilters.filter((v) => {
      if (v.value !== null && v.value !== "" && v.value !== undefined) {
        return v;
      }
    });
    this.createDtConfig();
  }

  private createDtConfig() {
    this.arrayDataSource = new ArrayDataSource<ProcessedDataTableFilterDefinition>(this.activeProcessedFilters);
    this.dtConfig = {
      data: this.arrayDataSource,
      rows: Number.MAX_SAFE_INTEGER,
      columns: [
        {
          header: this.i18n.instant("core.dataTable.filter"),
          property: (v) => v.get("header"),
        },
        {
          header: this.i18n.instant("core.dataTable.selectedValue"),
          property: (v) => v.get("value"),
        },
      ]
    }
  }

  get profile(): DataTableFilterProfile {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      return {
        name: this.formGroup.get("name").value,
        default: false,
        filters: this.activeProcessedFilters.map((v) => {
          return {
            name: v.header,
            property: v.property.toString(),
            value: v.value
          }
        })
      }
    }
    return null;
  }
}
