import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MultiLanguageFormService {
  private enableAllLanguagesSubject = new BehaviorSubject(false);

  set enableAllLanguages(value) {
    this.enableAllLanguagesSubject.next(value);
  }

  get enableAllLanguages() {
    return this.enableAllLanguagesSubject.value;
  }

  get enableAllLanguages$() {
    return this.enableAllLanguagesSubject.asObservable();
  }
}
