import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Type,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ConfirmService } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";
export interface ControlSelectionEntry {
  sort: number;
  type: string;
  value: any;
  data: any;
}
export interface ControlSelectionConfig<T extends ControlValueAccessor> {
  [type: string]: {
    component: Type<T>;
    name: string;
    init?: (instance: T) => void;
  };
}
@Component({
  selector: "incert-control-selection",
  templateUrl: "./control-selection.component.html",
  styleUrls: ["./control-selection.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlSelectionComponent),
      multi: true,
    },
  ],
})
export class ControlSelectionComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  maxControls: number;

  @Input()
  controlConfig: ControlSelectionConfig<any> = {};

  @Input()
  autoAdd = false;

  private subscriptions: Subscription[] = [];
  private dragStartIndex: number;
  public fa: FormArray = new FormArray([]);

  onChange = (controlSelections: ControlSelectionEntry[]) => {};

  onTouched = () => {};

  public constructor(
    private fb: FormBuilder,
    private confirmService: ConfirmService,
    private i18nService: I18nService,
  ) {}

  ngOnInit() {
    if (this.autoAdd) {
      if (
        this.fa.controls.filter((value) => value.value.value === "").length ===
          0 &&
        this.fa.length < this.maxControls
      ) {
        this.add(Object.keys(this.controlConfig)[0]);
      }
      this.subscriptions.push(
        this.fa.valueChanges.subscribe(() => {
          if (
            (this.fa.length === 0 ||
              this.fa.controls.filter((value) => value.value.value === "")
                .length === 0) &&
            this.fa.length < this.maxControls
          ) {
            this.add(Object.keys(this.controlConfig)[0]);
          }
        }),
      );
    }
  }

  add(type: string, data?: any, emitEvent = true) {
    const formGroup = this.fb.group<ControlSelectionEntry>({
      sort: this.fa.length + 1,
      type: type,
      value: "",
      data: data,
    });

    if (this.autoAdd) {
      this.subscriptions.push(
        formGroup.valueChanges.subscribe((v) => {
          if (
            v.value &&
            this.fa.length < this.maxControls &&
            this.fa.controls.filter((value) => value.value.value === "")
              .length === 0
          ) {
            this.add(Object.keys(this.controlConfig)[0]);
          }
          this.change();
        }),
      );
    } else {
      this.subscriptions.push(
        formGroup.valueChanges.subscribe(() => {
          this.change();
        }),
      );
    }

    this.fa.push(formGroup);
    if (emitEvent) {
      this.change();
    }
  }

  dragStart(startIndex: number) {
    this.dragStartIndex = startIndex;
  }

  onDrop(dropIndex: number) {
    const [removed] = this.fa.controls.splice(this.dragStartIndex, 1); // Remove the item at the starting index
    this.fa.controls.splice(dropIndex, 0, removed); // Insert the removed item at the ending index
    this.change();
  }

  async remove(index: number) {
    const confirm = await this.confirmService.confirmWarning(
      this.i18nService.instant("core.confirm.confirmDelete"),
      this.i18nService.instant("core.confirm.confirmDelete"),
    );
    if (confirm) {
      this.fa.removeAt(index);
      this.change();
    }
  }

  change() {
    for (const index in this.fa.controls) {
      this.fa.controls[index]
        .get("sort")
        .patchValue(index, { emitEvent: false });
    }
    this.onChange(this.fa.value);
  }

  writeValue(entries: ControlSelectionEntry[]): void {
    if (entries) {
      entries = entries.sort((a, b) => a.sort - b.sort);
      this.fa = new FormArray([]);
      for (const entry of entries) {
        this.add(entry.type, entry.data, false);
      }
      this.fa.patchValue(entries, { emitEvent: false });
    }
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
