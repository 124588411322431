import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataSourceMapping } from "./data-source-mapping.interface";
import { nameof } from "../../functions";
import { OnInit, Type, Directive } from "@angular/core";
import { DataConverter } from "@incert/data-conversion";

interface DataTableMatchModes {
  equals: "eq";
  notEquals: "ne";
  lt: "lt";
  gt: "gt";
  lte: "le";
  gte: "ge";
  in: "in";
  contains: "contains";
}

@Directive()
export class RqlDatasource<T> implements OnInit {
  private path: string;
  public total: number;
  public limit: number;
  public loading: boolean;
  private mapping: DataSourceMapping<T>[];
  public data: T[] = [];
  public type: Type<T>;
  public query: string;

  constructor(
    limit: number,
    mapping: DataSourceMapping<T>[],
    private http: HttpClient,
    type: Type<T>,
  ) {
    this.limit = limit;
    this.mapping = mapping;
    this.type = type;
  }

  private getField(field: string) {
    return this.mapping.find((v) => v.property === field);
  }

  private getRQLSelect() {
    return (
      "select(" +
      this.mapping
        .map((v) => v.field + ":" + v.header.split(" ").join(""))
        .join(",") +
      ")"
    );
  }

  public async load(path, event?: any) {
    this.path = path;
    this.query = "?q=";
    this.query += this.getRQLSelect();
    if (event) {
      if (event.multiSortMeta !== undefined) {
        for (let i = 0; i < event.multiSortMeta.length; i++) {
          if (event.multiSortMeta[i].order === 1) {
            this.query += "sort(%2B" + event.multiSortMeta[i].field + ")";
          }
          if (event.multiSortMeta[i].order === -1) {
            this.query += "sort(-" + event.multiSortMeta[i].field + ")";
          }
        }
      }
      for (const field of Object.keys(event.filters)) {
        switch (nameof<DataTableMatchModes>(event.filters[field].matchMode)) {
          case "eq": {
            this.query +=
              "eq(" +
              this.getField(field).field +
              "," +
              '"' +
              event.filters[field].value +
              '"' +
              ")";
            break;
          }
          case "ne": {
            this.query +=
              "ne(" +
              this.getField(field).field +
              "," +
              '"' +
              event.filters[field].value +
              '"' +
              ")";
            break;
          }
          case "lt": {
            this.query +=
              "lt(" +
              this.getField(field).field +
              "," +
              event.filters[field].value +
              ")";
            break;
          }
          case "gt": {
            this.query +=
              "gt(" +
              this.getField(field).field +
              "," +
              event.filters[field].value +
              ")";
            break;
          }
          case "le": {
            this.query +=
              "le(" +
              this.getField(field).field +
              "," +
              event.filters[field].value +
              ")";
            break;
          }
          case "ge": {
            this.query +=
              "ge(" +
              this.getField(field).field +
              "," +
              event.filters[field].value +
              ")";
            break;
          }
          case "in": {
            this.query +=
              "in(" +
              this.getField(field).field +
              ",(" +
              '"' +
              event.filters[field].value.join('","') +
              '"' +
              "))";
            break;
          }
          case "contains": {
            this.query +=
              "like(" +
              this.getField(field).field +
              "," +
              '"' +
              "*" +
              event.filters[field].value +
              "*" +
              '"' +
              ")";
            break;
          }
          case "date": {
            const request = event.filters[field].value.toString();
            const date = request.split(".").reverse().join("-");
            const datePattern =
              /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g;

            if (request.match(datePattern)) {
              this.query +=
                "and(gt(" +
                this.getField(field).field +
                "," +
                date +
                "T00:00:00Z),lt(" +
                this.getField(field).field +
                "," +
                date +
                "T23:00:00Z))";
            } else {
              console.log("Date error");
            }
            break;
          }
          default: {
            console.log("No filter selected");
            break;
          }
        }
      }
      this.query += "limit(" + this.limit + "," + event.first + ")";
    } else {
      this.query += "limit(" + this.limit + ",0)";
    }
    this.loading = true;
    const headers = new HttpHeaders({ Accept: "application/csv" });

    if (this.query) {
      this.query = this.query.replace("in(productName", "in(productId");
      this.query = this.query.replace("in(aboName", "in(aboId");
      this.path += this.query;
    }

    await this.http
      .get(this.path, {
        headers: headers,
        responseType: "text",
        observe: "response",
      })
      .toPromise()
      .then(async (res) => {
        this.total = res.headers.has("Pagination-Count")
          ? Number(res.headers.get("Pagination-Count"))
          : null;
        this.limit = res.headers.has("Pagination-Limit")
          ? Number(res.headers.get("Pagination-Limit"))
          : null;
        const data = res.body
          .split("\n")
          .map((v) => v.split(";"))
          .map((v) => {
            const entry = {};
            for (let i = 0; i < this.mapping.length; i++) {
              const key = this.mapping[i].property.toString();
              entry[key] = v[i];
              if ((entry[key] + "").charAt(0) === '"') {
                entry[key] = entry[key].slice(1, -1);
              }
            }
            return entry;
          });

        //for Headers
        data.shift();
        data.splice(-1, 1);
        this.data = await DataConverter.convert<T[]>(this.type, data);
      });
    //this.data.shift();
    this.loading = false;
  }

  public async exportCSV(limit?: number) {
    let queryCSV = "";
    queryCSV += this.getRQLSelect();

    if (limit) {
      queryCSV += "limit(" + limit + ",0)";
    } else {
      queryCSV += "limit(10000,0)";
    }
    console.log("Query: " + queryCSV);
    this.loading = true;
    const headers = new HttpHeaders({ Accept: "application/csv" });
    await this.http
      .get(this.path + queryCSV, {
        headers: headers,
        responseType: "text",
        observe: "response",
      })
      .toPromise()
      .then((res) => {
        const blob = new Blob(["\ufeff" + res.body], {
          type: "value/csv;charset=utf-8;",
        });
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser =
          navigator.userAgent.indexOf("Safari") !== -1 &&
          navigator.userAgent.indexOf("Chrome") === -1;
        if (isSafariBrowser) {
          //if Safari open in new window to saveOpeningTimeEntries file with random filename.
          dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "Report.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      });
    this.loading = false;
  }

  public async exportPathToCSV(path) {
    const fullpath = path;

    this.loading = true;
    const headers = new HttpHeaders({ Accept: "application/csv" });
    await this.http
      .get(fullpath, {
        headers: headers,
        responseType: "text",
        observe: "response",
      })
      .toPromise()
      .then((res) => {
        const blob = new Blob(["\ufeff" + res.body], {
          type: "value/csv;charset=utf-8;",
        });
        const dwldLink = document.createElement("a");
        const url = URL.createObjectURL(blob);
        const isSafariBrowser =
          navigator.userAgent.indexOf("Safari") !== -1 &&
          navigator.userAgent.indexOf("Chrome") === -1;
        if (isSafariBrowser) {
          //if Safari open in new window to saveOpeningTimeEntries file with random filename.
          dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "Report.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
      });
    this.loading = false;
  }

  async ngOnInit() {}
}
