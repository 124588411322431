import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessageOutletComponent } from "./message-outlet.component";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";

@NgModule({
  imports: [CommonModule, MessagesModule, MessageModule],
  declarations: [MessageOutletComponent],
  exports: [MessageOutletComponent],
})
export class MessageOutletModule {}
