import { Component, OnInit } from "@angular/core";
import { AccountSystem } from "../../../../core/models/account-system.interface";
import {
  ArrayDataSource,
  createComponentConfiguration,
  DataTableConfig,
} from "@incert/incert-gui";
import { Memo } from "../../../tools/memo/memo.interface";
import { DataTableTagComponent } from "@incert/incert-gui";
import {
  CommentAreaConfig,
  DataTableCommentAreaComponent,
} from "../../../../core/data-table-components/data-table-comment-area/data-table-comment-area.component";

@Component({
  selector: "incert-account-system-memo",
  templateUrl: "./account-system-memo.component.html",
  styleUrls: ["./account-system-memo.component.css"],
})
export class AccountSystemMemoComponent implements OnInit {
  accountSystem: AccountSystem;
  dtConfig: DataTableConfig<Memo>;
  memoCategoryId: number;

  async ngOnInit() {
    const memosFiltered = this.accountSystem.memos.filter(
      (v) => v.category.id === this.memoCategoryId,
    );
    this.createDtConfig(memosFiltered);
  }

  createDtConfig(memosFiltered: Memo[]) {
    this.dtConfig = {
      data: new ArrayDataSource(memosFiltered),
      rows: 50,
      columns: [
        {
          header: "Name",
          property: (v) => v.get("title"),
        },
        {
          header: "Kategorie",
          property: (v) => v.get("category", "name"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Memo) => {
              return [
                {
                  name: row.category.name.toUpperCase(),
                  bgColor: row.category.bgColor,
                  fgColor: row.category.fgColor,
                },
              ];
            },
          }),
        },
        {
          header: "Tags",
          property: (v) => v.get("tags"),
          class: () => "dt-width-150",
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Memo) => {
              return row.tags;
            },
          }),
        },
        {
          header: "Ablaufdatum",
          property: (v) => v.get("validUntil"),
        },
        {
          header: "Kommentar",
          property: (v) => v.get("comment"),
          component: createComponentConfiguration(
            DataTableCommentAreaComponent,
            {
              setTextAreaData: (row: Memo): CommentAreaConfig<Memo> => {
                return {
                  header: "Kommentar",
                  maxLengthWrap: 192,
                  text: row.comment,
                  createdBy: row.createdBy,
                  createdAt: row.createdAt,
                };
              },
            },
          ),
        },
      ],
    };
  }
}
