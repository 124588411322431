import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LanguageTabsComponent } from "./language-tabs.component";
import { TabViewModule } from "primeng/tabview";

@NgModule({
  imports: [CommonModule, TabViewModule],
  declarations: [LanguageTabsComponent],
  exports: [LanguageTabsComponent],
})
export class LanguageTabsModule {}
