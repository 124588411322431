import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { AccountSystem } from "../../../core/models/account-system.interface";

import { NotificationService } from "@incert/incert-core";
import { UserManager } from "../user-manager.service";

@Component({
  selector: "incert-internal-tools-user-add-account-system",
  templateUrl: "./user-add-account-system.component.html",
  styleUrls: ["./user-add-account-system.component.scss"],
})
export class UserAddAccountSystemComponent implements OnInit {
  public dataTableConfig: DataTableConfig<AccountSystem>;
  public availableAccountSystems: Array<AccountSystem>;
  public arrayDataSource: ArrayDataSource<AccountSystem> = null;
  public userId: number;

  constructor(
    private accountSystemManager: AccountSystemManager,
    private notificationService: NotificationService,
    private userManager: UserManager,
  ) {}

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    this.createDataTable();
  }

  private createDataTable() {
    this.arrayDataSource = new ArrayDataSource<AccountSystem>(
      this.availableAccountSystems,
    );

    this.dataTableConfig = {
      data: this.arrayDataSource,
      mode: "pagination",
      emptyMessage:
        "Dieser Benutzer hat bereits Zugriff auf alle Kundensysteme",
      rows: 20,
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("name"),
          }),
          sort: true,
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("short"),
          }),
          sort: true,
        },
        {
          header: "Aktion",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "plus",
                  onClick: (row: AccountSystem) => this.addSystemToUser(row),
                },
              ],
            },
          ),
        },
      ],
    };
  }

  private async addSystemToUser(accountSystem: AccountSystem) {
    try {
      await this.accountSystemManager
        .addAccountSystemToUser(this.userId, [accountSystem])
        .then((addedSystem: AccountSystem) => {
          this.notificationService.notifySuccess(
            "Kundensystem: " + addedSystem.name + " hinzugefügt",
            "Hinzufügen Erfolgreich",
          );
          this.arrayDataSource.reloadDataTable(
            this.arrayDataSource.data.filter((a) => a.id !== addedSystem.id),
          );
        });
    } catch (e) {
      this.notificationService.notifyInfo(
        "Der Zugang zu diesem Kundensystem wurde bereits erteilt.",
        "Hinzufügen Erfolgreich",
      );
    }
  }

  public save() {
    this.userManager.userData$.next(undefined);

    return true;
  }
}
