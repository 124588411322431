import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingService, NotificationService } from "@incert/incert-core";
import { ChangeUserPassword } from "../user";
import { IUserManager } from "../user";
import { AuthModel, IAuthManager } from "../../core/auth";
import { ErrorResponseI } from "../../core/errorResponseI";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "sac-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public isLoading: boolean;
  public email: string;
  public password: string;
  public firstLogin: boolean;
  public userId: number;
  public display: boolean;
  public oldPassword: string;
  public newPassword: string;
  public newPasswordConfirm: string;

  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    @Inject("UserManager") private userManager: IUserManager,
    private router: Router,
    private loadingService: LoadingService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = false;
    this.firstLogin = false;
  }

  public async login() {
    this.isLoading = true;

    try {
      const authModel: AuthModel = await this.authManager.login(
        this.email,
        this.password,
      );

      if (authModel) {
        if (authModel.resetPassword) {
          this.userId = authModel.id;
          this.firstLogin = true;
          this.display = true;
        } else {
          await this.router.navigate(["account-system-overview"]);
        }
      }
    } catch (e) {
      this.notificationService.notifyError("Login", e.message);
    } finally {
      this.isLoading = false;
    }
  }

  public async save() {
    if (!this.oldPassword || !this.newPassword || !this.newPasswordConfirm) {
      this.notificationService.notifyInfo(
        "Passwort",
        "Eingabefelder sind nicht richtig ausgefüllt.",
      );
    } else if (this.newPassword !== this.newPasswordConfirm) {
      this.notificationService.notifyInfo(
        "Passwort",
        "Feld neues Passwort und neues Passwort wiederholen müssen gleich sein.",
      );
    } else {
      const changeUserPassword: ChangeUserPassword = {
        id: this.userId,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        creationUserId: null,
      };

      try {
        const userId: number =
          await this.userManager.changePassword(changeUserPassword);
        this.notificationService.notifySuccess(
          "Passwort ändern",
          "Passwort wurde geändert.",
        );
        this.authManager.authModel.firstLogin = Date.now();
      } catch (e) {
        if (e.status === 400) {
          const errorResponse = new ErrorResponseI(e._body);
          this.notificationService.notifyError(
            "Passwort ändern",
            errorResponse.message,
          );
        } else {
          this.notificationService.notifyError(
            "Passwort ändern",
            "Es ist leider etwas schief gelaufen. Bitte versuchen Sie es erneut.",
          );
        }
        this.display = false;
        this.onClose();
        this.authManager.logout();
      } finally {
        this.display = false;
        this.onClose();
        if (this.firstLogin) {
          this.router.navigate(["account-system-overview"]);
        }
      }
    }
  }

  public onClose(): void {
    this.display = false;
  }
}
