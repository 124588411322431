import { Component, Input, OnInit } from "@angular/core";
import { download } from "../../../../incert-core/src";

@Component({
  selector: "incert-text-file-display",
  templateUrl: "./text-file-display.component.html",
  styleUrls: ["./text-file-display.component.css"],
})
export class TextFileDisplayComponent implements OnInit {
  @Input()
  textRaw = "";
  @Input()
  textType = "";
  @Input()
  header = "";

  textEdited = "";

  ngOnInit(): void {}

  assignType(): void {
    switch (this.textType) {
      case "md_standard":
        this.textEdited = this.mdStandard(this.textRaw);
    }
  }

  public mdStandard(textRaw: string) {
    let formatted = "";
    const rawArray = textRaw.split(/(\n)/);
    for (let i = 0; i < rawArray.length; i++) {
      const r = rawArray[i];
      switch (r[0] ? r[0] : "") {
        case "#":
          if (r[0] === r[1]) {
            rawArray[i] =
              '<h2 class="clear-separator-bottom">' +
              r.replace("###", "") +
              "</h2>";
          }
          break;
        case "*":
          rawArray[i] = "<i>" + r.replace(/(\*\*\*)/g, "") + "</i>";
          break;
        case ">":
          rawArray[i] =
            '<h4 class="clear-separator-bottom clear-separator-top">' +
            r.replace("> ", "") +
            "</h4>";
          break;
        case "-":
          rawArray[i] = "<li>" + r.replace("- ", "") + "</li>";
          break;
        default:
          rawArray[i] = "<div>" + r + "</div>";
      }
      formatted += rawArray[i];
    }
    return formatted;
  }

  downloadFile() {
    download(this.textRaw, this.header);
    return true;
  }
}
