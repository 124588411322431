import {
  AccountSystem,
  AccountSystemPersistence,
} from "../../core/models/account-system.interface";
import { AccountSystemManager } from "./account-system-manager.service";
import {
  LoadingService,
  OverlayService,
  OverlaySize,
  Status,
} from "@incert/incert-core";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ArrayDataSource,
  BooleanFilterComponent,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DataTableOptionIconComponent,
  SelectFilterComponent,
  StatusComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { AccountManager } from "../account/account-manager.service";
import { AccountSystemNewComponent } from "./account-system-new/account-system-new.component";
import { Globals } from "../../core/globals";
import { Memo } from "../tools/memo/memo.interface";
import { AuthManager } from "../../core/auth";
import { DataTableSubdomainComponent } from "../../core/data-table-components/data-table-subdomain/data-table-subdomain.component";
import { MemoManager } from "../tools/memo/memo.manager.service";
import { AccountSystemActionComponent } from "./account-system-action/account-system-action.component";
import { SettingsComponent } from "../settings/settings.component";
import { Subscription } from "rxjs";
import { DataTableTagComponent } from "../../core/data-table-components/data-table-tag/data-table-tag.component";
import { ServerService } from "../../core/services/server.service";
import { DiscrEnum } from "../../core/enums/discr.enum";
import { AccountSystemShowDetailsComponent } from "./account-system-overlays/account-system-show-details/account-system-show-details.component";
import { AccountSystemSystemValueComponent } from "./account-system-overlays/account-system-system-value/account-system-system-value.component";
import { AccountSystemInstallationStatusComponent } from "./account-system-overlays/account-system-installation-status/account-system-installation-status.component";
import { AccountSystemServerComponent } from "./account-system-overlays/account-system-server/account-system-server.component";
import { AccountSystemMemoComponent } from "./account-system-overlays/account-system-memo/account-system-memo.component";
import { DataTablePersistenceService } from "../../core/services/data-table-persistence.service";
import { RoutesDef } from "../../app-routing.module";
import { SearchEngineService } from "../../core/autocomplete/search-engine-service";
import { SystemValueManagerService } from "../tools/system-value/system-value.manager.service";

@Component({
  selector: "sac-account-system",
  templateUrl: "./account-system.component.html",
  styleUrls: ["./account-system.component.scss"],
})
export class AccountSystemComponent implements OnInit, OnDestroy {
  public _accountSystems: Array<AccountSystem> = [];
  public accountSystems: Array<AccountSystem> = [];
  public discr: DiscrEnum;
  public DiscrEnum = DiscrEnum;
  public accountSystemConfig: DataTableConfig<AccountSystem>;
  public arrayDataSource: ArrayDataSource<AccountSystem>;
  public portalNameSI: SelectItem[];
  public clusterSI: SelectItem[];
  public subscriptions: Array<Subscription> = [];
  public preAccountSystemFilter: string;
  public localPersistence: AccountSystemPersistence;

  constructor(
    private authManager: AuthManager,
    private systemValueManagerService: SystemValueManagerService,
    private accountSystemManager: AccountSystemManager,
    private memoManager: MemoManager,
    private accountManager: AccountManager,
    private overlayService: OverlayService,
    private router: Router,
    private globals: Globals,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private searchEngineService: SearchEngineService,
    private serverService: ServerService,
    private persistenceService: DataTablePersistenceService,
  ) {
    this.initializeRouteSubscriptions();
    this.initializeComponentSubscriptions();

    this.serverService.portalNameEntries$.subscribe((portalNameEntries) => {
      this.portalNameSI = portalNameEntries.map((v) => {
        return { value: v, label: v };
      });
    });
    this.serverService.clusterEntries$.subscribe((clusterEntries) => {
      this.clusterSI = clusterEntries.map((v) => {
        return { value: v, label: v };
      });
    });
  }

  async ngOnInit() {
    await this.initialize();
  }

  private initializeRouteSubscriptions() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params["discr"] !== this.discr) {
        this.discr = params["discr"];
        await this.initialize();
      }

      if (params["preFilter"]) {
        this.preAccountSystemFilter = params["preFilter"];
      }
    });
  }

  private initializeComponentSubscriptions() {
    this.subscriptions = [
      this.accountSystemManager.dataHasChanged.subscribe((v) =>
        v ? this.initialize() : null,
      ),
      this.systemValueManagerService.dataHasChanged$.subscribe((v) =>
        v ? this.filterDataTable(v) : null,
      ),
      this.searchEngineService.onResult.subscribe((d) =>
        this.getAccountSystemSubsetById(d),
      ),
    ];
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      this.discr = this.discr ? this.discr : this.DiscrEnum.ecom;
      await this.handlePersistence();
      await this.getAccountSystems();
      await this.assignSystemValuesToAccountSystems();
      await this.assignMemosToAccountSystems();
      await this.createDataTableConfig(this.accountSystems);
    });
  }

  private async getAccountSystems() {
    this.accountSystems = this._accountSystems =
      await this.accountSystemManager.getAccountSystemsByUser(
        this.authManager.getLoggedInUserId(),
        this.discr,
      );
  }

  private getSystemTypeSI() {
    switch (this.discr) {
      case DiscrEnum.ecom:
        return [
          { label: "professional", value: "professional" },
          { label: "express", value: "express" },
        ];
      case DiscrEnum.loy:
        return [
          { label: "basic", value: "basic" },
          { label: "premium", value: "premium" },
          { label: "gwg", value: "gwg" },
        ];
    }
  }

  private async assignMemosToAccountSystems() {
    const memos = await this.memoManager.getMemos();

    for (const memo of memos) {
      for (const memoAS of memo.accountSystems) {
        const accountSystem: AccountSystem = this.accountSystems.find(
          (aS) => memoAS.id === aS.id,
        );
        if (accountSystem) {
          if (!accountSystem.memos) {
            accountSystem.memos = [];
          }
          accountSystem.memos.push(memo);
        }
      }
    }
  }

  private async assignSystemValuesToAccountSystems() {
    const systemConfigMetaData =
      await this.systemValueManagerService.getSystemConfigMetadata();

    Object.keys(systemConfigMetaData).forEach((key) => {
      const accountSystem = this.accountSystems.find((a) => a.id === +key);
      if (accountSystem) {
        for (const metaProperty of systemConfigMetaData[key]) {
          if (
            this.localPersistence.config.systemValues.includes(metaProperty)
          ) {
            if (!accountSystem.properties) {
              accountSystem.properties = [];
            }
            accountSystem.properties.push(metaProperty);
          }
        }
      }
    });
  }

  private async createDataTableConfig(accountSystems: Array<AccountSystem>) {
    this.arrayDataSource = new ArrayDataSource<AccountSystem>(
      this.handlePinnedSystems(accountSystems),
    );

    this.accountSystemConfig = {
      mode: "pagination",
      data: this.arrayDataSource,
      rows: 15,
      columnEdit: true,
      persistence: {
        persistenceId: "accountSystemOverview",
        persistenceService: this.persistenceService,
      },
      filterArea: {
        filters: [
          {
            header: "Status",
            property: (v) => v.get("installationStatus"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: [
                { label: "added", value: "added" },
                { label: "installed", value: "installed" },
                { label: "active", value: "active" },
                { label: "productive", value: "productive" },
              ],
            }),
          },
          {
            header: "Typ",
            property: (v) => v.get("systemType"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.getSystemTypeSI(),
            }),
          },
          {
            header: "Kürzel",
            property: (v) => v.get("short"),
            filter: createFilterDefinition(TextFilterComponent, {
              defaultValue: this.preAccountSystemFilter,
            }),
          },
          {
            header: "Name",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Kunde",
            property: (v) => v.get("account", "name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Portal",
            property: (v) => v.get("portalName"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.portalNameSI,
            }),
          },
          {
            header: "Cluster",
            property: (v) => v.get("cluster"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.clusterSI,
            }),
          },
          {
            header: "Kundendomains",
            property: (v) =>
              v.get("accountSystemSubDomains", 0, "subdomainLinkToSystem"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Testsystem",
            property: (v) => v.get("isDev"),
            filter: createFilterDefinition(BooleanFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Status",
          property: (v) => v.get("installationStatus"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccountSystem) => {
              return [
                {
                  name: row.installationStatus,
                  bgColor: this.globals.installationStatusColors.find(
                    (v) => v.value === row.installationStatus,
                  ).color,
                },
              ];
            },
            pointer: true,
            onClick: (row) => this.overlayInstallationStatus(row),
          }),
          class: () => "dt-width-150",
          sort: true,
        },
        {
          header: "Typ",
          property: (v) => v.get("systemType"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccountSystem) => {
              return [
                {
                  name: row.systemType,
                  bgColor: this.globals.systemTypeColors.find(
                    (v) => v.value === row.systemType,
                  ).color,
                },
              ];
            },
          }),
          class: () => "dt-width-150",
          sort: true,
        },
        {
          header: "Testsystem",
          property: (v) => v.get("isDev"),
          component: {
            component: StatusComponent,
            init: (instance: StatusComponent, row: AccountSystem) => {
              instance.status = row.isDev ? Status.success : Status.alert;
            },
          },
          class: () => "inc-dt-center",
          width: "60px",
          sort: true,
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
          sort: true,
        },
        {
          header: "Name",
          property: (v) => v.get("name"),
          class: () => "nowrap",
          sort: true,
        },
        {
          header: "Kunde",
          property: (v) => v.get("account", "name"),
          class: () => "nowrap",
          sort: true,
        },
        {
          header: "Portal",
          property: (v) => v.get("portalName"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccountSystem) => {
              return [
                {
                  name: row.portalName,
                  bgColor: "#009711",
                },
              ];
            },
            pointer: true,
            onClick: (row: AccountSystem) => this.overlayServerInfo(row),
          }),
          class: () => "dt-width-150",
          sort: true,
        },
        {
          header: "Kundendomains",
          property: (v) => v.get("accountSystemSubDomains"),
          component: createComponentConfiguration(DataTableSubdomainComponent, {
            resolveRowData: (row: AccountSystem) => row,
            loginPermission: this.loginWithOAuth,
          }),
        },
        {
          header: "Wissensnotizen",
          property: (v) => v.get("memos"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccountSystem) => {
              const sortedMemos = [];
              if (row.memos) {
                for (const memo of row.memos) {
                  const memoCategory = sortedMemos.find(
                    (v) => v.category.id === memo.category.id,
                  );
                  if (!memoCategory) {
                    memo.category.count = 1;
                    sortedMemos.push(memo);
                  } else {
                    memoCategory.category.count++;
                  }
                }
              }
              return sortedMemos?.map((v: Memo) => {
                return {
                  id: v.category.id,
                  name: v.category.name.toUpperCase(),
                  badge: v.category.count.toString(),
                  bgColor: v.category.bgColor,
                  fgColor: v.category.fgColor,
                };
              });
            },
            pointer: true,
            onClick: (row, entry) => this.overlayMemos(row, entry),
          }),
        },
        {
          header: "DWH",
          property: (v) => v.get("availableForDWH"),
          component: createComponentConfiguration(
            DataTableOptionIconComponent,
            {
              setOptionValue: (row: AccountSystem) => row.availableForDWH,
              optionConfig: {
                value: true,
                icon: "upload",
                asButton: true,
                onClick: (row: AccountSystem) =>
                  AccountSystemComponent.importToETL(row.short),
              },
            },
          ),
          sort: true,
        },
        {
          header: "Systemwerte",
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: AccountSystem) => {
              return row.properties?.map((v) => {
                return {
                  name: v,
                  bgColor: "#009711",
                };
              });
            },
            pointer: true,
            onClick: (row: AccountSystem, entry) =>
              this.overlaySystemValues(row, entry),
          }),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "search",
                  tooltip: "Details",
                  onClick: (row: AccountSystem) => this.overlayDetails(row),
                },
                {
                  icon: "edit",
                  tooltip: "Bearbeiten",
                  hidden: !this.writeSystem,
                  onClick: (row: AccountSystem) => this.overlayUpsert(row),
                },
                {
                  icon: "memo",
                  tooltip: "Wissensnotizen",
                  hidden: !this.readMemo,
                  onClick: (accountSystem: AccountSystem) => {
                    this.router.navigate([RoutesDef.memo], {
                      queryParams: { accountSystem: accountSystem.name },
                    });
                  },
                },
                {
                  icon: "pinned",
                  tooltip: "Anpinnen",
                  onShow: (row: AccountSystem) =>
                    !this.localPersistence.config.pinnedSystems?.find(
                      (v: number) => v === row.id,
                    ),
                  onClick: async (row: AccountSystem) => {
                    this.localPersistence.config.pinnedSystems.push(row.id);
                    await this.persistenceService.updateDataTablePersistence(
                      this.localPersistence,
                    );
                  },
                },
                {
                  icon: "unpinned",
                  tooltip: "Lösen",
                  onShow: (row: AccountSystem) =>
                    !!this.localPersistence.config.pinnedSystems?.find(
                      (v: number) => v === row.id,
                    ),
                  onClick: async (row: AccountSystem) => {
                    const index =
                      this.localPersistence.config.pinnedSystems.indexOf(
                        row.id,
                      );
                    if (index > -1) {
                      this.localPersistence.config.pinnedSystems.splice(
                        index,
                        1,
                      );
                    }
                    await this.persistenceService.updateDataTablePersistence(
                      this.localPersistence,
                    );
                  },
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "coding",
              label: "Aktionen",
              onClick: () => this.overlayActions(),
            },
            {
              hidden: !this.writeSystem,
              icon: "plus",
              label: "System",
              onClick: () => this.overlayUpsert(),
            },
            {
              icon: "settings",
              label: "Einstellungen",
              onClick: () => this.overlaySettings(),
            },
          ],
        }),
      ],
    };
  }

  private filterDataTable(accountSystems: Array<AccountSystem>) {
    if (!accountSystems.length) {
      this.arrayDataSource.reloadDataTable(this.accountSystems);
    } else {
      this.arrayDataSource.reloadDataTable(accountSystems);
    }
  }

  private getAccountSystemSubsetById(ids: Array<any>) {
    if (this.arrayDataSource) {
      if (ids[0] === -1) {
        this.arrayDataSource.refreshDataTableSource([]);
      } else if (ids.length === 0) {
        this.arrayDataSource.refreshDataTableSource(this._accountSystems);
      } else {
        const acs = this._accountSystems.filter(
          (a) => ids.map((res) => res.id).indexOf(a.id) !== -1,
        );
        if (acs.length > 0) {
          this.arrayDataSource.refreshDataTableSource(acs);
        }
      }
    }
  }

  private static importToETL(short: string) {
    window.open(
      `https://dwh-etl.incert.at/etl/start/gms?sourceDbs[]=${short}&initial=false&full=true`,
    );
    window.open(
      `https://dwh-etl.incert.at/etl/start/tracking?sourceDbs[]=${short}&initial=false&full=true`,
    );
  }

  private async handlePersistence() {
    this.localPersistence =
      await this.persistenceService.getDataTablePersistence(
        "accountSystemOverview",
      );
    if (!this.localPersistence.config) {
      this.localPersistence = {
        dataTableId: "accountSystemOverview",
        config: {
          specialFilter: false,
          pinnedSystems: [],
          systemValues: [],
          filterProfiles: [],
          columns: [],
        },
      };
      await this.persistenceService.updateDataTablePersistence(
        this.localPersistence,
      );
    }
  }

  private handlePinnedSystems(systems: Array<AccountSystem>) {
    const pinnedSystems = systems.filter(
      (a) => this.localPersistence.config.pinnedSystems.indexOf(a.id) !== -1,
    );
    systems = systems.filter(
      (a) =>
        this.localPersistence.config.pinnedSystems
          .map((s) => s)
          .indexOf(a.id) === -1,
    );

    pinnedSystems.forEach((system) => systems.unshift(system));
    return systems;
  }

  public async overlayUpsert(accountSystem?: AccountSystem) {
    await this.overlayService.show<AccountSystemNewComponent>({
      header: accountSystem
        ? "Kundensystem bearbeiten"
        : "Kundensystem hinzufügen",
      size: OverlaySize.large,
      type: AccountSystemNewComponent,
      displayAsSidebar: true,
      init: (c) => {
        c.portalNameSI = this.portalNameSI;
        c.clusterSI = this.clusterSI;
        if (accountSystem) {
          c.accountSystems = this.accountSystems;
          c.accountSystem = accountSystem;
        }
      },
      actions: [
        {
          label: "Speichern",
          action: (c) => c.upsertAccountSystem(),
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  public async overlayActions() {
    const selectedSystems: Array<AccountSystem> = this.arrayDataSource.data;

    await this.overlayService.show<AccountSystemActionComponent>({
      type: AccountSystemActionComponent,
      header: "Aktion für Kundensysteme wählen",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: (c) => c.initialize(selectedSystems, this.accountSystems),
      actions: [
        { label: "Schließen", displayAsLink: true, action: () => true },
      ],
    });

    return true;
  }

  private async overlayInstallationStatus(row: AccountSystem) {
    if (this.executeSystem) {
      await this.overlayService.show<AccountSystemInstallationStatusComponent>({
        type: AccountSystemInstallationStatusComponent,
        header: row.short + " | " + row.installationStatus,
        size: OverlaySize.medium,
        displayAsSidebar: true,
        init: (component) => {
          component.accountSystem = row;
        },
        actions: [
          { label: "Schließen", displayAsLink: true, action: () => true },
        ],
      });
    }
  }

  private async overlayServerInfo(row: AccountSystem) {
    await this.overlayService.show<AccountSystemServerComponent>({
      type: AccountSystemServerComponent,
      header: "Portal Details",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: (component) => {
        component.accountSystem = row;
      },
      actions: [
        { label: "Schließen", displayAsLink: true, action: () => true },
      ],
    });
  }

  private async overlaySystemValues(row: AccountSystem, entry) {
    if (this.readSystemValue) {
      await this.overlayService.show<AccountSystemSystemValueComponent>({
        type: AccountSystemSystemValueComponent,
        header: "Systemwert: " + entry.name,
        size: OverlaySize.medium,
        displayAsSidebar: true,
        init: (component) => {
          component.accountSystem = row;
          component.systemValueKey = entry.name;
        },
        actions: [
          { label: "Schließen", displayAsLink: true, action: () => true },
        ],
      });
    }
  }

  public async overlaySettings() {
    await this.overlayService.show<SettingsComponent>({
      header: "Einstellungen",
      size: OverlaySize.medium,
      type: SettingsComponent,
      displayAsSidebar: true,
      init: async (c) => {
        this.localPersistence =
          await this.persistenceService.getDataTablePersistence(
            "accountSystemOverview",
          );
        c.specialFilter = this.localPersistence.config.specialFilter;
        c.activeSystemValues = this.localPersistence.config.systemValues;
      },
      actions: [
        {
          label: "Speichern",
          action: async (instance: SettingsComponent) => {
            this.localPersistence.config.systemValues =
              instance.activeSystemValues;
            this.localPersistence.config.specialFilter = instance.specialFilter;
            await this.persistenceService.updateDataTablePersistence(
              this.localPersistence,
            );
            await this.initialize();
            return true;
          },
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  private async overlayMemos(row: any, entry: any) {
    if (this.readMemo) {
      await this.overlayService.show<AccountSystemMemoComponent>({
        type: AccountSystemMemoComponent,
        header: "Wissensnotizen",
        size: OverlaySize.large,
        displayAsSidebar: true,
        init: (component) => {
          component.accountSystem = row;
          component.memoCategoryId = entry.id;
        },
        actions: [
          { label: "Schließen", displayAsLink: true, action: () => true },
        ],
      });
    }
  }

  private async overlayDetails(accountSystem: AccountSystem) {
    const account = await this.accountManager.getAccount(
      accountSystem.account.id,
    );
    await this.overlayService.show<AccountSystemShowDetailsComponent>({
      type: AccountSystemShowDetailsComponent,
      header: "System Informationen",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: (component) => {
        component.accountSystem = accountSystem;
        component.account = account;
      },
      actions: [
        {
          displayAsLink: true,
          label: "Schließen",
          action: () => {
            return true;
          },
        },
      ],
    });
  }

  public ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public get writeSystem(): boolean {
    return this.authManager.hasPermission("writeSystem");
  }

  public get executeSystem(): boolean {
    return this.authManager.hasPermission("executeSystem");
  }

  public get loginWithOAuth(): boolean {
    return this.authManager.hasPermission("loginWithOAuth");
  }

  public get readMemo(): boolean {
    return this.authManager.hasPermission("readMemo");
  }

  public get readSystemValue(): boolean {
    return this.authManager.hasPermission("readSystemValue");
  }
}
