<form [formGroup]='formGroup'>
  <form-field label="Veröffentlicht">
    <p-inputSwitch
      [formControlName]="nameofForm('published')"
      class="input-switch-padding">
    </p-inputSwitch>
  </form-field>
  <form-field label="Marketplace Typen">
    <p-multiSelect
      [formControlName]="nameofForm('types')"
      [options]="marketplaceTypeSI"
      placeholder="Marketplace Typen"
      appendTo="body">
    </p-multiSelect>
  </form-field>
  <form-field label="Access Profile">
    <p-multiSelect
      [formControlName]="nameofForm('accessProfiles')"
      [options]="accessProfileSI"
      placeholder="Access Profile"
      appendTo="body">
    </p-multiSelect>
  </form-field>
  <h1>Übersetzungen</h1>
  <div *ngFor="let translation of translations?.controls; let i = index" formArrayName="translations">
    <div class="box-entry separator-bottom" [formGroupName]="i">
      <div class="box-entry-content">
        <div class="box-entry-left">
          <form-field label="Sprache">
            <p-dropdown
              [formControlName]="nameofFormTranslation('language')"
              [options]="languageSI"
              placeholder="Sprache"
              appendTo="body">
            </p-dropdown>
          </form-field>
          <form-field label="Bezeichnung">
            <input [formControlName]="nameofFormTranslation('name')" type='text' placeholder="Bezeichnung">
          </form-field>
        </div>
        <div class="box-entry-right">
          <icon-button iconOnly='true' iconType="close" (click)="deleteTranslation(i)"
                       displayIconButtonAsButton="true">
          </icon-button>
        </div>
      </div>
    </div>
  </div>
  <div class="align-right">
    <icon-button iconType="plus" (click)="addTranslation()" label="Übersetzung"></icon-button>
  </div>
  <h1>Ressourcen</h1>
  <div *ngFor="let resource of resources?.controls; let i = index" formArrayName="resources">
    <div class="box-entry separator-bottom" [formGroupName]="i">
      <div class="box-entry-content">
        <div class="box-entry-left">
          <form-field>
            <p-dropdown
              [formControlName]="nameofFormResource('id')"
              [options]="resourceSI"
              placeholder="Ressource"
              appendTo="body">
            </p-dropdown>
          </form-field>
        </div>
        <div class="box-entry-right">
          <icon-button iconOnly='true' iconType="close" (click)="deleteResource(i)"
                       displayIconButtonAsButton="true">
          </icon-button>
        </div>
      </div>
    </div>
  </div>
  <div class="align-right">
    <icon-button iconType="plus" (click)="addResource()" label="Ressource"></icon-button>
  </div>
</form>
