import { Component, OnInit } from "@angular/core";
import { AccessProfileManager } from "../access-profile.manager.service";
import { AccessGroupModel } from "../access-group.model";
import { AccessLevelModel } from "../access-level.model";
import { AccessProfile } from "../access-profile.model";
import {
  createNameof,
  LoadingService,
  NotificationService,
} from "@incert/incert-core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AccessProfileComponent } from "../access-profile.component";
import { markAsDirty, modelToForm } from "@incert/incert-gui";

@Component({
  selector: "incert-internal-tools-access-profile-add",
  templateUrl: "./access-profile-add.component.html",
  styleUrls: ["./access-profile-add.component.scss"],
})
export class AccessProfileAddComponent implements OnInit {
  constructor(
    public accessProfileManager: AccessProfileManager,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
  ) {
    this.form = this.fb.group(
      modelToForm<AccessProfile>({
        title: ["", [Validators.required]],
        description: ["", [Validators.required]],
      }),
    );
  }

  public nameofForm = createNameof<AccessProfile>();
  form: UntypedFormGroup;

  accessProfile: AccessProfile;
  accessProfileComp: AccessProfileComponent;
  accessGroups: AccessGroupModel[] = [];
  accessLevels: AccessLevelModel[] = [];
  accessProfiles: AccessProfile[] = [];

  accessGroupChanges: AccessGroupModel[] = [];
  accessLevelChanges: AccessLevelModel[] = [];

  parentGroups: any;
  parentLevels: any;

  async ngOnInit() {
    await this.loadingService.load(async () => {
      if (!this.accessProfile) {
        this.accessProfile = {
          id: null,
          title: "",
          description: "",
          parent: null,
          interfaceType: null,
          accessLevels: [],
          accessGroups: [],
          children: [],
        };
      }
      this.form.patchValue(this.accessProfile);
      this.accessGroups = await this.accessProfileManager.getAccessGroups();
      this.accessLevels = await this.accessProfileManager.getAccessLevels();
      this.accessProfiles = await this.accessProfileManager.getAccessProfiles();

      this.assignChildren(this.accessGroups);
      this.assignChildren(this.accessProfiles);

      if (this.accessProfile.parent) {
        this.parentGroups = this.accessProfiles.find(
          (v) => v.id === this.accessProfile.parent,
        ).accessGroups;
        this.parentLevels = this.accessProfiles.find(
          (v) => v.id === this.accessProfile.parent,
        ).accessLevels;
      }
    });
  }

  async insertAccessProfile() {
    markAsDirty(this.form);
    if (this.form.valid) {
      await this.accessProfileManager.upsertAccessProfile({
        id: this.accessProfile ? this.accessProfile.id : null,
        title: this.form.get("title").value,
        description: this.form.get("description").value,
        parent: this.accessProfile.parent,
        interfaceType: null,
        accessGroups: this.accessGroupChanges,
        accessLevels: this.accessLevelChanges,
      });
      await this.accessProfileComp.ngOnInit();
      return true;
    }
    return false;
  }

  assignChildren(accessArray: any) {
    for (const a of accessArray) {
      if (a.parent) {
        const match = accessArray.find((v) => v.id === a.parent);
        match.children = match.children ? match.children : [];
        match.children.push(a);
      }
    }
  }

  isMatchId(id: any) {
    if (this.accessProfile) {
      return this.accessProfile.id === id;
    }
  }

  addElement(accessElement: any, accessChanges: any) {
    accessChanges.push(accessElement);
  }

  removeElement(accessElement: any, accessChanges: any) {
    accessChanges.splice(
      accessChanges.indexOf(
        accessChanges.find((v) => v.id === accessElement.id),
      ),
      1,
    );
  }

  clickParentProfile(event, ap: AccessProfile) {
    if (event.checked) {
      this.addParentProfile(ap);
    } else {
      this.removeParentProfile(ap);
    }
  }

  addParentProfile(ap: AccessProfile) {
    this.notificationService.notifySuccess("Profil Elemente wurden zugewiesen");
    this.accessProfile.parent = ap.id;
    this.parentGroups = ap.accessGroups;
    this.parentLevels = ap.accessLevels;

    for (const ag of ap.accessGroups) {
      if (this.accessProfile.accessGroups.find((v) => v.id === ag.id)) {
        if (this.accessGroupChanges.find((v) => v.id === ag.id)) {
          this.removeElement(ag, this.accessGroupChanges);
        }
      } else {
        if (!this.accessGroupChanges.find((v) => v.id === ag.id)) {
          this.addElement(ag, this.accessGroupChanges);
        }
      }
    }

    for (const al of ap.accessLevels) {
      if (this.accessProfile.accessLevels.find((v) => v.id === al.id)) {
        if (this.accessLevelChanges.find((v) => v.id === al.id)) {
          this.removeElement(al, this.accessLevelChanges);
        }
      } else {
        if (!this.accessLevelChanges.find((v) => v.id === al.id)) {
          this.addElement(al, this.accessLevelChanges);
        }
      }
    }
  }

  removeParentProfile(ap: AccessProfile) {
    this.notificationService.notifyInfo("Profil Elemente wurden entfernt");
    this.accessProfile.parent = null;
    this.parentGroups = null;
    this.parentLevels = null;

    for (const ag of ap.accessGroups) {
      if (this.accessProfile.accessGroups.find((v) => v.id === ag.id)) {
        if (!this.accessGroupChanges.find((v) => v.id === ag.id)) {
          this.addElement(ag, this.accessGroupChanges);
        }
      } else {
        if (this.accessGroupChanges.find((v) => v.id === ag.id)) {
          this.removeElement(ag, this.accessGroupChanges);
        }
      }
    }

    for (const al of ap.accessLevels) {
      if (this.accessProfile.accessLevels.find((v) => v.id === al.id)) {
        if (!this.accessLevelChanges.find((v) => v.id === al.id)) {
          this.addElement(al, this.accessLevelChanges);
        }
      } else {
        if (this.accessLevelChanges.find((v) => v.id === al.id)) {
          this.removeElement(al, this.accessLevelChanges);
        }
      }
    }
  }
}
