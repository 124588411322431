export function isEqual(a: any, b: any): boolean {
  if (typeof a !== "object" || typeof b !== "object") {
    return a === b;
  }

  if (a instanceof Date && b instanceof Date) {
    return (
      a.getTime() === b.getTime() &&
      a.getTimezoneOffset() === b.getTimezoneOffset()
    );
  }

  const propsA = Object.getOwnPropertyNames(a);
  const propsB = Object.getOwnPropertyNames(b);

  if (propsA.length !== propsB.length) {
    return false;
  }

  return propsA.every((prop) => isEqual(a[prop], b[prop]));
}
