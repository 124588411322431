import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "incert-source-view-overlay",
  templateUrl: "./source-view-overlay.component.html",
  styleUrls: ["./source-view-overlay.component.css"],
})
export class SourceViewOverlayComponent implements OnInit {
  form = new UntypedFormControl();
  public get source() {
    return this.form.value;
  }
  public set source(v: string) {
    this.form.patchValue(v);
  }
  constructor() {}
  ngOnInit(): void {
    this.form.setValue(this.source);
  }
}
