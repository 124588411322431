import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthHttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("TOKEN");
    if (token && !request?.headers.has("Authorization")) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].includes(err.status)) {
          this.router.navigate(["/"]);
        }

        const error = err.error?.message || err.statusText;
        console.error(err);
        return throwError(() => error);
      }),
    );
  }
}
