import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ArrayDataSource } from "@incert/incert-gui";
import { IconType } from "@incert/incert-core";

@Component({
  selector: "data-table-global-search",
  templateUrl: "./data-table-global-search.component.html",
  styleUrls: ["./data-table-global-search.component.scss"],
})
export class DataTableGlobalSearchComponent implements OnInit {
  @Input() searchString: string;
  @Input() arrayDataSource: ArrayDataSource<any>;
  @Input() placeholder: string;
  @Input() hasOptionButton: boolean;
  @Input() optionButtonIcon: keyof typeof IconType;
  @Input() optionButtonIconInverse: keyof typeof IconType;
  @Output() onOptionButtonClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.validate();
  }

  private validate() {
    if (!this.optionButtonIconInverse) {
      this.optionButtonIconInverse = this.optionButtonIcon;
    }
  }

  public onGlobalSearch() {
    if (this.arrayDataSource) {
      this.arrayDataSource.globalFilter(this.searchString);
    } else {
      console.error(
        "Provided ArrayDataSource is either invalid or cannot be queried",
      );
    }
  }
}
