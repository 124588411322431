import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "incert-internal-tools-show-comment-overlay",
  templateUrl: "./data-table-show-comment-overlay.component.html",
  styleUrls: ["./data-table-show-comment-overlay.component.scss"],
})
export class DataTableShowCommentOverlayComponent implements OnInit {
  @Input() comment: string;

  constructor() {}

  ngOnInit(): void {}
}
