import { Observable, ReplaySubject } from "rxjs";

export class OverlayHandle {
  public get header() {
    return this._header;
  }

  public set header(value: string) {
    this._header = value;
    this._headerChange$.next(value);
  }

  public get headerDescription() {
    return this._headerDescription;
  }

  public set headerDescription(value: string) {
    this._headerDescription = value;
    this._headerChange$.next(value);
  }

  private _headerChange$ = new ReplaySubject<string>();
  public headerChange$: Observable<string>;

  public constructor(
    public close: (data?: any) => void,
    private _header: string,
    private _headerDescription,
  ) {
    this._headerChange$.next(undefined);
    this.headerChange$ = this._headerChange$.asObservable();
  }
}
