import { ArrayType, DataConversion, Required } from "@incert/data-conversion";
import { FontFaceModel } from "./font-face.model";

@DataConversion()
export class FontFamilyModel {
  @Required()
  name: string;

  @Required()
  displayName: string;

  @Required()
  @ArrayType(() => import("./font-face.model").then((mod) => mod.FontFaceModel))
  fonts: FontFaceModel[];
}
