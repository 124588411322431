import { Component, Input, OnInit } from "@angular/core";
import { Severity } from "@incert/incert-core";

@Component({
  selector: "inline-label-text",
  templateUrl: "./inline-label-text.component.html",
  styleUrls: ["./inline-label-text.component.scss"],
})
export class InlineLabelTextComponent implements OnInit {
  @Input()
  public label: string;

  @Input()
  public data: string;
  @Input()
  public severity: keyof typeof Severity;

  @Input()
  public break = false;

  constructor() {}

  ngOnInit() {}
}
