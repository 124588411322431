import { Injectable } from "@angular/core";
import {
  Category,
  Memo,
  Tag,
} from "./memo.interface";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MemoManager {
  public dataChanged = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getMemos(): Promise<Array<Memo>> {
    return this.httpService.get<Array<Memo>>(
      this.settingsService.baseUrl + "memo",
    );
  }

  public saveMemo(memo: Memo) {
    return this.httpService.post<Memo>(
      this.settingsService.baseUrl + "memo",
      memo,
    );
  }

  public updateMemo(memo: Memo) {
    return this.httpService.put<Memo>(
      this.settingsService.baseUrl + "memo",
      memo,
    );
  }

  public getMemoHistory(id: number) {
    return this.httpService.get<Array<Memo>>(
      this.settingsService.baseUrl + "memo/" + id + "/history",
    );
  }

  public getMemoCategories(): Promise<Array<Category>> {
    return this.httpService.get<Array<Category>>(
      this.settingsService.baseUrl + "category",
    );
  }

  public getMemoTags() {
    return this.httpService.get<Array<Tag>>(
      this.settingsService.baseUrl + "tags",
    );
  }

  public saveCategory(category: Category) {
    return this.httpService.post<Category>(
      this.settingsService.baseUrl + "category",
      category,
    );
  }

  public deleteCategory(id: number) {
    return this.httpService.delete<Category>(
      this.settingsService.baseUrl + "category/" + id,
    );
  }

  public saveTag(tag: Tag) {
    return this.httpService.post<Tag>(
      this.settingsService.baseUrl + "tags",
      tag,
    );
  }

  public deleteTag(id: number) {
    return this.httpService.delete<Tag>(
      this.settingsService.baseUrl + "tags/" + id,
    );
  }

  public deleteHistoryForMemo(id: number) {
    return this.httpService.delete<Memo>(
      this.settingsService.baseUrl + "memo/" + id + "/history",
    );
  }

  public deleteSingleHistoryEntry(historyId: number) {
    return this.httpService.delete<Memo>(
      this.settingsService.baseUrl + "memohistory/" + historyId,
    );
  }

  public deleteMemo(id: number) {
    return this.httpService.delete<Memo>(
      this.settingsService.baseUrl + "memo/" + id,
    );
  }

  public markChanged() {
    this.dataChanged.next(true);
  }
}
