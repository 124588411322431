import { Component, OnInit } from "@angular/core";
import {
  ConfirmService,
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AccountManager } from "../account-manager.service";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableArrayComponent,
  DataTableArrayOptions,
  DataTableConfig,
  DataTableIconButtonsComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { AccountSystem } from "../../../core/models/account-system.interface";
import {
  DataTableLinkComponent,
  LinkDisplayOptions,
} from "../../../core/data-table-components/data-table-link/data-table-link.component";
import { AuthManager } from "../../../core/auth";
import { AddAccountComponent } from "./add-account/add-account.component";
import { Constants } from "../../../core/constants";
import { Account } from "../../../core/models/account.model";

@Component({
  selector: "sac-account",
  templateUrl: "./account-overview.component.html",
  styleUrls: ["./account-overview.component.scss"],
})
export class AccountOverviewComponent implements OnInit {
  public accounts: Array<Account> = [];
  public dtConfig: DataTableConfig<Account>;
  public arrayDataSource: ArrayDataSource<Account>;

  constructor(
    private accountManager: AccountManager,
    private notificationService: NotificationService,
    private confirmService: ConfirmService,
    private overlayService: OverlayService,
    private loadingService: LoadingService,
    private authManager: AuthManager,
  ) {
    this.accountManager.accountDataChanged.subscribe(() => this.initialize());
  }

  async ngOnInit() {
    await this.initialize();
  }

  public async initialize() {
    await this.loadingService.load(async () => {
      await Promise.all([this.getAccounts()]).then(() => {
        this.createDtConfig();
      });
    });
  }

  public async getAccounts() {
    try {
      this.accounts = await this.accountManager.getAccountsAssocWithSystems();
    } catch (e) {
      this.notificationService.notifyError(
        "Kundenübersicht",
        "Leider kann die Kundenübersicht nicht angezeigt werden.",
      );
    }
  }

  private async createDtConfig() {
    this.arrayDataSource = new ArrayDataSource<Account>(this.accounts);
    this.dtConfig = {
      mode: "pagination",
      data: this.arrayDataSource,
      rows: 25,
      filterArea: {
        filters: [
          {
            header: "Name",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Zugewiesene Kundensysteme",
            property: (v) => v.get("accountSystems"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Postleitzahl",
            property: (v) => v.get("zipCode"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Land",
            property: (v) => v.get("country", "name"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: Constants.countryOptions(),
            }),
          },
          {
            header: "Sprache",
            property: (v) => v.get("language", "iso2"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: Constants.languageOptions(),
            }),
          },
          {
            header: "Währung",
            property: (v) => v.get("currency", "iso3"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: Constants.currencyOptions(),
            }),
          },
        ],
      },
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
          sort: true,
        },
        {
          header: "Zugewiesene Kundensysteme",
          property: (v) => v.get("accountSystems"),
          component: createComponentConfiguration(DataTableArrayComponent, {
            setArrayData: (
              row: Account,
            ): DataTableArrayOptions<AccountSystem> => {
              return {
                arrayData: row.accountSystems,
                displayProperty: "name",
                maxItems: 5,
              };
            },
          }),
        },
        {
          header: "Postleitzahl",
          property: (v) => v.get("zipCode"),
          sort: true,
        },
        {
          header: "Land",
          property: (v) => v.get("country", "name"),
          sort: true,
        },
        {
          header: "Sprache",
          property: (v) => v.get("language", "iso2"),
          sort: true,
        },
        {
          header: "Währung",
          property: (v) => v.get("currency", "iso3"),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.writeAccount,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  hidden: !this.writeAccount,
                  onClick: (account: Account) =>
                    this.showAccountDetails(account),
                  tooltip: "Bearbeiten",
                },
                {
                  icon: "delete",
                  hidden: !this.executeAccount,
                  onClick: (account: Account) => this.deleteAccount(account),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Kunde",
              onClick: () => this.showNewAccountOverlay(),
              onShow: () => this.writeAccount,
            },
          ],
        }),
      ],
    };
  }

  public async showNewAccountOverlay() {
    await this.overlayService.show<AddAccountComponent>({
      type: AddAccountComponent,
      header: "Kunde hinzufügen",
      size: OverlaySize.medium,
      displayAsSidebar: true,
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: (component) => {
            return component
              .saveAccount()
              .then((res) => (res ? this.initialize() && true : false));
          },
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: (component) => {
            return component.cancel();
          },
        },
      ],
    });
  }

  public async showAccountDetails(account: Account) {
    await this.overlayService.show<AddAccountComponent>({
      type: AddAccountComponent,
      size: OverlaySize.medium,
      header: "Kunde bearbeiten",
      init: (component) => {
        component.onEditAccount(account);
      },
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: (component) => {
            return component
              .saveAccount()
              .then((res) => (res ? this.initialize() && true : false));
          },
        },
        {
          displayAsLink: true,
          label: "Schließen",
          action: (component) => {
            return component.cancel();
          },
        },
      ],
    });
  }

  public async deleteAccount(account: Account) {
    const response = await this.confirmService.confirmError(
      "",
      "Kunde löschen",
    );
    if (response) {
      {
        try {
          await this.accountManager.deleteAccount(account.id);
          await this.initialize();
          this.notificationService.notifySuccess(
            "Der Kunde wurde erfolgreich gelöscht.",
            "Kunde löschen",
          );
        } catch (e) {
          if (e.status) {
            this.notificationService.notifyError(
              "Es ist mindestens ein Testsystem mit diesem Kunden verknüpft.",
              "Kunde löschen",
            );
          }
        }
      }
    }
  }

  public get writeAccount(): boolean {
    return this.authManager.hasPermission("writeAccount");
  }

  public get executeAccount(): boolean {
    return this.authManager.hasPermission("executeAccount");
  }
}
