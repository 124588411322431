import {Component, OnInit} from '@angular/core';
import {
  ArrayDataSource, createFilterDefinition,
  DataTableConfig, DateFilterComponent, SelectFilterComponent, TextFilterComponent
} from "@incert/incert-gui";
import {ProtocolManager} from "../protocol-manager";
import {AccountSystem} from "../../../core/models/account-system.interface";

export interface InstallationHistory {
  accountSystem: AccountSystem;
  statusOld: string;
  statusNew: string;
  date: Date;
}

@Component({
  selector: 'incert-installation-history',
  templateUrl: './installation-history.component.html',
  styleUrls: ['./installation-history.component.css']
})
export class InstallationHistoryComponent implements OnInit {

  constructor(private protocolManager: ProtocolManager) {
  }

  dtConfig: DataTableConfig<InstallationHistory>;
  installationHistoryData: InstallationHistory[];

  async ngOnInit() {
    this.installationHistoryData = await this.protocolManager.getInstallationHistoryEntries();
    this.createDtConfig();
  }

  createDtConfig() {
    this.dtConfig = {
      mode: "pagination",
      data: new ArrayDataSource(this.installationHistoryData),
      rows: 30,
      columns: [
        {
          header: "Name",
          property: (v) => v.get("accountSystem", "name"),
          sort: true
        },
        {
          header: "Kürzel",
          property: (v) => v.get("accountSystem", "short"),
          sort: true
        },
        {
          header: "Status alt",
          property: (v) => v.get("statusOld"),
          sort: true
        },
        {
          header: "Status neu",
          property: (v) => v.get("statusNew"),
          sort: true
        },
        {
          header: "Datum",
          property: (v) => v.get("date")
        }
      ],
      filterArea: {
        filters: [
          {
            header: "Name",
            property: (v) => v.get("accountSystem", "name"),
            filter: createFilterDefinition(TextFilterComponent, {})
          },
          {
            header: "Kürzel",
            property: (v) => v.get("accountSystem", "short"),
            filter: createFilterDefinition(TextFilterComponent, {})
          },
          {
            header: "Status alt",
            property: (v) => v.get("statusOld"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: [
                {label: "added", value: "added"},
                {label: "installed", value: "installed"},
                {label: "active", value: "active"},
                {label: "productive", value: "productive"}
              ],
            }),
          },
          {
            header: "Status neu",
            property: (v) => v.get("statusNew"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: [
                {label: "added", value: "added"},
                {label: "installed", value: "installed"},
                {label: "active", value: "active"},
                {label: "productive", value: "productive"}
              ],
            }),
          },
          {
            header: "Datum",
            property: (v) => v.get("date"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            })
          }
        ]
      }
    };
  }
}
