export interface Partitions<T> {
  [key: string]: T[];
}

export function partitionBy<T>(array: T[], property: keyof T): Partitions<T> {
  const partitions: Partitions<T> = {};
  const uniqueValues = Array.from(new Set(array.map((v) => v[property])));

  // We create different arrays depending on the number of uniqueValues
  for (const uniqueValue of uniqueValues) {
    partitions[uniqueValue.toString()] = [];
  }

  for (const value of array) {
    partitions[value[property].toString()].push(value);
  }

  return partitions;
}

export function partitionByFn<T>(
  array: T[],
  fn: (val: T) => string,
): Partitions<T> {
  const partitions: Partitions<T> = {};
  const uniqueValues = Array.from(new Set(array.map((v) => fn(v))));

  // We create different arrays depending on the number of uniqueValues
  for (const uniqueValue of uniqueValues) {
    partitions[uniqueValue.toString()] = [];
  }

  for (const value of array) {
    partitions[fn(value)].push(value);
  }

  return partitions;
}
