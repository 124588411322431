import { Component, Inject, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table";
import { DataTableArrayOptions } from "./data-table-array.interface";

@Component({
  selector: "data-table-array",
  templateUrl: "./data-table-array.component.html",
  styleUrls: ["./data-table-array.component.scss"],
})
export class DataTableArrayComponent<T> implements OnInit {
  public config: DataTableArrayOptions<T> = null;
  public hoverData = "";
  public setArrayData: (row: T) => DataTableArrayOptions<any> = () => null;

  constructor(@Inject(ROW_DATA) private row_data: any) {}

  ngOnInit(): void {
    this.config = this.setArrayData(this.row_data);

    if (this.config.arrayData) {
      this.validateConfig();
      this.createToolTipData();
    }
  }

  private validateConfig() {
    if (!this.config.maxItems) {
      this.config.maxItems = 5;
    }
  }

  public getArrayData(arr: T[], properties: Array<keyof T> | keyof T) {
    let arrData = "";
    if (Array.isArray(properties)) {
      properties.forEach((p, i) => {
        if (i < properties.length) {
          arrData += arr[p as any] + " ";
        }
      });
    } else {
      arrData += arr[properties as any];
    }
    return arrData.trim();
  }

  public createToolTipData() {
    this.config.arrayData.forEach((d: T, i: number) => {
      if (Array.isArray(this.config.displayProperty)) {
        this.config.displayProperty.forEach((prop) => {
          this.hoverData += ` ${d[prop]}`;
        });
      } else {
        this.hoverData += `${d[this.config.displayProperty]}`;
      }
      if (i !== this.config.arrayData.length - 1) {
        this.hoverData += ", ";
      }
    });
  }
}
