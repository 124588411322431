<p-tabView styleClass="tabs-left">
    <p-tabPanel [header]="item.headline" *ngFor="let item of tabsContent; let i=index">
      <div class="default-tabs-content">
        <img *ngIf="item.image" src="{{item.image}}" alt="{{item.headline}}">
        <div class="default-tabs-headline">
          <h1>{{item.headline}}</h1>
          <a *ngIf="item.url" [href]="item.url" target="_blank" class="default-tabs-url" [incTooltip]="item.headline" tooltipPosition="top">
            <incert-icon iconType="externalLink"></incert-icon>
          </a>
        </div>
        <p *ngIf="item.text">{{item.text}}</p>
        <p *ngIf="item.body" [innerHTML]="item.body"></p>
      </div>
    </p-tabPanel>
</p-tabView>
