import {ReplaySubject} from "rxjs";
import {Injectable} from "@angular/core";
import {HttpService} from "../http.service";
import {APIService} from "../api.service";
import {Account} from "../models/account.model";

@Injectable({providedIn: "root"})
export class AccountService {
  public constructor(private httpService: HttpService,
                     private settingsService: APIService) {
  }

  private _accounts$ = new ReplaySubject<Account[]>(1);
  private _accountsInit = false;

  public refreshAccounts() {
    this.httpService.get<Account[]>(
      this.settingsService.baseUrl + "account")
      .then((v) => this._accounts$.next(v));
  }

  public get accounts$() {
    if (!this._accountsInit) {
      this.refreshAccounts();
      this._accountsInit = true;
    }
    return this._accounts$.asObservable();
  }

}
