import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LegendComponent } from "./legend.component";
import { StatusComponent } from "./status/status.component";

@NgModule({
  imports: [CommonModule],
  exports: [LegendComponent, StatusComponent],
  declarations: [LegendComponent, StatusComponent],
})
export class LegendModule {}
