import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import {
  OverlayAction,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { ImagePreviewComponent } from "./image-preview/image-preview.component";
import { I18nService } from "@incert/i18n";

interface previewData {
  show: boolean;
  header?: string;
  isDownloadable?: boolean;
  downloadUrl?: string;
  voucherCode?: string;
}

@Component({
  selector: "incert-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.scss"],
})
export class ImageComponent {
  @Input()
  get src(): string {
    return this._src;
  }

  set src(value: string) {
    if (value && typeof value === "string" && value.length) {
      this.hasError = false;
    }
    this._src = value;
    this._trustedSrc = this.domSanitizer.bypassSecurityTrustUrl(value);
  }

  @Input()
  maxWidth?: string;

  @Input()
  maxHeight?: string;

  @Input()
  rounded? = false;

  @Input()
  preview?: previewData;

  @Input()
  tooltip?: string;

  @Input()
  defaultImage? = true;

  get roundedImg() {
    return this.rounded ? "rounded" : null;
  }

  get previewImg() {
    return this.preview?.show ? "preview" : null;
  }

  get trustedSrc() {
    return this._trustedSrc;
  }

  private _src: string;

  private _trustedSrc: SafeUrl;

  @Output() error = new EventEmitter<ErrorEvent>();
  @Output() load = new EventEmitter<Event>();
  @Output() loadeddata = new EventEmitter<Event>();
  @Output() loadedmetadata = new EventEmitter<Event>();
  @Output() loadstart = new EventEmitter<Event>();
  @Output() lostpointercapture = new EventEmitter<Event>();

  hasError = false;

  constructor(
    public domSanitizer: DomSanitizer,
    private overlayService: OverlayService,
    private i18n: I18nService,
  ) {}

  handleError(event: ErrorEvent) {
    this.hasError = true;
    this.error.emit(event);
  }

  public async openPreview() {
    if (!this.preview?.show) return;
    const actions: OverlayAction<ImagePreviewComponent>[] = [];
    if (this.preview?.isDownloadable) {
      let downloadUrl = "";
      if (this.preview?.downloadUrl) {
        downloadUrl = this.preview.downloadUrl;
      }
      actions.push({
        label: "Download",
        action: () => {
          if (downloadUrl) {
            window.open(downloadUrl);
          }
          return true;
        },
      });
    }
    await this.overlayService.show<ImagePreviewComponent>({
      header: this.preview?.header,
      type: ImagePreviewComponent,
      init: (comp) => {
        comp.src = this._trustedSrc;
        comp.alt = this.preview?.header;
      },
      size: OverlaySize.medium,
      actions: [
        ...actions,
        {
          label: "Schließen",
          displayAsLink: true,
          action: () => true,
        },
      ],
    });
  }
}
