import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingWrapperComponent } from "./loading-wrapper.component";
import { BlockUIModule } from "primeng/blockui";

@NgModule({
  imports: [CommonModule, BlockUIModule],
  declarations: [LoadingWrapperComponent],
  exports: [LoadingWrapperComponent],
})
export class LoadingWrapperModule {}
