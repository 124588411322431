import { Component } from "@angular/core";

@Component({
  selector: "sac-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor() {
    // Important for detecting global window resize events
    window.onresize = (e) => {};
  }
}
