<a *ngIf="this?.dtConfig; else noHoverTable" (click)="fix($event, op)" class="pointer">
  <incert-icon iconType="info" showLabel="true" [label]="hoverLabel"/>
</a>
<ng-template #noHoverTable>
  {{ hoverLabel }}
</ng-template>

<icon-button *ngIf="helpText" iconType="help" iconOnly="true" [tooltip]="helpText"></icon-button>

<p-overlayPanel #op appendTo="body">
  <data-table [config]="this?.dtConfig"></data-table>
</p-overlayPanel>
