export interface DataTableArrayOptions<T> {
  arrayData: T[];

  displayProperty: keyof T | Array<keyof T>;

  fallbackString?: string | keyof T;

  maxItems?: number;

  customOverflowText?: string;

  overflowClass?: string;
}
