import { Component, Input, OnInit } from "@angular/core";
import { AccountSystem } from "../../../core/models/account-system.interface";
import { ArrayDataSource } from "@incert/incert-gui";
import { AccountSystemConfigFilterSaveComponent } from "./account-system-config-filter-save/account-system-config-filter-save.component";
import { OverlayService, OverlaySize } from "@incert/incert-core";
import { SearchEngineService } from "../../../core/autocomplete/search-engine-service";
import { AutoCompleteService } from "../../../core/autocomplete/autocomplete.service";
import {
  SearchEngine,
  SearchEngineMode,
  SearchEngineResult,
} from "../../../core/autocomplete/search-engine.interface";
import { DiscrEnum } from "../../../core/enums/discr.enum";
import { AutoComplete } from "primeng/autocomplete";

@Component({
  selector: "account-system-config-filter",
  templateUrl: "./account-system-config-filter.component.html",
  styleUrls: ["./account-system-config-filter.component.scss"],
})
export class AccountSystemConfigFilterComponent implements OnInit {
  @Input() accountSystems: Array<AccountSystem> = [];
  @Input() accountSystemDS: ArrayDataSource<AccountSystem> = null;
  searchEngine: SearchEngine;
  searchString: any = null;
  searchStringArchive: SearchEngineResult[] = [];
  suggestions: Array<SearchEngineResult> = [];
  protected readonly SearchEngineMode = SearchEngineMode;

  constructor(
    private overlayService: OverlayService,
    private searchEngineService: SearchEngineService,
    private autoCompleteService: AutoCompleteService,
  ) {}

  ngOnInit(): void {
    this.searchEngine = this.autoCompleteService.setSearchEngine(
      SearchEngineMode.GENERAL,
    );
  }

  async onElementChosen(autoComplete: AutoComplete, event: any) {
    if (event.type === SearchEngineMode.TEASER) {
      this.searchEngine = this.autoCompleteService.setSearchEngine(
        SearchEngineMode.TEASER,
      );
    }

    this.searchStringArchive.push(event);
    let tempDisplay = "";
    for (const searchString of this.searchStringArchive) {
      tempDisplay += searchString.display;
    }
    this.searchString = { display: tempDisplay };
    await this.searchEngine.onElementChosen(autoComplete, event);
  }

  async search(event) {
    this.autoCompleteService
      .getSuggestions(event.query.split(/<=|<|>=|>|!=|=|&&|[||]/gm).pop(), {
        system: this.getCurrentDiscr(),
      })
      .then((suggestions) => {
        this.suggestions = [...suggestions];
      });
    if (this.searchEngine.getMode() === SearchEngineMode.GENERAL) {
      this.searchEngine.onInputWritten(this.searchString);
    }
  }

  public async saveFilterOverlay() {
    await this.overlayService.show<AccountSystemConfigFilterSaveComponent>({
      size: OverlaySize.medium,
      type: AccountSystemConfigFilterSaveComponent,
      header: "Filter hinzufügen",
      init: (component) => {
        component.searchString = this.searchString;
      },
      actions: [
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
        {
          label: "Lokal Speichern",
          action: (component) =>
            component.saveFilter(parseInt(localStorage.getItem("USER_ID"), 10)),
        },
        {
          label: "Global Speichern",
          action: (component) => component.saveFilter(),
        },
      ],
    });
  }

  clearResults() {
    this.searchEngine.cleanUp();
    this.searchEngine = this.autoCompleteService.setSearchEngine(
      SearchEngineMode.GENERAL,
    );
    this.searchEngineService.onResult.next([]);
    this.suggestions = [];
    this.searchString = null;
    this.searchStringArchive = [];
  }

  private getCurrentDiscr() {
    if (this.accountSystems) {
      return this.accountSystems[0].discr;
    } else {
      return DiscrEnum.ecom;
    }
  }
}
