<form-field [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <p-calendar
    showIcon="true" showButtonBar="true" appendTo="body" dateFormat="dd.mm.yy"
    [formControl]="form.get('filter')"
    [selectionMode]="selectionMode"
    (onClose)="filterExplicit()"
    [yearRange]="yearRangeString"
    [placeholder]="floatingLabel ? '' : config.header">
  </p-calendar>
</form-field>




