import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";
import { I18nModule } from "@incert/i18n";
import { FormExtensionModule } from "../../form-extension";
import { AutocompleteFilterComponent } from "./autocomplete-filter.component";

@NgModule({
  declarations: [AutocompleteFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    FormExtensionModule,
    AutoCompleteModule,
    DropdownModule,
    InputTextModule,
  ],
})
export class AutocompleteFilterModule {}
