import { Component, Injectable, OnInit } from "@angular/core";
import { NotificationService } from "@incert/incert-core";
import { CountryService } from "../../../../core/country/country.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Language } from "../../../../core/language/language";
import { Utils } from "../../../../core/utils";
import { Country } from "../../../../core/country/country";
import { AccountManager } from "../../account-manager.service";
import { CurrencyService } from "../../../../core/currency/currency.service";
import { LanguageService } from "../../../../core/language/language.service";
import { Account, Currency } from "../../../../core/models/account.model";

@Component({
  selector: "sac-new-account-dialog",
  templateUrl: "./add-account.component.html",
  styleUrls: ["./add-account.component.scss"],
})
@Injectable()
export class AddAccountComponent implements OnInit {
  public languages: Array<Language> = [];
  public currencies: Array<Currency> = [];
  public countries: Array<Country> = [];
  public accountForm: UntypedFormGroup;
  public isEditMode = false;

  constructor(
    private languageService: LanguageService,
    private currencyService: CurrencyService,
    private countryService: CountryService,
    private notificationService: NotificationService,
    private accountManager: AccountManager,
  ) {
    this.initFormGroup();
  }

  async ngOnInit() {
    await this.getAccountData();
  }

  private async getAccountData() {
    await Promise.all([
      this.getLanguages(),
      this.getCurrencies(),
      this.getCountries(),
    ]);
  }

  private async getLanguages() {
    try {
      this.languages = await this.languageService.getLanguages();
    } catch (e) {
      this.notificationService.notifyError(
        "Sprachen konnten nicht geladen werden",
        "Es ist ein Fehler aufgetreten",
      );
    }
  }

  private async getCurrencies() {
    try {
      this.currencies = await this.currencyService.getCurrencies();
    } catch (e) {
      this.notificationService.notifyError(
        "Währungen konnten nicht geladen werden",
        "Es ist ein Fehler aufgetreten",
      );
    }
  }

  private async getCountries() {
    try {
      this.countries = await this.countryService.getCountries();
    } catch (e) {
      this.notificationService.notifyError(
        "Länder konnten nicht geladen werden",
        "Es ist ein Fehler aufgetreten",
      );
    }
  }

  public async saveAccount() {
    this.accountForm = Utils.markInvalidFieldsAsDirty(this.accountForm);
    let createdAccount = null;

    if (this.accountForm.valid) {
      try {
        if (this.isEditMode) {
          createdAccount = await this.accountManager.updateAccount(
            this.accountForm.value,
          );
          this.notificationService.notifySuccess(
            "Kundendaten wurden erfolgreich gespeichert",
            "Speicherung erfolgreich",
          );
        } else {
          createdAccount = await this.accountManager.insertAccount(
            this.accountForm.value,
          );
          this.accountManager.accountCreated.next(createdAccount);
          this.notificationService.notifySuccess(
            "Kundendaten wurden erfolgreich gespeichert",
            "Speicherung erfolgreich",
          );
        }

        return createdAccount;
      } catch (e) {}
    } else {
      this.accountForm.markAllAsTouched();
      this.notificationService.notifyInfo(
        "Bitte Eingaben kontrollieren",
        "Eingabedaten fehlerhaft",
      );
    }
    return false;
  }

  private initFormGroup() {
    this.accountForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      zipCode: new UntypedFormControl(null, Validators.required),
      country: new UntypedFormControl(null, Validators.required),
      language: new UntypedFormControl(null, Validators.required),
      currency: new UntypedFormControl(null, Validators.required),
    });
  }

  private clearForm() {
    this.accountForm.reset();
  }

  public onEditAccount(account: Account) {
    this.isEditMode = true;
    this.accountForm.patchValue(account);
  }

  public cancel() {
    this.clearForm();

    return true;
  }
}
