import { MinLengthValidator } from "../../validators/minLengthValidator";

export function MinLength(minLength: number) {
  /* tslint:disable:only-arrow-functions */
  return function (target: any, key: string) {
    const type = Reflect.getMetadata("design:type", target, key);

    if (type !== String) {
      throw new Error("Only String is allowed for MinLength Decorator!");
    }
    if (!target["__dataConversionMapping"]) {
      target["__dataConversionMapping"] = [];
    }
    if (!target["__dataConversionMapping"][key]) {
      target["__dataConversionMapping"][key] = {};
    }

    let previousValidators: any = {};

    if (target["__dataConversionMapping"][key]["validators"]) {
      previousValidators = target["__dataConversionMapping"][key];
    }

    target["__dataConversionMapping"][key] = {
      ...target["__dataConversionMapping"][key],
      minLength: minLength,
      validators: [...previousValidators, MinLengthValidator],
    };
  };
}
