<div class='flex-container'>
  <form class='innerForm'>
    <div>
      <img src='incert-logo.png' alt='Kundenliste Logo' width='250px'>
    </div>
    <div class="separator-top">
      <form-field label="Email-Adresse">
        <input [(ngModel)]='email' type='email' name='username'>
      </form-field>
      <form-field label="Passwort">
        <input [(ngModel)]='password' type='password' name='password' (keyup.enter)="login()">
      </form-field>
    </div>

    <div class='loading-indicator' *ngIf='isLoading'>
      <loading-indicator [isLoading]='isLoading'></loading-indicator>
    </div>

    <div class='separator-top'>
      <p-button class='full-width separator-bottom' (click)='login()'
                label='Anmelden'></p-button>
    </div>
  </form>
</div>

<p-dialog [(visible)]='display' [closable]='false' *ngIf='display'>
  <p-header>
    Passwort ändern
  </p-header>

  <div class='p-field'>
    <label>Altes Passwort</label>
    <input type='password' pInputText [(ngModel)]='oldPassword'>
  </div>
  <div class='p-field'>
    <label>Neues Passwort</label>
    <input type='password' pInputText [(ngModel)]='newPassword'>
  </div>
  <div class='p-field'>
    <label>Neues Password</label>
    <input type='password' pInputText [(ngModel)]='newPasswordConfirm'>
  </div>

  <p-footer>
    <p-button (click)='onClose()' label='Abbrechen'></p-button>
    <p-button (click)='save()' label='Speichern'></p-button>
  </p-footer>
</p-dialog>
