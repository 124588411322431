<form [formGroup]="form">
  <form-field label="Kürzel" *ngIf="newContact">
    <p-dropdown [options]="systemSelection" [formControlName]="nameofForm('short')" name="test" placeholder="Kürzel"></p-dropdown>
  </form-field>
  <form-field label="Rolle" *ngIf="newContact">
    <p-dropdown [options]="roleSelection" [formControlName]="nameofForm('contactRole')" name="test" placeholder="Rolle"></p-dropdown>
  </form-field>
  <form-field label="Anrede">
    <p-dropdown [options]="genderSelection" [formControlName]="nameofForm('gender')" name="test" placeholder="Anrede"></p-dropdown>
  </form-field>
  <div class="grid">
    <div class="col">
      <form-field label="Vorname">
        <input placeholder="Vorname" type="text" [formControlName]="nameofForm('firstName')"/>
      </form-field>
    </div>
    <div class="col">
      <form-field label="Nachname">
        <input placeholder="Nachname" type="text" [formControlName]="nameofForm('lastName')"/>
      </form-field>
    </div>
  </div>
  <form-field label="Email">
    <input placeholder="Email" type="email" [formControlName]="nameofForm('email')"/>
  </form-field>
</form>
