import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shorten",
})
export class ShortenPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value.length > 50) {
      return value.slice(0, 42) + "...";
    }

    return value;
  }
}
