import { Component, Inject, Input, OnInit } from "@angular/core";
import { ROW_DATA } from "@incert/incert-gui";
import {
  AccountSystem,
  AccountSystemSubdomain,
} from "../../models/account-system.interface";
import { ClipboardService } from "../../../../../../../shared-libs/incert-core/src/lib/services/clipboard/clipboard.service";

type SubdomainLink = Partial<AccountSystemSubdomain>;

@Component({
  selector: "subdomain-link",
  templateUrl: "./data-table-subdomain.component.html",
  styleUrls: ["./data-table-subdomain.component.scss"],
})
export class DataTableSubdomainComponent implements OnInit {
  @Input() accountSystem: AccountSystem;

  @Input() loginPermission: boolean;

  public MAX_LINK_LENGTH = 60;

  public resolveRowData: (row: any) => any = (): Array<SubdomainLink> => null;

  constructor(
    @Inject(ROW_DATA) private column_data: any,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit(): void {
    this.accountSystem = this.resolveRowData(this.column_data);

    this.accountSystem.accountSystemSubDomains =
      this.accountSystem.accountSystemSubDomains.sort((a1) => {
        return a1.mainSubDomain ? -1 : 1;
      });
  }

  public async copyToClipboard(link: string) {
    await this.clipboardService.copyToClipboard(link, {
      icon: "check",
      message: "Kopiert",
      disabledClick: true,
      duration: 1500,
    });
  }
}
