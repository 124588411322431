import { Component, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "go-back-action",
  templateUrl: "./go-back-action.component.html",
})
export class GoBackActionComponent implements OnInit {
  @Input() text = "zurück";

  constructor() {}

  ngOnInit() {}
}
