import { Inject, Injectable } from "@angular/core";
import { ApiHttpAccess } from "./http/api-http-access.service";
import { ENVIRONMENT } from "./environment.token";
import {
  ConfigurationEntry,
  Environment,
  TranslatedConfigurationEntry,
  InputConfig,
} from "../models";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { HttpParams } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class ConfigurationService {
  configurationCache: Map<string, any>;

  constructor(
    private apiHttpAccess: ApiHttpAccess,
    @Inject(ENVIRONMENT) private environment: Environment,
    private fb: UntypedFormBuilder,
  ) {}

  async getConfigurationValue(key: string): Promise<string> {
    return (await this.getConfigurationValues([key]))[key] ?? "";
  }

  async getConfigurationValues(
    keys: string[],
  ): Promise<{ [key: string]: string }> {
    if (!this.configurationCache) {
      this.configurationCache = new Map<string, any>();
    }
    const result = {};
    for (const key of keys) {
      if (this.configurationCache.has(key)) {
        result[key] = this.configurationCache.get(key);
        keys.splice(keys.indexOf(key), 1);
      }
    }
    if (keys.length) {
      const entries = await this.getConfigurationsByKeys(keys);
      for (const entry of entries) {
        result[entry.key] = entry.value;
        this.configurationCache.set(entry.key, entry.value);
      }
    }
    return result;
  }

  async getConfigurationsByKeys(
    keys: string[],
  ): Promise<TranslatedConfigurationEntry[]> {
    if (!keys.length) {
      return [];
    }

    const keyQuery = keys.map((k) => '"' + k + '"').join(",");
    const langCode = this.environment.currentLanguage.code;

    return await this.apiHttpAccess.get<TranslatedConfigurationEntry[]>(
      `configuration?q=in(key,(${keyQuery}))&language=${langCode}`,
      TranslatedConfigurationEntry,
    );
  }

  async updateConfigurations(values: { [key: string]: string }) {
    await this.apiHttpAccess.put(
      "configuration/value",
      Object.keys(values).map((key) => ({
        key,
        value: values[key],
      })),
    );
  }

  async updateSubShopConfigurations(subShops: any, key: string) {
    await this.apiHttpAccess.put("configuration/value/subShop", {
      key: key,
      subShops: subShops,
    });
  }

  async getConfigurationGroups() {
    return await this.apiHttpAccess.get("configuration/group");
  }

  async getInputConfigurationsRaw(
    groupId?: string,
    filter?: string,
  ): Promise<TranslatedConfigurationEntry[]> {
    let params: HttpParams = new HttpParams();
    if (groupId) {
      params = params.append("groupId", groupId);
    }
    if (filter) {
      params = params.append("filter", filter);
    }
    return await this.apiHttpAccess.get("configuration/inputConfig?" + params);
  }

  public async getInputConfigurations(
    configurations: TranslatedConfigurationEntry[],
    formGroup: UntypedFormGroup,
  ) {
    const inputConfigurations: InputConfig[] = [];
    for (const configuration of configurations) {
      let inputConfigOptions = null;
      if (!configuration.inputConfigOptions && configuration.setFunction) {
        inputConfigOptions = await this.getConfigurationSetFunction(
          configuration.setFunction,
        );
      } else {
        inputConfigOptions = configuration.inputConfigOptions;
      }
      inputConfigurations.push({
        name: configuration.key,
        title: configuration.title ?? configuration.key,
        description: configuration.description,
        initialValue: configuration.value,
        control: formGroup.get(configuration.key) as UntypedFormControl,
        group: formGroup,
        options: inputConfigOptions,
        groupId: configuration.groupId,
        subShopId: configuration.subShopId,
      });
    }
    return inputConfigurations;
  }

  public async getConfigurationSetFunction(setFunction: string) {
    return await this.apiHttpAccess.get(
      "configuration/setFunction/" + encodeURIComponent(setFunction),
    );
  }

  public configurationsToModel(configurations: ConfigurationEntry[]) {
    const model = {};
    for (const configuration of configurations) {
      model[configuration.key] = [configuration.value];
    }
    return this.fb.group(model);
  }
}
