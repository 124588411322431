import { Component, Input, OnChanges, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  DataTableConfig,
} from "@incert/incert-gui";
import { User } from "../user.interface";
import { NotificationService } from "@incert/incert-core";
import { UserDetailCheckboxComponent } from "./user-detail-checkbox/user-detail-checkbox.component";
import { ChangeListService } from "../change-list.service";
import { UserManager } from "../user-manager.service";
import { AccountSystemGroup } from "../../account-system-group/account-system-group";
import { AccountSystemGroupManager } from "../../account-system-group/account-system-group-manager.service";

@Component({
  selector: "user-detail-edit",
  templateUrl: "./user-detail-edit.component.html",
  styleUrls: ["./user-detail-edit.component.scss"],
})
export class UserDetailEditComponent implements OnInit, OnChanges {
  @Input() user: User;

  public editAccountSystemGroupsConfig: DataTableConfig<AccountSystemGroup>;
  public accountSystemGroups: Array<AccountSystemGroup>;
  public notAssignedAccountSystemGroups: Array<AccountSystemGroup>;

  constructor(
    private accountSystemGroupManager: AccountSystemGroupManager,
    private changeListService: ChangeListService,
    private notificationService: NotificationService,
    private userManager: UserManager,
  ) {}

  async ngOnInit() {
    await this.getAccountSystemData();
  }

  public async getAccountSystemData() {
    if (this.user.id !== -1) {
      this.notAssignedAccountSystemGroups =
        await this.accountSystemGroupManager.getNotAssignedAccountSystemGroupsByUserId(
          this.user.id,
        );
      this.createDataTableConfig();
    }

    this.accountSystemGroups =
      await this.accountSystemGroupManager.getAccountSystemGroups();
  }

  async ngOnChanges() {
    await this.getAccountSystemData();
  }

  public createDataTableConfig() {
    this.editAccountSystemGroupsConfig = {
      mode: "pagination",
      data: new ArrayDataSource(this.notAssignedAccountSystemGroups),
      rows: 20,
      columns: [
        {
          header: "Id",
          property: (v) => v.get("id"),
        },
        {
          header: "Name",
          property: (v) => v.get("name"),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(UserDetailCheckboxComponent, {
            resolveRowData: (row: AccountSystemGroup) => row.id,
          }),
        },
      ],
    };
  }

  async save() {
    try {
      if (this.changeListService.getChangeList().length > 0) {
        const addedSystems = this.changeListService
          .getChangeList()
          .map((addedService: number) => {
            return this.accountSystemGroups.find(
              (system) => system.id === addedService,
            );
          });

        await this.accountSystemGroupManager.addAccountSystemGroupsToUser(
          this.user.id,
          addedSystems,
        );
        this.notAssignedAccountSystemGroups =
          await this.accountSystemGroupManager.getNotAssignedAccountSystemGroupsByUserId(
            this.user.id,
          );
        this.notificationService.notifySuccess(
          addedSystems.length + " Änderungen wurden  vorgenommen",
          "Systemgruppen hinzufügen",
        );

        this.userManager.userData$.next(undefined);
      } else {
        this.notificationService.notifyInfo(
          "Es wurden keine Änderungen vorgenommen",
          "Keine Änderungen",
        );
      }
      return true;
    } catch (e) {
      this.notificationService.notifyError(
        "Es ist ein Fehler aufgetreten" + e.message,
        "Fehler",
      );
    }
    return false;
  }
}
