import { DataConversion } from "@incert/data-conversion";
import { AccessProfile } from "../access-profile/access-profile.model";
import { Resource } from "../resource/shared/resource.model";

@DataConversion()
export class Marketplace {
  id?: number;
  created?: any;
  published: boolean;
  installable: boolean;
  types: MarketplaceType[];
  associations: MarketplaceAssociation[];
  resources: Resource[];
  translations: MarketplaceTranslation[];
  statistic?: MarketplaceStat;
}

export interface MarketplaceType {
  id: number;
  name?: string;
}

export interface MarketplaceTranslation {
  language: "de" | "en";
  name: string;
}

export interface MarketplaceAssociation {
  id: number;
  discr: "bundle" | "access_profile";
  bundle?: Bundle;
  accessProfile?: AccessProfile;
}

export interface MarketplaceStat {
  totalRequested: number;
  totalInstalled: number;
}

export interface Bundle {
  identifier: string;
  name?: string;
}
