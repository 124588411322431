import { LazyDataSourceInputConverter } from "./lazy-data-source-input-converter.interface";
import { LazyDataSourceDispatcher } from "./lazy-data-source-dispatcher.interface";
import { Type } from "typescript";
import { LazyDataSourceOutput } from "./model/lazy-data-source-output.model";
import { LazyDataSourceInput } from "./model/lazy-data-source-input.model";

export class LazyDataSource<T> {
  private _inputConverter: LazyDataSourceInputConverter;
  private _dispatcher: LazyDataSourceDispatcher;
  private _path: string;
  private _limit: number;
  private _type: Type;
  private _loading = false;
  private _output: LazyDataSourceOutput<T>;

  public get data(): T[] {
    if (this._output) {
      return this._output.data;
    }
    return [];
  }

  public get page() {
    if (this._output) {
      return this._output.page;
    }
    return 0;
  }

  public get totalCount() {
    if (this._output) {
      return this._output.totalCount;
    }
    return 0;
  }

  public get limit() {
    return this._limit;
  }

  public get loading() {
    return this._loading;
  }

  public constructor(
    inputConverter: LazyDataSourceInputConverter,
    dispatcher: LazyDataSourceDispatcher,
  ) {
    this._inputConverter = inputConverter;
    this._dispatcher = dispatcher;
  }

  public init(path: string, limit: number, type?: any) {
    this._path = path;
    this._type = type;
    this._limit = limit;
  }

  public async load(data?: any): Promise<LazyDataSourceOutput<T>> {
    this._loading = true;
    let input: LazyDataSourceInput = {};
    if (data) {
      input = this._inputConverter.convert(data, this._limit);
    }
    const output = await this._dispatcher.dispatch(
      input,
      this._path,
      this._type,
    );
    this._loading = false;
    this._output = output;
    return output;
  }
}
