import { AbstractControl, ValidatorFn } from "@angular/forms";
import { DateValidators } from "../control/date-validators";

export class DateGroupValidators {
  public static lessThan(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 < date2,
      "dateLessThan",
    );
  }

  public static greaterThan(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 > date2,
      "dateGreaterThan",
    );
  }

  public static lessThanOrEqual(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 <= date2,
      "dateLessThanOrEqual",
    );
  }

  public static greaterThanOrEqual(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 >= date2,
      "dateGreaterThanOrEqual",
    );
  }

  private static compare(
    control1: AbstractControl,
    control2: AbstractControl,
    cmp: (a: Date, b: Date) => boolean,
    error: string,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let validationErrors = control1.errors ? control1.errors : {};
      const date1 = DateValidators.convertDate(control1.value);
      const date2 = DateValidators.convertDate(control2.value);

      if (!cmp(date1, date2)) {
        validationErrors[error] = { date: date2 };
      } else {
        delete validationErrors[error];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control1.setErrors(validationErrors);

      return null;
    };
  }
}
