import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {ROW_DATA} from "@incert/incert-gui";

export interface Tag {
  id?: number;
  name: string;
  description?: string;
  badge?: string;
  bgColor?: string;
  fgColor?: string;
}

@Component({
  selector: 'data-table-tag',
  templateUrl: './data-table-tag.component.html',
  styleUrls: ['./data-table-tag.component.scss']
})
export class DataTableTagComponent implements OnInit {

  constructor(@Inject(ROW_DATA) @Optional() public row: any) {
  }

  @Input()
  public entries: Tag[] = [];
  public onClick: (row: any, entry: any) => any = () => null;
  public resolveRowData: (row: any) => Tag[];
  public pointer = false;

  ngOnInit(): void {
    if (this.row) {
      this.entries = this.resolveRowData(this.row);
    }
  }

  buildClass(): string {
    let styleClass = "tag";

    if (this.pointer) {
      styleClass += " pointer"
    }
    return styleClass;
  }
}
