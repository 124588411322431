<div
  class="p-mt-1 p-mb-1"
  *ngIf="uri"
  [ngSwitch]="displayStyle"
  [ngClass]="{'iconLink': displayStyle === LinkDisplayOptions.onlyIcon}">
  <a
    [attr.href]="'https://' + link" target="_blank"
    class="link-url"
    [ngClass]="{'dticon': displayStyle === LinkDisplayOptions.onlyIcon}"
    *ngFor="let link of uri">
    <incert-icon
      *ngSwitchCase="LinkDisplayOptions.standard"
      [iconType]="icon"> {{ link }}
    </incert-icon>

    <incert-icon
      *ngSwitchCase="LinkDisplayOptions.onlyIcon"
      [iconType]="icon">
    </incert-icon>

    <p
      class="p-mr-2"
      *ngSwitchCase="LinkDisplayOptions.onlyText"> {{ link.slice(0, 92)  }} <br>
    </p>
  </a>
</div>
