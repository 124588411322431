import { Component, Input, OnInit } from "@angular/core";
import { Status } from "@incert/incert-core";

@Component({
  selector: "incert-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  @Input()
  public status: Status;
  @Input()
  public color: string;
  @Input()
  public label: string;

  get labelExists() {
    return this.label ? "status-text" : null;
  }

  constructor() {}

  ngOnInit() {}
}
