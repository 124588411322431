import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Severity } from "@incert/incert-core";
import { Subscription } from "rxjs";
import { AlertMessagePersistorService } from "./alert-message-persistor.service";
import { objectsDeeplyEqual } from "@incert/gms/src/lib/frontend/shared/functions/objects-deeply-equal.function";

export interface AlertMessageConfig {
  content?: string;
  header?: string;
  closeable?: boolean;
  status?: Severity;
  color?: string;
  button?: AlertMessageButton;
}

export interface AlertMessageButton {
  label: string;
  onClick: () => any;
}

export enum AlertMessageStatus {
  success = "success",
  info = "info",
  warn = "warn",
  error = "error",
}

@Component({
  selector: "alert-message",
  templateUrl: "./alert-message.component.html",
  styleUrls: ["./alert-message.component.scss"],
})
export class AlertMessageComponent implements OnInit, OnDestroy {
  private _content?: string;
  private _header?: string;
  private _closeable?: boolean;
  private _status?: AlertMessageStatus;
  private _color?: string;
  private _button?: AlertMessageButton;

  public darkFont = false;
  public closed = false;

  @Input()
  public persistKey = "";

  private subscription?: Subscription;

  @Input()
  public set config(config: AlertMessageConfig) {
    this.closable = config.closeable;
    this.content = config.content;
    this.header = config.header;
    this.button = config.button;
    switch (config.status) {
      case Severity.error:
        this.status = AlertMessageStatus.error;
        break;
      case Severity.info:
        this.status = AlertMessageStatus.info;
        break;
      case Severity.warning:
        this.status = AlertMessageStatus.warn;
        break;
      case Severity.success:
        this.status = AlertMessageStatus.success;
        break;
      default:
        this.status = AlertMessageStatus.info;
        break;
    }
  }

  /**
   * @deprecated use content instead
   */
  @Input()
  public set html(value: string) {
    this.content = value;
  }

  @Input()
  public set status(value: AlertMessageStatus) {
    if (value !== this._status) {
      this._status = value in AlertMessageStatus ? value : undefined;
      this.closed = false;
    }
  }

  public get status() {
    return this._status;
  }

  @Input()
  public set header(value: string) {
    if (value !== this._header) {
      this._header = value;
      this.closed = false;
    }
  }

  public get header() {
    return this._header;
  }

  @Input()
  public set content(value: string) {
    if (value !== this._content) {
      this._content = value;
      this.closed = false;
    }
  }

  public get content() {
    return this._content;
  }

  @Input()
  public set closable(value: boolean) {
    if (value !== this._closeable) {
      this._closeable = value;
      this.closed = false;
    }
  }

  public get closable() {
    return this._closeable;
  }

  @Input()
  public set color(value: string) {
    if (value !== this._color) {
      this._color = value;
      this.closed = false;
      this.darkFont =
        this.getWhiteContrastRatio(value) < this.getBlackContrastRatio(value);
    }
  }

  public get color() {
    return this._color;
  }

  @Input()
  public set button(value: AlertMessageButton) {
    if (!objectsDeeplyEqual(value, this._button)) {
      this._button = value;
      this.closed = false;
    }
  }

  public get button() {
    return this._button;
  }

  constructor(
    private alertMessagePersistorService: AlertMessagePersistorService,
  ) {}

  ngOnInit(): void {
    if (this.persistKey !== "") {
      this.subscription = this.alertMessagePersistorService.closed$.subscribe(
        (closed) => (this.closed = closed[this.persistKey] ?? false),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public get backgroundColor() {
    return this.status ? null : this._color;
  }

  async close() {
    this.closed = true;
    if (this.persistKey !== "") {
      await this.alertMessagePersistorService.setClosed(this.persistKey, true);
    }
  }

  private getWhiteContrastRatio(color) {
    return this.getContrastRatio(color, 1);
  }

  private getBlackContrastRatio(color) {
    return this.getContrastRatio(color, 0);
  }

  private getContrastRatio(color, otherLum) {
    const parsedColor = this.parseColor(color);
    if (!parsedColor) {
      return null;
    }

    const lum =
      0.2126 * this.sRGBtoLin(parsedColor.r) +
      0.7152 * this.sRGBtoLin(parsedColor.g) +
      0.0722 * this.sRGBtoLin(parsedColor.b);

    return (Math.max(lum, otherLum) + 0.05) / (Math.min(lum, otherLum) + 0.05);
  }

  private parseColor(color: string) {
    const match = color.match(
      /#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/,
    );
    if (!match) {
      return null;
    }

    return {
      r: parseInt(match[1], 16) / 255.0,
      g: parseInt(match[2], 16) / 255.0,
      b: parseInt(match[3], 16) / 255.0,
    };
  }

  private sRGBtoLin(colorChannel) {
    return colorChannel <= 0.04045
      ? colorChannel / 12.92
      : Math.pow((colorChannel + 0.055) / 1.055, 2.4);
  }
}
