import { Type } from "@angular/core";

export function ObjectType(objectInnerType: () => Promise<Type<any>>) {
  /* tslint:disable:only-arrow-functions */
  return function (target: any, key: string) {
    if (!target["__dataConversionMapping"]) {
      target["__dataConversionMapping"] = [];
    }
    if (!target["__dataConversionMapping"][key]) {
      target["__dataConversionMapping"][key] = {};
    }

    target["__dataConversionMapping"][key] = {
      ...target["__dataConversionMapping"][key],
      innerObjectType: objectInnerType,
    };
  };
}
