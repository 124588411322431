import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InfosComponent } from "./infos.component";
import { TabViewModule } from "primeng/tabview";
import { ImageModule } from "@incert/incert-gui";
import { InfosEntryComponent } from "./infos-entry/infos-entry.component";
import { AccordionModule } from "primeng/accordion";

@NgModule({
  declarations: [InfosComponent, InfosEntryComponent],
  imports: [ImageModule, TabViewModule, CommonModule, AccordionModule],
})
export class InfosModule {}
