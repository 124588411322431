<form [formGroup]="formGroup">
  <form-field label="Bezeichnung">
    <input pInputText type="text" [formControlName]="nameofForm('name')" placeholder="Bezeichnung">
  </form-field>
    <form-field label="System Filter">
      <p-dropdown
        [options]="accountSystemFilters"
        [formControlName]="nameofForm('accountSystemFilter')"
        (onChange)="updateFilter()"
        appendTo="body"
        [showClear]="true"
        placeholder="System Filter">
      </p-dropdown>
    </form-field>
  <div class="grid">
    <form-field label="Systeme" class="col-10">
      <p-multiSelect
        [options]="accountSystemSI"
        [formControlName]="nameofForm('accountSystems')"
        placeholder="Systeme"
        appendTo="body">
      </p-multiSelect>
    </form-field>
    <form-field label="Bearbeiten" class="col-2">
      <p-inputSwitch
        (onChange)="toggleSystemEdit($event.checked)"
        [formControlName]="nameofForm('edit')">
      </p-inputSwitch>
    </form-field>
  </div>
</form>
