import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from "@angular/forms";

export function updateValueAndValidity(
  control: UntypedFormGroup | AbstractControl,
) {
  control.updateValueAndValidity();
  if (control instanceof UntypedFormGroup) {
    for (const key of Object.keys(control.controls)) {
      updateValueAndValidity(control.controls[key]);
    }
  }
  if (control instanceof UntypedFormArray) {
    for (const key of Object.keys(control.controls)) {
      updateValueAndValidity(control.controls[key]);
    }
  }
  return;
}
