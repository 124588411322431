import { Component, Input, OnInit } from "@angular/core";
import { DataTableConfig } from "../shared/interfaces/data-table-config.interface";

@Component({
  selector: "incert-sub-data-table",
  templateUrl: "./sub-data-table.component.html",
  styleUrls: ["./sub-data-table.component.scss"],
})
export class SubDataTableComponent implements OnInit {
  @Input()
  config: DataTableConfig<any> = null;

  constructor() {}

  ngOnInit(): void {}
}
