import * as dayjs from "dayjs";
// @ts-ignore
import customParseFormat from "dayjs/plugin/customParseFormat";

export class DateDataConverter {
  public static decode(value: string | string[]) {
    if (Array.isArray(value)) {
      return (<string[]>value).map((v) => {
        return this.strToDate(v);
      });
    } else {
      return this.strToDate(<string>value);
    }
  }

  public static strToDate(value: string) {
    let date = dayjs(value).toDate();

    // fix for safari
    if (isNaN(date.valueOf())) {
      dayjs.extend(customParseFormat);
      date = dayjs(<string>value, "YYYY-MM-DDTHH:mm:ss.000ZZ").toDate();
    }
    return date;
  }

  public static encode(value: Date) {
    return value.toISOString();
  }
}
