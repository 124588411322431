import { Inject, Injectable } from "@angular/core";
import {
  FORM_EXTENSION_I18N,
  FormExtensionI18n,
} from "../form-extension-i18n.token";
import { validationMessageKey } from "./validation-message.function";

export type ValidationMessageFn = (data: any) => string;

export interface ValidationMessages {
  [key: string]: ValidationMessageFn;
}

@Injectable({
  providedIn: "root",
})
export class ValidationMessageService {
  public constructor(
    @Inject(FORM_EXTENSION_I18N)
    private formExtensionI18n: FormExtensionI18n,
  ) {}

  public getMessage(key: string, data: any) {
    if (key.startsWith(validationMessageKey)) {
      return data;
    }
    if (key in this.formExtensionI18n) {
      return this.formExtensionI18n[key](data);
    }
    return this.formExtensionI18n.default(data);
  }
}
