/* tslint:disable:import-destructuring-spacing */
import {} from "reflect-metadata";
import { Type } from "@angular/core";

export function ArrayType(arrayInnerType: () => Promise<Type<any>>) {
  /* tslint:disable:only-arrow-functions */
  return function (target: any, key: string) {
    if (!target["__dataConversionMapping"]) {
      target["__dataConversionMapping"] = [];
    }
    if (!target["__dataConversionMapping"][key]) {
      target["__dataConversionMapping"][key] = {};
    }

    target["__dataConversionMapping"][key] = {
      ...target["__dataConversionMapping"][key],
      innerArrayType: arrayInnerType,
    };
  };
}
