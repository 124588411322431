import { Component, Input, OnInit, Optional } from "@angular/core";
import { OverlayService, OverlaySize } from "@incert/incert-core";
import { DefaultCarouselComponent } from "../default-slide-components/default-carousel/default-carousel.component";
import { HelpProviderService } from "./help-provider.service";
import { SlideContent } from "../default-slide-components/slide-content";
import { DefaultTabsComponent } from "../default-slide-components/default-tabs/default-tabs.component";

@Component({
  selector: "incert-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  @Input()
  public contentPages: SlideContent[];
  @Input()
  public label: string;
  @Input()
  public overlayHeadline = "";
  @Input()
  public alternativeLabel = "";
  @Input()
  public displayAsTabs = false;
  @Input()
  public overlayHeadlineDescription = "";

  constructor(
    private overlayService: OverlayService,
    @Optional() private helpProvider: HelpProviderService,
  ) {}

  ngOnInit() {}

  public async showHelp() {
    let content = this.contentPages;

    if (!this.contentPages && this.helpProvider) {
      content = await this.helpProvider.getContent();
    }
    if (content && content.length) {
      if (this.displayAsTabs) {
        this.overlayService.show<DefaultTabsComponent>({
          type: DefaultTabsComponent,
          header: this.overlayHeadline,
          headerDescription: this.overlayHeadlineDescription,
          size: OverlaySize.full,
          displayAsSidebar: true,
          styleClass: "incert-help-dialog",
          init: (component: DefaultTabsComponent) => {
            component.tabsContent = content;
          },
        });
      } else {
        this.overlayService.show<DefaultCarouselComponent>({
          type: DefaultCarouselComponent,
          header: this.overlayHeadline,
          headerDescription: this.overlayHeadlineDescription,
          size: OverlaySize.full,
          displayAsSidebar: true,
          styleClass: "incert-help-dialog",
          init: (component: DefaultCarouselComponent) => {
            component.carouselPages = content;
          },
        });
      }
    }
  }
}
