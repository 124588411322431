import { Injectable, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { DataConverter } from "@incert/data-conversion";
import { OverlayService } from "../overlay";
import { UntypedFormGroup } from "@angular/forms";
import {
  ClipboardDisplayOptions,
  ImportIntoFormGroup,
  ImportIntoOverlayConfig,
} from "./clipboard.interface";

@Injectable({
  providedIn: "root",
})
export class ClipboardService implements OnDestroy {
  private _notifyOutlet$: Subject<ClipboardDisplayOptions> =
    new Subject<ClipboardDisplayOptions>();
  private _clipboardFocused$: Subject<any> = new Subject<any>();
  private _importAccepted$: Subject<boolean> = new Subject<boolean>();

  private importSub: Subscription;

  constructor(private overlayService: OverlayService) {}

  public async importIntoOverlay<T, K>(config: ImportIntoOverlayConfig<T, K>) {
    try {
      const clipboardData = await this.getFromClipboard();
      const parsedData = JSON.parse(clipboardData);

      if (
        parsedData["copyUrl"]
          ? parsedData["copyUrl"] == window.location.href
          : null
      ) {
        return null;
      }

      const classObject = new config.validate.type();
      for (const property of Object.getOwnPropertyNames(classObject)) {
        if (!Object.keys(parsedData).find((v) => v == property)) {
          return null;
        }
      }

      this._notifyOutlet$.next({
        duration: config.duration,
        message: "Importieren",
      });
      if (!this.importSub) {
        this.importSub = this.importAccepted$.subscribe(async () => {
          await this.overlayService.show<K>({
            type: config.overlay.type,
            actions: config.overlay.actions,
            header: config.overlay.header,
            init: (i) =>
              (i[config.overlayFormGroup] as any).patchValue(parsedData),
            size: config.overlay.size,
            closable: config.overlay.closable,
          });
        });
      }
    } catch (e) {
      console.log("Imported data is not suitable");

      return null;
    }
  }

  public async importIntoFormGroup<T>(config: ImportIntoFormGroup<T>) {
    try {
      const clipboardData = await this.getFromClipboard();
      await DataConverter.convert(
        config.validate.type,
        JSON.parse(clipboardData),
      );

      this._notifyOutlet$.next({
        duration: config.duration,
        message: "Importieren",
      });

      if (!this.importSub) {
        this.importSub = this.importAccepted$.subscribe(async () => {
          if (config.formGroup) {
            config.formGroup.patchValue(JSON.parse(clipboardData));
          }
        });
      }
    } catch (e) {
      console.log("Imported data is not suitable");

      return null;
    }
  }

  public async copyToClipboard<T>(
    content: T,
    notify?: ClipboardDisplayOptions,
  ): Promise<void> {
    navigator.permissions
      // @ts-ignore
      .query({ name: "clipboard-write" })
      .then(async (result) => {
        if (result.state === "granted" || result.state === "prompt") {
          try {
            if (typeof content === "object") {
              await navigator.clipboard.writeText(JSON.stringify(content));
            } else {
              await navigator.clipboard.writeText(content.toString());
            }

            this._notifyOutlet$.next(
              notify ? notify : { message: "Kopiert!", duration: 1500 },
            );
          } catch (e) {
            console.error("could not parse obj");
          }
        }
      });
  }

  public getFromClipboard(): Promise<string> {
    return (
      navigator.permissions
        // @ts-ignore
        .query({ name: "clipboard-read" })
        .then(async (result) => {
          if (result.state === "granted" || result.state === "prompt") {
            return await navigator.clipboard.readText();
          }
        })
    );
  }

  public get clipboardFocused$() {
    window.addEventListener(
      "focus",
      async (_) => {
        this._clipboardFocused$.next(this);
      },
      { passive: true },
    );

    return this._clipboardFocused$.asObservable();
  }

  get notifyOutlet$(): Subject<ClipboardDisplayOptions> {
    return this._notifyOutlet$;
  }

  get importAccepted$(): Subject<boolean> {
    return this._importAccepted$;
  }

  public injectRow<T>(formGroup: UntypedFormGroup, newObj: T) {
    return formGroup.patchValue(newObj);
  }

  public rowExists<T>(data: T[], newObj: T, identifier: keyof T) {
    return data.find((t) => t[identifier] === newObj[identifier]);
  }

  public ngOnDestroy() {
    this.importSub.unsubscribe();
  }
}
