import { Injectable } from "@angular/core";

@Injectable()
export class NumberFilterExpRangeService {
  min = 0;
  max = 100;
  minlval = Math.log(1);
  maxlval = Math.log(100000);
  scale = (this.maxlval - this.minlval) / (this.max - this.min);

  init(valMin: number, valMax: number) {
    this.minlval = valMin === 0 ? 0 : Math.log(valMin);
    this.maxlval = valMax === 0 ? 0 : Math.log(valMax);
    this.scale = (this.maxlval - this.minlval) / (this.max - this.min);
  }

  // Calculate value from a slider position
  sliderValue(position) {
    return position === 0
      ? 0
      : Math.round(Math.exp((position - this.min) * this.scale + this.minlval));
  }

  // Calculate slider position from a value
  sliderPosition(value) {
    return value === 0
      ? 0
      : this.min + (Math.log(value) - this.minlval) / this.scale;
  }
}
