import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "ordered-multi-select",
  templateUrl: "./ordered-multi-select.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderedMultiSelectComponent),
      multi: true,
    },
  ],
})
export class OrderedMultiSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  public selectionFormControl = new UntypedFormControl([]);
  private _subscription: Subscription;

  public _selectItems: SelectItem[] = [];
  public sortedSelectedItems: SelectItem[] = [];

  private _onChange: (_: any) => void;
  private _value: any[] = [];

  @Input()
  public selectItemsFormGroup: UntypedFormGroup;

  private draggedElementIndex: number;
  private draggedItem: { label: string; value: string };

  @Input()
  public set selectItems(v: SelectItem[]) {
    this._selectItems = v.map((v) => {
      return {
        label: v.label,
        value: v,
      };
    });
  }

  @Input()
  multiSelectLabel = "";

  @Input()
  columnHeader: [string, string, string] = ["", "", ""];

  @Input()
  toolTipMsg = "";

  constructor() {}

  ngOnInit() {
    this._subscription = this.selectionFormControl.valueChanges.subscribe(
      (selectItems: SelectItem[]) => {
        const newSelectItems = selectItems.filter(
          (s1) => !this.sortedSelectedItems.find((s2) => s1 === s2),
        );
        const removedSelectItems = this.sortedSelectedItems.filter(
          (s1) => !selectItems.find((s2) => s1 === s2),
        );
        this.sortedSelectedItems = [
          ...this.sortedSelectedItems,
          ...newSelectItems,
        ];
        this.sortedSelectedItems = this.sortedSelectedItems.filter(
          (s1) => !removedSelectItems.find((s2) => s1 === s2),
        );
        this.change();
      },
    );

    if (this._value) {
      this.writeValue(this._value);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  writeValue(arr: any[]): void {
    if (Array.isArray(arr)) {
      this._value = arr;
      if (this._selectItems) {
        const sortedSelectItems: SelectItem[] = [];
        for (const value of arr) {
          const selectItem = this._selectItems.find(
            (v) => v.value.value === value,
          );
          if (selectItem) {
            sortedSelectItems.push(selectItem);
          }
        }
        this.sortedSelectedItems = sortedSelectItems;
        this.selectionFormControl.setValue(
          sortedSelectItems.map((v) => v.value),
        );
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  sortUp(i: number) {
    if (i === 0) {
      return;
    }
    const tmp = this.sortedSelectedItems[i];
    this.sortedSelectedItems[i] = this.sortedSelectedItems[i - 1];
    this.sortedSelectedItems[i - 1] = tmp;
    this.change();
  }

  sortDown(i: number) {
    if (i === this.sortedSelectedItems.length - 1) {
      return;
    }
    const tmp = this.sortedSelectedItems[i];
    this.sortedSelectedItems[i] = this.sortedSelectedItems[i + 1];
    this.sortedSelectedItems[i + 1] = tmp;
    this.change();
  }

  private change() {
    if (this._onChange) {
      this._onChange(this.sortedSelectedItems.map((v) => v.value));
    }
  }

  getToolTip(isDisabled): string {
    return isDisabled ? this.toolTipMsg : "";
  }

  onDragStart(item, index) {
    this.draggedItem = item;
    this.draggedElementIndex = index;
  }

  onDropElement(index) {
    this.sortedSelectedItems.splice(this.draggedElementIndex, 1);
    this.sortedSelectedItems.splice(index, 0, this.draggedItem);
    this.change();
  }
}
