export interface DataTableConfigPersistor {
  load(key: string): { columnsByProperty; columnsWithFilters; filterPositions };
  save(
    data: {
      columnsByProperty: any[];
      columnsWithFilters: any[];
      filterPositions: {};
    },
    key: string,
  ): void;
}
