import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class DateValidators {
  static ensure(): ValidatorFn {
    return (control: AbstractControl) => {
      const validationErrors: ValidationErrors = {};
      const date = DateValidators.convertDate(control.value);
      if (!(date instanceof Date)) {
        validationErrors["dateEnsure"] = {};
        return validationErrors;
      }
      return null;
    };
  }

  public static between(min: Date, max: Date): ValidatorFn {
    return (control: AbstractControl) => {
      const ensureFn = DateValidators.ensure();
      let validationErrors: ValidationErrors = ensureFn(control);

      if (validationErrors !== null) {
        return validationErrors;
      } else {
        validationErrors = {};
      }
      const date = DateValidators.convertDate(control.value);

      if (date < min || date > max) {
        validationErrors["dateBetween"] = { min: min, max: max };
        return validationErrors;
      }

      return null;
    };
  }

  public static min(min: Date): ValidatorFn {
    return (control: AbstractControl) => {
      const ensureFn = DateValidators.ensure();
      let validationErrors: ValidationErrors = ensureFn(control);

      if (validationErrors !== null) {
        return validationErrors;
      } else {
        validationErrors = {};
      }
      const date = DateValidators.convertDate(control.value);

      if (date < min) {
        validationErrors["dateMin"] = { min: min };
        return validationErrors;
      }

      return null;
    };
  }

  public static max(max: Date): ValidatorFn {
    return (control: AbstractControl) => {
      const ensureFn = DateValidators.ensure();
      let validationErrors: ValidationErrors = ensureFn(control);

      if (validationErrors !== null) {
        return validationErrors;
      } else {
        validationErrors = {};
      }
      const date = DateValidators.convertDate(control.value);

      if (date > max) {
        validationErrors["dateMax"] = { max: max };
        return validationErrors;
      }

      return null;
    };
  }

  public static convertDate(data: any) {
    if (data instanceof Date) {
      return data;
    }
    return new Date(data);
  }
}
