import { NgModule } from "@angular/core";

import { InputSwitchModule } from "primeng/inputswitch";
import { DataTableModule, QuillEditorModule } from "@incert/incert-gui";
import { DropdownModule } from "primeng/dropdown";
import { DialogModule } from "primeng/dialog";
import { CheckboxModule } from "primeng/checkbox";
import { AccordionModule } from "primeng/accordion";
import { PaginatorModule } from "primeng/paginator";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TabViewModule } from "primeng/tabview";
import { ChipsModule } from "primeng/chips";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { SpinnerModule } from "primeng/spinner";
import { MenubarModule } from "primeng/menubar";
import { TabMenuModule } from "primeng/tabmenu";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { ColorPickerModule } from "primeng/colorpicker";
import { CalendarModule } from "primeng/calendar";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SelectButtonModule } from "primeng/selectbutton";
import { TableModule } from "primeng/table";
import { FieldsetModule } from "primeng/fieldset";
import { RadioButtonModule } from "primeng/radiobutton";
import { ToggleButtonModule } from "primeng/togglebutton";
import { SharedModule } from "primeng/api";

@NgModule({
  declarations: [],
  imports: [
    DropdownModule,
    DialogModule,
    RadioButtonModule,
    CheckboxModule,
    FieldsetModule,
    AccordionModule,
    PaginatorModule,
    ConfirmDialogModule,
    TabViewModule,
    ChipsModule,
    InputSwitchModule,
    MessageModule,
    MessagesModule,
    DataTableModule,
    SpinnerModule,
    MenubarModule,
    TabMenuModule,
    InputTextareaModule,
    MultiSelectModule,
    ColorPickerModule,
    CalendarModule,
    OverlayPanelModule,
    SelectButtonModule,
    TableModule,
    ToggleButtonModule,
    QuillEditorModule,
  ],
  exports: [
    SharedModule,
    DropdownModule,
    DialogModule,
    RadioButtonModule,
    CheckboxModule,
    FieldsetModule,
    AccordionModule,
    PaginatorModule,
    ConfirmDialogModule,
    TabViewModule,
    ChipsModule,
    InputSwitchModule,
    MessageModule,
    MessagesModule,
    DataTableModule,
    SpinnerModule,
    MenubarModule,
    TabMenuModule,
    InputTextareaModule,
    MultiSelectModule,
    ColorPickerModule,
    CalendarModule,
    OverlayPanelModule,
    SelectButtonModule,
    ToggleButtonModule,
    TableModule,
  ],
})
export class CustomerListPrimeModule {}
