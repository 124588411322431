<form [formGroup]='formGroup'>
  <form-field label="System">
    <p-dropdown
      [formControlName]="nameofForm('partId')"
      [options]="accountSystems"
      optionValue="short"
      optionLabel="short"
      placeholder="System"
      appendTo="body">
      <ng-template let-accountSystem pTemplate="item">
        {{ accountSystem.name }} ({{ accountSystem.short }})
      </ng-template>
    </p-dropdown>
  </form-field>
  <form-field label="Dashboard">
    <p-dropdown
      [formControlName]="nameofForm('dashboardId')"
      [options]="dashboardSI"
      placeholder="Dashboard"
      appendTo="body">
    </p-dropdown>
  </form-field>
  <form-field *ngIf="report?.status !== ReportStatus.HUB_LIVE" label="Status">
    <p-dropdown
      [formControlName]="nameofForm('status')"
      [options]="reportStatusSI"
      placeholder="Status"
      appendTo="body">
    </p-dropdown>
  </form-field>
  <div class="grid">
    <form-field label="Statistiken ab" class="col-6">
      <p-calendar
        [formControlName]="nameofForm('dateFrom')"
        showIcon="true"
        placeholder="Statistiken ab"
        dateFormat="dd/mm/yy">
      </p-calendar>
    </form-field>
    <form-field label="Statistiken bis" class="col-6">
      <p-calendar
        [formControlName]="nameofForm('dateTo')"
        showIcon="true"
        [showTime]="false"
        placeholder="Statistiken bis"
        dateFormat="dd/mm/yy">
      </p-calendar>
    </form-field>
  </div>
  <form-field label="Aktiv bis">
    <p-calendar
      [formControlName]="nameofForm('ttl')"
      showIcon="true"
      placeholder="Aktiv bis"
      dateFormat="dd/mm/yy">
    </p-calendar>
  </form-field>
</form>
