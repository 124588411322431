import { Injectable } from "@angular/core";
import { APIService } from "../../core/api.service";
import { HttpService } from "../../core";

@Injectable({ providedIn: "root" })
export class InfosService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getAllLibraryEntries() {
    return this.httpService.get<any>(this.settingsService.baseUrl + "library");
  }

  public getGitFile(url: string) {
    return this.httpService.get<any>(
      this.settingsService.baseUrl + "git/file?url=" + url,
    );
  }
}
