import { Component, OnInit } from "@angular/core";
import { AccountSystemGroupManager } from "./account-system-group-manager.service";
import { AccountSystemGroup } from "./account-system-group";
import {
  ConfirmService,
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { User } from "../user";
import { AuthManager } from "../../core/auth";
import { AccountSystemGroupUpsertComponent } from "./account-system-group-upsert/account-system-group-upsert.component";
import { AccountSystemGroupInfoComponent } from "./account-system-group-info/account-system-group-info.component";

@Component({
  selector: "sac-account-system-group",
  templateUrl: "./account-system-group.component.html",
  styleUrls: ["./account-system-group.component.scss"],
})
export class AccountSystemGroupComponent implements OnInit {
  public user: User[] = [];
  public dtConfig: DataTableConfig<AccountSystemGroup>;
  public arrayDataSource: ArrayDataSource<any>;
  accountSystemFilters: any[] = [];

  constructor(
    private systemGroupManager: AccountSystemGroupManager,
    private authManager: AuthManager,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private confirmService: ConfirmService,
  ) {}

  public async ngOnInit() {
    await this.init();
  }

  private async init() {
    await this.loadingService.load(async () => {
      try {
        await this.systemGroupManager
          .getAccountSystemGroups()
          .then((accountSystemGroups) =>
            this.createDtConfig(accountSystemGroups),
          );
      } catch (e) {
        this.notificationService.notifyError(
          "Systemgruppen",
          "Systemgruppen konnten nicht geladen werden",
        );
      }
    });
    this.accountSystemFilters = await this.systemGroupManager.getFilters();
  }

  async createDtConfig(accountSystemGroups: Array<AccountSystemGroup>) {
    this.arrayDataSource = new ArrayDataSource(accountSystemGroups);
    this.dtConfig = {
      mode: "pagination",
      data: this.arrayDataSource,
      rows: 20,
      filterArea: {
        filters: [
          {
            header: "Name",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("name"),
            }),
          },
        ],
      },
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.readSystemGroup,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  onClick: (accountSystemGroup: AccountSystemGroup) =>
                    this.overlayUpsertSystemGroup(accountSystemGroup),
                  tooltip: "Bearbeiten",
                  hidden: !this.writeSystemGroup,
                  icon: "edit",
                },
                {
                  onClick: (row: AccountSystemGroup) =>
                    this.overlayListSystems(row),
                  tooltip: "Systeme",
                  hidden: !this.readSystemGroup,
                  icon: "file",
                },
                {
                  onClick: (accountSystemGroup: AccountSystemGroup) =>
                    this.deleteSystemGroup(accountSystemGroup),
                  tooltip: "Löschen",
                  hidden: !this.executeSystemGroup,
                  icon: "delete",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Systemgruppe",
              onClick: () => this.overlayUpsertSystemGroup(),
              onShow: () => this.writeSystemGroup,
            },
          ],
        }),
      ],
    };
  }

  public async overlayUpsertSystemGroup(
    systemGroup: AccountSystemGroup = null,
  ) {
    await this.overlayService.show<AccountSystemGroupUpsertComponent>({
      type: AccountSystemGroupUpsertComponent,
      header: systemGroup
        ? "Systemgruppe bearbeiten"
        : "Systemgruppe hinzufügen",
      size: OverlaySize.medium,
      displayAsSidebar: true,
      init: (component) => {
        component.accountSystemFilters = this.accountSystemFilters;
        if (systemGroup) {
          component.systemGroup = systemGroup;
        }
      },
      actions: [
        {
          label: "Speichern",
          action: async (component) => {
            if (await component.upsertSystemGroup()) {
              await this.init();
              return true;
            }
            return false;
          },
        },
        { label: "Schließen", action: () => true, displayAsLink: true },
      ],
    });
  }

  private async overlayListSystems(systemGroup: AccountSystemGroup) {
    await this.overlayService.show<AccountSystemGroupInfoComponent>({
      type: AccountSystemGroupInfoComponent,
      header: "Systeme",
      size: OverlaySize.medium,
      displayAsSidebar: true,
      init: (component) => {
        component.systemGroup = systemGroup;
      },
      actions: [
        { label: "Schließen", action: () => true, displayAsLink: true },
      ],
    });
  }

  public async deleteSystemGroup(systemGroup?: AccountSystemGroup) {
    const response = await this.confirmService.confirmError(
      "Benutzer und Kontaktgruppen Verknüpfungen müssen im Vorhinein bereinigt werden!",
      "Systemgruppe löschen",
    );
    if (response) {
      try {
        await this.systemGroupManager.deleteSystemGroup(systemGroup.id);
      } catch (e) {
        this.notificationService.notifyError(
          "",
          "Systemgruppe konnte nicht gelöscht werden",
        );
      }
      await this.init();
    }
  }

  public get readSystemGroup(): boolean {
    return this.authManager.hasPermission("readSystemGroup");
  }

  public get writeSystemGroup(): boolean {
    return this.authManager.hasPermission("writeSystemGroup");
  }

  public get executeSystemGroup(): boolean {
    return this.authManager.hasPermission("executeSystemGroup");
  }
}
