import { Injectable } from "@angular/core";
import { AccessProfile } from "./access-profile.model";
import { HttpService } from "../../../core";
import { APIService } from "../../../core/api.service";
import { SelectItem } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class AccessProfileManager {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getAccessGroups() {
    return this.httpService.get<any>(
      this.settingsService.baseUrl + "gms-access/group",
    );
  }

  public getAccessLevels() {
    return this.httpService.get<any>(
      this.settingsService.baseUrl + "gms-access/level",
    );
  }

  public async getAccessProfiles() {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "gms-access/profile",
    );
  }

  async getAccessProfilesSI(): Promise<SelectItem[]> {
    return await this.httpService.get(
      this.settingsService.baseUrl + "gms-access/profileSI",
    );
  }

  public async getAccessProfilesLegacy() {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "gms-access/profile/interface",
    );
  }

  public getInterfaceTypes() {
    return this.httpService.get<any>(
      this.settingsService.baseUrl + "gms-access/interface",
    );
  }

  public async deleteAccessProfile(accessProfile: AccessProfile) {
    return await this.httpService.delete<any>(
      this.settingsService.baseUrl + "gms-access/profile/" + accessProfile.id,
    );
  }

  public upsertAccessProfile(accessProfile: AccessProfile) {
    return this.httpService.post<any>(
      this.settingsService.baseUrl + "gms-access/profile",
      accessProfile,
    );
  }

  public async syncAccessProfile() {
    return this.httpService.post<any>(
      this.settingsService.baseUrl + "gms-access/sync",
      [],
    );
  }
}
