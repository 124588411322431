import { AbstractControl, ValidatorFn } from "@angular/forms";
import { DateValidators } from "../control/date-validators";

export class TimeGroupValidators {
  public static lessThan(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 < date2,
      "timeLessThan",
    );
  }

  public static greaterThan(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 > date2,
      "timeGreaterThan",
    );
  }

  public static lessThanOrEqual(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 <= date2,
      "timeLessThanOrEqual",
    );
  }

  public static greaterThanOrEqual(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return this.compare(
      control1,
      control2,
      (date1, date2) => date1 >= date2,
      "timeGreaterThanOrEqual",
    );
  }

  private static compare(
    control1: AbstractControl,
    control2: AbstractControl,
    cmp: (a: Date, b: Date) => boolean,
    error: string,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control1.value || !control2.value) {
        return null;
      }

      let validationErrors = control1.errors ? control1.errors : {};
      const date1 = TimeGroupValidators.nomalizeTime(
        DateValidators.convertDate(control1.value),
      );
      const date2 = TimeGroupValidators.nomalizeTime(
        DateValidators.convertDate(control2.value),
      );

      if (!cmp(date1, date2)) {
        validationErrors[error] = { time: date2 };
      } else {
        delete validationErrors[error];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control1.setErrors(validationErrors);

      return null;
    };
  }

  private static nomalizeTime(date: Date): Date {
    return new Date(
      0,
      0,
      0,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    );
  }
}
