<div class="wizard" [class]="{'wizard-edit-mode': isEdit}">
  <div class="wizard-steps" >
    <p-steps
      [(activeIndex)]="activeIndex"
      [readonly]="false"
      [model]="stepsItems"
      (activeIndexChange)="onActiveIndexChange()"
      [class]="{'steps-tab-style':isEdit}">
    </p-steps>
  </div>
  <ng-content select="[alert-message]"></ng-content>
  <div class="wizard-step-container wizard-step-container-header">
    <div class="wizard-step-container-content wizard-step-header-actions" *ngIf="displayLanguageSwitch">
      <div class="wizard-step-header-actions-action">
        <div *ngIf="displayLanguageSwitch" class="inputswitch-with-text">
          <p-inputSwitch [(ngModel)]="multiLanguageFormService.enableAllLanguages"></p-inputSwitch>
          <span>{{'core.activateLanguage' | i18n}}</span>
        </div>
      </div>
    </div>
    <div class="wizard-step-container-summary">&nbsp;</div>
  </div>
  <div class="wizard-step-container">
    <div class="wizard-step-container-content">
      <ng-content select="[steps]"></ng-content>
    </div>
    <div class="wizard-step-container-summary" *ngIf="!isEdit">
      <ng-content *ngIf="touched" select="[summary]"></ng-content>
      <wizard-summary *ngIf="!touched">
        <wizard-summary-step [contentText]="'core.wizard.makeSelection' | i18n">
        </wizard-summary-step>
      </wizard-summary>
    </div>
  </div>
</div>
