import { Component, Inject, OnInit } from "@angular/core";
import { ROW_DATA } from "@incert/incert-gui";
import { AccountSystem } from "../../models/account-system.interface";

@Component({
  selector: "subdomain-link",
  templateUrl: "./data-table-subdomain.component.html",
  styleUrls: ["./data-table-subdomain.component.scss"],
})
export class DataTableSubdomainComponent implements OnInit {
  loginPermission: boolean;
  MAX_LINK_LENGTH = 60;

  constructor(@Inject(ROW_DATA) public rowData: AccountSystem) {}

  ngOnInit(): void {
    this.rowData.accountSystemSubDomains =
      this.rowData.accountSystemSubDomains.sort((a1) => {
        return a1.mainSubDomain ? -1 : 1;
      });
  }
}
