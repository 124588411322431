import {
  getPropertyPath,
  isNumeric,
  PropertyPathAccessor,
} from "../../functions";
import { RQLProperty } from "../interfaces/rql-property.type";

export function createRqlField(field: RQLProperty<any>, alias?: string) {
  let fieldStr = "";
  if (typeof field === "function") {
    fieldStr = getPropertyPath<any>(<PropertyPathAccessor<any>>field)
      .filter((v) => !isNumeric(v))
      .join(".");
  } else if (Array.isArray(field)) {
    fieldStr = field.filter((v) => !isNumeric(v)).join(".");
  } else {
    fieldStr = field.toString();
  }

  if (alias) {
    alias = alias.replace(/ /g, "_");
    alias = alias.replace(/ä/g, "ae");
    alias = alias.replace(/ö/g, "oe");
    alias = alias.replace(/ü/g, "ue");
    alias = alias.replace(/Ä/g, "Ae");
    alias = alias.replace(/Ö/g, "Oe");
    alias = alias.replace(/Ü/g, "Ue");
    alias = alias.replace(/ß/g, "ss");
  }
  return alias ? fieldStr + ":" + alias : fieldStr;
}
