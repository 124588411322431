<div [class]="classEntry + ' separator-bottom pointer'">
  <div class="p-p-3">
    <h3 class="clear-separator-bottom clear-separator-top">{{header}}</h3>
    <div *ngIf="description">{{description}}</div>
    <div class="grid">
      <div *ngIf="version" class="col-2">{{version}}</div>
      <div *ngIf="date" class="col-2">{{date}}</div>
    </div>
  </div>
</div>
