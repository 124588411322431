import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class FontFaceModel {
  @Required()
  url: string;

  @Required()
  format: string;

  @Required()
  weight: number;

  @Required()
  style: string;

  @Required()
  range: string;
}
