import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Directive({ selector: "[ifControlValid]" })
export class IfControllValidDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  private hasView = false;

  @Input()
  set ifControlValid(control: AbstractControl) {
    this.changeState(control.valid);
    control.statusChanges.subscribe(() => {
      this.changeState(control.valid);
    });
  }

  private changeState(valid: boolean) {
    if (valid === true && this.hasView === false) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (valid === false) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
