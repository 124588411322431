import { Pipe, PipeTransform } from "@angular/core";
import { ArithmeticOperation } from "./arithmetic-strategy";

@Pipe({
  name: "aggregatePipe",
})
export class AggregatePipe implements PipeTransform {
  transform(
    value: keyof typeof ArithmeticOperation,
    ...args: unknown[]
  ): unknown {
    switch (value) {
      case "AVG":
        return "Durchschnitt";
      case "MED":
        return "Median";
      case "SUM":
        return "Summe";
      default:
        return "";
    }
  }
}
