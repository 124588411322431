import { Component, Input, OnInit } from "@angular/core";
import { LegendModel } from "./models/legend.model";

@Component({
  selector: "legend-component",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
})
export class LegendComponent implements OnInit {
  @Input()
  public legendData: LegendModel[];

  constructor() {}

  ngOnInit() {}
}
