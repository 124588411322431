import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { allowEmpty } from "../allow-empty.function";

export class UrlValidators {
  static ensure(protocols: string[] = ["http:", "https:"]): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      const validationErrors: ValidationErrors = {};
      let url;

      try {
        url = new URL(control.value);
      } catch (_) {
        validationErrors["invalidUrl"] = {};
        return validationErrors;
      }

      if (protocols.indexOf(url.protocol) == -1) {
        validationErrors["protocolNotAllowed"] = {
          protocolsString: protocols.join(", "),
        };
        return validationErrors;
      } else if (
        !control.value
          .toString()
          .match(/(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})[\/\w .-]*/)
      ) {
        validationErrors["invalidUrl"] = {};
        return validationErrors;
      }

      return null;
    });
  }
}
