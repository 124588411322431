import { Inject, Injectable } from "@angular/core";
import { OverlayService } from "@incert/incert-core";
import { BarcodeScannerComponent } from "./barcode-scanner.component";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

@Injectable({
  providedIn: "root",
})
export class BarcodeScannerService {
  static isScannerAvailable: boolean;

  public constructor(
    private overlayService: OverlayService,
    @Inject(INCERT_GUI_I18N) private incertGuiI18n: IncertGUII18n,
  ) {}

  public async scan() {
    const value = await this.overlayService.show<BarcodeScannerComponent>({
      header: this.incertGuiI18n.barcodeScanner.header,
      type: BarcodeScannerComponent,
    });
    return value;
  }

  async isScannerAvailable() {
    if (BarcodeScannerService.isScannerAvailable === undefined) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return;
      }
      const arr = (await navigator.mediaDevices.enumerateDevices()).filter(
        (v) => v.kind === "videoinput",
      );
      BarcodeScannerService.isScannerAvailable = arr.length > 0;
    }
    return BarcodeScannerService.isScannerAvailable;
  }
}
