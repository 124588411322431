<div class="form-field" [ngClass]="{'clear-separator-bottom': clearSeparatorBottom}">
  <div *ngIf="formControl;else standardForm" [ngClass]="{'disabled': !this.formControl.enabled}">
    <ng-container *ngIf="!floatingLabel">
      <ng-container *ngTemplateOutlet="standardForm"></ng-container>
    </ng-container>
    <div class="p-float-label" *ngIf="floatingLabel">
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </div>
    <p *ngIf="additionalInfoMessage" class="text separator-top-small clear-separator-bottom">{{additionalInfoMessage}}</p>
    <ng-container *ngIf="formControl.invalid == true && formControl.pristine == false">
      <div class="error" *ngFor="let message of messages">
        {{message}}
      </div>
    </ng-container>
  </div>

  <ng-template #standardForm>
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-template>

  <ng-template #labelTemplate>
    <ng-container *ngIf="label !== null">
      <label [for]="id" [ngClass]="{'sr-only': !displayLabel}">
        {{label}}
        <span class="required" *ngIf="required">*
                      <span class="sr-only">{{'core.action.required'}}</span>
                  </span>
        <icon-button *ngIf="helpText" iconType="help" iconOnly="true" [tooltip]="helpText"></icon-button>
        <ng-content select="[info]"></ng-content>
      </label>
    </ng-container>
  </ng-template>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>

  <p *ngIf="!formControl && additionalInfoMessage" class="text separator-top-small clear-separator-bottom">{{additionalInfoMessage}}</p>
</div>
