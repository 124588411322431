import {AccountSystem} from "../../core/models/account-system.interface";

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roleId: number;
  roleName: string;
  inactive: boolean;
}

export interface UpdateUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: NewUserRole;
  inactive: boolean;
}

export interface NewUserRole {
  id: number;
}

export interface ChangeUserPassword {
  id: number;
  oldPassword: string;
  newPassword: string;
  creationUserId: number;
}

export interface IUserManager {
  getUsers(): Promise<Array<User>>;
  getUser(userId: number): Promise<User>;
  createUser(user: User): Promise<User>;
  updateUser(user: UpdateUser): Promise<Array<User>>;
  deleteUser(userId: number): Promise<any>;
  getUsersAssignedToCSG(accountSystemGroupId: number): Promise<Array<User>>;
  changePassword(changeUserPassword: ChangeUserPassword): Promise<number>;
  getUsersAccountSystems(userId: number): Promise<Array<AccountSystem>>;
  getNotAssignedAccountSystemsByUserId(
    userId: number,
  ): Promise<Array<AccountSystem>>;
}
