import { Injectable } from "@angular/core";
import { AccountSystemGroup } from "./account-system-group";
import { HttpService } from "../../core";
import { APIService } from "../../core/api.service";
import { SelectItem } from "primeng/api";

@Injectable({ providedIn: "root" })
export class AccountSystemGroupManager {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getAccountSystemGroups(): Promise<Array<AccountSystemGroup>> {
    return this.httpService.get<Array<AccountSystemGroup>>(
      this.settingsService.baseUrl + "account-system-group",
    );
  }

  public async getAccountSystemGroupsSI(): Promise<Array<SelectItem>> {
    const systemGroups = await this.httpService.get<Array<AccountSystemGroup>>(
      this.settingsService.baseUrl + "account-system-group",
    );
    return systemGroups.map((v) => {
      return {
        label: v.name + " | " + v.accountSystemFilter?.filter,
        value: v.id,
      };
    });
  }

  public getAccountSystemGroupsByUserId(
    userId: number,
  ): Promise<Array<AccountSystemGroup>> {
    return this.httpService.get<Array<AccountSystemGroup>>(
      this.settingsService.baseUrl + "user/" + userId + "/allgroups",
    );
  }

  public getNotAssignedAccountSystemGroupsByUserId(
    userId: number,
  ): Promise<Array<AccountSystemGroup>> {
    return this.httpService.get<Array<AccountSystemGroup>>(
      this.settingsService.baseUrl + "user/" + userId + "/groups",
    );
  }

  public async getFilters() {
    const response = await this.httpService.get<any>(
      this.settingsService.baseUrl + "account-system-filter/global",
    );
    let items: any[];
    items = Object.values(response).map((r: any) => ({
      label: r.name + " | " + r.filter,
      filter: r.filter,
      value: r.id,
    }));
    return items;
  }

  public addAccountSystemGroupsToUser(
    userId: number,
    ids: Array<AccountSystemGroup>,
  ): Promise<any> {
    return this.httpService.post(
      this.settingsService.baseUrl + "user/" + userId + "/groups",
      ids,
    );
  }

  public upsertAccountSystemGroup(
    accountSystemGroup: AccountSystemGroup,
  ): Promise<any> {
    return this.httpService.post(
      this.settingsService.baseUrl + "account-system-group",
      accountSystemGroup,
    );
  }

  public async deleteSystemGroup(id: number) {
    await this.httpService.delete(
      this.settingsService.baseUrl + "account-system-group/" + id,
    );
  }

  public deleteAccountSystemGroupFromUser(
    userId: number,
    accountSystemGroupId: number,
  ): Promise<any> {
    return this.httpService.delete(
      this.settingsService.baseUrl +
        "user/" +
        userId +
        "/groups/" +
        accountSystemGroupId,
    );
  }
}
