import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createFilterDefinition,
  DataTableConfig,
  TextFilterComponent,
} from "@incert/incert-gui";
import { AccessProfile } from "../access-profile.model";

@Component({
  selector: "incert-internal-tools-access-profile-detail",
  templateUrl: "./access-profile-detail.component.html",
})
export class AccessProfileDetailComponent implements OnInit {
  public dtConfig: DataTableConfig<any>;
  public type: string;
  public accessProfile: AccessProfile;

  createPreviewGroupDtConfig() {
    if (this.accessProfile.accessGroups) {
      const arrayDataSource = new ArrayDataSource(
        this.accessProfile.accessGroups,
      );
      this.dtConfig = {
        data: arrayDataSource,
        rows: 50,
        columns: [
          {
            header: "Bezeichnung",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("name"),
            }),
          },
          {
            header: "Access Group",
            property: (v) => v.get("accessGroup"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("accessGroup"),
            }),
          },
        ],
      };
    }
  }

  createPreviewLevelDtConfig() {
    if (this.accessProfile.accessLevels) {
      const arrayDataSource = new ArrayDataSource(
        this.accessProfile.accessLevels,
      );
      this.dtConfig = {
        data: arrayDataSource,
        rows: 50,
        columns: [
          {
            header: "Bezeichnung",
            property: (v) => v.get("name"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("name"),
            }),
          },
          {
            header: "Access Level",
            property: (v) => v.get("viewGroup"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("viewGroup"),
            }),
          },
        ],
      };
    }
  }

  ngOnInit(): void {
    if (this.type === "group") {
      this.createPreviewGroupDtConfig();
    } else {
      this.createPreviewLevelDtConfig();
    }
  }
}
