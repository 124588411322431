
<div class="p-m-5">
  <div class="separator-bottom">
    <p-tabView>
      <p-tabPanel header="Stammdatenverwaltung">
        <div class="grid">
          <div class="col-12">
            <p-button *ngIf="writeUser" (click)="changePasswordOverlay()"
                      label="Passwort ändern">
            </p-button>
          </div>
          <div *ngIf="selectedRole" class="col-12">
            <div [formGroup]="formGroup">
              <form-field label="Vorname">
                <input formControlName="firstName" type="text" pInputText>
              </form-field>
              <form-field label="Nachname">
                <input formControlName="lastName" type="text" pInputText>
              </form-field>
              <form-field label="Email">
                <input formControlName="email" type="email" pInputText>
              </form-field>
              <form-field label="Rolle">
                <p-dropdown formControlName="role" placeholder="Select a Brand" [options]="roles" optionLabel="name" appendTo="body"></p-dropdown>
              </form-field>
            </div>
            <div class="col-12">
              <p-button (click)="cancel()" styleClass="reset-button-to-link"
                        label="Abbrechen"></p-button>
              <p-button (click)="save()" label="Speichern"></p-button>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <p-tabView *ngIf="accountSystemsByUser && accountSystemGroups && allAccountSystemsManuallyAssigned">
    <p-tabPanel header="Systeme und Systemgruppen">
      <div [style.height.px]="cardHeight">
        <div *ngIf="writeUser" class="separator-bottom">
          <icon-button
            [disabled]="notAssignedAccountSystemGroups?.length < 1"
            (click)="addAccountSystemGroupsOverlay()"
            label="Systemgruppen"
            iconType="plus">
          </icon-button>
          <icon-button
            [disabled]="notAssignedAccountSystemGroups?.length < 1"
            (click)="addAccountSystemToUser()"
            label="Kundensystem"
            iconType="plus">
          </icon-button>
        </div>
        <p-accordion>
          <p-accordionTab header="Systemgruppen ({{ accountSystemGroups.length }})">
            <data-table [config]="accountSystemGroupsConfig" *ngIf="accountSystemGroupsConfig"></data-table>
          </p-accordionTab>

          <p-accordionTab header="Zugeordnete Kundensysteme ({{ accountSystemsByUser.length }})">
            <data-table [config]="accountSystemsConfig" *ngIf="accountSystemsConfig"></data-table>
          </p-accordionTab>

          <p-accordionTab header="Manuelle Zuordnungen ({{ allAccountSystemsManuallyAssigned.length }})">
            <data-table [config]="accountSystemsManuallyConfig" *ngIf="accountSystemsManuallyConfig"></data-table>
          </p-accordionTab>
        </p-accordion>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
