import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LoadingService } from "@incert/incert-core";

@Component({
  selector: "loading-wrapper",
  templateUrl: "./loading-wrapper.component.html",
  styleUrls: ["./loading-wrapper.component.scss"],
  providers: [LoadingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingWrapperComponent {
  public constructor(public loadingService: LoadingService) {}
}
