import { Injectable } from "@angular/core";
import { History } from "./protocol";
import { ImportData } from "../tools/key-value-panel/key-value.interface";
import { APIService } from "../../core/api.service";
import { HttpService } from "../../core";
import {InstallationHistory} from "./installation-history/installation-history.component";

@Injectable({ providedIn: "root" })
export class ProtocolManager {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getProtocolByType(protocolType: string): Promise<Array<History>> {
    return this.httpService.get<Array<History>>(
      this.settingsService.baseUrl + "protocol/" + protocolType,
    );
  }

  public getImportProtocol(): Promise<Array<ImportData>> {
    return this.httpService.get<Array<ImportData>>(
      this.settingsService.baseUrl + "protocol/import",
    );
  }

  public getInstallationHistoryEntries() {
    return this.httpService.get<InstallationHistory[]>(
      this.settingsService.baseUrl + "protocol/installationHistory",
    );
  }
}
