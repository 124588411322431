// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The log-list of which env maps to which file can be found in `.angular-cli.json`.

let env;

const forceLive = 0;

if (forceLive) {
  env = {
    production: true,
    apiPath: "https://accounts-api.incert.at/",
  };
} else {
  env = {
    production: false,
    apiPath: "https://accountlistdev-api.incert.at/",
  };
}

export const environment = env;
