import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IUserManager, User } from "../";
import {
  ConfirmService,
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { UserAddComponent } from "../user-add/user-add.component";
import { IAuthManager } from "../../../core/auth";

@Component({
  selector: "sac-user-overview",
  templateUrl: "./user-overview.component.html",
  styleUrls: ["./user-overview.component.scss"],
})
export class UserOverviewComponent implements OnInit {
  public dtConfig: DataTableConfig<User>;
  public arrayDataSource: ArrayDataSource<User>;

  constructor(
    @Inject("UserManager") private userManager: IUserManager,
    @Inject("AuthManager") private authManager: IAuthManager,
    public notificationService: NotificationService,
    private router: Router,
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private confirmService: ConfirmService,
  ) {}

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      await this.getUsers().then((users) => this.createDtConfig(users));
    });
  }

  private async getUsers() {
    try {
      return await this.userManager.getUsers();
    } catch (e) {
      this.notificationService.notifyError(
        "Laden fehlgeschlagen",
        "Benutzer konnten nicht geladen werden.",
      );
    }
  }

  private createDtConfig(users: Array<User>) {
    this.arrayDataSource = new ArrayDataSource<User>(users);
    this.dtConfig = {
      mode: "pagination",
      data: this.arrayDataSource,
      rows: 30,
      filterArea: {
        filters: [
          {
            header: "Vorname",
            property: (v) => v.get("firstName"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Nachname",
            property: (v) => v.get("lastName"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Rolle",
            property: (v) => v.get("roleName"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Email Adresse",
            property: (v) => v.get("email"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
        ],
      },
      columns: [
        {
          header: "Vorname",
          property: (v) => v.get("firstName"),
          sort: true,
        },
        {
          header: "Nachname",
          property: (v) => v.get("lastName"),
          sort: true,
        },
        {
          header: "Rolle",
          property: (v) => v.get("roleName"),
          sort: true,
        },
        {
          header: "Email Adresse",
          property: (v) => v.get("email"),
          sort: true,
        },
        {
          header: "Aktionen",
          property: (v) => v.get("id"),
          hidden: !this.writeUser && !this.executeUser && !this.loginAsUser,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "search",
                  hidden: !this.writeUser,
                  onClick: (user: User) => this.openDetailView(user),
                  tooltip: "Details anzeigen",
                },
                {
                  icon: "logout",
                  hidden: !this.loginAsUser,
                  onClick: (user: User) => this.loginAs(user),
                  tooltip: "Login",
                },
                {
                  icon: "delete",
                  hidden: !this.executeUser,
                  onClick: (user: User) => this.deleteUser(user),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Benutzer",
              onClick: () => this.addNewUser(),
              onShow: () => this.writeUser,
            },
          ],
        }),
      ],
    };
  }

  public async openDetailView(user: User) {
    await this.router.navigate(["/user-detail"], {
      queryParams: { id: user.id, update: true },
    });
  }

  public async addNewUser() {
    await this.overlayService.show<UserAddComponent>({
      type: UserAddComponent,
      header: "Benutzer hinzufügen",
      size: OverlaySize.medium,
      displayAsSidebar: true,
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: async (component) => {
            const createdUser = await component.save();
            return await this.onCreatedUser(createdUser);
          },
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  private async onCreatedUser(user: User) {
    if (user) {
      await this.initialize();
      this.notificationService.notifySuccess(
        "Benutzer hinzufügen",
        "User wurde erfolgreich hinzugefügt.",
      );
      return true;
    } else {
      this.notificationService.notifyWarning(
        "Bitte Passwörter überprüfen",
        "Fehler bei Erstellung",
      );
      return false;
    }
  }

  public async deleteUser(user: User) {
    const response = await this.confirmService.confirmError(
      "",
      "Benutzer " + user.lastName + " löschen",
    );
    if (response) {
      try {
        await this.userManager.deleteUser(user.id);
        this.arrayDataSource.reloadDataTable(
          this.arrayDataSource.getData().filter((f) => f.id !== user.id),
        );
        this.notificationService.notifySuccess(
          "Benutzer löschen",
          "Benutzer " + user.lastName + " wurde erfolgreich gelöscht.",
        );
      } catch (e) {
        if (e.status === 404) {
          this.notificationService.notifyError(
            "Benutzer löschen",
            "Benutzer " + user.id + " existiert nicht.",
          );
        }
      }
    }
  }

  public async loginAs(user: User) {
    try {
      await this.authManager.loginAsForAdmin(user.email);
    } catch (e) {
      if (e.status !== 401) {
        this.notificationService.notifyError("Login", e.message);
      } else {
        this.notificationService.notifyError(
          "Login",
          "Da ist leider etwas schief gelaufen",
        );
      }
    }
  }

  public get loginAsUser(): boolean {
    return this.authManager.hasPermission("loginAsUser");
  }

  public get writeUser(): boolean {
    return this.authManager.hasPermission("writeUser");
  }

  public get executeUser(): boolean {
    return this.authManager.hasPermission("executeUser");
  }
}
