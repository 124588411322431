import { NgModule } from "@angular/core";
import { IncertTagComponent } from "./tag.component";
import { CommonModule } from "@angular/common";
import { TagsComponent } from "./tags/tags.component";
import { IconModule } from "../icon/icon.module";
import { TooltipModule } from "../tooltip/tooltip.module";

@NgModule({
  declarations: [IncertTagComponent, TagsComponent],
  imports: [CommonModule, IconModule, TooltipModule],
  exports: [IncertTagComponent, TagsComponent],
})
export class IncertTagModule {}
