import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { APIService } from "../api.service";
import { SearchEngine, SearchResultKeyValue } from "./search-engine.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SearchEngineService {
  _onResult$: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(
    null,
  );
  allSystemsRaw: any[] = [];

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public async getFilters() {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl +
        "account-system-filter/user/" +
        parseInt(localStorage.getItem("USER_ID"), 10),
    );
  }

  public async getData(filter: string) {
    const response = await this.httpService.get<Array<SearchResultKeyValue>>(
      this.settingsService.baseUrl + "system-value/filter" + filter,
    );
    if (response.length === 0) {
      this.onResult.next([-1]);
    } else {
      this.onResult.next(response.map((srkv) => ({ id: srkv })));
    }
    return response;
  }

  async updateFilter(filter: any, filterEngine: SearchEngine) {
    if (filter) {
      if (this.allSystemsRaw.length <= 0) {
        this.allSystemsRaw = await this.getAllAccountSystemsRaw();
      }

      const checkType = filter.split(/<=|<|>=|>|!=|=|&&|[||]{2}/gm);
      let filteredData: any[];
      if (checkType.length > 1) {
        filteredData = await this.teaserFilter(filter, filterEngine);
      } else {
        filteredData = await this.textFilter(filter);
      }
      return filteredData;
    }
  }

  async textFilter(filter: any) {
    const partIds = await this.getData("?" + filter);
    const filteredData = [];
    if (partIds) {
      for (const partId of partIds) {
        filteredData.push(this.allSystemsRaw.find((s) => s.id == partId));
      }
    }
    return filteredData;
  }

  async teaserFilter(filter: any, searchEngine: SearchEngine) {
    searchEngine.cleanUp();
    const logicRegex = /<=|<|>=|>|!=|=|&&|[||]{2}/gm;
    const text = filter.split(logicRegex);
    const logic = filter.match(logicRegex);

    for (let i = 0; i < text.length; i++) {
      searchEngine.resultExpression.expressions.push(text[i]);
      if (logic[i]) {
        searchEngine.resultExpression.expressions.push(logic[i]);
      }
    }

    const partIds = await this.processSingleMulti(searchEngine);
    const filteredData = [];
    if (partIds) {
      for (const partId of partIds) {
        filteredData.push(this.allSystemsRaw.find((s) => s.id == partId));
      }
    }
    return filteredData;
  }

  async processSingleMulti(filterEngine: SearchEngine) {
    if (filterEngine.resultExpression?.expressions?.length === 3) {
      return await this.onSingleRequest(
        filterEngine.resultExpression.expressions,
      );
    } else {
      switch (filterEngine.resultExpression.expressions.length % 4) {
        case 3: {
          return await this.onMultiDataRequest(
            filterEngine.resultExpression.expressions,
          );
        }
      }
    }
  }

  public async onSingleRequest(expression: Array<any>) {
    const str =
      "?" +
      this.createParam("name", expression[0]) +
      this.createParam("&value", expression[2]) +
      this.createParam("&logic", this.parseLogic(expression[1]));

    return await this.getData(str);
  }

  public async onMultiDataRequest(data: Array<any>) {
    const resObj: {
      name: string;
      logic: string;
      value: string;
      concat?: string;
    }[] = [];

    resObj.push({
      name: data[0],
      logic: this.parseLogic(data[1]),
      value: data[2],
    });

    for (let i = 3; i < data.length; i += 4) {
      resObj.push({
        concat: data[i] === "&&" ? "and" : "or",
        name: data[i + 1],
        logic: this.parseLogic(data[i + 2]),
        value: data[i + 3],
      });
    }

    let appx = "";

    resObj.forEach((chunk, index) => {
      if (index === 0) {
        appx += "?";
        appx += this.createParam("name", chunk.name);
      } else {
        appx += this.createParam("&concat", chunk.concat);
        appx += this.createParam("&name", chunk.name);
      }

      appx += this.createParam("&value", chunk.value);
      appx += this.createParam("&logic", chunk.logic);
    });

    return await this.getData(appx);
  }

  public createParam = (name, value) => name + "=" + value;

  public parseLogic(logExpr: string) {
    switch (logExpr) {
      case "=":
        return "eq";
      case ">":
        return "gt";
      case "<":
        return "lt";
      case "%":
        return "like";
      case ">=":
        return "ge";
      case "<=":
        return "le";
      case "!=":
        return "ne";
      default:
        return "like";
    }
  }

  public getAllAccountSystemsRaw() {
    return this.httpService.get<any>(
      this.settingsService.baseUrl + "accountsystem",
    );
  }

  public filterToString(filter: any) {
    let filterToString = "";
    if (Array.isArray(filter)) {
      for (const f of filter) {
        filterToString += f.display;
      }
      return filterToString;
    }
    if (filter?.display) {
      return filter.display;
    }
    return filter;
  }

  public get onResult() {
    return this._onResult$;
  }
}
