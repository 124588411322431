import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IncFileUploadComponent } from "./inc-file-upload.component";
import { FileUploadModule } from "primeng/fileupload";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { IconModule } from "../icon/icon.module";
import { I18nModule } from "@incert/i18n";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    IconModule,
    IconButtonModule,
    I18nModule,
    ReactiveFormsModule,
  ],
  exports: [IncFileUploadComponent],
  declarations: [IncFileUploadComponent],
})
export class IncFileUploadModule {}
