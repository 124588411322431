import { ExporterInterface } from "./exporter.interface";

export class CsvExporter implements ExporterInterface {
  export(data: any[][], header: string[]): string {
    const result = data.map((row) =>
      row.map((val) => this.escape(`${val}`)).join(";"),
    );
    result.unshift(header.map((v) => this.escape(v)).join(";"));
    return result.join("\r\n");
  }

  private escape(val: string): string {
    if (
      val.includes(";") ||
      val.includes("\r") ||
      val.includes("\n") ||
      val.includes('"')
    ) {
      return '"' + val.replace(/"/g, '""') + '"';
    }
    return val;
  }

  get extension(): string {
    return "csv";
  }
}
