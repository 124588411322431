<div>
  <p-tabView [(activeIndex)]="tabIndex">
    <p-tabPanel header="Tag hinzufügen">
      <div class="col-12" [formGroup]="form">
        <div class="grid">
          <div class="col-12">
            <form-field label="Name">
              <input type="text" placeholder="Name" formControlName="name" (keyup.enter)="onAddTag()">
            </form-field>
          </div>
          <div class="col-12">
            <form-field label="Beschreibung">
              <input type="text" placeholder="Beschreibung" formControlName="description"
                     aria-label="" (keyup.enter)="onAddTag()">
            </form-field>
          </div>
          <div class="col-10">
            <form-field label="Hintergrundfarbe">
              <input #f type="text" [value]="getBGColor()" formControlName="bgColor"
                     placeholder="Hintergrundfarbe" (keyup.enter)="onAddTag()">
            </form-field>
          </div>
          <div class="col-2">
            <form-field label="Farbe">
              <p-colorPicker class="color-picker" formControlName="bgColor" appendTo="body"></p-colorPicker>
            </form-field>
          </div>
          <div class="col-10">
            <form-field label="Textfarbe">
              <input #f type="text" aria-label="" [value]="getFGColor()" formControlName="fgColor"
                     placeholder="Textfarbe"
                     (keyup.enter)="onAddTag()">
            </form-field>
          </div>
          <div class="col-2">
            <form-field label="Farbe">
              <p-colorPicker class="color-picker" formControlName="fgColor" appendTo="body"></p-colorPicker>
            </form-field>
          </div>
          <div class="col-12 align-center">
            <data-table-tag [entries]="getExampleTag()"></data-table-tag>
          </div>
          <div class="col-12 align-center separator-top">
            <icon-button fullWidth="true" (click)="onAddTag()" iconType="save" label="Speichern"></icon-button>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Tags bearbeiten">
      <data-table [config]="tagTableConfig" *ngIf="tagTableConfig && tags"></data-table>
    </p-tabPanel>
  </p-tabView>
</div>

