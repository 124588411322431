export class ChangeMap<T> {
  private _map: { [key: string]: T } = {};
  private _changes: { [key: string]: T } = {};

  public constructor(
    private changeCallback?: (key: string, newValue: T, oldValue: T) => void,
  ) {}
  public clear() {
    this.resetChanges();
    this._map = {};
  }
  public getChanges() {
    return { ...this._changes };
  }
  public resetChanges() {
    this._changes = {};
  }

  public getKeys() {
    return Object.keys(this._map);
  }

  public add(key: string, value: T) {
    this._map[key] = value;
    Object.defineProperty(this, key, {
      get: () => {
        return this._map[key];
      },
      set: (newValue: T) => {
        const oldValue = this._map[key];
        if (oldValue !== newValue) {
          this._map[key] = newValue;
          this._changes[key] = newValue;
          if (this.changeCallback) {
            this.changeCallback(key, newValue, oldValue);
          }
        }
      },
    });
  }
}
