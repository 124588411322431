<ng-container class="page">
    <header class="page-header" *ngIf="header"> <!-- Pages not in gms-backend (gms-apps) need pageheader -->
            <h1 *ngIf="header" class="page-headline" >{{header}}</h1>
            <h4 *ngIf="description"> {{description}}</h4>
            <ng-container *ngIf="stepsEnabled">
                <p-steps [readonly]="false" styleClass="steps-custom" [activeIndex]="activeIndex"
                         (activeIndexChange)="activeStepIndex=$event" [model]="steps"></p-steps>
            </ng-container>
    </header>
    <main>
        <ng-content></ng-content>
    </main>

    <footer>
        <bottom-bar *ngIf="stepsEnabled">
            <ng-container bottombar-left *ngIf="stepsEnabled && hasPrevStep">
                <p-button [label]="'Zurück'" (onClick)="prevStep()"></p-button>
            </ng-container>
            <ng-container bottombar-right *ngIf="stepsEnabled && hasNextStep">
                <p-button [label]="'Weiter'" (onClick)="nextStep()"></p-button>
            </ng-container>
        </bottom-bar>
    </footer>
</ng-container>

