import { Component, Input } from "@angular/core";
import { WizardComponent } from "../../wizard.component";

@Component({
  selector: "wizard-summary-step",
  templateUrl: "./wizard-summary-step.component.html",
})
export class WizardSummaryStepComponent {
  @Input() wizardStep: any;
  @Input() headline: string;
  @Input() subHeadline: string;
  @Input() contentText: string;

  constructor(private wizardComponent: WizardComponent) {}

  get visible() {
    return (
      !this.wizardStep ||
      this.wizardComponent.visibleStepComponents.includes(this.wizardStep)
    );
  }

  isClickable(): boolean {
    return this.wizardComponent && this.wizardStep;
  }

  onSummaryStepClick(): void {
    if (this.isClickable() && this.wizardComponent) {
      this.wizardComponent.navigateTo(this.wizardStep);
    }
  }
}
