import { Component, Inject, OnInit } from "@angular/core";
import { ChangeListService } from "../../change-list.service";
import { ROW_DATA } from "@incert/incert-gui";

@Component({
  selector: "user-detail-checkbox",
  templateUrl: "./user-detail-checkbox.component.html",
  styleUrls: ["./user-detail-checkbox.component.scss"],
})
export class UserDetailCheckboxComponent implements OnInit {
  public active: number;
  public accountSystemGroupId: number;
  public resolveRowData: (row: any) => number = () => null;

  constructor(
    @Inject(ROW_DATA) private row_data: any,
    private changeListService: ChangeListService,
  ) {}

  ngOnInit(): void {
    this.accountSystemGroupId = this.resolveRowData(this.row_data);
  }

  onChange() {
    if (this.active) {
      if (
        !this.changeListService
          .getChangeList()
          .includes(this.accountSystemGroupId)
      ) {
        this.changeListService.addToChangelist(this.accountSystemGroupId);
      }
    } else {
      this.changeListService.removeFromChangelist(this.accountSystemGroupId);
    }
  }
}
