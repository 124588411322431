import { Inject, Injectable, Injector } from "@angular/core";
import { OverlayParam } from "./overlay-param.model";
import { OverlayModel } from "./overlay.model";
import { OVERLAY_SUBJECT } from "./overlay-subject.token";
import { Subject } from "rxjs";
import { OverlayHandle } from "./overlay-handle.service";

@Injectable({ providedIn: "root" })
export class OverlayService {
  constructor(
    @Inject(OVERLAY_SUBJECT) private overlaySubject: Subject<OverlayParam>,
    private injector: Injector,
  ) {}

  public show<T>(overlay: OverlayModel<T>): Promise<any> {
    let resolve = null;
    const promise = new Promise<any>((resolve1) => {
      resolve = resolve1;
    });

    const handle = new OverlayHandle(
      resolve,
      overlay.header,
      overlay.headerDescription,
    );

    const overlayInjector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: OverlayHandle,
          useValue: handle,
        },
      ],
    });

    if (!this.overlaySubject.observed) {
      console.error(
        "No overlay observer is subscribed. " +
          "Either there is no overlay outlet or it isn't subscribed yet.",
      );
    }

    this.overlaySubject.next({
      overlayModel: overlay,
      resolve: resolve,
      promise: promise,
      injector: overlayInjector,
      handle: handle,
    });

    return promise;
  }
}
