<p-tabView [(activeIndex)]='tabIndex'>
  <p-tabPanel header='Kategorie hinzufügen'>
    <div class='grid' [formGroup]='form'>
      <div class='col-12'>
        <form-field label='Kategoriename'>
          <input #f type='text' aria-label='' placeholder='Kategorie' formControlName='name'
                 (keyup.enter)='onAddCategory()'>
        </form-field>
      </div>
      <div class='col-10'>
        <form-field label='Hintergrundfarbe'>
          <input type='text' aria-label='' [value]='getBGColor()' formControlName='bgColor'
                 placeholder='Hintergrundfarbe' (keyup.enter)='onAddCategory()'>
        </form-field>
      </div>
      <div class='col-2'>
        <form-field label='Farbe'>
          <p-colorPicker class='color-picker' formControlName='bgColor' appendTo='body'></p-colorPicker>
        </form-field>
      </div>
      <div class='col-10'>
        <form-field label='Textfarbe'>
          <input type='text' aria-label='' [value]='getFGColor()' formControlName='fgColor' placeholder='Textfarbe'
                 (keyup.enter)='onAddCategory()'>
        </form-field>
      </div>
      <div class='col-2'>
        <form-field label='Farbe'>
          <p-colorPicker class='color-picker' formControlName='fgColor' appendTo='body'></p-colorPicker>
        </form-field>
      </div>
      <div class="col-12 align-center">
        <data-table-tag [entries]="getExampleCategory()"></data-table-tag>
      </div>
      <div class='col-12 align-center separator-top'>
        <icon-button fullWidth="true" (click)='onAddCategory()' iconType='save' label='Speichern'></icon-button>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header='Kategorien bearbeiten'>
    <data-table [config]='categoryTableConfig' *ngIf='categoryTableConfig && categories'></data-table>
  </p-tabPanel>
</p-tabView>
