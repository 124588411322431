<section >
    <div class="section-inner-container" *ngIf="header">
        <div class="grid section-header p-align-end" [ngClass]="{'separator-top-large': !clearSeparatorTop}">
            <div class="col section-header-headline">
                <h2>{{header}}</h2> <incert-icon *ngIf="helpInfoText" iconType="help" [label]="'core.showHelp' | i18n" [incTooltip]="helpInfoText"></incert-icon>
            </div>
            <div class="col align-right">
                <ng-content select="[header]"></ng-content>
            </div>
        </div>
    </div>
    <div class="section-container" [ngClass]="{'clear-spacing-top': clearSpacingTop, 'clear-spacing-bottom': clearSpacingBottom}">
        <div class="section-inner-container">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
</section>
