import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { RoleManager } from "../../role/role-manager.service";
import { UserManager } from "../user-manager.service";
import { Role } from "../../role/role";
import { NotificationService } from "@incert/incert-core";

@Component({
  selector: "incert-internal-tools-user-add",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.scss"],
})
export class UserAddComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public roles: Array<Role>;

  async ngOnInit() {
    await this.initialize();
  }

  constructor(
    private notificationService: NotificationService,
    private roleManager: RoleManager,
    private userManager: UserManager,
  ) {
    this.createForm();
  }

  private async initialize() {
    await this.getRoles();
  }

  private createForm() {
    this.formGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl("", Validators.required),
      lastName: new UntypedFormControl("", Validators.required),
      email: new UntypedFormControl("", [
        Validators.email,
        Validators.required,
      ]),
      role: new UntypedFormControl("", Validators.required),
      password: new UntypedFormControl("", Validators.required),
      passwordConfirm: new UntypedFormControl("", Validators.required),
    });
  }

  private async getRoles() {
    this.roles = await this.roleManager.getRoles();
  }

  public async save() {
    if (this.formGroup.valid) {
      if (
        this.formGroup.get("password").value ===
        this.formGroup.get("passwordConfirm").value
      ) {
        return await this.userManager.createUser(this.formGroup.value);
      } else {
        return null;
      }
    }
    this.notificationService.notifyInfo(
      "Ungültige Eingaben",
      "Eingaben überprüfen",
    );
  }
}
