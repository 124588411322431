export function download(file: string | Blob, name, type = "file") {
  if (!(file instanceof Blob)) {
    file = new Blob([file], { type: type });
  }

  const a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.download = name;
  a.click();
}
