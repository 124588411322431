import { InjectionToken } from "@angular/core";
import { FormLanguage } from "./form-language.model";

export const FORM_LANGUAGES = new InjectionToken<FormLanguage[]>(
  "FORM_LANGUAGES",
  {
    providedIn: "root",
    factory: () => [],
  },
);
