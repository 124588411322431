import { EventEmitter, Injectable } from "@angular/core";
import { FormLanguage } from "./form-language.model";
import { LanguageFormBuilder } from "./language-form-builder.service";

@Injectable()
export class LanguageFormService {
  public onActiveLanguageChange = new EventEmitter<FormLanguage>();
  private _activeLanguage: FormLanguage;

  get activeLanguage(): FormLanguage {
    return this._activeLanguage;
  }

  set activeLanguage(value: FormLanguage) {
    this._activeLanguage = value;
    this.onActiveLanguageChange.emit(this.activeLanguage);
  }

  public constructor(private lfb: LanguageFormBuilder) {
    this.activeLanguage = lfb.defaultLanguage;
  }
}
