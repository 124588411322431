<div *ngIf='formGroup'>
  <form [formGroup]='formGroup'>
    <p-tabView>
      <p-tabPanel header='Allgemein'>
        <div class='grid'>
          <div class='col-12'
               *ngIf="!this.accountSystem || this.accountSystem?.installationStatus === InstallationStatusEnum.added">
            <form-field label='System'>
              <p-dropdown
                [options]='globals.systems'
                placeholder='System auswählen'
                formControlName='discr'
                appendTo='body'></p-dropdown>
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.added)">
            <form-field label="Type">
              <div class="radio-block">
                <p-radioButton *ngIf="accountSystemDiscr === DiscrEnum.ecom" name="systemType" value="professional"
                               label="Professional"
                               formControlName="systemType"></p-radioButton>
                <p-radioButton *ngIf="accountSystemDiscr === DiscrEnum.ecom" name="systemType" value="express"
                               label="Express"
                               formControlName="systemType"></p-radioButton>
                <p-radioButton *ngIf="accountSystemDiscr === DiscrEnum.loy" name="systemType" value="basic"
                               label="Basic"
                               formControlName="systemType"></p-radioButton>
                <p-radioButton *ngIf="accountSystemDiscr === DiscrEnum.loy" name="systemType" value="premium"
                               label="Premium"
                               formControlName="systemType"></p-radioButton>
                <p-radioButton *ngIf="accountSystemDiscr === DiscrEnum.loy" name="systemType" value="gwg"
                               label="Freunde werben (GwG)"
                               formControlName="systemType"></p-radioButton>
              </div>
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.added)">
            <form-field label="Testsystem">
              <div class="radio-block">
                <p-radioButton name="isDev" [value]="false" label="Livesystem" formControlName="isDev"></p-radioButton>
                <p-radioButton name="isDev" [value]="true" label="Testsystem" formControlName="isDev"></p-radioButton>
              </div>
            </form-field>
          </div>
          <div class='col-6'>
            <form-field label='Name'>
              <input type='text' pInputText name='name' placeholder="Name" formControlName='name'>
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.added)">
            <form-field label='Kürzel'>
              <input type='text' formControlName='short' placeholder="Kürzel" maxlength="14">
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.added)">
            <form-field label='Kunde'>
              <p-dropdown [options]='accounts' formControlName='account' optionLabel='name' filter='true'
                          appendTo='body'
                          placeholder='Kunde'></p-dropdown>
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.added)">
            <form-field label='Portal Name'>
              <p-dropdown [options]="portalNameSI" formControlName='portalName' placeholder="Portal Name" appendTo="body"></p-dropdown>
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.added)">
            <form-field label='Cluster'>
              <p-dropdown [options]="clusterSI" formControlName='cluster' placeholder="Cluster" appendTo="body"></p-dropdown>
            </form-field>
          </div>
          <div class='col-6'
               *ngIf="isInstallationStatus(InstallationStatusEnum.productive)">
            <form-field label="DWH aktiv">
              <p-inputSwitch
                formControlName='availableForDWH'
                name='availableForDWH'>
              </p-inputSwitch>
            </form-field>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Subdomains & Proxies">
        <div class="separator-bottom">
          <icon-button
            (click)='addSubdomain()'
            label='Subdomain'
            iconType='plus'>
          </icon-button>
        </div>

        <ng-container formArrayName="accountSystemSubDomains">
          <ng-container *ngFor="let subDomainFormGroup of accountSystemSubDomains.controls; let i = index"
                        class="separator-bottom">
            <div class="separator-bottom" [formGroup]="subDomainFormGroup">
              <div class='grid p-align-center'>
                <div class='col-1 align-center'>
                  <p-inputSwitch
                    formControlName='mainSubDomain'
                    (onChange)="selectSubdomain(subDomainFormGroup)">
                  </p-inputSwitch>
                </div>
                <div class='col-4'>
                  <form-field label='Subdomain'>
                    <input
                      name='subDomainLinkToSystem{{i}}' pInputText
                      type='text'
                      formControlName='subdomainLinkToSystem'>
                  </form-field>
                </div>
                <div class='col-2'>
                  <form-field label='Proxy'>
                    <input name='proxy{{i}}' pInputText type='text' formControlName='proxy'>
                  </form-field>
                </div>
                <div class='col-2'>
                  <form-field label='Sub-Shop Id'>
                    <input name='defaultSubShopId{{i}}' pInputText type='text' formControlName='defaultSubShopId'>
                  </form-field>
                </div>
                <div class='col-2'>
                  <form-field label='Sprache'>
                    <p-dropdown
                      id='{{ i }}' name='languageCode{{i}}' appendTo='body' [options]='languageSI'
                      formControlName='languageCode'>
                    </p-dropdown>
                  </form-field>
                </div>
                <div class='col-1'>
                  <icon-button
                    iconOnly='true'
                    iconType='close'
                    (click)='deleteSubDomain(i)'
                    displayIconButtonAsButton='true'>
                  </icon-button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </p-tabPanel>
      <p-tabPanel header='RKSV'
                  *ngIf="(isInstallationStatus(InstallationStatusEnum.active)
                  || isInstallationStatus(InstallationStatusEnum.productive)) && accountSystemDiscr === DiscrEnum.ecom">
        <account-system-rksv
          viewMode='edit'
          [accountSystems]='accountSystems'
          [accountSystemId]='accountSystem?.id'>
        </account-system-rksv>
      </p-tabPanel>
    </p-tabView>
  </form>
</div>
