import { Component, OnInit } from "@angular/core";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { ContactEmail } from "./contact-email.model";
import { ContactEmailUpsertComponent } from "./contact-email-upsert/contact-email-upsert.component";
import { SelectItem } from "primeng/api";
import { ContactEmailManagerService } from "./contact-email-manager.service";
import { AuthManager } from "../../../core/auth";

@Component({
  selector: "incert-internal-tools-contact-email",
  templateUrl: "./contact-email.component.html",
  styleUrls: ["./contact-email.component.css"],
})
export class ContactEmailComponent implements OnInit {
  constructor(
    private overlayService: OverlayService,
    private contactEmailManager: ContactEmailManagerService,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private authManager: AuthManager,
  ) {}

  public contactsAll: any[] = [];
  public dtConfig: DataTableConfig<ContactEmail>;

  systemSelection: SelectItem[] = [];
  roleSelection: SelectItem[] = [];
  genderSelection: SelectItem[] = [
    { label: "Herr", value: "m" },
    { label: "Frau", value: "w" },
    { label: "Divers", value: "d" },
  ];

  async ngOnInit() {
    await this.loadingService.load(async () => {
      this.roleSelection = await this.contactEmailManager.getContactRoles();
      this.systemSelection =
        await this.contactEmailManager.getAccountSystemsSI();
      this.contactsAll = await this.contactEmailManager.getContactEmails();
      await this.createDtConfig();
    });
  }

  private async createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.contactsAll);
    this.dtConfig = {
      mode: "pagination",
      rows: 15,
      data: arrayDataSource,
      emptyMessage: "Keine Systeme zugeteilt",
      export: true,
      filterArea: {
        filters: [
          {
            header: "Kürzel",
            property: (v) => v.get("accountSystem", "short"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("accountSystem", "short"),
            }),
          },
          {
            header: "Rolle",
            property: (v) => v.get("contactRole", "value"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.roleSelection,
              type: "eq",
            }),
          },
          {
            header: "Anrede",
            property: (v) => v.get("gender"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: [
                { label: "M", value: "m" },
                { label: "W", value: "w" },
                { label: "D", value: "d" },
              ],
              type: "eq",
            }),
          },
          {
            header: "Vorname",
            property: (v) => v.get("firstName"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("firstName"),
            }),
          },
          {
            header: "Nachname",
            property: (v) => v.get("lastName"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("lastName"),
            }),
          },
          {
            header: "Email",
            property: (v) => v.get("email"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("email"),
            }),
          },
        ],
      },
      columns: [
        {
          header: "Kürzel",
          property: (v) => v.get("accountSystem", "short"),
          sort: true,
        },
        {
          header: "Rolle",
          property: (v) => v.get("contactRole", "label"),
          sort: true,
        },
        {
          header: "Anrede",
          property: (v) => v.get("gender"),
          sort: true,
        },
        {
          header: "Vorname",
          property: (v) => v.get("firstName"),
          sort: true,
        },
        {
          header: "Nachname",
          property: (v) => v.get("lastName"),
          sort: true,
        },
        {
          header: "Email",
          property: (v) => v.get("email"),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.writeContact,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  hidden: !this.writeContact,
                  icon: "edit",
                  onClick: (contact: ContactEmail) =>
                    this.contactEmailUpsertOverlay(
                      "Kontakt bearbeiten",
                      false,
                      contact,
                    ),
                  tooltip: "Bearbeiten",
                },
                {
                  hidden: !this.executeContact,
                  icon: "delete",
                  onClick: (contact: ContactEmail) =>
                    this.deleteContact(contact),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.executeContact,
              icon: "download",
              label: "Import",
              onClick: () => this.importFallbackEmails(),
            },
            {
              hidden: !this.writeContact,
              icon: "plus",
              label: "Kontakt",
              onClick: () =>
                this.contactEmailUpsertOverlay("Kontakt hinzufügen", true),
            },
          ],
        }),
      ],
    };
  }

  private async deleteContact(contact: ContactEmail) {
    const response = await this.confirmService.confirmError(
      "",
      "Kontakt löschen",
    );
    if (response) {
      await this.contactEmailManager.deleteContactData(contact.id);
      await this.ngOnInit();
    }
  }

  public async contactEmailUpsertOverlay(
    header: string,
    newContact: boolean,
    contact?: ContactEmail,
  ) {
    await this.overlayService.show<ContactEmailUpsertComponent>({
      size: OverlaySize.medium,
      header: header,
      type: ContactEmailUpsertComponent,
      displayAsSidebar: true,
      init: (component) => {
        component.contact = contact;
        component.newContact = newContact;
        component.genderSelection = this.genderSelection;
        component.roleSelection = this.roleSelection;
        component.systemSelection = this.systemSelection;
      },
      actions: [
        {
          label: "Speichern",
          action: (t) =>
            t
              .saveContact()
              .then((saveAnswer) => {
                if (saveAnswer) {
                  this.ngOnInit();
                }
                return saveAnswer;
              })
              .catch((err) => {
                console.error(err);
                return false;
              }),
        },
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async importFallbackEmails() {
    const response = await this.confirmService.confirmError(
      "Der Import der Fallback Emails wird einige Minuten dauern.",
      "Fallback Emails importieren",
    );
    if (response) {
      try {
        await this.contactEmailManager.importFallbackEmails();
        await this.ngOnInit();
      } catch (e) {
        console.log(e);
      }
    }
  }

  public get writeContact(): boolean {
    return this.authManager.hasPermission("writeContact");
  }

  public get executeContact(): boolean {
    return this.authManager.hasPermission("executeContact");
  }
}
