import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { APIService } from "../api.service";
import { PersistenceService } from "@incert/incert-gui";

@Injectable({ providedIn: "root" })
export class DataTablePersistenceService implements PersistenceService {
  public constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  async getDataTablePersistence(dataTableId: string) {
    let persistence = await this.httpService.get<any>(
      this.settingsService.baseUrl +
        "dataTable/persistence/" +
        dataTableId +
        "/" +
        localStorage.getItem("USER_ID"),
    );
    if (Array.isArray(persistence) && persistence.length < 1) {
      persistence = {};
    }
    return persistence;
  }

  async updateDataTablePersistence(persistence: any) {
    persistence.userId = localStorage.getItem("USER_ID");
    await this.httpService.post(
      this.settingsService.baseUrl + "dataTable/persistence",
      persistence,
    );
  }
}
