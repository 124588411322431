<p-tabView>
  <p-tabPanel header="Systemwerte">
    <div *ngIf='systemValues'>
      <div class='grid'>
        <ng-container *ngFor='let systemValue of systemValues'>
          <p-checkbox
            class='col-6'
            [(ngModel)]='activeSystemValues'
            [value]='systemValue'
            [label]='systemValue'
            name='keyvaluesettings'>
          </p-checkbox>
        </ng-container>
        <p *ngIf='!systemValues'> Es wurden noch keine Systemwerte importiert.</p>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Spezialfilter">
    <form-field label="Spezialfilter aktivieren">
      <p-inputSwitch
        [(ngModel)]="specialFilter">
      </p-inputSwitch>
    </form-field>
  </p-tabPanel>
</p-tabView>
