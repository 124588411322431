import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "urlSubdomain",
})
export class UrlSubdomainPipe implements PipeTransform {
  public MAX_LINK_LENGTH = 36;

  transform(value: string, chars: number): unknown {
    if (!value) {
      value = "";
    }

    const limit = chars ? chars : this.MAX_LINK_LENGTH;

    if (value.length > limit) {
      return value.substr(0, value.length - (value.length - limit)) + "...";
    } else {
      return value;
    }
  }
}
