<div [class]=getCardItemClass() (click)="clickItem($event)">
    <ng-content select="[visualAid]"></ng-content>
    <div class="inc-card-item-details">
      <div class="inc-card-item-content">
        <strong class="inc-card-item-title">{{cardTitle}}</strong>
        <div class="inc-card-item-language-tags">
          <ng-content select="[languages]"></ng-content>
        </div>
        <ng-content select="[content]"></ng-content>
      </div>
      <div class="inc-card-item-footer">
        <ng-content select="[footer]"></ng-content>
        <div class="inc-card-item-actions">
          <ng-content select="[actions]"></ng-content>
        </div>
      </div>
    </div>
</div>
