import { Permission } from "../../pages/role/permission";

export interface IAuthManager {
  isUserLoggedIn: boolean;
  authModel: AuthModel;
  login(email: string, password: string): Promise<AuthModel>;
  loginAsForAdmin(email: string);
  relogin(admin: number);
  logout(): void;
  hasPermission(functionName: PermissionShort): boolean;
  getLoggedInUserId(): number;
  verifyToken(token: string): Promise<boolean>;
}

export interface AuthModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: number;
  accessToken: string;
  firstLogin: number;
  permissions: Array<Permission>;
  adminLoggedIn: number;
  resetPassword: boolean;
  resourcesToken: string;
}

export const AuthConstants = {
  TOKEN: "TOKEN",
  USER_ID: "USER_ID",
  ROLE_ID: "ROLE_ID",
  USER_NAME: "USER_NAME",
  PERMISSIONS: "PERMISSIONS",
  ADMIN_LOGGED_IN: "ADMIN_LOGGED_IN",
  RESOURCES_TOKEN: "RESOURCES_TOKEN",
};

//keep up to date with DB
export type PermissionShort =
  | "readAccount"
  | "readSystem"
  | "readSystemGroup"
  | "readMemo"
  | "readNotification"
  | "readSystemValue"
  | "readContact"
  | "readContactGroup"
  | "readAccessProfile"
  | "readMarketplace"
  | "readResource"
  | "readInfo"
  | "readLoginProtocol"
  | "readUserProtocol"
  | "readInstallationProtocol"
  | "readAccessProtocol"
  | "readRunnerProtocol"
  | "readRole"
  | "readUser"
  | "writeRole"
  | "writeAccount"
  | "writeSystemGroup"
  | "writeMemo"
  | "writeNotification"
  | "writeSystemValue"
  | "writeContact"
  | "writeContactGroup"
  | "writeAccessProfile"
  | "writeMarketplace"
  | "writeUser"
  | "writeSystem"
  | "writeResource"
  | "executeMemo"
  | "executeMemoAdditional"
  | "executeRole"
  | "executeUser"
  | "executeSystem"
  | "executeContactGroup"
  | "executeNotification"
  | "executeAccessProfile"
  | "executeContact"
  | "executeAccount"
  | "executeSystemGroup"
  | "executeMarketplace"
  | "executeResource"
  | "loginAsUser"
  | "loginWithOAuth";
