export function addToList(
  list: string,
  valueToAdd: string,
  separator = ";",
): string {
  const values = list.split(separator);

  if (!values.includes(valueToAdd)) {
    values.push(valueToAdd);
  }

  return values.join(separator);
}
