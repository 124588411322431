import { Inject, Pipe, PipeTransform } from "@angular/core";
import { Iso8601Converter } from "./iso8601-converter";
import { DatePipe } from "@angular/common";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

@Pipe({
  name: "timeFormat",
})
export class TimeFormatPipe implements PipeTransform {
  public constructor(
    private datePipe: DatePipe,
    @Inject(INCERT_GUI_I18N) private config: IncertGUII18n,
  ) {}

  transform(value: any, args?: any): any {
    try {
      return this.datePipe.transform(
        value,
        Iso8601Converter.toDatePipeFormat(this.config.timeFormat),
      );
    } catch (e) {
      return String(value);
    }
  }
}
