import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  OverlayAction,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { ROW_DATA } from "../../data-table/column-host/row-data.token";
import { Type } from "@angular/core";

export interface DTOverlayConfig<T> {
  buttonText: string;
  overlayHeader: string;
  component: Type<T>;
  actions?: OverlayAction<T>[];
  closable?: boolean;
  size?: OverlaySize;
  init?: (type: T) => void;
}

@Component({
  selector: "incert-internal-tools-data-table-overlay",
  templateUrl: "./data-table-overlay.component.html",
  styleUrls: ["./data-table-overlay.component.css"],
})
export class DataTableOverlayComponent<T> implements OnInit {
  @Input() public config: DTOverlayConfig<T>;
  public setConfig: (row: T) => DTOverlayConfig<T> = () => null;

  constructor(
    @Inject(ROW_DATA) private row_data: any,
    private overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    this.config = this.setConfig(this.row_data);
  }

  public async showOverlay() {
    await this.overlayService.show<T>({
      header: this.config.overlayHeader
        ? this.config.overlayHeader
        : "Mehr Informationen",
      type: this.config.component,
      size: this.config.size,
      init: this.config.init,
      actions: this.config.actions,
      closable: this.config.closable,
    });
  }
}
