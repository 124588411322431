import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BarcodeScannerComponent } from "./barcode-scanner.component";
import { LoadingWrapperModule } from "../loading-wrapper/loading-wrapper.module";
import { BarcodeScannerButtonComponent } from "./barcode-scanner-button/barcode-scanner-button.component";
import { I18nModule } from "@incert/i18n";
import { ButtonModule } from "primeng/button";

@NgModule({
  declarations: [BarcodeScannerComponent, BarcodeScannerButtonComponent],
  exports: [BarcodeScannerButtonComponent],
  imports: [CommonModule, LoadingWrapperModule, ButtonModule, I18nModule],
})
export class BarcodeScannerModule {}
