import { DataTableConfigPersistor } from "./data-table-config-persistor.interface";

export class LocalStorageDataTableConfigPersistor
  implements DataTableConfigPersistor
{
  constructor() {}

  load(key: string): {
    columnsByProperty;
    columnsWithFilters;
    filterPositions;
  } {
    try {
      const data = JSON.parse(localStorage.getItem(key));
      return {
        columnsByProperty: data.columnsByProperty,
        columnsWithFilters: data.columnsWithFilters,
        filterPositions: data.filterPositions,
      };
    } catch (e) {
      console.error("Invalid JSON Data: " + e.message);
      return null;
    }
  }

  save(
    data: { columnsByProperty; columnsWithFilters; filterPositions },
    key: string,
  ): void {
    localStorage.setItem(key, JSON.stringify(data));
  }
}
