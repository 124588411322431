<div class="grid p-m-5">
  <div class="col-6">
    <p-tabView>
      <p-tabPanel header="Rollen">
        <div [style.height.px]="cardHeight">
          <data-table *ngIf="roleTableConfig" [config]="roleTableConfig"></data-table>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="col-6" *ngIf="statusDetailDataTable">
    <p-tabView>
      <p-tabPanel [header]="setStatusHeader()">
        <div [style.height.px]="cardHeight">
          <div *ngIf="statusDetailDataTable === 1 && assignedUsers && assignedUsersTableConfig">
            <data-table [config]="assignedUsersTableConfig"></data-table>
          </div>

          <div *ngIf="statusDetailDataTable === 2">
            <div>
              <label>Beschreibung</label>
              <textarea rows="4" aria-label="" pInputText disabled>{{selectedRole.description}}</textarea>
            </div>
            <div *ngIf="this.dynamicTables">
              <label>Rechte </label>
              <h1>{{selectedRole.name}}</h1>
              <p-accordion>
                <div *ngFor="let permissionTable of this.dynamicTables; let i = index">
                  <p-accordionTab *ngIf="permissionTable.data.total" [header]="getAccordionTabHeader(i, permissionTable)">
                    <div>
                      <data-table [config]="permissionTable" *ngIf="permissionTable"></data-table>
                    </div>
                  </p-accordionTab>
                </div>
              </p-accordion>
            </div>
          </div>

          <!-- Edit Roles Table  -->
          <div *ngIf="(statusDetailDataTable === 3 || statusDetailDataTable === 4)" [formGroup]="form">
            <form-field label="Name">
              <input type="text" pInputText formControlName="name" aria-label="">
            </form-field>
            <form-field label="Beschreibung">
              <textarea type="text" rows="4" pInputText formControlName="description" aria-label=""></textarea>
            </form-field>
            <div *ngIf="this.dynamicTables">
              <p-accordion *ngIf="this.selectedRole.id !== 1">
                <p-accordionTab *ngFor="let permissionTable of this.dynamicTables; let i = index"
                                [header]="getAccordionTabHeader(i, permissionTable)">
                  <div *ngIf="permissionTable.data.total !== 0">
                    <data-table [config]="permissionTable"></data-table>
                  </div>
                </p-accordionTab>
              </p-accordion>
              <div>
                <div *ngIf="this.selectedRole.id !== 1">
                  <p-button styleClass="reset-button-to-link" label="Abbrechen"></p-button>
                  <p-button (onClick)="saveChanges()" label="Speichern"></p-button>
                </div>
              </div>
              <p *ngIf="this.selectedRole.id === 1"> Die Rechte des Admins können nicht
                verändert werden.</p>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
