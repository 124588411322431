<div *ngIf='accountForm'>
  <form [formGroup]='accountForm' class='grid'>
    <div class='col-12'>
      <form-field label='Name'>
        <input pInputText type='text' name='accountName' formControlName='name' placeholder="Name">
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Zip Code'>
        <input pInputText type='text' name='zipCode' formControlName='zipCode' placeholder="Zip Code">
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Land'>
        <p-dropdown [options]='countries' formControlName='country' placeholder='Land'
                    optionLabel='name' appendTo='body'></p-dropdown>
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Sprache'>
        <p-dropdown [options]='languages' formControlName='language' placeholder='Sprache'
                    optionLabel='name' appendTo='body'></p-dropdown>
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Währung'>
        <p-dropdown [options]='currencies' formControlName='currency'
                    placeholder='Währung'
                    optionLabel='name' appendTo='body'></p-dropdown>
      </form-field>
    </div>
  </form>
</div>
