import { AutoComplete } from "primeng/autocomplete";

export interface SearchResultKeyValue {
  accountSystemId: string;
  name: string;
  value: string;
  description: string;
}

export interface SearchEngineResult {
  display: string;
  additionalInformation?: string;
  type?: SearchEngineMode;
}

export enum TeaserEngineState {
  NONE = "",
  KEY = "PROPERTY",
  VALUE = "VALUE",
  CONCAT = "CONCAT",
  LOGIC = "LOGIC",
}

export enum SearchEngineMode {
  GENERAL = "general",
  TEASER = "teaser",
}

export interface Expression {
  expressions: Array<string>;
}

export interface SearchEngine {
  suggest(
    searchString: string,
    additionalConfig?: any,
  ): Promise<Array<SearchEngineResult>>;
  onElementChosen(autoComplete: AutoComplete, element: any): any;
  onDropDownClick(): any;
  onInputWritten(element: string): any;
  cleanUp(): void;
  getMode(): string;
  resultExpression?: Expression;
}
