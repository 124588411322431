import { DataPersistorInterface } from "./data-persistor.interface";
import { Injectable } from "@angular/core";
import { SerializerService } from "./serializer.service";

@Injectable({
  providedIn: "root",
})
export class LocalStorageDataPersistor implements DataPersistorInterface {
  constructor(private serializer: SerializerService) {}

  async load(key: string): Promise<any> {
    const rawData = localStorage.getItem(key);
    if (rawData === null) {
      return null;
    }
    return this.serializer.deserialize(JSON.parse(rawData));
  }

  async save(key: string, data: any): Promise<void> {
    localStorage.setItem(key, JSON.stringify(this.serializer.serialize(data)));
  }

  async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
  }

  async has(key: string): Promise<boolean> {
    return localStorage.getItem(key) !== null;
  }
}
