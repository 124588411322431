import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  AutoUnsub,
  ConfirmService,
  LoadingService,
  OverlayService,
} from "@incert/incert-core";
import { WidgetModel } from "../shared/widget.model";
import { DashboardConfigService } from "../shared/dashboard-config.service";
import {
  ChangeStateCallback,
  WidgetHandle,
} from "../shared/widget-handle.service";
import { Observable, Subject, Subscription } from "rxjs";
import { DashboardFilterService } from "../shared/dashboard-filter.service";
import { LoadingWrapperComponent } from "@incert/incert-gui";
import {
  WidgetConfigurationService,
  WidgetConfigurationValueResolver,
} from "../shared/widget-configuration/widget-configuration.service";
import { debounceTime } from "rxjs/operators";
import { WidgetDescriptionEntry } from "@incert/dashboard";
import { I18nService } from "@incert/i18n";

@AutoUnsub()
@Component({
  selector: "widget-host",
  templateUrl: "./widget-host.component.html",
  styleUrls: ["./widget-host.component.scss"],
  providers: [OverlayService],
})
export class WidgetHostComponent {
  @Output()
  public widgetStateChanged = new EventEmitter<void>();

  @Output()
  public widgetRemove = new EventEmitter<void>();

  @ViewChild("widgetHost", { read: ViewContainerRef, static: true })
  private widgetHostViewContainerRef: ViewContainerRef;

  @ViewChild("widgetHost", { read: ElementRef, static: true })
  private widgetHostViewElementRef: ElementRef;

  @ViewChild("printArea", { read: ElementRef, static: true })
  public printAreaRef: ElementRef;

  @ViewChild(LoadingWrapperComponent, { static: true })
  public loadingWrapperComponent: LoadingWrapperComponent;

  @Input()
  public showcase = false;

  @Input()
  public isEtrackerActive = true;

  public isEtrackerWidget = false;

  public showActions = false;

  private _widget: WidgetModel;

  private _resize$ = new Subject<void>();

  public showConfiguration$ = new Subject<void>();

  public widgetHandle: WidgetHandle;

  public disablePrint = false;

  public calcSize = 0;

  private widgetConfigurationService: WidgetConfigurationService;

  // Subscription
  private widgetConfigurationSubscription: Subscription;
  private filterChangeSubscription: Subscription;
  private showConfigurationSubscription: Subscription;
  public widgetDescriptionEntries: WidgetDescriptionEntry[] = [];

  get widget(): WidgetModel {
    return this._widget;
  }

  get width() {
    return this.elementRef.nativeElement.offsetParent.offsetWidth;
  }

  /**
   * Adds listeners for crossbrowser print callback
   * @param callback - callback function
   */
  onPrint(callback) {
    this.widgetHostViewElementRef.nativeElement
      .matchMedia("print")
      .addListener((query) => (query.matches ? callback() : null));
    this.widgetHostViewElementRef.nativeElement.addEventListener(
      "beforeprint",
      () => callback(),
    );
  }

  @Input()
  set widget(value: WidgetModel) {
    const defaultSize = this.dashboardConfigService.getWidgetSize(
      value.componentName,
      "default",
    );
    if (defaultSize?.rows > 3) {
      this.calcSize = 30;
    } else {
      this.calcSize = 8;
    }

    if (this.filterChangeSubscription) {
      this.filterChangeSubscription.unsubscribe();
    }

    if (this.showConfigurationSubscription) {
      this.showConfigurationSubscription.unsubscribe();
    }

    if (this.widgetConfigurationSubscription) {
      this.widgetConfigurationSubscription.unsubscribe();
    }
    if (
      !this.widget ||
      (this._widget && this._widget.componentName !== value.componentName)
    ) {
      this._widget = value;
      const component = this.dashboardConfigService.getComponent(
        value.componentName,
      );
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(component);

      // callback for widget state change
      const changeStateCallback: ChangeStateCallback = (state: any) => {
        this._widget.state = state;
        this.widgetStateChanged.emit();
      };

      // get disable print
      this.disablePrint = this.dashboardConfigService.getWidgetDisablePrint(
        value.componentName,
      );

      //Etracker
      this.isEtrackerWidget = this.dashboardConfigService.isEtrackerWidget(
        value.componentName,
      );

      // setup configuration service
      const configurations =
        this.dashboardConfigService.getWidgetConfigurations(
          value.componentName,
        );
      let configurationChange$: Observable<WidgetConfigurationValueResolver>;

      // only when configurations are provided
      if (configurations) {
        this.widgetConfigurationService = new WidgetConfigurationService(
          configurations,
          value,
          changeStateCallback,
          this.overlayService,
          this.componentFactoryResolver,
          this.injector,
          this.loadingWrapperComponent.loadingService,
          this.i18n,
        );

        // reload config when filter changed => quickfix for double subscription todo: remove
        this.filterChangeSubscription =
          this.dashBoardFilterService.filterChange$
            .pipe(debounceTime(50))
            .subscribe((v) => {
              this.widgetConfigurationService.globalFilterState = v;
              this.widgetConfigurationService.init();
            });

        // show dialog
        this.showConfigurationSubscription = this.showConfiguration$.subscribe(
          () => {
            this.widgetConfigurationService.show();
          },
        );
        configurationChange$ = this.widgetConfigurationService.change$;
      }
      // provide dummy observable
      else {
        configurationChange$ =
          new Observable<WidgetConfigurationValueResolver>();
      }

      // create widget handle
      this.widgetHandle = new WidgetHandle(
        this._widget.state,
        this._resize$.asObservable(),
        this.showConfiguration$.asObservable(),
        changeStateCallback,
        configurationChange$,
      );
      const titleModel = this.dashboardConfigService.getWidgetTitle(
        value.componentName,
      );
      this.widgetHandle.title = titleModel.title;
      this.widgetHandle.tooltip = titleModel.tooltip;

      // set subtitle from configuration description
      if (this.widgetConfigurationService) {
        this.widgetConfigurationSubscription =
          this.widgetConfigurationService.change$.subscribe(() => {
            this.widgetDescriptionEntries =
              this.widgetConfigurationService.descriptionEntries;
            this.widgetHandle.localFilterActive =
              this.widgetConfigurationService.globalFilterOverridden;
          });
        this.widgetHandle.isConfigurable = true;
      }

      const widgetInjector = Injector.create({
        providers: [
          {
            provide: WidgetHandle,
            useValue: this.widgetHandle,
          },
          {
            provide: DashboardFilterService,
            useValue: this.dashBoardFilterService,
          },
          {
            provide: LoadingService,
            useValue: this.loadingWrapperComponent.loadingService,
          },
        ],
        parent: this.injector,
      });

      if (
        !this.isEtrackerWidget ||
        (this.isEtrackerWidget && this.isEtrackerActive)
      ) {
        this.widgetHostViewContainerRef.clear();
        this.widgetHostViewContainerRef.createComponent(
          componentFactory,
          undefined,
          widgetInjector,
        );
      }
    }
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public elementRef: ElementRef,
    private overlayService: OverlayService,
    private dashboardConfigService: DashboardConfigService,
    private loadingService: LoadingService,
    private injector: Injector,
    private dashBoardFilterService: DashboardFilterService,
    private confirmService: ConfirmService,
    private i18n: I18nService,
  ) {}

  public remove() {
    this.widgetRemove.emit();
  }

  public resize() {
    this._resize$.next();
  }

  // Convert the widget content to an PDF and start the download
  public async export() {}

  async setGlobalFilter(notify = true) {
    if (this.widgetConfigurationService) {
      let result = true;
      if (notify) {
        result = await this.confirmService.confirmInfo(
          "Sind Sie sicher?",
          "Globaler Filter setzen",
        );
        if (result) {
          await this.widgetConfigurationService.setGlobalFilter();
        }
      }
    }
  }
}
