import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CodeEditorComponent } from "./code-editor.component";
import { FormsModule } from "@angular/forms";
import { FormExtensionModule } from "../form-extension";

@NgModule({
  declarations: [CodeEditorComponent],
  exports: [CodeEditorComponent],
  imports: [CommonModule, FormsModule, FormExtensionModule],
})
export class CodeEditorModule {}
