import { ExporterInterface } from "./exporter.interface";
import { utils, write } from "xlsx-republish";

export class XlsxExporter implements ExporterInterface {
  private fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  export(data: any[][], header: string[]): Blob {
    const sheet = utils.aoa_to_sheet([header, ...data]);
    const buffer = write(
      {
        Sheets: { data: sheet, cols: [] },
        SheetNames: ["data"],
      },
      { bookType: "xlsx", type: "buffer" },
    );
    return new Blob([buffer], {
      type: this.fileType,
    });
  }

  get extension(): string {
    return "xlsx";
  }
}
