import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { I18nService } from "./i18n.service";

export interface I18nRouteData {
  i18nPaths: string[];
}

function isI18nRouteData(data: any): data is I18nRouteData {
  return (data as I18nRouteData).i18nPaths !== undefined;
}

@Injectable({
  providedIn: "root",
})
export class I18nGuard implements CanActivate {
  constructor(private i18n: I18nService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isI18nRouteData(route.data)) {
      const promises: Promise<any>[] = [];
      for (const path of route.data.i18nPaths) {
        promises.push(this.i18n.load(path));
      }
      for (const promise of promises) {
        await promise;
      }
    }
    return true;
  }
}
