<div class="dashboard-widget-outer-container"
     #printArea
     (mouseenter)="showActions=true"
     (mouseleave)="showActions=false"
     [ngClass]="{'showcase': showcase, 'no-print':disablePrint, 'disabled-widget': isEtrackerWidget &&!isEtrackerActive}"
     [id]="'dashboard-widget-' + widget.componentName">
  <div class="widget-header">
    <div class="widget-header-inner">
      <h2 *ngIf="widgetHandle.title && !showcase" [incTooltip]="widgetHandle.tooltip" appendTo="body"
          tooltipPosition="bottom">{{ widgetHandle.title }}</h2>
      <span class="widget-header-description" *ngIf="widgetDescriptionEntries.length">
                <icon-button iconType="globalfilter"
                             (click)="setGlobalFilter()"
                             [incTooltip]="'dashboard.widgets.resetFilter' | i18n"
                             *ngIf="widgetHandle.localFilterActive"></icon-button>
                <ng-container *ngFor="let description of widgetDescriptionEntries; let i = index">
                    <span [incTooltip]="description.info" [innerHTML]="description.title"
                          [ngClass]="{'multiple': description.multiple}"></span>
                    <span *ngIf="i < widgetDescriptionEntries.length-1" aria-hidden="true" class="delimiter">
                       &bull;
                    </span>
                </ng-container>
            </span>
    </div>
    <div class="widget-buttons" *ngIf="!showcase">
      <icon-button *ngIf="false" iconType="print"
                   (click)="export()"
                   [incTooltip]="'dashboard.widgets.printWidget' | i18n"></icon-button>
      <icon-button iconType="settings"
                   *ngIf="widgetHandle?.isConfigurable"
                   (click)="showConfiguration$.next()"
                   [incTooltip]="'dashboard.widgets.widgetSettings' | i18n"></icon-button>
      <icon-button iconType="close" class="enabled-button"
                   (click)="remove()"
                   [incTooltip]="'dashboard.widgets.closeWidget' | i18n"></icon-button>
    </div>
  </div>
  <div class="widget-content" [style.height]="showcase ? calcSize+'vh' : '' "
       [ngClass]="{'widget-content-etracker': isEtrackerWidget}">
    <h3 *ngIf="isEtrackerWidget && !isEtrackerActive" class="align-center error">
      {{ "dashboard.widgets.etracker.etrackerIsDisabled" |i18n }}
    </h3>
    <loading-wrapper>
      <ng-template #widgetHost>
      </ng-template>
    </loading-wrapper>
  </div>
  <div class="widget-etracker-ad" *ngIf="isEtrackerWidget">
    <img src="assets/images/etracker-badge.png" alt="Etracker Logo"/>
    {{ 'dashboard.widgets.etracker.information' |i18n }}
    <a
      href="https://www.etracker.com/de-signup/?ref=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJqdGkiOiI1OWU2YTAyNjg5OWY3MDYxMjA3MzVkNTlmY2UwNDE1NDIwNTBiY2YzMjE1MmMwOGU5MjA4MWEzZTEzOGZjYjRlIiwiaWF0IjoxNjE3ODAxNjM3LCJpc3MiOiJhcHBsaWNhdGlvbi5ldHJhY2tlci5jb20iLCJwYXJ0bmVySWQiOjMzOX0.JWY--fAl7ILyz9vQAa2Bft72zpmf4VScmrZyD12VdCcCi9OIqtUouYuw64zyuans1vGW_Vdx4JtKF1hpXWz4zlAI1lcwKB7DTcSCa8lVsuwYFSDXUz0pwOAvfPS1te5_PdFKhZ_Gdyp8didomo0nx6rGd3MIhssrnKNrlEd1Hm32XTV6Z4e9Ugsm86VSn8uax0Tv6uACU4fMdLg4E-_pd-3nMn8K-EyS_B-1V22XBJSk-HOCEzFHFjrO-RuOeV4FkqF60r5mZU_OfgSeTKyaFaKnN1x2fFszrWQzJ7skaUdf7pcqpGVfybteH1WNG8KIujaLT0jNYManNNxvuDNPkg"
      target="_blank">{{ 'dashboard.widgets.etracker.link' |i18n }}</a>
  </div>
</div>

