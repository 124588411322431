import { NgModule } from "@angular/core";
import { AccountOverviewComponent } from "./account-overview/account-overview.component";
import { AddAccountComponent } from "./account-overview/add-account/add-account.component";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { CustomerListDataTableModule } from "../../core/data-table-components/customer-list-data-table.module";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
    FormExtensionModule,
  ],
  declarations: [AccountOverviewComponent, AddAccountComponent],
  exports: [],
})
export class AccountModule {}
