import { NgModule } from "@angular/core";
import { IncertCoreModule } from "@incert/incert-core";
import { IncertGUIModule } from "@incert/incert-gui";
import { AppRoutingModule } from "./app-routing.module";

@NgModule({
  imports: [
    IncertCoreModule.forRoot(),
    IncertGUIModule.forRoot(),
    AppRoutingModule,
  ],
  declarations: [],
  exports: [IncertCoreModule, IncertGUIModule, AppRoutingModule],
})
export class CustomerListModule {}
