import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { LoginComponent } from "./pages/login";
import { AuthManager } from "./core/auth";
import { AuthHttpInterceptorService } from "./core/auth/auth-http-interceptor.service";
import { AccountModule } from "./pages/account/account.module";
import { RoleModule } from "./pages/role/role.module";
import { ProtocolModule } from "./pages/protocol/protocol.module";
import { NotificationModule } from "./pages/tools/notification/notification.module";
import { ClipboardOutletModule } from "../../../../shared-libs/incert-gui/src/lib/outlet/clipboard-outlet/clipboard-outlet.module";
import { UserModule } from "./pages/user/user.module";
import { CustomerListPrimeModule } from "./customer-list-prime.module";
import { MemoModule } from "./pages/tools/memo/memo.module";
import { Globals } from "./core/globals";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CustomerListModule } from "./customer-list.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { UserManager } from "./pages/user";
import { SettingsModule } from "./pages/settings/settings.module";
import { AccountSystemModule } from "./pages/account-system/account-system.module";
import { IncertI18nLoader, NotificationService } from "@incert/incert-core";
import { CustomerListSharedModule } from "./customer-list-shared.module";
import { PageModule } from "./core/header-footer/page.module";
import { I18nModule, I18nService } from "@incert/i18n";
import { AccessProfileModule } from "./pages/tools/access-profile/access-profile.module";
import { ContactGroupModule } from "./pages/tools/contact-group/contact-group.module";
import { ContactEmailModule } from "./pages/tools/contact-email/contact-email.module";
import { InfosModule } from "./pages/infos/infos.module";
import { FormExtensionModule } from "@incert/incert-gui";
import { MarketplaceModule } from "./pages/tools/marketplace/marketplace.module";
import { SystemValueModule } from "./pages/tools/system-value/system-value.module";
import { ResourceModule } from "./pages/tools/resource/resource.module";

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CustomerListModule,
    CustomerListPrimeModule,
    CustomerListSharedModule,
    SettingsModule,
    PageModule,
    HttpClientModule,
    AccountModule,
    RoleModule,
    ProtocolModule,
    UserModule,
    AccountSystemModule,
    ClipboardOutletModule,
    NotificationModule,
    ConfirmDialogModule,
    I18nModule.forRoot({
      loader: IncertI18nLoader,
    }),
    MemoModule,
    AccessProfileModule,
    ContactGroupModule,
    ContactEmailModule,
    InfosModule,
    FormExtensionModule,
    MarketplaceModule,
    SystemValueModule,
    ResourceModule,
  ],
  providers: [
    ConfirmationService,

    ConfirmDialogModule,
    Globals,
    NotificationService,
    { provide: "AuthManager", useClass: AuthManager },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorService,
      multi: true,
    },
    { provide: "UserManager", useClass: UserManager },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(private i18n: I18nService) {
    this.i18n.setLanguage("de");
  }
}
