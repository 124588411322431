<ng-container *ngFor="let tag of tags">
  <incert-tag
    [label]="tag.name"
    [icon]="icon"
    [severity]="severity"
    [bgColor]="bgColor"
    [fontColor]="fontColor"
    [tooltipText]="tooltipText"
    [badge]="badge">
  </incert-tag>
</ng-container>
