import { InjectionToken } from "@angular/core";
import { Subject } from "rxjs";
import { ConfirmParam } from "./confirm.param";

export const CONFIRM_SUBJECT = new InjectionToken<Subject<ConfirmParam>>(
  "CONFIRM_SUBJECT",
  {
    providedIn: "root",
    factory: () => {
      return new Subject<ConfirmParam>();
    },
  },
);
