import {Injectable} from "@angular/core";
import {InstallationStatusError} from "./account-system-installation-status.component";
import {HttpService} from "../../../../core";
import {APIService} from "../../../../core/api.service";
import {AccountSystem} from "../../../../core/models/account-system.interface";

@Injectable({providedIn: "root"})
export class AccountSystemInstallationService {

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {
  }

  public async getInstallationStatusErrors(accountSystem: AccountSystem): Promise<InstallationStatusError[]> {
    return await this.httpService.get<InstallationStatusError[]>(
      this.settingsService.baseUrl + "accountsystem/installationStatusErrors/" + accountSystem.id);
  }

  public installAccountSystem(accountSystem: AccountSystem): Promise<boolean> {
    return this.httpService.post(
      this.settingsService.baseUrl + "accountsystem/install",
      accountSystem,
    );
  }

  async toggleActiveAccountSystem(accountSystem: AccountSystem) {
    return this.httpService.post(
      this.settingsService.baseUrl + "accountsystem/active",
      accountSystem,
    );
  }

  async toggleProductiveAccountSystem(accountSystem: AccountSystem) {
    return this.httpService.post(
      this.settingsService.baseUrl + "accountsystem/productive",
      accountSystem,
    );
  }

}
