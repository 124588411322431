import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
} from "@angular/core";
import { PageStepComponent } from "./page-step/page-step.component";
import { PageContentComponent } from "./page-content/page-content.component";
import { MenuItem } from "primeng/api";

@Component({
  /* tslint:disable */
  selector: "page",
  /* tslint:enable */
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.css"],
})
export class PageComponent implements OnInit, AfterViewInit {
  @Input() header: string; //For pages not in gms-backend
  @Input() description: string; //For pages not in gms-backend
  @ContentChildren(PageStepComponent)
  public pageSteps = new QueryList<PageStepComponent>();

  @ContentChildren(PageContentComponent, { descendants: true })
  public pageContents = new QueryList<PageContentComponent>();

  public steps: MenuItem[] = [];

  private _activeStepIndex = 0;

  public set activeStepIndex(index: number) {
    this._activeStepIndex = index;
    this.setActiveStep();
  }

  public get activeIndex() {
    return this._activeStepIndex;
  }

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.stepsEnabled === true) {
      this.pageSteps.forEach((pageStep) => {
        this.steps.push({ label: pageStep.header });
      });
      this.setActiveStep();
    }
  }

  public get stepsEnabled() {
    return this.pageSteps.length !== 0;
  }

  public get hasNextStep() {
    return this.pageSteps.length - 1 > this._activeStepIndex;
  }

  public get hasPrevStep() {
    return this._activeStepIndex !== 0;
  }

  public nextStep() {
    if (this._activeStepIndex < this.pageSteps.length - 1) {
      this._activeStepIndex++;
      this.setActiveStep();
    }
  }

  public prevStep() {
    if (this._activeStepIndex > 0) {
      this._activeStepIndex--;
      this.setActiveStep();
    }
  }

  private setActiveStep() {
    let i = 0;
    this.pageSteps.forEach((wizardStep) => {
      wizardStep.active = this._activeStepIndex === i;
      i++;
    });
  }
}
