<div class="date-list-box">
  <div class="date-list-box-date">
    <span class="dlb-day">{{day}}</span>
    <span class="dlb-month">{{month}}</span>
    <span class="dlb-time">{{time}}</span>
  </div>
  <div class="date-list-box-details">
    <div class="dlb-details-content">
      <h4>{{headline}} <incert-tag *ngIf="tagLabel" [bgColor]="tagColor" [label]="tagLabel"></incert-tag></h4>
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="dlb-actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
</div>
