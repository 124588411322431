<div class="p-grid p-jc-around">
  <div [formGroup]="form.get('permissions')" >
    <p-checkbox
      *ngIf="permissionData.permissionToEditRole"
      [formControlName]="permissionData.permission.id"
      binary="true"
      class="p-col">
    </p-checkbox>
  </div>
</div>
