import { ArithmeticStrategy } from "../arithmetic-strategy";

export class AvgOperation implements ArithmeticStrategy {
  calculate(data, property, ...args): number | string {
    let sum = 0;

    try {
      data.forEach((dataItem) => (sum += +dataItem[property]));
    } catch (e) {
      console.error("Could not calculate average for", e);
    }

    return sum / data.length;
  }
}
