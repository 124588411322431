import {ReplaySubject} from "rxjs";
import {Injectable} from "@angular/core";
import {HttpService} from "../http.service";
import {APIService} from "../api.service";
import {Server} from "../models/server.model";

@Injectable({providedIn: "root"})
export class ServerService {
  public constructor(private httpService: HttpService,
                     private settingsService: APIService) {
  }

  private _serverEntries$ = new ReplaySubject<Server[]>(1);
  private _serverEntriesInit = false;

  public refreshServerEntries() {
    this.httpService.get<Server[]>(
      this.settingsService.baseUrl + "server", Server)
      .then((v) => this._serverEntries$.next(v));
  }

  public get serverEntries$() {
    if (!this._serverEntriesInit) {
      this.refreshServerEntries();
      this._serverEntriesInit = true;
    }
    return this._serverEntries$.asObservable();
  }


  private _portalNameEntries$ = new ReplaySubject<any[]>(1);
  private _portalNameEntriesInit = false;

  public refreshPortalNameEntries() {
    this.httpService.get<any[]>(
      this.settingsService.baseUrl + "server/portalName")
      .then((v) => this._portalNameEntries$.next(v));
  }

  public get portalNameEntries$() {
    if (!this._portalNameEntriesInit) {
      this.refreshPortalNameEntries();
      this._portalNameEntriesInit = true;
    }
    return this._portalNameEntries$.asObservable();
  }

  private _clusterEntries$ = new ReplaySubject<any[]>(1);
  private _clusterEntriesInit = false;

  public refreshClusterEntries() {
    this.httpService.get<any[]>(
      this.settingsService.baseUrl + "server/cluster")
      .then((v) => this._clusterEntries$.next(v));
  }

  public get clusterEntries$() {
    if (!this._clusterEntriesInit) {
      this.refreshClusterEntries();
      this._clusterEntriesInit = true;
    }
    return this._clusterEntries$.asObservable();
  }
}
