import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NoDataInfoComponent } from "./no-data-info.component";
import { IconModule } from "../icon/icon.module";

@NgModule({
  imports: [CommonModule, IconModule],
  exports: [NoDataInfoComponent],
  declarations: [NoDataInfoComponent],
})
export class NoDataInfoModule {}
