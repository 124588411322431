import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClipboardOutletComponent } from "./clipboard-outlet.component";
import { IconModule } from "../../icon/icon.module";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";

@NgModule({
  imports: [CommonModule, MessagesModule, MessageModule, IconModule],
  declarations: [ClipboardOutletComponent],
  exports: [ClipboardOutletComponent],
})
export class ClipboardOutletModule {}
