<form-field [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <p-dropdown
    [formControl]="filter"
    [options]="options"
    (onChange)="filterExplicit()"
    [showClear]="true"
    [placeholder]="floatingLabel ? ' ' : config.header"
    [filter]="config.filter"
    appendTo="body">
  </p-dropdown>
</form-field>
