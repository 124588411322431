import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ChangeListService {
  accountSystemGroupChangeList: Array<number> = [];

  constructor() {}

  addToChangelist(id) {
    this.accountSystemGroupChangeList.push(id);
  }

  getChangeList() {
    return this.accountSystemGroupChangeList;
  }

  removeFromChangelist(id: number) {
    this.accountSystemGroupChangeList =
      this.accountSystemGroupChangeList.filter((i) => i !== id);
  }
}
