import {
  SearchEngine,
  SearchEngineMode,
  SearchEngineResult,
} from "../search-engine.interface";
import { Injectable } from "@angular/core";
import { SearchEngineService } from "../search-engine-service";
import { TeaserEngine } from "./teaser.engine";

@Injectable({
  providedIn: "root",
})
export class GeneralEngine implements SearchEngine {
  public resultExpression = {
    expressions: [],
  };

  constructor(
    private searchEngineService: SearchEngineService,
    private teaserEngine: TeaserEngine,
  ) {}

  public async suggest(searchString: string): Promise<any> {
    const teaserSuggestions = await this.teaserEngine.suggest(searchString);
    const filterSuggestions = await this.searchEngineService
      .getFilters()
      .then((data) => this.getFilterSuggestions(data, searchString));

    return [...teaserSuggestions, ...filterSuggestions];
  }

  private getFilterSuggestions(
    data: Array<any>,
    searchString: string,
  ): Array<SearchEngineResult> {
    const filtered = Object.values(data).filter((r) => {
      return r.filter.toLowerCase().includes(searchString.toLowerCase());
    });
    return filtered.map((r: any) => ({
      display: r.filter,
      additionalInformation: r.name,
      type: SearchEngineMode.GENERAL,
    }));
  }

  public async onElementChosen(autocomplete: any, element: SearchEngineResult) {
    await this.searchEngineService.updateFilter(element.display, this);
  }

  public async onInputWritten(element: string) {
    await this.searchEngineService.updateFilter(element, this);
  }

  onDropDownClick() {}

  public cleanUp(): void {
    this.resultExpression.expressions = [];
  }

  public getMode(): string {
    return SearchEngineMode.GENERAL;
  }
}
