import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { validationMessage } from "../validation-message.function";
import { allowEmpty } from "../allow-empty.function";

export class StringValidators {
  public static matches(text: string, regex: RegExp): ValidatorFn {
    return validationMessage(text, (control: AbstractControl) =>
      (control.value || "").match(regex) ? null : {},
    );
  }

  public static maxChars(chars: number): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      const validationErrors: ValidationErrors = {};

      const exceeding = control.value.length - chars;
      if (control.value.length > chars) {
        validationErrors["maxChars"] = {
          maxChars: chars,
          exceeding: exceeding,
        };
        return validationErrors;
      }
      return null;
    });
  }
}
