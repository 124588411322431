<div *ngIf="(_rksvData || mode === 'create') && isAdminLogin">
  <div>
    <icon-button
      *ngIf='mode === "show"'
      iconType="edit"
      label="Bearbeiten"
      (click)="toggleMode('edit')">
    </icon-button>

    <icon-button
      iconType="save"
      label="Änderungen speichern"
      *ngIf="mode === 'edit' || mode === 'create'"
      (click)="mode === 'edit' ? update() : save()">
    </icon-button>
  </div>

  <div class="grid" *ngIf="formGroup" [formGroup]="formGroup">
    <div class="col-2">
      <form-field label="UID">
        <input type="text" pInputText formControlName="uid">
      </form-field>
    </div>
    <div class="col-6">
      <form-field label="Web Service URI">
        <input type="text" pInputText formControlName="webServiceUrl">
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="User">
        <input type="text" pInputText formControlName="webServiceUser">
      </form-field>
    </div>
    <div class="col-2">
      <form-field label="Password">
        <input type="text" pInputText formControlName="webServicePass">
      </form-field>
    </div>
    <div class="col-6">
      <form-field label="AES Key">
        <input type="text" pInputText formControlName="aesKey">
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Fon TID">
        <input type="text" pInputText formControlName="webServiceFonTid">
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Fon User">
        <input type="text" pInputText formControlName="webServiceFonUser">
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Fon Password">
        <input type="text" pInputText formControlName="webServiceFonPass">
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="RKSV Status">
        <p-dropdown [options]="activeStatusSI" formControlName="rksvActivated"></p-dropdown>
      </form-field>
    </div>
  </div>
</div>
<div *ngIf="!_rksvData && mode !== 'create'">
  <icon-button iconType="plus" label="RKSV" (click)="toggleMode('create')"></icon-button>
</div>
