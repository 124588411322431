import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { I18nModule } from "@incert/i18n";
import { DataTableExpandTextComponent } from "./data-table-expand-text.component";

@NgModule({
  declarations: [DataTableExpandTextComponent],
  imports: [CommonModule, ButtonModule, I18nModule],
  exports: [DataTableExpandTextComponent],
})
export class DataTableExpandTextModule {}
