import {
  Component,
  ContentChildren,
  Inject,
  Input,
  Optional,
  QueryList,
} from "@angular/core";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";
import { LanguageFormComponent } from "../form-extension";
import { MultiLanguageFormService } from "@incert/incert-gui";

@Component({
  selector: "multi-language-form",
  templateUrl: "./multi-language-form.component.html",
})
export class MultiLanguageFormComponent {
  @Input()
  enableAllLanguages?: boolean;

  @Input()
  displayAsGrid = false;

  @Input()
  colClass = "";

  @ContentChildren(LanguageFormComponent, { descendants: true })
  public languageFormEntries = new QueryList<LanguageFormComponent>();

  constructor(
    @Inject(INCERT_GUI_I18N) private incertGUII18n: IncertGUII18n,
    @Optional()
    public multiLanguageFormService: MultiLanguageFormService | null,
  ) {}

  get displayAllLanguages() {
    if (typeof this.enableAllLanguages === "boolean") {
      return this.enableAllLanguages;
    }
    return this.multiLanguageFormService
      ? this.multiLanguageFormService.enableAllLanguages
      : false;
  }

  public getLangCodeFromId(id: number | string): string {
    if (typeof id === "string") id = parseInt(id, 10);
    const lang = this.incertGUII18n.languages.find((l) => l.id == id);
    return lang ? lang.code : "";
  }

  getActiveLangTemplate() {
    const activeLangForm = this.languageFormEntries.find(
      (form) =>
        this.incertGUII18n.currentLanguage.id ===
        parseInt(form.formGroupName, 10),
    );
    return activeLangForm.template;
  }
}
