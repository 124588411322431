import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function allowEmpty(vFn: ValidatorFn): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    } else {
      return vFn(control);
    }
  };
}
