import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { markAsDirty } from "./mark-as-dirty.function";
import { updateValueAndValidity } from "./update-value-and-validity.function";

export class FormUtil {
  public static markAsDirty(control: UntypedFormGroup | AbstractControl) {
    markAsDirty(control);
  }

  public static updateValueAndValidity(
    control: UntypedFormGroup | AbstractControl,
  ) {
    updateValueAndValidity(control);
  }

  public static removeRequired(control: UntypedFormGroup | AbstractControl) {
    if (control instanceof UntypedFormGroup) {
      for (const key of Object.keys(control.controls)) {
        this.removeRequired(control.controls[key]);
      }
    } else if (control instanceof UntypedFormControl) {
      const validator = control.validator;
      if (validator) {
        control.setValidators((c) => (c.value ? validator(c) : null));
      }
    }
  }
}
