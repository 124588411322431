import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageModule } from "../page/page.module";
import { DefaultCarouselComponent } from "./default-carousel/default-carousel.component";
import { DefaultAccordionComponent } from "./default-accordion/default-accordion.component";
import { DefaultTabsComponent } from "./default-tabs/default-tabs.component";
import { CarouselModule } from "primeng/carousel";
import { AccordionModule } from "primeng/accordion";
import { TabViewModule } from "primeng/tabview";
import { ButtonModule } from "primeng/button";
import { I18nModule } from "@incert/i18n";
import { IconModule } from "../icon/icon.module";

@NgModule({
  imports: [
    CommonModule,
    PageModule,
    CarouselModule,
    AccordionModule,
    TabViewModule,
    ButtonModule,
    I18nModule,
    IconModule,
  ],
  exports: [DefaultCarouselComponent, DefaultAccordionComponent],
  declarations: [
    DefaultCarouselComponent,
    DefaultAccordionComponent,
    DefaultTabsComponent,
  ],
})
export class DefaultSlideModule {}
