import { NgModule } from "@angular/core";
import { MemoCategoryComponent } from "./memo-category-new/add-new-memo-category.component";
import { MemoComponent } from "./memo.component";
import { MemoHistoryComponent } from "./memo-history/memo-history.component";
import { MemoAdd } from "./memo-add/memo-add.component";
import { MemoRoutingModule } from "./memo-routing.module";
import { CustomerListDataTableModule } from "../../../core/data-table-components/customer-list-data-table.module";
import { CustomerListPrimeModule } from "../../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../../customer-list-shared.module";
import {
  FormExtensionModule,
  IncertTagModule,
  QuillEditorModule,
} from "@incert/incert-gui";
import { MemoTagAdd } from "./memo-tag-new/memo-tag-add.component";

@NgModule({
  declarations: [
    MemoComponent,
    MemoHistoryComponent,
    MemoAdd,
    MemoCategoryComponent,
    MemoTagAdd,
  ],
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
    MemoRoutingModule,
    QuillEditorModule,
    IncertTagModule,
    FormExtensionModule,
  ],
  exports: [
    MemoAdd,
    MemoCategoryComponent,
    MemoTagAdd,
    MemoComponent,
    MemoHistoryComponent,
  ],
})
export class MemoModule {}
