import {
  NgModule,
  Component,
  ElementRef,
  OnDestroy,
  Input,
  OnInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { Calendar } from "@fullcalendar/core";
import deLocale from "@fullcalendar/core/locales/de";

@Component({
  selector: "p-fullCalendar",
  template: '<div [ngStyle]="style" [class]="styleClass"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "p-element",
  },
})
export class FullCalendar implements OnDestroy, OnInit, AfterViewChecked {
  @Input() style: any;

  @Input() styleClass: string;

  initialized: boolean;

  calendar: any;

  config: any;

  _options: any;

  _events: any[];

  constructor(public el: ElementRef) {}

  ngOnInit() {
    this.config = {};

    if (this.options) {
      for (const prop in this.options) {
        this.config[prop] = this.options[prop];
      }
    }
  }

  ngAfterViewChecked() {
    if (!this.initialized && this.el.nativeElement.offsetParent) {
      this.initialize();
    }
  }

  @Input() get events(): any {
    return this._events;
  }

  set events(value: any) {
    this._events = value;

    if (this._events && this.calendar) {
      this.calendar.removeAllEventSources();
      this.calendar.addEventSource(this._events);
    }
  }

  @Input() get options(): any {
    return this._options;
  }

  set options(value: any) {
    this._options = value;

    if (this._options && this.calendar) {
      for (const prop in this._options) {
        const optionValue = this._options[prop];
        this.config[prop] = optionValue;
        this.calendar.setOption(prop, optionValue);
      }
    }
  }

  initialize() {
    this.config.locale = deLocale;
    this.config.firstDay = 1;
    this.calendar = new Calendar(
      this.el.nativeElement.children[0],
      this.config,
    );
    this.calendar.render();
    this.initialized = true;

    if (this.events) {
      this.calendar.removeAllEventSources();
      this.calendar.addEventSource(this.events);
    }
  }

  getCalendar() {
    return this.calendar;
  }

  ngOnDestroy() {
    if (this.calendar) {
      this.calendar.destroy();
      this.initialized = false;
      this.calendar = null;
    }
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [FullCalendar],
  declarations: [FullCalendar],
})
export class FullCalendarModule {}
