import { DataConversion, Required } from "@incert/data-conversion";

@DataConversion()
export class Server {
  @Required()
  portalName: string;
  @Required()
  cluster: string;
  @Required()
  type: string;
  @Required()
  prefix: string;
  @Required()
  localIpAddress: string;
  @Required()
  publicIpAddress: string;
  @Required()
  hostname: string;
}
