import { Component, Input, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableArrayComponent,
  DataTableArrayOptions,
  DataTableConfig,
  DataTableIconButtonsComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { Memo } from "../memo.interface";
import { MemoManager } from "../memo.manager.service";
import { LoadingService, NotificationService } from "@incert/incert-core";
import { User } from "../../../user";
import {
  CommentAreaConfig,
  DataTableCommentAreaComponent,
} from "../../../../core/data-table-components/data-table-comment-area/data-table-comment-area.component";
import { Utils } from "../../../../core/utils";
import { SelectItem } from "primeng/api";
import { DataTableTagComponent } from "@incert/incert-gui";

@Component({
  selector: "incert-internal-tools-memo-history",
  templateUrl: "./memo-history.component.html",
  styleUrls: ["./memo-history.component.scss"],
})
export class MemoHistoryComponent implements OnInit {
  @Input() memoId: number;
  public memoHistory: Array<Memo>;
  public memoHistoryTableConfig: DataTableConfig<Memo>;
  public isEmptyHistory = false;

  constructor(
    private memoManager: MemoManager,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
  ) {}

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      this.memoHistory = await this.memoManager.getMemoHistory(this.memoId);
      this.checkIfEmptyHistory();
      this.createDataTable();
    });
  }

  private createDataTable() {
    this.memoHistoryTableConfig = {
      data: new ArrayDataSource(this.memoHistory),
      rows: 10,
      mode: "pagination",
      columns: [
        {
          header: "Titel",
          property: (v) => v.get("title"),
        },
        {
          header: "Kategorie",
          property: (v) => v.get("category", "name"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Memo) => {
              return [
                {
                  name: row.category.name.toUpperCase(),
                  bgColor: row.category.bgColor,
                  fgColor: row.category.fgColor,
                },
              ];
            },
          }),
        },
        {
          header: "Tags",
          property: (v) => v.get("tags"),
          class: () => "dt-width-150",
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Memo) => {
              return row.tags;
            },
          }),
        },
        {
          header: "Erstellungsdatum",
          property: (v) => v.get("createdAt"),
          transform: (v) => Utils.showLastChanged(v),
        },
        {
          header: "Bearbeiter",
          property: (v) => v.get("createdBy", "lastName"),
        },
        {
          header: "Ansprechpartner",
          component: createComponentConfiguration(DataTableArrayComponent, {
            setArrayData: (row: Memo): DataTableArrayOptions<User> => {
              return {
                arrayData: row.speakTo,
                displayProperty: "lastName",
              };
            },
          }),
        },
        {
          header: "Kommentar",
          property: (v) => v.get("comment"),
          component: createComponentConfiguration(
            DataTableCommentAreaComponent,
            {
              setTextAreaData: (row: Memo): CommentAreaConfig<Memo> => {
                return {
                  header: "Kommentar",
                  maxLengthWrap: 192,
                  text: row.comment,
                  createdBy: row.createdBy,
                  createdAt: row.createdAt,
                };
              },
            },
          ),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: {
                icon: "delete",
                onClick: (row: Memo) => this.deleteSingleHistoryEntry(row.id),
              },
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "delete",
              label: "Verlauf löschen",
              onClick: () => this.onDeleteCompleteHistory(),
            },
          ],
        }),
      ],
    };
  }

  private checkIfEmptyHistory() {
    if (!this.memoHistory.length) {
      this.isEmptyHistory = true;
    }
  }

  public async deleteSingleHistoryEntry(historyId) {
    await this.memoManager.deleteSingleHistoryEntry(historyId);
    await this.initialize();

    this.notificationService.notifySuccess(
      "History Eintrag wurde erfolgreich gelöscht",
      "Löschen erfolgreich",
    );
  }

  public async onDeleteCompleteHistory() {
    await this.memoManager.deleteHistoryForMemo(this.memoId);
    await this.initialize();

    this.notificationService.notifySuccess(
      "Verlauf wurde erfolgreich gelöscht",
      "Löschen erfolgreich",
    );
  }
}
