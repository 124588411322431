import { ExporterInterface } from "./exporter.interface";

export class JsonExporter implements ExporterInterface {
  export(data: any[][], header: string[]): string {
    return JSON.stringify(
      data.map((row) => {
        const result = {};
        for (let i = 0; i < row.length; ++i) {
          result[header[i] ?? i] = row[i];
        }
        return result;
      }),
      null,
      2,
    );
  }

  get extension(): string {
    return "json";
  }
}
