import { Injectable } from "@angular/core";
import { HttpService } from "../index";
import { Language } from "./language";
import { APIService } from "../api.service";

@Injectable({ providedIn: "root" })
export class LanguageService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getLanguages(): Promise<Array<Language>> {
    return this.httpService.get<Array<Language>>(
      this.settingsService.baseUrl + "language",
    );
  }
}
