import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { I18nService } from "@incert/i18n";
import {
  IconType,
  loadScript,
  OverlayService,
  resolveAsset,
} from "@incert/incert-core";
import { SourceViewOverlayComponent } from "./source-view-overlay/source-view-overlay.component";
import {
  matchMsTitle,
  matchMsWordList,
  maybeMatchMsWordList,
  validateQuillEditorValue,
} from "./quill-editor.function";
import { OverlayPanel } from "primeng/overlaypanel";
import {
  createQuillTagBlot,
  insertTagBlot,
  QuillTagBlotConfigInterface,
} from "./create-quill-tag-blot.function";
import { cloneQuillRegistry } from "./clone-quill-registry.function";
import Quill, { QuillOptions } from "quill";
import {
  transformQuillInput,
  transformQuillOutput,
} from "./quill-editor-transformer.function";
import { FontFamilyModel } from "@incert/gms-core";
import { QuillCustomFontModule } from "./quill-custom-font-module.class";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import Link from "quill/formats/link";

declare const quillBetterTable: any;

export interface QuillEditorButtonConfigInterface {
  iconType: keyof typeof IconType;
  command: (quill: Quill) => void;
}

@Component({
  selector: "incert-quill-editor",
  templateUrl: "./quill-editor.component.html",
  styleUrls: ["./quill-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorComponent),
      multi: true,
    },
  ],
})
export class QuillEditorComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor
{
  @Input() config: QuillOptions = null;
  @Input() formControlName: string | undefined;
  @Input() height: string | undefined;
  @Input() fonts?: FontFamilyModel[];
  @Input() tags: QuillTagBlotConfigInterface[] = [];
  @Input() buttons: QuillEditorButtonConfigInterface[] = [];

  @ViewChild("editor")
  set editor(value: ElementRef | undefined) {
    this.editor$.next(value);
  }

  @ViewChild("toolbar")
  set toolbar(value: ElementRef | undefined) {
    this.toolbar$.next(value);
  }

  @ViewChild("op") op: OverlayPanel;

  tableModule: any;
  quill: Quill | null = null;

  private _value: string;
  private _onChange = (value: any) => {};
  private _onTouch = (value: any) => {};

  i18nLoaded = false;
  quillLoaded = false;

  addTableRows = Array.from({ length: 10 }).map((v, i) => i);
  addTableColumns = Array.from({ length: 10 }).map((v, i) => i);
  addTableState: { row: number; column: number } = {
    row: 0,
    column: 0,
  };

  private editor$ = new BehaviorSubject<ElementRef | undefined>(undefined);
  private toolbar$ = new BehaviorSubject<ElementRef | undefined>(undefined);
  private subscriptions = new Subscription();
  private fontsModule?: QuillCustomFontModule;
  private lastRawEditorHTML?: string;

  constructor(
    public i18n: I18nService,
    public overlayService: OverlayService,
  ) {
    // todo: refactor
    this.i18n.load("gms/frontend");
  }

  async ngOnInit() {
    await this.i18n.loading;
    this.i18nLoaded = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngAfterViewInit() {
    await loadScript(resolveAsset("assets/quill/quill.js"));
    await loadScript(resolveAsset("assets/quill/quill.snow.css"));
    await loadScript(
      resolveAsset("assets/quill-better-table/quill-better-table.js"),
    );
    await loadScript(
      resolveAsset("assets/quill-better-table/quill-better-table.css"),
    );
    await loadScript(
      resolveAsset("assets/quill-better-table/quill-better-table.css"),
    );
    Quill.register(
      {
        "modules/better-table": quillBetterTable,
      },
      true,
    );
    this.addLinkSanitizer();
    this.quillLoaded = true;

    this.subscriptions.add(
      combineLatest({ editor: this.editor$, toolbar: this.toolbar$ }).subscribe(
        ({ editor, toolbar }) => {
          if (!this.quill && editor && toolbar) {
            this.initQuillEditor(editor, toolbar);
          }
        },
      ),
    );
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  writeValue(value: any): void {
    value = typeof value === "string" ? value : "";

    if (!this.quill) {
      this._value = value;
    } else {
      this.setQuillHTML(this.transformInput(value));
    }
  }

  handleClickAddTable(i, ii) {
    this.tableModule.insertTable(i + 1, ii + 1);
    this.op.hide();
  }

  updateAddTableState(i, ii) {
    this.addTableState = { row: i, column: ii };
  }

  viewSource() {
    const html = this.quill.root.innerHTML;
    this.overlayService.show<SourceViewOverlayComponent>({
      type: SourceViewOverlayComponent,
      header: this.i18n.instant("gms.frontend.overlay.sourceCodeEditor.header"),
      init: (comp: SourceViewOverlayComponent) => {
        comp.source = this.transformOutput(html);
      },
      actions: [
        {
          action: (comp) => {
            this.writeValue(comp.source);
            return true;
          },
          label: this.i18n.instant("core.action.save"),
        },
      ],
    });
  }

  setQuillHTML(value: string) {
    if (this.quill) {
      const delta = this.quill.clipboard.convert({ html: value });
      this.quill.setContents(delta, "silent");
    }
  }

  initQuillEditor(editor: ElementRef, toolbar: ElementRef): void {
    if (this.config === null) {
      this.config = this.createDefaultConfig(toolbar.nativeElement);
    }

    const registry = cloneQuillRegistry(
      this.config.registry ?? Quill.DEFAULTS.registry,
    );

    for (const tag of this.tags) {
      registry.register(createQuillTagBlot(Quill, tag));
    }

    const fontSelect = toolbar.nativeElement.querySelector(".ql-font");
    if (this.fonts && this.fonts.length) {
      this.fontsModule = new QuillCustomFontModule(Quill);
      for (const font of this.fonts) {
        const id = this.fontsModule.registerFontFamilyAndFaces(font);

        const optionEl = document.createElement("OPTION") as HTMLOptionElement;
        optionEl.value = id;
        optionEl.text = font.displayName;
        fontSelect.options.add(optionEl);
      }
      this.fontsModule.install(registry);
    } else {
      fontSelect.parentNode.removeChild(fontSelect);
    }

    const config = { ...this.config };
    config.registry = registry;

    this.quill = new Quill(editor.nativeElement, config);
    this.tableModule = this.quill.getModule("better-table");

    // Add rules to Quill to convert MS-Word texts by copy pasting
    // Rules to convert ordered and unordered lists
    this.quill.clipboard.addMatcher(
      "p.MsoListParagraphCxSpFirst",
      matchMsWordList,
    );
    this.quill.clipboard.addMatcher(
      "p.MsoListParagraphCxSpMiddle",
      matchMsWordList,
    );
    this.quill.clipboard.addMatcher(
      "p.MsoListParagraphCxSpLast",
      matchMsWordList,
    );
    this.quill.clipboard.addMatcher("p.MsoListParagraph", matchMsWordList);
    this.quill.clipboard.addMatcher("p.msolistparagraph", matchMsWordList);
    this.quill.clipboard.addMatcher("p.MsoNormal", maybeMatchMsWordList);
    // Rule to convert Title
    this.quill.clipboard.addMatcher("p.MsoTitle", matchMsTitle);
    // set initial value
    if (this._value) {
      this.setQuillHTML(this.transformInput(this._value));
    }
    this.quill.on("editor-change", (texAndSelection, ...args) => {
      this.detectChange();
    });
    this.detectChange();
  }

  private addLinkSanitizer() {
    const link = Quill.import("formats/link") as typeof Link;
    if ("isCustom" in link && link.isCustom) {
      return;
    }

    class CustomLinkSanitizer extends link {
      public static isCustom = true;

      static sanitize(url) {
        const sanitizedUrl = super.sanitize(url);

        if (!sanitizedUrl || sanitizedUrl === "about:blank") {
          return sanitizedUrl;
        }

        if (sanitizedUrl.startsWith("/")) {
          return sanitizedUrl;
        }

        if (QuillEditorComponent.tryParseURL(sanitizedUrl)) {
          return sanitizedUrl;
        }

        if (QuillEditorComponent.tryParseURL("https://" + sanitizedUrl)) {
          return "https://" + sanitizedUrl;
        }

        return sanitizedUrl;
      }
    }

    Quill.register(CustomLinkSanitizer, true);
  }

  private static tryParseURL(url) {
    try {
      return new URL(url);
    } catch (e) {
      return null;
    }
  }

  private transformInput(value: string) {
    value = transformQuillInput(validateQuillEditorValue(value));

    if (this.fontsModule) {
      const el = document.createElement("DIV");
      el.innerHTML = value;
      this.fontsModule.prepareInput(el);
      value = el.innerHTML;
    }

    return value;
  }

  private transformOutput(value: string) {
    value = transformQuillOutput(validateQuillEditorValue(value));

    if (this.fontsModule) {
      const el = document.createElement("DIV");
      el.innerHTML = value;
      this.fontsModule.prepareOutput(el);
      value = el.innerHTML;
    }

    return value;
  }

  private detectChange() {
    const html = this.quill.root.innerHTML;
    if (this.lastRawEditorHTML !== html) {
      this.lastRawEditorHTML = html;
      this._onChange(this.transformOutput(html));
    }
  }

  private createDefaultConfig = (toolbar): QuillOptions => {
    return {
      theme: "snow",
      modules: {
        table: false, // disable table module
        "better-table": {
          operationMenu: {
            items: {
              insertColumnRight: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertColumnRight",
                ),
              },
              insertColumnLeft: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertColumnLeft",
                ),
              },
              insertRowUp: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertRowUp",
                ),
              },
              insertRowDown: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.insertRowDown",
                ),
              },
              mergeCells: {
                text: this.i18n.instant("gms.frontend.quillOptions.mergeCells"),
              },
              unmergeCells: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.unmergeCells",
                ),
              },
              deleteColumn: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.deleteColumn",
                ),
              },
              deleteRow: {
                text: this.i18n.instant("gms.frontend.quillOptions.deleteRow"),
              },
              deleteTable: {
                text: this.i18n.instant(
                  "gms.frontend.quillOptions.deleteTable",
                ),
              },
            },
            color: {
              colors: ["#fff", "red", "rgb(0, 0, 0)"], // colors in operationMenu
              text: "Background Colors", // subtitle
            },
          },
        },
        keyboard: {
          bindings: quillBetterTable.keyboardBindings,
        },
        toolbar: toolbar ?? [
          [{ size: [false, "huge", "large", "small"] }], // custom dropdown
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [
            {
              align: [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" },
              ],
            },
          ],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          ["clean"], // remove formatting button,
        ],
      },
    };
  };
}
