import { ObjectOrArrayType } from "@incert/data-conversion";

export interface HttpAccessInterface {
  get(url: string): Promise<any>;

  get<T extends any>(url: string, type: ObjectOrArrayType<T>): Promise<T>;

  post(url: string, payload: any): Promise<any>;

  post<T extends any>(
    url: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;

  delete(url: string): Promise<any>;

  delete<T extends any>(url: string, type: ObjectOrArrayType<T>): Promise<T>;

  put(url: string, payload: any): Promise<any>;

  put<T extends any>(
    url: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
}
