export class WizardStepContext {
  onNext: (() => boolean) | (() => Promise<boolean>) | null = null;
  onPrev: (() => boolean) | (() => Promise<boolean>) | null = null;

  onNextLabel: string | null = null;
  onPrevLabel: string | null = null;
}

export class WizardStepService {
  context: WizardStepContext | null = null;
}
