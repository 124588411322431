import { Type } from "@angular/core";
import { DataTableFilterComponent } from "./interfaces/data-table-filter-component.interface";
import { FilterDefinition } from "./interfaces/column-definition.interface";

export function createFilterDefinition<
  T extends DataTableFilterComponent,
  K extends "config",
>(component: Type<T>, config: T[K]): FilterDefinition<any> {
  return {
    component: component,
    config: config ? config : { properties: null },
  };
}
