import { Component, OnInit } from "@angular/core";
import { DataTableFilterProfile } from "../../shared/interfaces/data-table-persistence.interface";
import {
  ArrayDataSource,
  createComponentConfiguration,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DataTableToggleButtonComponent,
} from "@incert/incert-gui";
import { I18nService } from "@incert/i18n";
import { DataTableFilterAreaComponent } from "../data-table-filter-area.component";
import { DataTableFilterProfileExpansionComponent } from "./data-table-filter-profile-expansion/data-table-filter-profile-expansion.component";
import { OverlayHandle } from "@incert/incert-core";

@Component({
  selector: "incert-data-table-filter-profile-overlay",
  templateUrl: "./data-table-filter-profile-overlay.component.html",
  styleUrls: ["./data-table-filter-profile-overlay.component.css"],
})
export class DataTableFilterProfileOverlayComponent implements OnInit {
  filterProfiles: DataTableFilterProfile[] = [];
  dtConfig: DataTableConfig<DataTableFilterProfile> = null;
  arrayDataSource: ArrayDataSource<DataTableFilterProfile> = null;
  dtFilterAreaComponent: DataTableFilterAreaComponent;

  constructor(
    private i18n: I18nService,
    private overlayHandle: OverlayHandle,
  ) {}

  ngOnInit(): void {
    this.createDtConfig();
  }

  private createDtConfig() {
    this.arrayDataSource = new ArrayDataSource<DataTableFilterProfile>(
      this.filterProfiles,
    );
    this.dtConfig = {
      data: this.arrayDataSource,
      rows: Number.MAX_SAFE_INTEGER,
      displayAsNewIncList: true,
      resetSubtablesToRegularStyle: true,
      columns: [
        {
          header: this.i18n.instant("core.default"),
          description: this.i18n.instant(
            "core.dataTable.defaultFilterProfileDesc",
          ),
          component: createComponentConfiguration(
            DataTableToggleButtonComponent,
            {
              onClick: (row: DataTableFilterProfile) => {
                this.setDefaultFilterProfile(row);
              },
              isChecked: (row: DataTableFilterProfile) => row.default,
            },
          ),
        },
        {
          header: this.i18n.instant("core.form.designation"),
          property: (v) => v.get("name"),
        },
        {
          header: this.i18n.instant("core.action.actions"),
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "delete",
                  iconOnly: true,
                  tooltip: this.i18n.instant("core.action.delete"),
                  tooltipPosition: "top",
                  onClick: (row: DataTableFilterProfile) =>
                    this.deleteFilterProfile(row),
                },
                {
                  icon: "move",
                  iconOnly: true,
                  tooltip: this.i18n.instant("core.action.apply"),
                  tooltipPosition: "top",
                  onClick: (row: DataTableFilterProfile) =>
                    this.applyFilterProfile(row),
                },
              ],
            },
          ),
        },
      ],
      expansion: {
        singleRowExpanded: false,
        component: {
          component: DataTableFilterProfileExpansionComponent,
          init: (
            instance: DataTableFilterProfileExpansionComponent,
            row: DataTableFilterProfile,
          ) => {
            instance.filterProfileEntries = row.filters;
          },
        },
      },
    };
  }

  private setDefaultFilterProfile(filterProfile: DataTableFilterProfile) {
    for (const v of this.filterProfiles) {
      if (v != filterProfile) {
        v.default = false;
      }
    }
    filterProfile.default = !filterProfile.default;
    this.arrayDataSource.refreshDataTableSource();
  }

  private async deleteFilterProfile(filterProfile: DataTableFilterProfile) {
    this.filterProfiles = this.filterProfiles.filter((v) => v != filterProfile);
    this.arrayDataSource.reloadDataTable(this.filterProfiles);
  }

  private async applyFilterProfile(row: DataTableFilterProfile) {
    this.dtFilterAreaComponent.clearAll();
    for (const filterProfileEntry of row.filters) {
      this.dtFilterAreaComponent.processedFilters.map((v) => {
        if (
          filterProfileEntry.property === v.property?.toString() ||
          filterProfileEntry.property === v.externalProperty
        ) {
          v.instance.setValue(filterProfileEntry.value);
        }
      });
    }
    await this.dtFilterAreaComponent.filter();
    this.overlayHandle.close();
  }
}
