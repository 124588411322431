import { NgModule } from "@angular/core";
import { MarketplaceComponent } from "./marketplace.component";
import {
  DataTableModule,
  FormExtensionModule,
  IconButtonModule,
  IncFileUploadModule,
  LanguageTabsModule,
} from "@incert/incert-gui";
import { MarketplaceUpsertComponent } from "./marketplace-upsert/marketplace-upsert.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgForOf, NgIf, NgSwitch } from "@angular/common";
import { InputTextModule } from "primeng/inputtext";
import { TabViewModule } from "primeng/tabview";
import { InputSwitchModule } from "primeng/inputswitch";
import { DropdownModule } from "primeng/dropdown";
import { I18nModule } from "@incert/i18n";
import { MultiSelectModule } from "primeng/multiselect";

@NgModule({
  imports: [
    DataTableModule,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    FormExtensionModule,
    InputTextModule,
    TabViewModule,
    InputSwitchModule,
    LanguageTabsModule,
    NgForOf,
    DropdownModule,
    I18nModule,
    IconButtonModule,
    MultiSelectModule,
    IncFileUploadModule,
    NgSwitch,
  ],
  declarations: [MarketplaceComponent, MarketplaceUpsertComponent],
  exports: [MarketplaceComponent],
})
export class MarketplaceModule {}
