import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  DataTableConfig,
  DataTableIconButtonsComponent,
} from "@incert/incert-gui";
import { LoadingService, NotificationService } from "@incert/incert-core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Tag } from "../memo.interface";
import { MemoManager } from "../memo.manager.service";

@Component({
  selector: "incert-internal-tools-add-new-tag-overlay",
  templateUrl: "./memo-tag-new.component.html",
  styleUrls: ["./memo-tag-new.component.scss"],
})
export class MemoTagAdd implements OnInit {
  public tagTableConfig: DataTableConfig<Tag>;
  public tags: Array<Tag> = [];
  public form: UntypedFormGroup;
  public isEditMode = true;
  public tabIndex = 0;
  public arrayDataSource: ArrayDataSource<Tag>;

  constructor(
    private notificationService: NotificationService,
    private memoManager: MemoManager,
    private loadingService: LoadingService,
    private fb: UntypedFormBuilder,
  ) {
    this.createForm();
  }

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      await this.getTags();
      await this.createDataTableConfig();
    });
  }

  private createForm() {
    this.form = this.fb.group({
      id: [""],
      name: ["", Validators.required],
      description: ["", Validators.required],
      bgColor: ["#b2ffad"],
      fgColor: ["#319e37"],
    });
  }

  private async getTags() {
    this.tags = await this.memoManager.getMemoTags();
  }

  public async onAddTag() {
    const tag: Tag = this.form.value;

    if (this.form.valid) {
      if (this.isEditMode) {
        this.tags = [
          ...this.tags.filter((t) => t.id !== tag.id),
          await this.memoManager.saveTag(tag),
        ];
      } else {
        this.tags = [...this.tags, await this.memoManager.saveTag(tag)];
      }

      this.resetForm();
      await this.refreshDataTable();
      this.notificationService.notifySuccess(
        "Tag " + tag.name + " wurde erfolgreich gespeichert",
        "Tag gespeichert",
      );
    } else {
      this.notificationService.notifyInfo(
        "Mindestens ein Feld ist leer",
        "Ungültige Eingabedaten",
      );
    }
  }

  private resetForm() {
    this.isEditMode = false;
    this.form.get("id").reset();
    this.form.get("name").reset("");
    this.form.get("description").reset("");
  }

  public onEditTag(tag: Tag) {
    this.isEditMode = true;
    this.tabIndex = 0;

    this.form.patchValue({
      id: tag.id,
      name: tag.name,
      description: tag.description,
      bgColor: tag.bgColor,
      fgColor: tag.fgColor,
    });
  }

  private async onDeleteTag(tag: Tag) {
    try {
      await this.memoManager.deleteTag(tag.id);
      this.tags = this.tags.filter((t) => t.id !== tag.id);
      await this.initialize();

      this.notificationService.notifySuccess(
        "Tag " + tag.name + " wurde erfolgreich gelöscht!",
        "Tag löschen",
      );
    } catch (e) {}
  }

  private async refreshDataTable() {
    this.memoManager.markChanged();

    await this.createDataTableConfig();
  }

  private async createDataTableConfig() {
    this.arrayDataSource = new ArrayDataSource<Tag>(this.tags);
    this.tagTableConfig = {
      data: this.arrayDataSource,
      mode: "pagination",
      rows: 10,
      columns: [
        {
          header: "Id",
          hidden: true,
          class: () => "nowrap",
          property: (v) => v.get("id"),
        },
        {
          header: "Name",
          class: () => "nowrap",
          property: (v) => v.get("name"),
        },
        {
          header: "Beschreibung",
          property: (v) => v.get("description"),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  onClick: (tag: Tag) => this.onEditTag(tag),
                },
                {
                  icon: "delete",
                  onClick: (tag: Tag) => this.onDeleteTag(tag),
                },
              ],
            },
          ),
        },
      ],
    };
  }

  public getBGColor() {
    return this.form.controls["bgColor"].value;
  }

  public getFGColor() {
    return this.form.controls["fgColor"].value;
  }

  public getExampleTag() {
    const tag: Tag = this.form.value;

    if (tag.name === "") {
      tag.name = "Beispieltext";
    }
    return [tag];
  }
}
