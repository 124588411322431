import { LazyDataSourceInput } from "./model/lazy-data-source-input.model";
import { LazyDataSourceOutput } from "./model/lazy-data-source-output.model";

export interface LazyDataSourceDispatcher {
  dispatch(
    input: LazyDataSourceInput,
    path: string,
    type?: any,
  ): Promise<LazyDataSourceOutput<any>>;
}
