import { NgModule } from "@angular/core";
import { CustomerListDataTableModule } from "../../core/data-table-components/customer-list-data-table.module";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { HistoryProtocolComponent } from "./history-protocol/history-protocol.component";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { ServiceProtocol } from "./service-protocol/service-protocol.component";
import { InstallationHistoryComponent } from './installation-history/installation-history.component';

@NgModule({
  imports: [
    CustomerListDataTableModule,
    CustomerListSharedModule,
    CustomerListPrimeModule,
  ],
  declarations: [HistoryProtocolComponent, ServiceProtocol, InstallationHistoryComponent],
  exports: [HistoryProtocolComponent],
})
export class ProtocolModule {}
