import { DataConversion, Required } from "@incert/data-conversion";
import { Optional } from "@angular/core";

@DataConversion()
export class ConfigurationEntry {
  @Required()
  key: string;

  @Required()
  value: string;

  @Required()
  setFunction: string;

  @Optional()
  groupId: number;

  @Optional()
  inputConfigOptions: any;

  @Optional()
  subShopId: number;

  @Optional()
  cleanKey: string;
}
