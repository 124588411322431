import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiSelectI18nDirective } from "./multi-select-i18n.directive";
import { MultiSelectModule } from "primeng/multiselect";

@NgModule({
  imports: [CommonModule, MultiSelectModule],
  declarations: [MultiSelectI18nDirective],
  exports: [MultiSelectI18nDirective],
})
export class MultiSelectI18nModule {}
