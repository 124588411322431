<div class='passwordField' *ngIf='password'>
  <div *ngIf='!showPassword'>
    <icon-button
      tooltipPosition='top'
      (click)='toggle()'
      incTooltip="{{'core.showPassword' | i18n}}"
      iconType='hidePassword'>
    </icon-button>
  </div>
  <p *ngIf='showPassword' (click)='toggle()'> {{password}} </p>
</div>
