<form (keydown.enter)='save()'>
  <div class="p-field">
    <label>Neues Passwort</label>
    <input type="password" pInputText [(ngModel)]="newPassword" name="newPassword" autocomplete="false">
  </div>
  <div class="p-field">
    <label>Neues Password</label>
    <input type="password" pInputText [(ngModel)]="newPasswordConfirm" name="newPasswordConfirm" autocomplete="false">
  </div>
</form>

