export enum Status {
  success = "success",
  info = "info",
  warn = "warn",
  error = "error",
  empty = "empty",
  alert = "alert",
  todo = "todo",
  enabled = "enabled",
  exceeded = "exceeded",
  disabled = "disabled",
  notified = "notified",
  tooEarly = "too_early",
}
