import { HttpAccess } from "../services";
import { Injectable } from "@angular/core";
import { I18nLoaderInterface } from "@incert/i18n";
import { resolveAsset } from "../functions/resolve-asset.function";

@Injectable()
export class IncertI18nLoader implements I18nLoaderInterface {
  public constructor(private httpAccess: HttpAccess) {}

  async load(path: string, language: string) {
    try {
      const response = await this.httpAccess.get(
        resolveAsset("assets/i18n/" + path + "/" + language + ".json"),
      );
      const result = {};
      let current = result;
      for (const part of path.split("/")) {
        current[part] = {};
        current = current[part];
      }
      Object.assign(current, response);
      return result;
    } catch (e) {
      console.error(
        "i18n file " + path + "/" + language + ".json not available",
      );
    }
  }
}
