import { ArithmeticStrategy } from "../arithmetic-strategy";

export class MedOperation implements ArithmeticStrategy {
  calculate(data, property, ...args): number {
    const sorted = data.slice().sort((a, b) => a[property] - b[property]);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 == 0) {
      return ((sorted[middle - 1] + sorted[middle]) / 2)[property];
    }

    return sorted[middle][property];
  }
}
