import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FormUtil } from "../util/form.util";

@Directive({
  selector: "[formSubmit]",
})
export class FormSubmitDirective implements OnInit {
  @Output() public submit: EventEmitter<void> = new EventEmitter<void>();
  @Input() public formGroup: UntypedFormGroup = null;

  ngOnInit(): void {
    if (!this.formGroup) {
      throw new Error("formGroup not defined");
    }
  }

  @HostListener("click")
  public click() {
    this.onClick();
  }

  @HostListener("click")
  public onClick() {
    if (this.formGroup.valid === true) {
      this.submit.emit();
    } else {
      FormUtil.markAsDirty(this.formGroup);
    }
  }
}
