import { Component, Input, OnInit } from "@angular/core";
import { AccessProfileService } from "../access-profile.service";

@Component({
  selector: "incert-access-group-assign",
  templateUrl: "./access-group-assign.component.html",
  styleUrls: ["./access-group-assign.component.css"],
})
export class AccessGroupAssignComponent implements OnInit {
  @Input()
  accessGroups: any[] = [];
  @Input()
  parentGroups: any;
  @Input()
  accessProfile: any;
  @Input()
  accessGroupChanges: any[] = [];
  @Input()
  withAccessProfiles = false;

  constructor(public aps: AccessProfileService) {}

  ngOnInit(): void {}

  public hasAccessElement(element: any, changeList: any, type: any): boolean {
    if (this.withAccessProfiles) {
      return this.aps.hasAccessElementAP(
        element,
        changeList,
        type,
        this.accessProfile,
      );
    } else {
      return this.aps.hasAccessElement(element, changeList);
    }
  }

  getActiveChildrenCount(accessGroup: any): number {
    let activeCount = 0;
    for (const v of accessGroup.children) {
      if (this.aps.hasAccessElement(v, this.accessGroupChanges)) {
        activeCount++;
      }
    }
    return activeCount;
  }
}
