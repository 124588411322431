import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "../../icon/icon.module";
import { DataTableHoverTableComponent } from "./data-table-hover-table.component";
import { DataTableModule } from "../../data-table";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { IconButtonModule } from "../../icon-button/icon-button.module";
import { TooltipModule } from "../../tooltip/tooltip.module";

@NgModule({
  declarations: [DataTableHoverTableComponent],
  imports: [
    CommonModule,
    IconModule,
    TooltipModule,
    DataTableModule,
    OverlayPanelModule,
    IconButtonModule,
  ],
  exports: [DataTableHoverTableComponent],
})
export class DataTableHoverTableModule {}
