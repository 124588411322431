import { Injectable } from "@angular/core";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";
import { Report } from "./report.model";
import { SelectItem } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  async getReports() {
    return await this.httpService.get<Report[]>(
      this.settingsService.baseUrl + "report",
    );
  }

  async getDashboards() {
    return await this.httpService.get<SelectItem[]>(
      this.settingsService.baseUrl + "report/dashboard",
    );
  }

  async upsertReports(report: Report) {
    return await this.httpService.post(
      this.settingsService.baseUrl + "report",
      report,
    );
  }

  async deleteReports(uuid: string) {
    return await this.httpService.delete(
      this.settingsService.baseUrl + "report/" + uuid,
    );
  }

  async insertReportBatch(reportBatch: any) {
    return await this.httpService.post(
      this.settingsService.baseUrl + "report/batch",
      reportBatch,
    );
  }
}
