import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnInit,
  QueryList,
} from "@angular/core";
import { PageContentComponent } from "../page-content/page-content.component";
import {
  UntypedFormArray,
  FormControl,
  UntypedFormGroup,
} from "@angular/forms";

@Component({
  selector: "page-step",
  templateUrl: "./page-step.component.html",
  styleUrls: ["./page-step.component.scss"],
})
export class PageStepComponent implements OnInit {
  @Input() public header: string;

  @ContentChildren(PageContentComponent)
  public pageContents = new QueryList<PageContentComponent>();

  @HostBinding("class.offset-step-active") public active = false;

  constructor() {}

  ngOnInit() {
    if (!this.header) {
      throw new Error("header not defined");
    }
  }

  public get forms(): UntypedFormArray {
    // Set forms
    const forms: UntypedFormGroup[] = [];
    /* this.pageContents.forEach((component: PageContentComponent) => {
      if (component.form !== null) {
        forms.push(component.form);
      }
    });*/
    return new UntypedFormArray(forms);
  }
}
