import { AbstractControl, ValidatorFn } from "@angular/forms";

export class ListValidators {
  public static ensureEnum(enumeration: any): ValidatorFn {
    return (control: AbstractControl) => {
      if (Array.isArray(control.value)) {
        for (const val of control.value) {
          if (
            Object.keys(enumeration).find((element) => element === val) == null
          ) {
            const returnValue: any = {};
            returnValue["listEnsure"] = {};
            return returnValue;
          }
        }
      } else {
        if (
          Object.keys(enumeration).find(
            (element) => element === control.value,
          ) == null
        ) {
          const returnValue: any = {};
          returnValue["listEnsure"] = {};
          return returnValue;
        }
      }
      return null;
    };
  }

  public static ensureArray(array: any[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (Array.isArray(control.value)) {
        for (const val of control.value) {
          if (array.find((element) => element === val) == null) {
            const returnValue: any = {};
            returnValue["listEnsure"] = {};
            return returnValue;
          }
        }
      } else {
        if (array.find((element) => element === control.value) == null) {
          const returnValue: any = {};
          returnValue["listEnsure"] = {};
          return returnValue;
        }
      }
      return null;
    };
  }

  public static max(max: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (Array.isArray(control.value) && control.value.length > max) {
        return {
          listMax: {
            max: max,
          },
        };
      }
      return null;
    };
  }
}
