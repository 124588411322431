import { Component, Input, OnInit } from "@angular/core";
import { IconType, Severity } from "@incert/incert-core";

@Component({
  selector: "incert-tag",
  templateUrl: "./tag.component.html",
})
export class IncertTagComponent implements OnInit {
  @Input() public icon: keyof typeof IconType;
  @Input() public severity: keyof typeof Severity = "info";
  @Input() public label: string;
  @Input() public bgColor: string;
  @Input() public fontColor: string;
  @Input() public tooltipText: string;
  @Input() public badge: string;

  @Input("class") classList = ""; //additional classes

  constructor() {}

  ngOnInit(): void {}

  getClassList() {
    let classList = "";
    if (this.severity) {
      classList += "incert-tag-" + this.severity;
    }
    if (this.badge) {
      classList += " incert-tag-has-badge";
    }
    return classList;
  }
}
