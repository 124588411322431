import { OverlayModel } from "./overlay.model";
import { ComponentFactoryResolver, Injector } from "@angular/core";
import { OverlayHandle } from "./overlay-handle.service";

export interface OverlayParam {
  overlayModel: OverlayModel<any>;
  resolve: (data?: any) => void;
  promise: Promise<any>;
  injector: Injector;
  handle: OverlayHandle;
}
