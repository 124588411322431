import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessLevelAssignComponent } from "./access-level-assign.component";
import {
  FormExtensionModule,
  IconButtonModule,
  IncertTagModule,
} from "@incert/incert-gui";
import { AccordionModule } from "primeng/accordion";
import { InputSwitchModule } from "primeng/inputswitch";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [AccessLevelAssignComponent],
  exports: [AccessLevelAssignComponent],
  imports: [
    CommonModule,
    FormExtensionModule,
    AccordionModule,
    IncertTagModule,
    InputSwitchModule,
    FormsModule,
    IconButtonModule,
  ],
})
export class AccessLevelAssignModule {}
