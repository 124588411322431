import { Component } from "@angular/core";
import { MessageService } from "primeng/api";
import { NotificationService } from "@incert/incert-core";

@Component({
  selector: "message-outlet",
  templateUrl: "./message-outlet.component.html",
  providers: [MessageService],
})
export class MessageOutletComponent {
  public constructor(
    private messageService: MessageService,
    private notificationService: NotificationService,
  ) {
    this.notificationService.message$.subscribe((v) => {
      this.messageService.clear();
      messageService.add(v);
    });
  }
}
