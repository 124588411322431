import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PanelHeadlineComponent } from "./panel-headline.component";

@NgModule({
  imports: [CommonModule],
  exports: [PanelHeadlineComponent],
  declarations: [PanelHeadlineComponent],
})
export class PanelHeadlineModule {}
