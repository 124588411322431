import "./arithmetic-strategy";
import {
  ArithmeticOperation,
  ArithmeticStrategy,
  ArithmeticStrategyHash,
} from "./arithmetic-strategy";
import { SumOperation } from "./operations/sum-operation";
import { MedOperation } from "./operations/med-operation";
import { AvgOperation } from "./operations/avg-operation";

export class ArithmeticHandler {
  private strategy: ArithmeticStrategy = null;
  private preparedStrategies: ArithmeticStrategyHash | {} = {};

  constructor() {
    this.prepareArithmeticStrategy();
  }

  public prepareArithmeticStrategy() {
    this.preparedStrategies["SUM"] = new SumOperation();
    this.preparedStrategies["MED"] = new MedOperation();
    this.preparedStrategies["AVG"] = new AvgOperation();
  }

  public setArithmeticOperation(
    arithmeticStrategy: keyof typeof ArithmeticOperation,
  ): ArithmeticHandler {
    try {
      this.strategy = this.preparedStrategies[arithmeticStrategy];

      return this;
    } catch (e) {
      console.error(
        "No strategy defined for: " + arithmeticStrategy,
        ", err: " + e,
      );
    }
  }

  public calculate(data: any[], property: string): number | string {
    return this.strategy.calculate(data, property);
  }
}
