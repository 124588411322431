import { SafeHtml } from "@angular/platform-browser";

//Accordion or Carousel Slide Content
export interface SlideContent {
  headline: string;
  text?: string;
  body?: string | SafeHtml;
  image?: string;
  url?: string;
}
