export class DateUtil {
  public static normalizeTimeZoneOffset(date: Date) {
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset);
  }

  public static setTime(date: Date, time: Date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setHours(
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds(),
    );
    return dateCopy;
  }

  public static stripTime(date: Date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setHours(0, 0, 0, 0);
    return dateCopy;
  }

  public static getNoon(date: Date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setHours(12, 0, 0, 0);
    return dateCopy;
  }

  public static getEndOfDay(date: Date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setHours(24, 0, 0, 0);
    return dateCopy;
  }

  public static isSameDay(a: Date, b: Date) {
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }
}
