<p-dialog *ngFor="let overlayParam of overlayParams"
          [position]="overlayParam.overlayModel.displayAsSidebar ? 'right' : ''"
          [(visible)]="overlayParam.visible"
          [closable]="overlayParam.closable"
          [draggable]="!overlayParam.overlayModel.displayAsSidebar"
          [dismissableMask]="overlayParam.dismissableMask"
          (onHide)="remove(overlayParam)"
          modal="modal"
          [styleClass]="'size-' + overlayParam.overlayModel.size + ' ' + this.confirmType + ' ' + overlayParam.overlayModel.styleClass">
  <ng-template pTemplate="header">
    <h2 *ngIf="overlayParam.handle.header">{{overlayParam.handle.header}}</h2>
    <p class="dialog-header-description-text"
       *ngIf="overlayParam.overlayModel.headerDescription">{{overlayParam.overlayModel.headerDescription}}</p>
    <p class="dialog-header-description-text"
       *ngIf="overlayParam.handle.headerDescription && !overlayParam.overlayModel.headerDescription"
       [innerHTML]="overlayParam.handle.headerDescription"></p>
  </ng-template>
  <overlay-content-host [overlayParam]="overlayParam"></overlay-content-host>
  <p-footer *ngIf="overlayParam.overlayModel.actions">
    <ng-container *ngFor="let action of overlayParam.overlayModel.actions">
      <icon-button *ngIf="!action?.hidden" [id]="action.id" [iconType]="action.iconType" [label]="action.label" [buttonType]="action.buttonType"
                   (click)="doAction(action.action, overlayParam)" [displayAsLink]="action.displayAsLink"
                   [autofocus]="!!action.focus" [disabled]="action.disableCondition"></icon-button>
    </ng-container>
  </p-footer>
</p-dialog>
