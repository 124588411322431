import { Injectable } from "@angular/core";
import { GeoMapChartConfig } from "./models/geo-map.model";

declare let google: any;

@Injectable()
export class GoogleGeoMapChartService {
  public buildGeoMapChart(
    nativeElement: any,
    data: any[],
    config: GeoMapChartConfig,
  ): void {
    const chartFunc = () => {
      return new google.visualization.GeoChart(nativeElement);
    };

    data = google.visualization.arrayToDataTable([
      // Results For US States
      // State format must be "US-**"
      // US represents region, while the ** section represents the individual state
      ["Country", "Views"],
      ["Germany", 200],
      ["United States", 300],
      ["Brazil", 400],
      ["Canada", 500],
      ["France", 600],
      ["RU", 700],
    ]);

    const options = {
      title: config.title,
      enableRegionInteractivity: "true",
      showZoomOut: true,
      zoomOutLabel: "Zoom",
    };
    // google.visualization.events.addListener(chartFunc, 'regionClick', this.selectHandler);

    const chart = new google.visualization.GeoChart(nativeElement);
    chart.draw(data, options);

    console.log(chart);
    google.visualization.events.addListener(chart, "regionClick", () => {});
  }

  selectHandler(e) {
    alert("A table row was selected");
  }
}
