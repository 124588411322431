import { Injectable } from "@angular/core";
import { HttpService } from "../../../../core";
import { APIService } from "../../../../core/api.service";
import { Resource, ResourceType } from "./resource.model";
import { AuthConstants } from "../../../../core/auth";

@Injectable({
  providedIn: "root",
})
export class ResourceService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  async getResources(): Promise<Resource[]> {
    return await this.httpService.get<Resource[]>(
      this.settingsService.baseUrl + "resource",
    );
  }

  async getResource(id: number): Promise<Resource> {
    return await this.httpService.get<Resource>(
      this.settingsService.baseUrl + "resource/" + id,
    );
  }

  async getResourceTypes(): Promise<ResourceType[]> {
    return await this.httpService.get<ResourceType[]>(
      this.settingsService.baseUrl + "resource/type",
    );
  }

  async getSelectItems() {
    return await this.httpService.get<any[]>(
      this.settingsService.baseUrl + "resource/si",
    );
  }

  async upsertResource(resource: Resource) {
    return await this.httpService.post<Resource>(
      this.settingsService.baseUrl + "resource",
      resource,
    );
  }

  async deleteResource(id: number) {
    return await this.httpService.delete<Resource>(
      this.settingsService.baseUrl + "resource/" + id,
    );
  }

  async uploadFile(file) {
    let res: any;
    try {
      res = await this.httpService.post<any>(
        this.settingsService.mediaLibraryUrl,
        new Blob([file], { type: file.type }),
        {
          headers: {
            Authorization: localStorage.getItem(AuthConstants.RESOURCES_TOKEN),
            PartId: "global",
          },
        },
      );
      return res.objectKey + "." + res.ext;
    } catch (e) {
      return false;
    }
  }

  async deleteFile(fileName: string) {
    try {
      await this.httpService.delete<any>(
        this.settingsService.mediaLibraryUrl + "/" + fileName,
        {
          headers: {
            Authorization: localStorage.getItem(AuthConstants.RESOURCES_TOKEN),
            PartId: "global",
          },
        },
      );
    } catch (e) {
      return false;
    }
  }
}
