import { TemplateRef, Type } from "@angular/core";
import {
  Modify,
  nameof,
  PropertyPathAccessor,
  Status,
} from "@incert/incert-core";
import { DataTableFilterComponent } from "./data-table-filter-component.interface";

export type ColumnDefinition<T> =
  | DynamicColumn<T>
  | PropertyColumn<T>
  | GroupColumn<T>
  | TemplateColumn<T>;

export interface BaseColumn<T> {
  header:
    | string
    | {
        label: string;
        component: Type<any> | ComponentConfiguration<any>;
      };
  description?: string;
  hidden?: boolean;
  filter?: FilterDefinition<any>;
  class?: (value: any, row: T) => string;
  width?: string;
  exportValue?: (value: any, row: T) => string;
}

export interface GroupColumn<T> extends BaseColumn<T> {
  columns: ColumnDefinition<T>[];
}

export interface TemplateColumn<T> extends BaseColumn<T> {
  template: TemplateRef<T>;
}

export interface DynamicColumn<T> extends BaseColumn<T> {
  component: Type<any> | ComponentConfiguration<any>;
}
export type InitFN<T> = (
  instance: T,
  row: any,
  expand: (value: boolean) => void,
) => void;

export function createComponentConfiguration<T>(
  type: Type<T>,
  properties: Partial<T> | InitFN<T>,
): ComponentConfiguration<T> {
  const config: ComponentConfiguration<T> = { component: type };
  if (typeof properties === "function") {
    config.init = properties;
  } else {
    config.properties = properties;
  }
  return config;
}

export interface ComponentConfiguration<T> {
  component: Type<T>;
  properties?: Partial<T>;
  init?: InitFN<T>;
}

export function isComponentConfiguration(
  arg: any,
): arg is ComponentConfiguration<any> {
  return arg.properties !== undefined || arg.init !== undefined;
}

export interface PropertyColumn<T> extends BaseColumn<T> {
  property: PropertyPathAccessor<T>;
  status?: (value: any, row: T) => keyof typeof Status;
  transform?: (value: any, row: T) => string;
  color?: (value: any, row: T) => string;
  fontColor?: (value: any, row: T) => string;
  sort?: boolean;
  nowrap?: boolean;
  align?: "left" | "center" | "right";
}

export interface FilterDefinition<T> {
  component: Type<DataTableFilterComponent>;
  config: FilterConfig;
}

export interface FilterConfig {
  header?: string;
  property?: PropertyPathAccessor<any>;
  externalProperty?: string;
  defaultValue?: any;
  value?: any;
  method?: (value) => any;

  [prop: string]: any;
}
export type ProcessedColumn = Modify<
  ProcessedColumnDefinition,
  {
    class: string;
    value: string;
    color: string;
    fontColor: string;
    status: string;
  }
>;
export interface ProcessedRow {
  canExpand: boolean;
  row: any;
  columns: ProcessedColumn[];
  key: string;
  additionalInfoRowText: string;
}
export type ProcessedColumnDefinition = ColumnDefinition<any> & {
  level: number;
  levels: number;
  children: number;
  colSpan: number;
  rowSpan: number;
  bottom: boolean;
  property?: Array<string>;
};

export function isPropertyColumn(value: any): value is PropertyColumn<any> {
  return value && nameof<PropertyColumn<any>>("property") in value;
}

export function isGroupColumn(value: any): value is GroupColumn<any> {
  return value && nameof<GroupColumn<any>>("columns") in value;
}

export function isDynamicColumn(value: any): value is DynamicColumn<any> {
  return value && nameof<DynamicColumn<any>>("component") in value;
}
