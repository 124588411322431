import { IconType } from "@incert/incert-core";
import { TooltipPosition } from "../data-table-actions.interface";

export interface IconButtonConfig {
  icon?: keyof typeof IconType;

  label?: string;

  onClick?: (row: any) => any;

  hidden?: boolean;

  tooltip?: string | ((row: any) => string);

  tooltipPosition?: TooltipPosition;

  onShow?: (row: any) => boolean;

  iconOnly?: boolean;
  onDisable?: (row: any) => boolean;
}
