import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BottombarComponent } from "./bottombar.component";

@NgModule({
  imports: [CommonModule],
  declarations: [BottombarComponent],
  exports: [BottombarComponent],
})
export class BottombarModule {}
