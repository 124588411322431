import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ControlSelectionComponent } from "./control-selection.component";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { DragDropModule } from "primeng/dragdrop";
import { ComponentOutletModule } from "../component-outlet/component-outlet.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ControlSelectionComponent],
  exports: [ControlSelectionComponent],
  imports: [
    CommonModule,
    IconButtonModule,
    DragDropModule,
    ComponentOutletModule,
    ReactiveFormsModule,
  ],
})
export class ControlSelectionModule {}
