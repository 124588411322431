import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LanguageItemComponent } from "./language-item/language-item.component";
import { LanguageItemsComponent } from "./language-items/language-items.component";

@NgModule({
  imports: [CommonModule],
  declarations: [LanguageItemComponent, LanguageItemsComponent],
  exports: [LanguageItemComponent, LanguageItemsComponent],
})
export class LanguageItemModule {}
