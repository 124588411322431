import {
  ArrayType,
  DataConversion,
  Optional,
  Required,
} from "@incert/data-conversion";
import { PasswordResetAdminAllowedReceiverType } from "../enums";
import { Menu } from "./menu.model";
import { Language } from "./language.model";
import { Currency } from "./currency.model";

@DataConversion()
export class EnvironmentStatus {
  @Required()
  id: number;

  @Required()
  name: string;
}

@DataConversion()
export class EnvironmentCustomer {
  @Required()
  id: number;

  @Required()
  firstName: string;

  @Required()
  lastName: string;
}

@DataConversion()
export class Environment {
  @Required()
  storeName: string;

  @Required()
  incertAdminEnabled: "true" | "false" | "force";

  @Required()
  @ArrayType(() => import("./language.model").then((mod) => mod.Language))
  languages: Language[];

  @Required()
  currentLanguage: Language;

  @Required()
  partId: string;

  @Required()
  adminLogin: boolean;

  @Required()
  defaultCurrency: string;

  @Optional()
  customer: EnvironmentCustomer;

  @Optional()
  menu: Menu;

  @Optional()
  userSwitch: boolean;

  @Optional()
  userSwitchResetURL: string;

  @Optional()
  userSwitchUsername: string;

  @Optional()
  staffId: number;

  @Optional()
  forcePasswordReset: boolean;

  @Optional()
  forcePasswordResetOneTime: boolean;

  @Optional()
  manufacturerId: number;

  @Optional()
  isRemoteIncertOffice = false;

  @Optional()
  isSuperAdmin = false;

  @Optional()
  isAdmin = false;

  @Optional()
  sessionId: string;

  @Optional()
  passwordResetAdminAllowedReceiverType: PasswordResetAdminAllowedReceiverType =
    PasswordResetAdminAllowedReceiverType.OWNER;

  @Optional()
  isRootOrAdmin: boolean;

  @Optional()
  @ArrayType(() => import("./currency.model").then((mod) => mod.Currency))
  currencies: Currency[];

  @Optional()
  administrationCurrencyIds: number[] = [];

  @Optional()
  permissions: string[];

  @Optional()
  bonApiKeyV1: string;

  @Optional()
  bonApiKeyV2: string;

  @Optional()
  bonUrlV1: string;

  @Optional()
  bonUrlV2: string;

  @Optional()
  supportChatBotEnabled: boolean;

  @Optional()
  isDevSystem: boolean;
}
