import { Component, Inject, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table";
import { HoverText } from "./data-table-hover-text.interface";

@Component({
  selector: "incert-data-table-hover-text",
  templateUrl: "./data-table-hover-text.component.html",
  styleUrls: ["./data-table-hover-text.component.css"],
})
export class DataTableHoverTextComponent implements OnInit {
  public hoverText: HoverText;

  public resolveRowText: (row: any) => any = () => null;

  constructor(@Inject(ROW_DATA) private row_data: any) {}

  ngOnInit(): void {
    this.hoverText = this.resolveRowText(this.row_data);
  }
}
