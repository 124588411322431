<div [ngClass]="{ 'grid inct-form-grid': displayAsGrid }">
  <ng-container *ngIf="displayAllLanguages; else activeLanguage">
    <div *ngFor="let langForm of languageFormEntries" [class]="colClass">
      <incert-language-item [langCode]="getLangCodeFromId(langForm.languageId)"></incert-language-item>
      <div class="separator-top-small">
        <ng-template [ngTemplateOutlet]="langForm.template"></ng-template>
      </div>
    </div>
  </ng-container>

  <ng-template #activeLanguage>
    <div [class]="colClass">
      <incert-language-item [langCode]="getLangCodeFromId(languageFormEntries.first.languageId)"></incert-language-item>
      <div class="separator-top-small">
        <ng-template [ngTemplateOutlet]="languageFormEntries.first.template"></ng-template>
      </div>
    </div>
  </ng-template>
</div>
