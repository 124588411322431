import { NgModule } from "@angular/core";
import { MemoComponent } from "./memo.component";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../core/auth";

const routes: Routes = [
  {
    path: "",
    component: MemoComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemoRoutingModule {}
