import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "incert-card-item",
  templateUrl: "./card-item.component.html",
  styleUrls: ["./card-item.component.scss"],
})
export class CardItemComponent implements OnInit {
  @Input() cardTitle: string;
  @Input() selected: boolean;
  @Input() pointer = false;
  @Output() clickEmitter = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  getCardItemClass() {
    let styleClass = "inc-card-item";
    if (this.selected) {
      styleClass += " inc-card-item-selected";
    }
    if (this.pointer) {
      styleClass += " pointer";
    }
    return styleClass;
  }

  clickItem(event) {
    this.clickEmitter.emit(event);
  }
}
