import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createFilterDefinition,
  DataTableConfig,
  DateFilterComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { ProtocolManager } from "../protocol-manager";
import { LoadingService, NotificationService } from "@incert/incert-core";
import { SelectItem } from "primeng/api";
import {
  ImportData,
  ImportStat,
} from "../../tools/key-value-panel/key-value.interface";

@Component({
  selector: "incert-internal-tools-key-value-protocol",
  templateUrl: "./service-protocol.component.html",
  styleUrls: ["./service-protocol.component.css"],
})
export class ServiceProtocol implements OnInit {
  public statusCodeSI: Array<SelectItem> = [];
  public serviceTypeSI: Array<SelectItem> = [];
  public byDate = new Date();
  public importData: Array<ImportData>;
  public importProtocolConfig: DataTableConfig<ImportData>;
  public initialized = false;
  public importDataByDate: ImportStat;
  public importEmpty: boolean;

  constructor(
    private protocolManager: ProtocolManager,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
  ) {}

  async ngOnInit() {
    await this.loadingService.load(async () => {
      await this.loadProtocol().then((data) => {
        data.forEach((d) => {
          if (!this.statusCodeSI.find((da) => da.value === d.statusCode)) {
            this.statusCodeSI.push({
              label: d.statusCode.toString(),
              value: d.statusCode,
            });
          }
        });

        this.importData = data;
        this.createServiceTypeDropdown();
        this.createDataTableConfig(data);
        this.createImportStatistic();
        this.initialized = true;
      });
    });
  }

  private createServiceTypeDropdown() {
    this.serviceTypeSI = [...new Set(this.importData.map((i) => i.action))].map(
      (i) => ({ value: i, label: i }),
    );
  }

  private async loadProtocol() {
    try {
      return await this.protocolManager.getImportProtocol();
    } catch (e) {
      this.notificationService.notifyError(
        "Protokolldaten konnten nicht geladen werden",
        "Fehler: Protokolldaten",
      );
    }
  }

  public createImportStatistic() {
    this.byDate = new Date(this.byDate);
    this.byDate.setHours(0, 0, 0, 0);

    const statisticsData: ImportData[] = this.importData.filter((row) => {
      const rowDate = new Date(row.date);
      rowDate.setHours(0, 0, 0, 0);
      return rowDate.valueOf() === this.byDate.valueOf();
    });

    this.createImportStat(statisticsData);
    this.initialized = true;
  }

  private createImportStat(data: ImportData[]) {
    this.importDataByDate = {
      imports: 0,
      fulfilled: 0,
      failed: 0,
      failedForbidden: 0,
      failedUnauthorized: 0,
      failedOther: 0,
      failedNotFound: 0,
    };

    this.importDataByDate.imports = data.length;

    data.forEach((item) => {
      switch (item.statusCode) {
        case "200":
          this.importDataByDate.fulfilled++;
          break;
        case "400":
          this.importDataByDate.failedOther++;
          break;
        case "401":
          this.importDataByDate.failedUnauthorized++;
          break;
        case "403":
          this.importDataByDate.failedForbidden++;
          break;
        case "404":
          this.importDataByDate.failedNotFound++;
          break;
      }
    });

    this.importDataByDate.failed =
      this.importDataByDate.imports - this.importDataByDate.fulfilled;
  }

  private createDataTableConfig(data: Array<ImportData>) {
    if (!data.length) {
      this.importEmpty = true;
    }
    this.importProtocolConfig = {
      data: new ArrayDataSource<ImportData>(data),
      mode: "pagination",
      rows: 35,
      columns: [
        {
          header: "Type",
          class: () => "nowrap",
          property: (v) => v.get("action"),
          filter: createFilterDefinition(SelectFilterComponent, {
            options: this.serviceTypeSI,
            property: (v) => v.get("action"),
          }),
        },
        {
          header: "Kürzel",
          class: () => "nowrap",
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("accountSystem", "name"),
            type: "eq",
          }),
          property: (v) => v.get("accountSystem", "short"),
        },
        {
          header: "Kundensystem",
          class: () => "nowrap",
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("accountSystem", "name"),
          }),
          property: (v) => v.get("accountSystem", "name"),
        },
        {
          header: "Status Code",
          property: (v) => v.get("statusCode"),
          filter: createFilterDefinition(SelectFilterComponent, {
            options: this.statusCodeSI,
            property: (v) => v.get("statusCode"),
          }),
          sort: true,
        },
        {
          header: "Beschreibung",
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("description"),
          }),
          property: (v) => v.get("description"),
        },
        {
          header: "Meldung",
          class: () => "nowrap",
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("statusMessage"),
          }),
          property: (v) => v.get("statusMessage"),
        },
        {
          header: "Date",
          class: () => "nowrap",
          property: (v) => v.get("date"),
          filter: createFilterDefinition(DateFilterComponent, {
            type: "between",
            property: (v) => v.get("date"),
          }),
          transform: (v) => new Date(v).toISOString(),
        },
      ],
    };
  }
}
