import { Injectable } from "@angular/core";
import {
  AuthConstants,
  AuthModel,
  IAuthManager,
  PermissionShort,
} from "./auth";
import { APIService } from "../api.service";
import { Utils } from "../utils";
import { UserManager } from "../../pages/user";
import { Permission } from "../../pages/role/permission";
import { Router } from "@angular/router";
import { NotificationService } from "@incert/incert-core";
import { HttpService } from "../http.service";

export interface LoginModel {
  email: string;
  password: string;
}

@Injectable({ providedIn: "root" })
export class AuthManager implements IAuthManager {
  public authModel: AuthModel;
  public adminAuthModel: AuthModel;

  constructor(
    private http: HttpService,
    private settingsService: APIService,
    private notificationService: NotificationService,
    private router: Router,
    private userManager: UserManager,
  ) {}

  public async verifyToken(token: string) {
    const existingToken = localStorage.getItem("TOKEN");
    if (!existingToken) return false;

    return !!(await this.http.get(
      this.settingsService.baseUrl + "verify-token/" + token,
    ));
  }

  public get isUserLoggedIn(): boolean {
    return localStorage.getItem("TOKEN") != null;
  }

  public async isAdminLoggedIn(): Promise<boolean> {
    return (
      (await this.userManager.getUser(this.getLoggedInUserId())).roleId === 1
    );
  }

  public getLoggedInUserId(): number {
    return parseInt(localStorage.getItem("USER_ID"), 10);
  }

  public async login(email: string, password: string): Promise<AuthModel> {
    const login: LoginModel = {
      email: email,
      password: password,
    };

    try {
      this.removelocalStorage();
      this.authModel = await this.http.post<AuthModel>(
        this.settingsService.baseUrl + "login",
        login,
      );

      if (
        Number.isNaN(this.authModel.adminLoggedIn) ||
        this.authModel.adminLoggedIn === undefined
      ) {
        this.authModel.adminLoggedIn = 0;
      }
      this.setlocalStorage();

      return this.authModel;
    } catch (e) {
      console.log(e);
      if (e.status === 403) {
        this.notificationService.notifyError(
          "Zugang gesperrt " +
            Utils.showTimeWithCustomText(e.error.message.blockedUntil, "für"),
          "Zu viele Anmeldeversuche",
        );
      } else {
        this.notificationService.notifyError(
          "E-Mail und Passwort stimmen nicht überein",
          "Ungültige Anmeldedaten",
        );
      }
      await this.router.navigate(["/"]);
    }
  }

  public async loginAsForAdmin(email: string) {
    try {
      const hasPermissionLoginAs = this.hasPermission("loginAsUser");
      const loginAsData = {
        email: email,
        adminUserId: this.getLoggedInUserId(),
      };
      if (hasPermissionLoginAs) {
        this.removelocalStorage();
        this.adminAuthModel = this.authModel;
        this.authModel = await this.http.post<AuthModel>(
          this.settingsService.baseUrl + "login-as",
          loginAsData,
        );
        this.setlocalStorage();
        localStorage.setItem(
          "ADMIN_LOGGED_IN",
          JSON.stringify(this.authModel.adminLoggedIn),
        );
        localStorage.setItem("ADMIN_TOKEN", this.authModel.accessToken);
      } else {
        this.notificationService.notifyError(
          "Anmelden als...",
          "Sie haben nicht die Berechtigung, um sich als dieser Benutzer anzumelden",
        );
      }
      if (this.authModel) {
        await this.router.navigate(["account-system-overview"]);
      }
    } catch (e) {
      this.notificationService.notifyError(
        "Anmelden als...",
        "Da ist leider etwas schief gelaufen...",
      );
    }
  }

  public async relogin() {
    try {
      const userId = parseInt(localStorage.getItem("ADMIN_LOGGED_IN"), 10);
      const accessToken = localStorage.getItem("ADMIN_TOKEN");

      const reloginData = {
        userId: userId,
        accessToken: accessToken,
      };
      this.authModel = await this.http.post<AuthModel>(
        this.settingsService.baseUrl + "relogin",
        reloginData,
      );
      this.setlocalStorage();
    } catch (e) {
      this.notificationService.notifyError(
        "Zurück zum eigenen Profil",
        "Da ist leider etwas schief gelaufen...",
      );
    } finally {
      await this.router.navigate(["user-overview"]);
    }
  }

  public async logout() {
    this.removelocalStorage();
    this.authModel = null;
    await this.router.navigate(["/"]);
  }

  public setlocalStorage() {
    localStorage.setItem(AuthConstants.TOKEN, this.authModel.accessToken);
    localStorage.setItem(AuthConstants.USER_ID, this.authModel.id.toString());
    localStorage.setItem(
      AuthConstants.USER_NAME,
      this.authModel.firstName.toString() +
        " " +
        this.authModel.lastName.toString(),
    );
    localStorage.setItem(AuthConstants.ROLE_ID, this.authModel.role.toString());
    localStorage.setItem(
      AuthConstants.PERMISSIONS,
      JSON.stringify(this.authModel.permissions),
    );
    localStorage.setItem(
      AuthConstants.ADMIN_LOGGED_IN,
      JSON.stringify(this.authModel.adminLoggedIn),
    );
    localStorage.setItem(
      AuthConstants.RESOURCES_TOKEN,
      this.authModel.resourcesToken,
    );
  }

  public removelocalStorage() {
    localStorage.removeItem(AuthConstants.TOKEN);
    localStorage.removeItem(AuthConstants.USER_ID);
    localStorage.removeItem(AuthConstants.USER_NAME);
    localStorage.removeItem(AuthConstants.PERMISSIONS);
    localStorage.removeItem(AuthConstants.ADMIN_LOGGED_IN);
    localStorage.removeItem(AuthConstants.ROLE_ID);
    localStorage.removeItem(AuthConstants.RESOURCES_TOKEN);
  }

  public hasPermission(functionName: PermissionShort): boolean {
    const userPermissions: Array<Permission> = JSON.parse(
      localStorage.getItem("PERMISSIONS"),
    );

    if (!userPermissions) {
      return false;
    }

    const permission: Permission = userPermissions.find(
      (p) => p.displayName === functionName,
    );

    return !!permission;
  }
}
