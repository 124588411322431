import { NgModule } from "@angular/core";
import { SystemValueComponent } from "./system-value.component";
import { SystemValueImportComponent } from "./system-value-import/system-value-import.component";
import { CustomerListSharedModule } from "../../../customer-list-shared.module";
import { CustomerListPrimeModule } from "../../../customer-list-prime.module";
import { CustomerListDataTableModule } from "../../../core/data-table-components/customer-list-data-table.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthHttpInterceptorService } from "../../../core/auth/auth-http-interceptor.service";
import { SystemValueInfoComponent } from './system-value-info/system-value-info.component';

@NgModule({
  declarations: [SystemValueComponent, SystemValueImportComponent, SystemValueInfoComponent],
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
  ],
  exports: [SystemValueComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorService,
      multi: true,
    },
  ],
})
export class SystemValueModule {}
