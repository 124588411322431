type ValidationResolver = (data: any) => string;
export const formExtensionI18n: { [validator: string]: ValidationResolver } = {
  default: (data) => {
    return "value is invalid";
  },
  required: (data) => {
    return "value is required";
  },
  email: (data) => {
    return "value must be valid email-address";
  },
  numberEnsure: (data) => {
    return "value has to be a numberValueToDisplay";
  },
  numberNoDecimals: (data) => {
    return "no decimals are allowed";
  },
  numberMaxDecimals: (data) => {
    return "max " + data.decimals + " decimals are allowed";
  },
  numberBetween: (data) => {
    return (
      "only numberValueToDisplay between " +
      data.min +
      " and " +
      data.max +
      " are allowed"
    );
  },
  numberMin: (data) => {
    return "minimum allowed value is " + data.min;
  },
  numberMax: (data) => {
    return "maximum allowed value is " + data.max;
  },
  dateMin: (data) => {
    return "minimum allowed date is " + data.min.toLocaleDateString();
  },
  dateMax: (data) => {
    return "maximum allowed date is " + data.max.toLocaleDateString();
  },
  dateBetween: (data) => {
    return (
      "only dates between " +
      data.min.toLocaleDateString() +
      " and " +
      data.max.toLocaleDateString() +
      " are allowed"
    );
  },
  dateEnsure: (data) => {
    return "value has to be a date";
  },
  ensureList: (data) => {
    return "value is not allowed";
  },
  dateGreaterThan: (data) => {
    return "date must be greater than " + data.date.toLocaleDateString();
  },
  dateLessThan: (data) => {
    return "date must be smaller than " + data.date.toLocaleDateString();
  },
  dateGreaterThanOrEqual: (data) => {
    return (
      "date must be greater or equal than " + data.date.toLocaleDateString()
    );
  },
  dateLessThanOrEqual: (data) => {
    return (
      "date must be smaller or equal than " + data.date.toLocaleDateString()
    );
  },
  numberGreaterThan: (data) => {
    return "date must be greater than " + data.number;
  },
  numberLessThan: (data) => {
    return "date must be smaller than " + data.number;
  },
  numberGreaterThanOrEqual: (data) => {
    return "date must be greater or equal than " + data.number;
  },
  numberLessThanOrEqual: (data) => {
    return "date must be smaller or equal than " + data.number;
  },
  timeGreaterThan: (data) => {
    return "timeSlot must be greater than " + data.time.toLocaleDateString();
  },
  timeLessThan: (data) => {
    return "timeSlot must be smaller than " + data.time.toLocaleDateString();
  },
  timeGreaterThanOrEqual: (data) => {
    return (
      "timeSlot must be greater or equal than " + data.time.toLocaleDateString()
    );
  },
  timeLessThanOrEqual: (data) => {
    return (
      "timeSlot must be smaller or equal than " + data.time.toLocaleDateString()
    );
  },
  passwordEqual: (data) => {
    return "passwords must be the same";
  },
  jsonEnsure: (data) => {
    return "value has to be a json format";
  },
  maxChars: (data) => {
    return (
      "characters exceed the limit of " +
      data.maxChars +
      " by " +
      data.exceeding
    );
  },
};
