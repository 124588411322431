import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { I18nModule } from "@incert/i18n";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
  DateTimeFormatPipeModule,
  IconButtonModule,
  IconModule,
  LoadingWrapperModule,
  MessageOutletModule,
  OverlayOutletModule,
  PageModule,
  ToastOutletModule,
} from "@incert/incert-gui";
import { A11yModule } from "@angular/cdk/a11y";
import { CustomerListPrimeModule } from "./customer-list-prime.module";
import { DataTableShowCommentOverlayComponent } from "./core/data-table-components/data-table-show-comment-overlay/data-table-show-comment-overlay.component";
import { LoadingIndicatorComponent } from "./core";
import { PwChangeDialogComponent } from "./core/pw-change-dialog/pw-change-dialog.component";
import { ReadableLogicPipe } from "./core/readable-logic.pipe";
import { DialogModule } from "primeng/dialog";
import { PanelModule } from "primeng/panel";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    HttpClientModule,
    DateTimeFormatPipeModule,
    LoadingWrapperModule,
    ToastOutletModule,
    IconModule,
    PageModule,
    MessageOutletModule,
    OverlayOutletModule,
    IconButtonModule,
    A11yModule,
    CustomerListPrimeModule,
    DialogModule,
    FormsModule,
    PanelModule,
  ],
  declarations: [
    DataTableShowCommentOverlayComponent,
    LoadingIndicatorComponent,
    PwChangeDialogComponent,
    ReadableLogicPipe,
  ],
  exports: [
    DataTableShowCommentOverlayComponent,
    LoadingIndicatorComponent,
    PwChangeDialogComponent,
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    HttpClientModule,
    DateTimeFormatPipeModule,
    LoadingWrapperModule,
    ToastOutletModule,
    IconModule,
    PageModule,
    MessageOutletModule,
    OverlayOutletModule,
    IconButtonModule,
    A11yModule,
    FormsModule,
    DialogModule,
    ReadableLogicPipe,
  ],
})
export class CustomerListSharedModule {}
