import { Component, Inject, Input, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table/column-host/row-data.token";

export interface LinkConfig<T> {
  link: string;
  displayAsLinkIcon?: boolean;
}

@Component({
  selector: "incert-data-table-link",
  templateUrl: "./data-table-link.component.html",
  styleUrls: ["./data-table-link.component.css"],
})
export class DataTableLinkComponent<T> implements OnInit {
  constructor(@Inject(ROW_DATA) private row_data: any) {}

  @Input() linkConfig: LinkConfig<T>;

  public setLink: (row: T) => LinkConfig<T> = () => null;

  ngOnInit(): void {
    this.linkConfig = this.setLink(this.row_data);
  }
}
