import { Component, Input } from "@angular/core";
import { WizardComponent } from "../wizard.component";
import { NotificationService } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";

@Component({
  selector: "wizard-nav-buttons",
  templateUrl: "./wizard-nav-buttons.component.html",
})
export class WizardNavButtonsComponent {
  @Input()
  isEdit = false;

  constructor(
    public wizardComponent: WizardComponent,
    private notificationService: NotificationService,
    private i18n: I18nService,
  ) {}

  get onNextLabel() {
    return this.wizardComponent.context.onNextLabel;
  }

  get onPrevLabel() {
    return this.wizardComponent.context.onPrevLabel;
  }

  isNavValid(): boolean {
    if (this.wizardComponent.context.onNext) {
      return true;
    }

    let valid = false;
    if (this.wizardComponent.wizardStepArray) {
      valid = true;
      for (let i = 0; i <= this.wizardComponent.activeIndex && valid; i++) {
        valid = this.wizardComponent.wizardStepArray[i].isEveryFormValid;
      }
    }
    return valid;
  }

  isFormValid(): boolean {
    let valid = false;
    if (this.wizardComponent.wizardStepArray) {
      valid = true;
      for (
        let i = 0;
        i < this.wizardComponent.wizardStepArray.length && valid;
        i++
      ) {
        valid = this.wizardComponent.wizardStepArray[i].isEveryFormValid;
      }
    }
    return valid;
  }

  async onClickGoBack() {
    if (
      this.wizardComponent.context.onPrev &&
      !(await this.wizardComponent.context.onPrev())
    ) {
      return;
    }

    await this.wizardComponent.goBack();
  }

  async onClickPrev() {
    if (
      this.wizardComponent.context.onPrev &&
      !(await this.wizardComponent.context.onPrev())
    ) {
      return;
    }

    this.wizardComponent.onClickPrev();
  }

  async onClickNext() {
    if (
      this.wizardComponent.context.onNext &&
      !(await this.wizardComponent.context.onNext())
    ) {
      return;
    }

    this.wizardComponent.onClickNext();
  }

  async onClickSubmit() {
    if (
      this.wizardComponent.context.onNext &&
      !(await this.wizardComponent.context.onNext())
    ) {
      return;
    }

    if (this.isFormValid()) {
      this.wizardComponent.onClickSubmit();
    } else {
      if (this.wizardComponent.wizardStepArray) {
        let invalidFound = false;
        this.wizardComponent.wizardStepArray.forEach((step, index) => {
          if (step.validationForms && !invalidFound) {
            Object.values(step.validationForms).forEach((form) => {
              if (!form.valid) {
                invalidFound = true;
                this.wizardComponent.navigateTo(step.children.first);
                this.notificationService.notifyWarning(
                  this.i18n.instant("core.warning.requiredFieldsMissing"),
                  this.i18n.instant("core.status.warning"),
                );
              }
            });
          }
        });
      }
    }
  }
}
