import { Injectable } from "@angular/core";
import { HttpService } from "../index";
import { APIService } from "../api.service";
import { Currency } from "../models/account.model";

@Injectable({ providedIn: "root" })
export class CurrencyService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getCurrencies(): Promise<Array<Currency>> {
    return this.httpService.get<Array<Currency>>(
      this.settingsService.baseUrl + "currency",
    );
  }
}
