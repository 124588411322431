<div class="grid">
  <div class="col-10 align-right">
    {{'core.dataTable.selectAllColumns' | i18n}}
  </div>
  <div class="col-2">
    <p-inputSwitch
      (onChange)="toggleAllColumns()"
      [formControl]="toggleAll">
    </p-inputSwitch>
  </div>
</div>

<data-table *ngIf="dtConfig" [config]="dtConfig"></data-table>
