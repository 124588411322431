<h3>{{'core.tooltip.download' | i18n}}</h3>
<div class="grid separator-bottom">
  <div class="col-4" *ngFor="let i of exportMenuItems">
    <div class="box-shadow-test align-center pointer" (click)="i.command()">
      <span class="{{i.icon}}"></span>
      <div>{{i.title}}</div>
    </div>
  </div>
</div>
<incert-data-table-column-overlay *ngIf="columns" class="separator-top-large"
                                  [columns]="columns">
</incert-data-table-column-overlay>

<!--<h3>{{'core.shipping.shipping' | i18n}}</h3>-->
<!--<form [formGroup]="formGroup">-->
<!--  <form-field>-->
<!--    <p-radioButton *ngFor="let o of exportMenuItems" formControlName="exportType" [value]="o" [label]="o.label"-->
<!--                   name="exportType">-->
<!--    </p-radioButton>-->
<!--  </form-field>-->

<!--  <form-field>-->
<!--    <div class="p-inputgroup">-->
<!--      <input formControlName="exportEmail"-->
<!--             type="text"-->
<!--             placeholder="{{'core.form.email' | i18n}}"-->
<!--             pInputText-->
<!--             autocomplete=""/>-->
<!--      <button pButton type="button" label="{{'core.action.send' | i18n}}" (click)="exportEmail()"></button>-->
<!--    </div>-->
<!--  </form-field>-->
<!--</form>-->
