/**
 * Shows a given text or icon if a given option is found within a data-table cell
 */
import { IconType } from "@incert/incert-core";

export interface Option {
  value: any;

  class?: string;

  icon?: keyof typeof IconType;

  text?: string;

  color?: string;

  hint?: string;

  rotation?: number;

  onClick?: (row: any) => any;

  asButton?: boolean;
}
