import { Component, Input, OnInit } from "@angular/core";
import { IconType, Severity } from "@incert/incert-core";

@Component({
  selector: "severity-icon",
  templateUrl: "./severity-icon.component.html",
})
export class SeverityIconComponent implements OnInit {
  @Input() public severity: keyof typeof Severity;
  @Input() public label: string;
  @Input() public showLabel = false; //Show the label next to the icon or only use it for screen readers?

  public iconType: keyof typeof IconType;

  ngOnInit(): void {
    switch (Severity[this.severity]) {
      case Severity.info:
        this.iconType = "help";
        break;
      case Severity.warning:
        this.iconType = "info";
        break;
      case Severity.success:
        this.iconType = "successCircle";
        break;
      case Severity.error:
        this.iconType = "warningTriangle";
        break;
    }
  }
}
