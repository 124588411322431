import { Component, Inject, Input, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table/column-host/row-data.token";
import { Option } from "./data-table-option-icon.interface";

@Component({
  selector: "data-table-option-icon",
  templateUrl: "./data-table-option-icon.component.html",
  styleUrls: ["./data-table-option-icon.component.css"],
})
export class DataTableOptionIconComponent implements OnInit {
  constructor(@Inject(ROW_DATA) private row_data: any) {}

  @Input() hidden: boolean;
  @Input() optionConfig: Array<Option> | Option;

  private value: any;
  public row: any;
  public option: Option = null;
  public notFound: Option = { value: " ", text: "" };

  /** get fallback clause */
  public static trueFalseNullOption(): Array<Option> {
    return [
      { value: null, icon: "error" },
      { value: true, icon: "check" },
      { value: false, icon: "error" },
    ];
  }

  public setOptionValue: (row: any) => any = () => "";
  public onClick: (row: any) => any = () => null;

  ngOnInit(): void {
    this.row = this.row_data;
    this.findOption();
  }

  public findOption(): void {
    this.value = this.setOptionValue(this.row_data);
    const config = this.optionConfig;
    this.optionConfig = [];

    if (!Array.isArray(config)) {
      this.optionConfig.push(config);
    } else {
      this.optionConfig = [...config];
    }

    if (this.optionConfig) {
      this.option =
        this.optionConfig.find(
          (option: Option) => option.value === this.value,
        ) ?? this.notFound;
    } else if (this.value === null && this.optionConfig) {
      this.option =
        this.optionConfig.find(
          (option: Option) => (option.value = this.value),
        ) ?? this.notFound;
    } else {
      this.option = this.notFound;
    }
  }
}
