import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiLanguageFormComponent } from "./multi-language-form.component";
import { LanguageItemModule } from "../language-item/language-item.module";

@NgModule({
  imports: [CommonModule, LanguageItemModule],
  declarations: [MultiLanguageFormComponent],
  exports: [MultiLanguageFormComponent],
})
export class MultiLanguageFormModule {}
