import {
  ColumnDefinition,
  isGroupColumn,
  isPropertyColumn,
  ProcessedColumn,
  ProcessedColumnDefinition,
  ProcessedRow,
} from "./interfaces/column-definition.interface";
import { SelectItem } from "primeng/api";
import { UntypedFormControl } from "@angular/forms";
import { getPropertyPathValue } from "@incert/incert-core";
import { DataTableConfig } from "./interfaces/data-table-config.interface";

export class DataTableColumnHelper {
  public columnSelectItems: SelectItem[] = [];
  public columnFormControl = new UntypedFormControl([]);
  public flattedColumns: ProcessedColumnDefinition[] = [];
  public columnsByLevel: ProcessedColumnDefinition[][];
  public hasFilter = false;

  // for colGroups
  public _columns: ProcessedColumnDefinition[];
  private _maxLevel = 0;
  private _columnsByLevel: { [level: number]: ProcessedColumnDefinition[] } =
    {};

  public constructor(
    columns: ColumnDefinition<any>[],
    private config: DataTableConfig<any>,
  ) {
    this._columns = <ProcessedColumnDefinition[]>[...columns];
    this.processColumns();

    const visibleIds = [];
    for (let i = 0; i < this.flattedColumns.length; i++) {
      const column = this.flattedColumns[i];
      this.columnSelectItems.push({
        label:
          typeof column.header === "string"
            ? column.header
            : column.header.label,
        value: i,
      });
      if (!column.hidden) {
        visibleIds.push(i);
      }

      // check if table has filter
      if (isPropertyColumn(column)) {
        if (column.filter) {
          this.hasFilter = true;
        }
      }
    }

    this.columnFormControl.patchValue(visibleIds);

    this.columnFormControl.valueChanges.subscribe((v) => {
      for (let i = 0; i < this._columns.length; i++) {
        this._columns[i].hidden = !this.columnFormControl.value.includes(i);
      }
    });
  }

  private processColumns() {
    this.processColumnsRec(this._columns);
    this.columnsByLevel = Object.keys(this._columnsByLevel).map(
      (v) => this._columnsByLevel[v],
    );

    // set rowSpan
    let firstLevel = true;
    for (const columns of this.columnsByLevel) {
      for (const column of columns) {
        column.rowSpan = this._maxLevel / column.levels;
        column.rowSpan = column.bottom
          ? Math.ceil(column.rowSpan)
          : Math.floor(column.rowSpan);
        column.colSpan = column.children === 0 ? 1 : column.children;
      }
      firstLevel = false;
    }
  }

  private processColumnsRec(
    columns: ProcessedColumnDefinition[],
    level = 1,
  ): { level: number; children: number } {
    let children = 0;
    if (level > this._maxLevel) {
      this._maxLevel = level;
    }
    let levels = level;
    for (const column of columns) {
      if (isGroupColumn(column)) {
        const ret = this.processColumnsRec(
          <ProcessedColumnDefinition[]>column.columns,
          level + 1,
        );
        levels = ret.level;
        children += ret.children;
        column.children = ret.children;
      } else {
        this.flattedColumns.push(column);
        column.bottom = true;
        column.children = 0;
        children++;
      }
      column.levels = levels;
      if (!this._columnsByLevel[level]) {
        this._columnsByLevel[level] = [];
      }

      this._columnsByLevel[level].push(column);
    }
    return { level: levels, children: children };
  }

  public processRows(rows: any) {
    const processedRows: ProcessedRow[] = [];
    for (const row of rows) {
      const processedColumns: ProcessedColumn[] = [];
      for (const column of this.flattedColumns) {
        let processedColumn: ProcessedColumn = {
          ...column,
          value: null,
          color: null,
          fontColor: null,
          status: null,
          class: null,
        };
        if (isPropertyColumn(column)) {
          const value = getPropertyPathValue(row, column.property);
          let _class = column.class ? column.class(value, row) : "";
          if (column.status) {
            _class += " col-status-" + column.status(value, row);
          }
          processedColumn = {
            ...processedColumn,
            value: column.transform ? column.transform(value, row) : value,
            color: column.color ? column.color(value, row) : null,
            fontColor: column.fontColor ? column.fontColor(value, row) : null,
            class: _class,
            status: column.status ? column.status(value, row) : null,
          };
        } else {
          if (column.class) {
            processedColumn.class = column.class(undefined, row);
          }
        }
        processedColumns.push(processedColumn);
      }

      const processedRow: ProcessedRow = {
        canExpand: false,
        columns: processedColumns,
        row: row,
        key: this.config.rowIdentifier
          ? getPropertyPathValue(row, this.config.rowIdentifier)
          : Math.random().toString(36),
        additionalInfoRowText: "",
      };
      if (this.config.expansion) {
        if (
          this.config.expansion.condition &&
          !this.config.expansion.condition(row)
        ) {
          processedRow.canExpand = false;
        } else {
          processedRow.canExpand = true;
        }
      }
      //I'm pretty sure nobody will know what i mean in here, but i didn't find a better variable name for this additional Text that's going to be displayed UNDER the regular row columns in a
      //kind of a second row. You can find it in the mockup via ticket /BACKENDGMS-893.
      if (
        this.config.expansion &&
        this.config.expansion.additionalInfoRowText &&
        this.config.expansion.additionalInfoRowText(row)
      ) {
        processedRow.additionalInfoRowText =
          this.config.expansion.additionalInfoRowText(row);
      }
      processedRows.push(processedRow);
    }
    return processedRows;
  }
}
