import { DataSourceFilter } from "./datasource.interface";
import { EventEmitter } from "@angular/core";
import { FilterConfig } from "./column-definition.interface";

export interface DataTableFilterComponent {
  change: EventEmitter<DataSourceFilter | null>;
  config: FilterConfig;
  getFilter: () => Promise<DataSourceFilter | null>;
  setValue: (val) => void;
  setFloatingLabel: (val) => void;
  filterExplicit: () => void;
  reset(): void;
}
