<form [formGroup]="form">
  <form-field label="Bezeichnung">
    <input type="text" formControlName="title" placeholder="Bezeichnung">
  </form-field>
  <form-field label="Beschreibung">
    <input type="text" formControlName="description" placeholder="Beschreibung">
  </form-field>
  <form-field label="Interface Typ">
    <p-dropdown
      [options]="selectItems"
      formControlName="interfaceType"
      placeholder="Interface Typ"
      appendTo="body">
    </p-dropdown>
  </form-field>
</form>



