import {} from "reflect-metadata";

export function Required() {
  /* tslint:disable:only-arrow-functions */
  return function (target: any, key: string) {
    const type = Reflect.getMetadata("design:type", target, key);

    if (!target["__dataConversionMapping"]) {
      target["__dataConversionMapping"] = [];
    }

    target["__dataConversionMapping"][key] = {
      ...target["__dataConversionMapping"][key],
      required: true,
      type: type,
      name: key,
    };
  };
}
