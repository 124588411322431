import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { I18nLoaderInterface } from "./i18n-loader.interface";

const isObject = (v) => !Array.isArray(v) && v instanceof Object;

@Injectable()
export class I18nService {
  private translations: any = {};
  private loadedPathToBool: any = {};
  private keyToSubject = {};
  private keyToTranslation = {};
  private language: string;
  public loading: Promise<void>;

  public constructor(private loader: I18nLoaderInterface) {}

  public async setLanguage(language: string) {
    this.language = language;
    this.loading = new Promise<void>(async (resolve) => {
      for (const path of Object.keys(this.loadedPathToBool)) {
        this.translations = mergeDeep(
          this.translations,
          await this.loader.load(path, this.language),
        );
      }
      resolve();
    });
    await this.loading;
    this.refreshSubscriptions();
  }

  public async load(path: string) {
    if (!this.loadedPathToBool[path]) {
      this.loading = new Promise<void>(async (resolve) => {
        this.translations = mergeDeep(
          this.translations,
          await this.loader.load(path, this.language),
        );
        this.loadedPathToBool[path] = true;
        resolve();
      });
      await this.loading;
      this.refreshSubscriptions();
    }
  }

  public get(key: string, params?: any): Observable<string> {
    const storageKey = this.calcStorageKey(key, params);
    if (!this.keyToSubject[storageKey]) {
      this.keyToSubject[storageKey] = new BehaviorSubject(
        this.getTranslation(storageKey),
      );
    }
    return this.keyToSubject[storageKey];
  }

  public instant(key: string, params?: any): string {
    return this.getTranslation(key, params);
  }

  private refreshSubscriptions() {
    this.keyToTranslation = {};
    for (const key of Object.keys(this.keyToSubject)) {
      this.keyToSubject[key].next(this.getTranslation(key));
    }
  }

  private calcStorageKey(key: string, params?: any): string {
    if (params) {
      for (const objectKey of Object.keys(params)) {
        key += "|" + objectKey + ":" + params[objectKey];
      }
    }
    return key;
  }

  private getTranslation(key, params?: any): string {
    const storageKey = this.calcStorageKey(key, params);
    if (!(storageKey in this.keyToTranslation)) {
      const parts = key.split(".");
      const length = parts.length;
      let i;
      let property = this.translations;
      for (i = 0; i < length; i++) {
        if (!property[parts[i]]) {
          this.keyToTranslation[storageKey] = key;
          return key;
        }
        property = property[parts[i]];
      }
      if (!(typeof property === "string")) {
        this.keyToTranslation[storageKey] = key;
        return key;
      }
      property = <string>property;
      if (params) {
        for (const param of Object.keys(params)) {
          property = property.replace("{{" + param + "}}", params[param]);
        }
      }
      this.keyToTranslation[storageKey] = property;
    }
    return this.keyToTranslation[storageKey];
  }
}

/**
 *
 * @param target
 * @param sources
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
