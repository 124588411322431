import { Subject } from "rxjs";
import { InjectionToken } from "@angular/core";
import { OverlayParam } from "./overlay-param.model";

export const OVERLAY_SUBJECT = new InjectionToken<Subject<OverlayParam>>(
  "OVERLAY_SUBJECT",
  {
    providedIn: "root",
    factory: () => {
      return new Subject<OverlayParam>();
    },
  },
);
