import {Injectable} from "@angular/core";
import {DiscrEnum} from "./enums/discr.enum";
import {SelectItem} from "primeng/api";
import {InstallationStatusEnum} from "./enums/installation-status.enum";
import {SystemTypeEnum} from "./enums/system-type.enum";

@Injectable({providedIn: "root"})
export class Globals {
  systems: Array<SelectItem> = [
    {label: "ECOM", value: DiscrEnum.ecom},
    {label: "LOY", value: DiscrEnum.loy}
  ];

  installationStatusColors = [
    {value: InstallationStatusEnum.added, color: "#ff8827"},
    {value: InstallationStatusEnum.installed, color: "#fec601"},
    {value: InstallationStatusEnum.active, color: "#a6c90f"},
    {value: InstallationStatusEnum.productive, color: "#009711"}
  ]

  systemTypeColors = [
    {value: SystemTypeEnum.professional, color: "#009711"},
    {value: SystemTypeEnum.express, color: "#fec601"},
    {value: SystemTypeEnum.basic, color: "#fec601"},
    {value: SystemTypeEnum.premium, color: "#fec601"},
    {value: SystemTypeEnum.gwg, color: "#fec601"}
  ]

}
