import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateListBoxComponent } from "./date-list-box.component";
import { IconButtonModule, IncertTagModule } from "../..";

@NgModule({
  imports: [CommonModule, IconButtonModule, IncertTagModule],
  exports: [DateListBoxComponent],
  declarations: [DateListBoxComponent],
})
export class DateListBoxModule {}
