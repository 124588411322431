import { NgModule } from "@angular/core";
import { CustomerListSharedModule } from "../../../customer-list-shared.module";
import { CustomerListPrimeModule } from "../../../customer-list-prime.module";
import { CustomerListDataTableModule } from "../../../core/data-table-components/customer-list-data-table.module";
import { ContactGroupComponent } from "./contact-group.component";
import { ContactGroupAddComponent } from "./contact-group-add/contact-group-add.component";
import { LegendModule } from "@incert/incert-gui";
import { ContactGroupContactsComponent } from "./contact-group-contacts/contact-group-contacts.component";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
    LegendModule,
    FormExtensionModule,
  ],
  declarations: [
    ContactGroupComponent,
    ContactGroupAddComponent,
    ContactGroupContactsComponent,
  ],
  exports: [ContactGroupComponent],
})
export class ContactGroupModule {}
