import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User, UserManager } from "../index";
import {
  ConfirmService,
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { Role } from "../../role/role";
import { RoleManager } from "../../role/role-manager.service";
import { AccountSystem } from "../../../core/models/account-system.interface";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { UserAddAccountSystemComponent } from "../user-add-account-system/user-add-account-system.component";
import { Subscription } from "rxjs";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IAuthManager } from "../../../core/auth";
import { UserDetailEditComponent } from "../user-detail-edit/user-detail-edit.component";
import { PwChangeDialogComponent } from "../../../core/pw-change-dialog/pw-change-dialog.component";
import { AccountSystemGroup } from "../../account-system-group/account-system-group";
import { AccountSystemGroupManager } from "../../account-system-group/account-system-group-manager.service";

@Component({
  selector: "user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  public accountSystemGroups: Array<AccountSystemGroup> = [];
  public allAccountSystemsManuallyAssigned: Array<AccountSystem> = [];
  public notAssignedAccountSystemGroups: Array<AccountSystemGroup> = [];
  public accountSystemsManuallyConfig: DataTableConfig<AccountSystem>;
  public accountSystemGroupsConfig: DataTableConfig<AccountSystemGroup>;
  public accountSystemsConfig: DataTableConfig<AccountSystem>;
  public accountSystemsByUser: Array<AccountSystem> = [];
  public allAccountSystems: Array<AccountSystem> = [];
  public accountSystemManualDS: ArrayDataSource<any>;
  public accountSystemGroupDS: ArrayDataSource<any>;
  public userDataSubscription: Subscription;
  public accountSystemDS: ArrayDataSource<any>;
  public formGroup: UntypedFormGroup;
  public selectedRole: Role;
  public roles: Array<Role>;
  public user: User;
  public userId: number;

  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private accountSystemGroupManager: AccountSystemGroupManager,
    private roleManager: RoleManager,
    private accountSystemManager: AccountSystemManager,
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private userManager: UserManager,
    private confirmService: ConfirmService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userId = +params["id"];
    });
    this.initializeFormGroup();
    this.userDataSubscription = this.userManager.userData$.subscribe(() =>
      this.initialize(),
    );
  }

  private initializeFormGroup() {
    this.formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(""),
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      email: new UntypedFormControl(""),
      role: new UntypedFormControl(""),
    });
  }

  private patchFormGroup(user: User) {
    this.formGroup.patchValue({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: this.roles.find((r) => r.id === user.roleId),
    });
  }

  public async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      await this.loadUserDetailData();
      await this.loadRoles();
      await this.loadAccountSystems();
      await this.loadAccountSystemGroupByUser(this.user.id);

      this.createDataTables();
      this.patchFormGroup(this.user);
    });
  }

  private async loadAccountSystems() {
    try {
      this.accountSystemsByUser =
        await this.accountSystemManager.getAccountSystemsByUser(this.userId);

      this.allAccountSystemsManuallyAssigned =
        await this.userManager.getUsersAccountSystems(this.userId);

      this.allAccountSystems =
        await this.accountSystemManager.getStandaloneAccountSystems();
    } catch (e) {
      this.notificationService.notifyError(
        "Kundensysteme konnten nicht geladen werden",
        "Laden fehlgeschlagen",
      );
    }
  }

  private async loadRoles() {
    this.roles = await this.roleManager.getRoles();
  }

  private async loadUserDetailData() {
    this.user = await this.userManager.getUser(this.userId);
    this.selectedRole = await this.roleManager.getRole(this.user.roleId);

    const loggedInUser = await this.userManager.getUser(
      this.authManager.getLoggedInUserId(),
    );

    if (loggedInUser.roleId !== 1) {
      this.formGroup.get("role").disable();
    }

    return this.user;
  }

  private async loadAccountSystemGroupByUser(id: number) {
    this.notAssignedAccountSystemGroups =
      await this.accountSystemGroupManager.getNotAssignedAccountSystemGroupsByUserId(
        this.user.id,
      );

    this.accountSystemGroups =
      await this.accountSystemGroupManager.getAccountSystemGroupsByUserId(id);
  }

  private createDataTables() {
    this.createAccountSystemGroupConfig();
    this.createAccountSystemsByUserConfig();
    this.createAccountSystemsManuallyConfig();
  }

  public get writeUser(): boolean {
    return this.authManager.hasPermission("writeUser");
  }

  private createAccountSystemsByUserConfig() {
    this.accountSystemDS = new ArrayDataSource<AccountSystem>(
      this.accountSystemsByUser,
    );
    this.accountSystemsConfig = {
      mode: "pagination",
      rows: 10,
      data: this.accountSystemDS,
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("name"),
          }),
          sort: true,
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("short"),
          }),
          sort: true,
        },
      ],
    };
  }

  private createAccountSystemsManuallyConfig() {
    this.accountSystemManualDS = new ArrayDataSource<AccountSystem>(
      this.allAccountSystemsManuallyAssigned,
    );
    this.accountSystemsManuallyConfig = {
      mode: "pagination",
      rows: 10,
      data: this.accountSystemManualDS,
      columns: [
        {
          header: "Name",
          property: (v) => v.get("name"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("name"),
          }),
          sort: true,
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("short"),
          }),
          sort: true,
        },
        {
          header: "Löschen",
          hidden: !this.writeUser,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: {
                onClick: (row: AccountSystem) =>
                  this.deleteAssignmentSystem(row),
                icon: "delete",
              },
            },
          ),
        },
      ],
    };
  }

  public createAccountSystemGroupConfig() {
    this.accountSystemGroupDS = new ArrayDataSource<any>(
      this.accountSystemGroups,
    );
    this.accountSystemGroupsConfig = {
      mode: "pagination",
      rows: 10,
      data: this.accountSystemGroupDS,
      columns: [
        {
          header: "Id",
          property: (v) => v.get("id"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("id"),
          }),
          sort: true,
        },
        {
          header: "Name",
          property: (v) => v.get("name"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("name"),
          }),
          sort: true,
        },
        {
          header: "Löschen",
          hidden: !this.writeUser,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: {
                icon: "delete",
                onClick: (row) => this.onDeleteACG(row),
              },
            },
          ),
        },
      ],
    };
  }

  public async save() {
    try {
      if (this.formGroup.valid) {
        await this.userManager.updateUser(this.formGroup.value);
      } else {
        this.notificationService.notifyInfo("Benutzer-Update", "Eingaben ");
      }
      this.notificationService.notifySuccess(
        "Benutzer-Update",
        "Änderungen wurden erfolgreich durchgeführt.",
      );
    } catch (e) {
      this.notificationService.notifyError(
        "Benutzer-Update",
        "Änderungen konnten nicht durchgeführt werden.",
      );
    }
    await this.router.navigate(["user-overview"]);
  }

  public async deleteAssignmentSystem(accountS: AccountSystem) {
    const response = await this.confirmService.confirmError(
      "",
      "Kundensystem löschen",
    );
    if (response) {
      try {
        await this.accountSystemManager.deleteAccountSystemFromUser(
          this.userId,
          accountS.id,
        );
        await this.initialize();

        this.notificationService.notifySuccess(
          "Kundensystem löschen",
          "Die Berechtigung für diesen User auf das Kundensystem wurde entfernt",
        );
      } catch (e) {
        this.notificationService.notifyError(
          "Kundensystem löschen",
          "Das Kundensystem kann nicht gelöscht werden",
        );
      }
    }
  }

  public async onDeleteACG(accountSystem: AccountSystemGroup) {
    const response = await this.confirmService.confirmError(
      "",
      "Systemgruppe löschen",
    );
    if (response) {
      try {
        await this.accountSystemGroupManager.deleteAccountSystemGroupFromUser(
          this.user.id,
          accountSystem.id,
        );
        this.notificationService.notifySuccess(
          "Gruppe löschen",
          "Die Gruppe wurde für diesen Benutzer erfolgreich gelöscht",
        );
        await this.initialize();
      } catch (e) {
        this.notificationService.notifyError(
          "Gruppe löschen",
          "Die Gruppe kann nicht gelöscht werden",
        );
      }
    }
  }

  public async addAccountSystemToUser() {
    await this.overlayService.show<UserAddAccountSystemComponent>({
      type: UserAddAccountSystemComponent,
      size: OverlaySize.medium,
      header: "Kundensystem hinzufügen",
      displayAsSidebar: true,
      init: (c) => {
        c.userId = this.userId;
        c.availableAccountSystems = this.allAccountSystems.filter(
          (a) =>
            this.accountSystemsByUser.map((s) => s.id).indexOf(a.id) === -1,
        );
      },
      actions: [
        {
          action: (c) => c.save(),
          buttonType: "primary",
          label: "Speichern",
        },
        {
          action: () => true,
          displayAsLink: true,
          label: "Abbrechen",
        },
      ],
    });
  }

  public async addAccountSystemGroupsOverlay() {
    await this.overlayService.show<UserDetailEditComponent>({
      type: UserDetailEditComponent,
      size: OverlaySize.medium,
      header: "Systemgruppen hinzufügen",
      displayAsSidebar: true,
      init: (component) => {
        component.user = this.user;
      },
      actions: [
        {
          action: (component) => component.save(),
          buttonType: "primary",
          label: "Speichern",
        },
        {
          action: () => true,
          displayAsLink: true,
          label: "Abbrechen",
        },
      ],
    });
  }

  public async changePasswordOverlay() {
    await this.overlayService.show<PwChangeDialogComponent>({
      type: PwChangeDialogComponent,
      size: OverlaySize.medium,
      header: "Passwort ändern",
      displayAsSidebar: true,
      init: (component) => {
        component.userId = this.user.id;
      },
      actions: [
        {
          action: (component) => component.save(),
          buttonType: "primary",
          label: "Speichern",
        },
        {
          action: (component) => component.cancel(),
          displayAsLink: true,
          label: "Abbrechen",
        },
      ],
    });
  }

  public get cardHeight(): number {
    return window.innerHeight - 166;
  }

  public async cancel(): Promise<void> {
    await this.router.navigate(["user-overview"]);
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
  }
}
