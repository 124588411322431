import { Injectable } from "@angular/core";
import { LocalStorageDataPersistor } from "@incert/incert-core";
import { ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AlertMessagePersistorService {
  private _closed$?: ReplaySubject<Record<string, boolean>>;
  private readonly key = "alertMessageClosed";

  constructor(private dataPersistor: LocalStorageDataPersistor) {}

  get closed$() {
    if (!this._closed$) {
      this._closed$ = new ReplaySubject<Record<string, boolean>>(1);
      this.dataPersistor
        .load(this.key)
        .then((value) => this._closed$.next(value || {}))
        .catch((err) => console.log(err));
    }
    return this._closed$.asObservable();
  }

  async setClosed(key: string, value: boolean) {
    const closed = (await this.dataPersistor.load(this.key)) || {};
    closed[key] = value;
    await this.dataPersistor.save(this.key, closed);
    this._closed$.next(closed);
  }
}
