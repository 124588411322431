import { Component, OnInit } from "@angular/core";
import { Report } from "../report.model";
import { SelectItem } from "primeng/api";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  createNameof,
  DateUtil,
  NotificationService,
} from "@incert/incert-core";
import { AccountSystem } from "../../../../core/models/account-system.interface";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { ReportService } from "../report.service";
import { ReportStatus } from "../report.enum";

@Component({
  selector: "incert-report-upsert",
  templateUrl: "./report-upsert.component.html",
  styleUrl: "./report-upsert.component.css",
})
export class ReportUpsertComponent implements OnInit {
  report: Report;
  accountSystems: AccountSystem[];
  dashboardSI: SelectItem[];
  reportStatusSI: SelectItem[] = [
    { label: "Custom Live", value: ReportStatus.CUSTOM_LIVE },
    { label: "Hubspot Unsynced", value: ReportStatus.HUB_UNSYNCED },
    { label: "Offline", value: ReportStatus.OFFLINE },
  ];
  formGroup: UntypedFormGroup;
  nameofForm = createNameof<Report>();

  constructor(
    private fb: UntypedFormBuilder,
    private reportService: ReportService,
    private notificationService: NotificationService,
  ) {
    this.formGroup = this.fb.group(
      modelToForm<Partial<Report>>({
        partId: this.fb.control(null, Validators.required),
        dashboardId: this.fb.control(null, Validators.required),
        status: this.fb.control(null, Validators.required),
        dateFrom: this.fb.control(null, Validators.required),
        dateTo: this.fb.control(null, Validators.required),
        ttl: this.fb.control(null, Validators.required),
      }),
    );
  }

  ngOnInit(): void {
    if (this.report) {
      this.formGroup.patchValue(this.report);
      this.formGroup
        .get(this.nameofForm("ttl"))
        .patchValue(this.report?.ttl ? new Date(this.report?.ttl) : null);
      this.formGroup
        .get(this.nameofForm("dateFrom"))
        .patchValue(
          this.report?.dateFrom ? new Date(this.report?.dateFrom) : null,
        );
      this.formGroup
        .get(this.nameofForm("dateTo"))
        .patchValue(this.report?.dateTo ? new Date(this.report?.dateTo) : null);
    }
  }

  async upsertReport() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      try {
        const report: Report = {
          ...this.formGroup.value,
          uuid: this.report ? this.report?.uuid : null,
          dateFrom: DateUtil.normalizeTimeZoneOffset(
            this.formGroup.get(this.nameofForm("dateFrom")).value,
          ),
          dateTo: DateUtil.normalizeTimeZoneOffset(
            this.formGroup.get(this.nameofForm("dateTo")).value,
          ),
          ttl: DateUtil.normalizeTimeZoneOffset(
            this.formGroup.get(this.nameofForm("ttl")).value,
          ),
          rls: "",
        };
        await this.reportService.upsertReports(report);
      } catch (e) {
        this.notificationService.notifyError(e, "Hinzufügen nicht möglich");
        return false;
      }
      return true;
    }
    return false;
  }

  protected readonly ReportStatus = ReportStatus;
}
