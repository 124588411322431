export class NumberDataConverter {
  public static encode(value: number): number {
    return value;
  }

  public static decode(value: any, options: any): number {
    if (value === 0) {
      return 0;
    }

    if (value === null || !value) {
      return null;
    }

    value = Number.parseFloat(value);

    if (Number.isNaN(value)) {
      console.error(options);
      console.error(
        'Given value "' + value + '" is not a valid numberValueToDisplay!',
      );
      throw new Error(
        'Given value "' + value + '" is not a valid numberValueToDisplay!',
      );
    }

    return value;
  }
}
