export function uniqueBy<T>(
  array: T[],
  property: keyof T,
): { [key: string]: T } {
  const partitions = {};

  for (const value of array) {
    partitions[value[property].toString()] = value;
  }

  return partitions;
}

export function uniqueByFn<T>(
  array: T[],
  fn: (val: T) => string,
): { [key: string]: T } {
  const partitions = {};

  for (const value of array) {
    partitions[fn(value)] = value;
  }

  return partitions;
}
