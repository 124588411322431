import { AbstractControl, ValidatorFn } from "@angular/forms";

export class NumberGroupValidators {
  public static lessThan(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let validationErrors = control1.errors ? control1.errors : {};
      if (!(control1.value < control2.value)) {
        validationErrors["numberLessThan"] = { number: control2.value };
      } else {
        delete validationErrors["numberLessThan"];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control1.setErrors(validationErrors);

      return null;
    };
  }

  public static greaterThan(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let validationErrors = control1.errors ? control1.errors : {};

      if (!(control1.value > control2.value)) {
        validationErrors["numberGreaterThan"] = { number: control2.value };
      } else {
        delete validationErrors["numberGreaterThan"];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control1.setErrors(validationErrors);

      return null;
    };
  }

  public static lessThanOrEqual(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let validationErrors = control1.errors ? control1.errors : {};
      if (!(control1.value <= control2.value)) {
        validationErrors["numberLessThanOrEqual"] = { number: control2.value };
      } else {
        delete validationErrors["numberLessThanOrEqual"];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control1.setErrors(validationErrors);

      return null;
    };
  }

  public static greaterThanOrEqual(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let validationErrors = control1.errors ? control1.errors : {};

      if (!(control1.value >= control2.value)) {
        validationErrors["numberGreaterThanOrEqual"] = {
          number: control2.value,
        };
      } else {
        delete validationErrors["numberGreaterThanOrEqual"];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control1.setErrors(validationErrors);

      return null;
    };
  }
}
