import {
  ApplicationRef,
  ElementRef,
  InjectionToken,
  Injector,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
} from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpAccess } from "./services/http/http-access.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ApiLazyDataSourceDispatcher } from "./services/lazy-data-source/api-lazy-data-source-dispatcher.service";
import { IncertI18nLoader } from "./i18n";
import { RqlDatasourceCreatorService } from "./services/rql-data-source";
import { ToastModule } from "primeng/toast";
import localeGerman from "@angular/common/locales/de";
import { setAssetUrl } from "./functions/resolve-asset.function";

export const APP_ATTRIBUTES = new InjectionToken<string[]>("attributes");

export function baseHttpAccessFactory(httpClient: HttpClient) {
  return new HttpAccess(httpClient);
}

export function attributesFactory(appRef: ApplicationRef) {
  const elementRef = appRef.components[0].injector.get(ElementRef);

  const appAttributes = {};
  for (const attribute of elementRef.nativeElement.attributes) {
    if (attribute.value.length > 0) {
      appAttributes[attribute.name] = attribute.value;
    }
  }
  return appAttributes;
}

export function localeFactory() {
  if (navigator.language.includes("de-")) {
    registerLocaleData(localeGerman);
    return "de-de";
  } else {
    return "en-us";
  }
}

@NgModule({
  imports: [CommonModule, HttpClientModule, ToastModule],
})
export class IncertCoreModule {
  static forRoot(): ModuleWithProviders<IncertCoreModule> {
    if (window["incertNgAssetUrl"]) {
      setAssetUrl(window["incertNgAssetUrl"]);
    }

    return {
      ngModule: IncertCoreModule,
      providers: [
        IncertI18nLoader,
        HttpAccess,
        {
          provide: HttpAccess,
          useFactory: baseHttpAccessFactory,
          deps: [HttpClient],
        },
        {
          provide: APP_ATTRIBUTES,
          useFactory: attributesFactory,
          deps: [ApplicationRef],
        },
        {
          provide: LOCALE_ID,
          useFactory: localeFactory,
        },
        ApiLazyDataSourceDispatcher,
        RqlDatasourceCreatorService,
      ],
    };
  }
}
