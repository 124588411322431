<form [formGroup]='formGroup'>
  <form-field label="Dashboard">
    <p-dropdown
      [formControlName]="nameofForm('dashboardId')"
      [options]="dashboardSI"
      placeholder="Dashboard"
      appendTo="body">
    </p-dropdown>
  </form-field>
  <form-field label="Report Typ">
    <p-dropdown
      [formControlName]="nameofForm('status')"
      [options]="reportStatusSI"
      placeholder="Report Typ"
      appendTo="body">
    </p-dropdown>
  </form-field>
  <div class="grid">
    <form-field label="Statistiken ab" class="col-6">
      <p-calendar
        [formControlName]="nameofForm('dateFrom')"
        showIcon="true"
        placeholder="Statistiken ab"
        dateFormat="dd/mm/yy">
      </p-calendar>
    </form-field>
    <form-field label="Statistiken bis" class="col-6">
      <p-calendar
        [formControlName]="nameofForm('dateTo')"
        showIcon="true"
        placeholder="Statistiken bis"
        dateFormat="dd/mm/yy">
      </p-calendar>
    </form-field>
  </div>
  <form-field label="Aktiv bis">
    <p-calendar
      [formControlName]="nameofForm('ttl')"
      showIcon="true"
      placeholder="Aktiv bis"
      dateFormat="dd/mm/yy">
    </p-calendar>
  </form-field>
</form>
