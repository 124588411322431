import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { createNameof, NotificationService } from "@incert/incert-core";
import { SelectItem } from "primeng/api";
import { ContactEmail } from "../contact-email.model";
import { ContactEmailManagerService } from "../contact-email-manager.service";
import { markAsDirty, modelToForm } from "@incert/incert-gui";

@Component({
  selector: "incert-internal-tools-contact-email-upsert",
  templateUrl: "./contact-email-upsert.component.html",
  styleUrls: ["./contact-email-upsert.component.css"],
})
export class ContactEmailUpsertComponent implements OnInit {
  contact: ContactEmail;
  form: UntypedFormGroup;
  nameofForm = createNameof<ContactEmail>();

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private contactEmailManager: ContactEmailManagerService,
  ) {
    this.form = this.fb.group(
      modelToForm<ContactEmail>({
        gender: ["", [Validators.required]],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        contactRole: ["", [Validators.required]],
        short: ["", [Validators.required]],
      }),
    );
  }

  genderSelection: SelectItem[] = [
    { label: "Herr", value: "m" },
    { label: "Frau", value: "w" },
    { label: "Divers", value: "d" },
  ];
  roleSelection: SelectItem[] = [];
  systemSelection: SelectItem[] = [];
  newContact = false;

  async ngOnInit() {
    if (this.contact) {
      this.contact.short = this.contact.accountSystem.short;
      this.form.patchValue(this.contact);
      this.form.get("contactRole").patchValue(this.contact.contactRole.value);
    }
  }

  public createContact() {
    this.contact = {
      id: this.newContact ? null : this.contact.id,
      contactRole: this.form.value.contactRole,
      gender: this.form.value.gender,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      short: this.form.value.short,
      accountSystem: null,
    };
  }

  async saveContact() {
    this.createContact();
    markAsDirty(this.form);
    if (this.form.valid) {
      await this.contactEmailManager.postContactData(this.contact);
      if (this.newContact) {
        this.notificationService.notifySuccess("Kontakt hinzugefügt");
      } else {
        this.notificationService.notifySuccess("Kontakt bearbeitet");
      }
      return true;
    }
    return false;
  }
}
