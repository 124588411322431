export interface ArithmeticStrategy {
  calculate(data: any[], property: string, ...args: any): string | number;
}

export enum ArithmeticOperation {
  SUM = "SUM",
  AVG = "AVG",
  MED = "MED",
}

export type ArithmeticStrategyHash = Record<
  ArithmeticOperation,
  ArithmeticStrategy
>;

export interface ArithmeticResult {
  value: number | string;
  operation: keyof typeof ArithmeticOperation;
}

export interface AggregateDefinition<T> {
  property: keyof T & string;
  arithmeticOperation: keyof typeof ArithmeticOperation;
}

export interface AggregateResult {
  property: string;
  result: ArithmeticResult;
}
