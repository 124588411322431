import { Injectable } from "@angular/core";
import { HttpAccessInterface } from "./http-access.interface";

export interface MockHttpAccessRequest {
  method: "GET" | "POST" | "DELETE" | "UPDATE";
  route: string;
  resp: (() => any) | any;
}
interface MockHttpAccessConfig {
  GET?: { [route: string]: () => any };
  POST?: { [route: string]: () => any };
  DELETE?: { [route: string]: () => any };
  PUT?: { [route: string]: () => any };
}
@Injectable()
export class MockHttpAccess implements HttpAccessInterface {
  config: MockHttpAccessConfig = {};
  constructor(...requests: MockHttpAccessRequest[]) {
    this.addRequests(requests);
  }
  protected addRequests(requests: MockHttpAccessRequest[]) {
    for (const request of requests) {
      if (!(request.method in this.config)) {
        this.config[request.method] = {};
      }
      this.config[request.method][request.route] = request.resp;
    }
  }
  get(url: string): any {
    return this.dispatch("GET", url);
  }
  delete(url: string, type?): any {
    return this.dispatch("DELETE", url);
  }
  post(url: string, payload: any, type?): any {
    return this.dispatch("POST", url);
  }
  put(url: string, payload: any, type?): any {
    return this.dispatch("PUT", url);
  }
  private dispatch(method: keyof MockHttpAccessConfig, route: string) {
    if (!(method in this.config) || !(route in this.config[method])) {
      throw new Error(method + " " + route + " not defined in MockHttpAccess");
    }
    const ret = this.config[method][route];
    if (typeof ret === "function") {
      return ret();
    } else {
      return ret;
    }
  }
}
