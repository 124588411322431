import { Component } from '@angular/core';

@Component({
  selector: 'incert-system-value-info',
  templateUrl: './system-value-info.component.html',
  styleUrls: ['./system-value-info.component.css']
})
export class SystemValueInfoComponent {

}
