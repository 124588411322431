import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  DataTableFilterComponent,
  FILTER_CONFIG,
  FilterConfig,
  DataSourceFilter,
} from "@incert/incert-gui";
import { DataTableFilterService } from "../data-table-filter-service";
import { I18nService } from "@incert/i18n";

interface Config extends FilterConfig {
  // at the moment only multipleEnabled=false is supported
  //multipleEnabled?: boolean;
  //dropdownEnabled?: boolean;
  searchDelay?: number;
  placeholder?: string;
  forceSelection?: boolean;
  selectionTooltipEnabled?: boolean; // useful if label is too long for the filter field
  minSearchChars?: number; // used to display info message if too little chars have been entered
}

export type AutocompleteSuggestion = {
  value: any;
  label: string;
};

@Component({
  selector: "incert-autocomplete-filter",
  templateUrl: "./autocomplete-filter.component.html",
})
export class AutocompleteFilterComponent implements DataTableFilterComponent {
  config: Config = {
    dropdownEnabled: false,
    multipleEnabled: false,
    forceSelection: false,
    searchDelay: 100,
    placeholder: "",
    type: "eq",
    selectionTooltipEnabled: false,
    minSearchChars: 1,
  };
  change = new EventEmitter<DataSourceFilter>();
  form: UntypedFormGroup;
  autocompleteSuggestions: AutocompleteSuggestion[];
  floatingLabel = false;
  prevValue = null;

  public search: (event) => any = () => null;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(FILTER_CONFIG) config: Config,
    private dataTableFilterService: DataTableFilterService,
    private cd: ChangeDetectorRef,
  ) {
    Object.assign(this.config, config);
    this.form = this.fb.group({
      filter: [this.config.defaultValue || null],
    });
  }

  async getFilter() {
    return this.buildFilter(this.form.get("filter").value);
  }

  setValue(val) {
    this.form.get("filter").patchValue(val);
  }

  setFloatingLabel(val) {
    this.floatingLabel = val;
  }

  reset(): void {
    this.form.get("filter").reset();
  }

  buildFilter(v): DataSourceFilter | null {
    if (v === null || (Array.isArray(v) && v.length === 0)) {
      return this.dataTableFilterService.getDataTableFilter(this.config, null);
    }
    return this.dataTableFilterService.getDataTableFilter(
      this.config,
      v?.value,
    );
  }

  handleUnselect() {
    this.form.get("filter").setValue(null);
    this.filterExplicit();
  }

  async handleSearch(event) {
    // check if no suggestion has been selected
    // -> fixes bug where clicking inside field in readonly state and pressing down key triggers completeMethod (this method) again
    if (!this.form.get("filter").value) {
      if (event.query.length >= this.config.minSearchChars) {
        // use search method provided in config
        this.autocompleteSuggestions = await this.config.search(event);
      } else {
        this.autocompleteSuggestions = [];
      }
      this.cd.detectChanges(); // without this the result dropdown wouldn't show without an extra click
    } else {
      // necessary to hide the autocomplete loading spinner
      this.autocompleteSuggestions = [];
    }
  }

  filterExplicit() {
    if (this.prevValue !== this.form.get("filter").value) {
      this.change.emit(this.buildFilter(this.form.get("filter").value));
    }
    this.prevValue = this.form.get("filter").value;
  }
}
