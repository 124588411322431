<form [formGroup]="form" *ngIf="accessGroups.length > 0">
  <form-field label="Bezeichnung">
    <input
      type="text"
      [formControlName]="nameofForm('title')"
      placeholder="Bezeichnung">
  </form-field>
  <form-field label="Beschreibung">
    <input
      type="text"
      [formControlName]="nameofForm('description')"
      placeholder="Beschreibung">
  </form-field>
</form>

<form-field label="Access Profile Parents">
  <p-accordion>
    <p-accordionTab header="Access Profiles">
      <p-accordion>
        <div *ngFor="let ap of accessProfiles">
          <p-accordionTab *ngIf="!isMatchId(ap.id) && !ap.parent"
                          [ngClass]="{'p-accordion-tab-non-toggleable': !ap.children  || ap.children[0].id === accessProfile.id}">
            <ng-template pTemplate="header">
              <span class="p-panel-header-label">{{ap.title}}</span>
              <div class="access-profile-add-button">
                <p-inputSwitch
                  (onChange)="clickParentProfile($event, ap)"
                  [ngModel]="ap.id === accessProfile.parent"
                  [disabled]="ap.id !== accessProfile.parent && accessProfile.parent !== null">
                </p-inputSwitch>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <ng-template ngFor let-apChild [ngForOf]="ap.children">
                <div class="inc-accordion-non-toggle-row" *ngIf="!isMatchId(apChild.id)">
                  <span class="p-panel-header-label">{{apChild.title}}</span>
                  <div class="access-profile-add-button">
                    <p-inputSwitch
                      (onChange)="clickParentProfile($event, apChild)"
                      [ngModel]="apChild.id === accessProfile.parent"
                      [disabled]="apChild.id !== accessProfile.parent && accessProfile.parent !== null">
                    </p-inputSwitch>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </p-accordionTab>
        </div>
      </p-accordion>
    </p-accordionTab>
  </p-accordion>
</form-field>

<form-field label="Access Groups">
  <p-accordion>
    <p-accordionTab header="Access Groups">
      <incert-access-group-assign
        [accessGroups]="accessGroups"
        [accessGroupChanges]="accessGroupChanges"
        [accessProfile]="accessProfile"
        [parentGroups]="parentGroups"
        [withAccessProfiles]="true">
      </incert-access-group-assign>
    </p-accordionTab>
  </p-accordion>
</form-field>
<form-field label="Access Levels">
  <p-accordion>
    <p-accordionTab header="Access Levels">
      <incert-access-level-assign
        [accessLevels]="accessLevels"
        [accessLevelChanges]="accessLevelChanges"
        [accessProfile]="accessProfile"
        [parentLevels]="parentLevels"
        [withAccessProfiles]="true">
      </incert-access-level-assign>
    </p-accordionTab>
  </p-accordion>
</form-field>




