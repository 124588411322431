import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "incert-wizard-add-voucher",
  templateUrl: "./wizard-add-voucher.component.html",
})
export class WizardAddVoucherComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    type: [undefined],
    product: [undefined],
  });

  @Input()
  types: { id: number; name: string }[] = [];

  @Input()
  products: { id: number; name: string }[] = [];

  @Input()
  isTicket = false;

  @Input()
  onlyExisting = false;

  @Output()
  onNew = new EventEmitter<number>();

  @Output()
  onExisting = new EventEmitter<number[]>();

  private typeSubscription?: Subscription;
  private productSubscription?: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.typeSubscription = this.form.controls.type.valueChanges.subscribe(
      (type) => {
        if (type) {
          this.form.controls.type.setValue(undefined);
          this.onNew.emit(type);
        }
      },
    );

    this.productSubscription =
      this.form.controls.product.valueChanges.subscribe((product) => {
        this.onExisting.emit(product || []);
      });
  }

  ngOnDestroy() {
    this.typeSubscription?.unsubscribe();
    this.productSubscription?.unsubscribe();
  }

  reset() {
    this.form.reset();
  }
}
