import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ROW_DATA } from "@incert/incert-gui";
import { Role } from "../../../pages/role/role";
import { Permission } from "../../../pages/role/permission";
import { SELECTED_ROLES } from "../../../pages/role/role-overview/role-overview.component";

export interface PermissionData {
  permission: Permission;
  permissionToEditRole?: boolean;
  role?: Role;
}

@Component({
  selector: "incert-checkbox",
  templateUrl: "./permission-checkbox.component.html",
  styleUrls: ["./permission-checkbox.component.scss"],
})
export class DataTablePermissionCheckbox implements OnInit {
  public permissionData: PermissionData;
  public resolveRowData: (row: any) => PermissionData = () => null;

  constructor(
    @Inject(ROW_DATA) private row_data: any,
    @Inject(SELECTED_ROLES) public form: UntypedFormGroup,
  ) {}

  ngOnInit(): void {
    this.permissionData = this.resolveRowData(this.row_data);

    const permissionForm = <UntypedFormGroup>this.form.get("permissions");

    if (
      !permissionForm.contains(this.permissionData.permission.id.toString())
    ) {
      permissionForm.registerControl(
        this.permissionData.permission.id.toString(),
        new UntypedFormControl(),
      );
    }

    permissionForm
      .get(this.permissionData.permission.id.toString())
      .patchValue(this.permissionData.permission.selected === true, {
        emitEvent: false,
      });
  }
}
