import { NgModule } from "@angular/core";
import { NotificationComponent } from "./notification.component";
import { AddNotificationComponent } from "./add-notification/add-notification.component";
import { CustomerListDataTableModule } from "../../../core/data-table-components/customer-list-data-table.module";
import { CustomerListPrimeModule } from "../../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../../customer-list-shared.module";
import { FormExtensionModule, QuillEditorModule } from "@incert/incert-gui";

@NgModule({
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
    QuillEditorModule,
    FormExtensionModule,
  ],
  declarations: [NotificationComponent, AddNotificationComponent],
  exports: [NotificationComponent, AddNotificationComponent],
})
export class NotificationModule {}
