import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProtocolManager } from "../protocol-manager";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableOptionIconComponent,
} from "@incert/incert-gui";
import { History } from "../protocol";
import { TextFilterComponent } from "@incert/incert-gui";
import { LoadingService } from "@incert/incert-core";

@Component({
  selector: "sac-history",
  templateUrl: "./history-protocol.component.html",
  styleUrls: ["./history-protocol.component.scss"],
})
export class HistoryProtocolComponent implements OnInit {
  public isLoading: boolean;
  public viewType: string;
  historyDataConfig: DataTableConfig<History>;

  constructor(
    private historyManager: ProtocolManager,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
  ) {}

  public async ngOnInit() {
    await this.loadingService.load(async () => {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.viewType = params["type"];
      });

      this.isLoading = true;

      await this.historyManager
        .getProtocolByType(this.viewType)
        .then((res: Array<History>) => this.createHistoryData(res));
    });
  }

  createHistoryData(data: Array<History>) {
    this.historyDataConfig = {
      mode: "pagination",
      data: new ArrayDataSource(data),
      rows: 30,
      columns: [
        {
          header: "Benutzer-Email",
          property: (v) => v.get("email"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("email"),
          }),
        },
        {
          header: "Datum",
          property: (v) => v.get("date"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("date"),
          }),
        },
        {
          header: "IP-Adresse",
          property: (v) => v.get("ipAdress"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("ipAdress"),
          }),
        },
        {
          header: "Status",
          component: createComponentConfiguration(
            DataTableOptionIconComponent,
            {
              setOptionValue: (row: History) => row.actionSuccess,
              optionConfig: [
                { value: true, icon: "check" },
                { value: false, icon: "error" },
              ],
            },
          ),
        },
        {
          header: this.getViewTypeHeader(),
          hidden: this.isLoginProtocol(),
          property: (v) => v.get(this.getProperty()),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get(this.getProperty()),
          }),
        },
      ],
    };
  }

  private isLoginProtocol() {
    return this.viewType !== "change" && this.viewType !== "access";
  }

  private getProperty(): any {
    if (this.viewType === "change") {
      return "changeObject";
    } else {
      return "accountSystem";
    }
  }

  private getViewTypeHeader() {
    if (this.viewType === "change") {
      return "Geändert wurde";
    } else if (this.viewType === "access") {
      return "Kundensystem";
    } else {
      return "";
    }
  }
}
