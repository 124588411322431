import { Component, Inject, Input, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table";
import { IconButtonConfig } from "./data-table-icon-buttons.interface";
import { MenuItem } from "primeng/api";

@Component({
  selector: "data-table-icon-buttons",
  templateUrl: "data-table-icon-buttons.component.html",
  styleUrls: ["data-table-icon-buttons.component.scss"],
})
export class DataTableIconButtonsComponent implements OnInit {
  @Input() iconConfig: IconButtonConfig[] | IconButtonConfig;
  @Input() contextMenuItems: MenuItem[];
  @Input() row: any;

  constructor(@Inject(ROW_DATA) public row_data: any) {}

  ngOnInit() {
    this.createIconConfig();
  }

  public show(iconButtonCfg: IconButtonConfig): boolean {
    if (!iconButtonCfg.onShow) {
      return true;
    }
    return iconButtonCfg.onShow(this.row_data);
  }

  public disable(iconButtonCfg: IconButtonConfig): boolean {
    if (!iconButtonCfg.onDisable) {
      return false;
    }
    return iconButtonCfg.onDisable(this.row_data);
  }

  public getTooltip(iconButtonCfg: IconButtonConfig): string {
    if (typeof iconButtonCfg.tooltip === "string") {
      return iconButtonCfg.tooltip;
    } else if (typeof iconButtonCfg.tooltip === "function") {
      return iconButtonCfg?.tooltip(this.row_data);
    }
  }

  private createIconConfig() {
    const config = this.iconConfig;

    if (!Array.isArray(config)) {
      this.iconConfig = [];
      this.iconConfig.push(config);
    }

    this.iconConfig = (this.iconConfig as Array<IconButtonConfig>).filter(
      (iC) => !iC.hidden,
    );
  }
}
