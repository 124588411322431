import { Component, Input, OnInit } from "@angular/core";
import { I18nService } from "@incert/i18n";

@Component({
  selector: "no-data-info",
  templateUrl: "./no-data-info.component.html",
  styleUrls: ["./no-data-info.component.scss"],
})
export class NoDataInfoComponent implements OnInit {
  @Input()
  public message: string = this.i18n.instant("core.searchEmpty");

  constructor(private i18n: I18nService) {}

  ngOnInit() {}
}
