<div class='p-m-5' *ngIf='!importEmpty && initialized'>
  <h1>Import nach Datum</h1>
  <div class='grid col-2'>
    <p-calendar
      showIcon="true"
      class='col-12'
      [(ngModel)]='byDate'
      (click)='createImportStatistic()'
      dateFormat='dd.mm.yy'>
    </p-calendar>

    <div class='col-12'>
      <table class='table'>
        <tr>
          <th>Importvorgänge</th>
          <td>{{importDataByDate?.imports}}</td>
        </tr>
        <tr>
          <th>Erfolgreich</th>
          <td>{{importDataByDate?.fulfilled ?? 0}}</td>
        </tr>
        <hr>
        <tr>
          <th>Fehlerhaft</th>
          <td>{{importDataByDate?.failed ?? 0}}</td>
        </tr>
        <tr>
          <th>Fehlerhaft (403)</th>
          <td>{{importDataByDate?.failedForbidden ?? 0}}</td>
        </tr>
        <tr>
          <th>Fehlerhaft (401)</th>
          <td>{{importDataByDate?.failedUnauthorized ?? 0}}</td>
        </tr>
        <tr>
          <th>Fehlerhaft (404)</th>
          <td>{{importDataByDate?.failedNotFound ?? 0}}</td>
        </tr>
      </table>
    </div>
  </div>

  <div class='col'>
    <div class='ui-widget-header col-12'>
      <div class='ui-inputgroup col-11'>
      </div>
    </div>
    <h1>Importstatistik</h1>
    <data-table *ngIf='importProtocolConfig' [config]='importProtocolConfig'></data-table>
  </div>
</div>
<div *ngIf='importEmpty'>
  <h1 class='p-text-center'> Kein Importverlauf</h1>
</div>
