import { getPropertyPathValue } from "@incert/incert-core";
import { Observable, Subject } from "rxjs";
import { GlobalArrayFilter } from "../shared/global-array-filter";
import {
  DataSource,
  DataSourceFilter,
  DataSourceLoadParameter,
  DataSourceSort,
  isDataSourceFilterBinaryExpression,
  isDataSourceFilterLogicalExpression,
} from "../shared/interfaces/datasource.interface";

export class ArrayDataSource<T> implements DataSource<T> {
  data: T[];
  total: number;
  param: DataSourceLoadParameter;

  private _data: T[];
  private filteredData: T[] = [];
  private _refreshDatasource$ = new Subject();
  private readonly globalArrayFilter: GlobalArrayFilter<T>;
  private isSearchRequest: boolean;

  public constructor(data: T[]) {
    this._data = data;
    this.total = data.length;
    this.globalArrayFilter = new GlobalArrayFilter();
  }

  get refreshDatasource$(): Observable<any> {
    return this._refreshDatasource$;
  }

  async export(): Promise<any[]> {
    return this._data;
  }

  async load(param: DataSourceLoadParameter): Promise<T[]> {
    if (this.isSearchRequest) {
      this.data = this.filteredData;
    } else {
      this.data = this._data;
    }

    this.param = param;

    this.data = this.filterData(param, this.data);
    this.total = this.data.length;
    this.data = this.sort(param, this.data);

    return this.data.slice(param.offset, param.limit + param.offset);
  }

  private filterData(param: DataSourceLoadParameter, data: T[]): any {
    if (param.filters.length > 0) {
      return data.filter((row) =>
        param.filters.every((filter) => this.matchesFilter(row, filter)),
      );
    }

    return data;
  }

  private matchesFilter(value: T, dsf: DataSourceFilter) {
    if (isDataSourceFilterBinaryExpression(dsf)) {
      if (!dsf.value) {
        return true;
      }

      const propertyValue = getPropertyPathValue(value as object, dsf.property);

      switch (dsf.type) {
        case "contains": {
          if (Number(propertyValue)) {
            return propertyValue.toString().indexOf(dsf.value) !== -1;
          } else {
            return (
              propertyValue
                .toString()
                .toLowerCase()
                .indexOf(dsf.value.toLowerCase()) !== -1
            );
          }
        }
        case "eq": {
          if (Number(propertyValue)) {
            return propertyValue === dsf.value;
          } else {
            return propertyValue === dsf.value;
          }
        }
        case "between": {
          const range = [];
          Object.keys(dsf).map((f) => {
            if (Array.isArray(dsf[f])) {
              return dsf[f].map((val) => {
                if (Number(val)) {
                  range.push(val);
                }
              });
            }
          });

          if (
            Object.prototype.toString.call(new Date(propertyValue)) ===
            "[object Date]"
          ) {
            return (
              range[0].getTime() < new Date(propertyValue).getTime() &&
              range[1].getTime() > new Date(propertyValue).getTime()
            );
          } else {
            return range[0] < propertyValue && range[1] > propertyValue;
          }
        }
        case "in": {
          let allFound = true;
          const keyLessPropertyValue = propertyValue.map((e) =>
            e !== null && typeof e === "object" ? e.id : e,
          );
          for (const i in dsf.value) {
            if (keyLessPropertyValue.indexOf(dsf.value[i]) === -1) {
              allFound = false;
            }
          }
          return allFound;
        }
        default: {
          if (Number(propertyValue)) {
            return propertyValue.toString().indexOf(dsf.value) !== -1;
          } else {
            return (
              propertyValue
                .toString()
                .toLowerCase()
                .indexOf(dsf.value.toLowerCase()) !== -1
            );
          }
        }
      }
    } else if (isDataSourceFilterLogicalExpression(dsf)) {
      if (dsf.filters.length === 0) {
        return true;
      } else {
        switch (dsf.type) {
          case "or":
            return dsf.filters.some((f) => this.matchesFilter(value, f));
          case "and":
            return dsf.filters.every((f) => this.matchesFilter(value, f));
        }
      }
    }

    return true;
  }

  private sort(param: DataSourceLoadParameter, data: T[]): any {
    if (param.sorts.length > 0) {
      param.sorts.forEach((sort: DataSourceSort) => {
        const property = sort.property[0];
        data.sort((el1, el2) => {
          if (el1[property] && el2[property]) {
            if (Number(el1[property]) && Number(el1[property])) {
              return sort.sortOrder === "asc"
                ? el1[property] - el2[property]
                : el2[property] - el1[property];
            } else {
              return sort.sortOrder === "asc"
                ? el1[property] > el2[property]
                  ? -1
                  : 1
                : el1[property] < el2[property]
                  ? -1
                  : 1;
            }
          }
        });
      });
    }

    return data;
  }

  public getData() {
    return this._data;
  }

  public reloadDataTable(updatedData: T[]) {
    this._data = updatedData;
    this.total = this._data.length;

    this.refreshDataTableSource();

    return true;
  }

  public globalFilter(searchString) {
    if (!searchString) {
      this.isSearchRequest = false;
      this.refreshDataTableSource();
    } else {
      this.isSearchRequest = true;
    }

    this.globalArrayFilter
      .filter(searchString, {
        data: this._data,
        maxArgs: 5,
      })
      .subscribe((filterResult) =>
        setTimeout(() => {
          this.filteredData = filterResult;
          this.total = filterResult.length;
          this.refreshDataTableSource();
        }, 500),
      );
  }

  public refreshDataTableSource(data?: T[]) {
    if (data) {
      this.filteredData = data;
      this._data = data;
      this.total = data.length;
      this._refreshDatasource$.next(this);
    } else {
      this._refreshDatasource$.next(this);
    }
  }
}
