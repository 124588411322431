import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  DataTableConfig,
  DataTableIconButtonsComponent,
} from "@incert/incert-gui";
import { Category } from "../memo.interface";
import { MemoManager } from "../memo.manager.service";
import { LoadingService, NotificationService } from "@incert/incert-core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "incert-internal-tools-add-new-category-overlay",
  templateUrl: "./add-new-memo-category.component.html",
  styleUrls: ["./add-new-memo-category.component.scss"],
})
export class MemoCategoryComponent implements OnInit {
  public categoryTableConfig: DataTableConfig<Category>;
  public categories: Array<Category>;
  public form: UntypedFormGroup;
  public tabIndex = 0;
  public isEditMode = false;
  @ViewChild("f", { static: true }) categoryInput: HTMLInputElement;

  constructor(
    private memoManager: MemoManager,
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder,
  ) {
    this.createForm();
  }

  async ngOnInit() {
    await this.loadingService.load(async () => {
      this.categories = await this.memoManager.getMemoCategories();
      await this.createDataTableConfig();
    });
  }

  private createForm() {
    this.form = this.fb.group({
      id: [""],
      name: ["", Validators.required],
      bgColor: ["#b2ffad"],
      fgColor: ["#319e37"],
    });
  }

  private async createDataTableConfig() {
    this.categoryTableConfig = {
      data: new ArrayDataSource(this.categories),
      rows: 20,
      mode: "scroll",
      columns: [
        {
          header: "Name",
          class: () => "nowrap",
          property: (v) => v.get("name"),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  onClick: (row: Category) => this.editCategory(row),
                },
                {
                  icon: "delete",
                  onClick: (row: Category) => this.deleteCategory(row),
                },
              ],
            },
          ),
        },
      ],
    };
  }

  private async deleteCategory(category: Category) {
    try {
      await this.memoManager.deleteCategory(category.id);
      this.categories = this.categories.filter((cat) => cat.id !== category.id);
      await this.refreshDataTableConfig();

      this.notificationService.notifySuccess(
        "Kategorie " + category.name + " wurde erfolgreich gelöscht!",
        "Löschen erfolgreich",
      );
    } catch (e) {
      this.notificationService.notifyError(
        e.error.message,
        "Löschen nicht möglich",
      );
    }
  }

  public async onAddCategory() {
    if (this.form.valid) {
      await this.saveCategory();
      await this.refreshDataTableConfig();

      this.resetForm();
    } else {
      this.notificationService.notifyInfo(
        "Der Name der Kategorie darf nicht leer sein",
        "Einaben überprüfen",
      );
    }
  }

  public async saveCategory() {
    let category: Category = this.form.value;
    category = await this.memoManager.saveCategory(category);
    this.updateCategoriesArray(category);

    this.notificationService.notifySuccess(
      "Kategorie " + category.name + " wurde gespeichert",
      "Speichern erfolgreich",
    );
    await this.refreshDataTable();
    return category;
  }

  private updateCategoriesArray(category: Category) {
    if (!this.isEditMode) {
      this.categories = [...this.categories, category];
    } else {
      this.categories = [
        ...this.categories.filter((c) => c.id !== category.id),
        category,
      ];
    }
  }

  private resetForm() {
    this.isEditMode = false;
    this.form.get("name").reset("");
    this.form.get("id").reset();
  }

  private async refreshDataTableConfig() {
    this.memoManager.markChanged();
    await this.createDataTableConfig();
  }

  getBGColor() {
    return this.form.controls["bgColor"].value;
  }

  getFGColor() {
    return this.form.controls["fgColor"].value;
  }

  public editCategory(row: Category) {
    this.isEditMode = true;
    this.tabIndex = 0;

    this.form.patchValue({
      id: row.id,
      name: row.name,
      bgColor: row.bgColor,
      fgColor: row.fgColor,
    });
  }

  private async refreshDataTable() {
    this.memoManager.markChanged();
    await this.createDataTableConfig();
  }

  getExampleCategory() {
    const category: Category = this.form.value;

    if (category.name === "") {
      category.name = "Beispieltext";
    }
    return [category];
  }
}
