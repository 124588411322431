import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentOutletComponent } from "./component-outlet.component";

@NgModule({
  declarations: [ComponentOutletComponent],
  exports: [ComponentOutletComponent],
  imports: [CommonModule],
})
export class ComponentOutletModule {}
