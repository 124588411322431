import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import * as FileSaver from "file-saver";
import { SelectItem } from "primeng/api";

export class Utils {
  public static mapIdToSelectItem<T>(
    data: T[],
    property?: keyof T,
    value?: keyof T,
  ): SelectItem[] {
    return data.map((t: T) => ({
      label: property ? (t as any)[property] : t.toString(),
      value: value ? { id: (t as any)[value] } : t.toString(),
    }));
  }

  public static mapCustomValueToSelectItem<T>(
    data: T[],
    property?: keyof T,
    value?: keyof T,
  ): SelectItem[] {
    return data.map((t: T) => ({
      label: property ? (t as any)[property] : t.toString(),
      value: value ? (t as any)[value] : t.toString(),
    }));
  }

  public static mapTwoFieldsToSelectItem<T>(
    data: T[],
    field1?: keyof T,
    field2?: keyof T,
  ): SelectItem[] {
    return data.map((element: T) => ({
      label: element[field1] + " " + element[field2],
      value: element,
    }));
  }

  public static mapArrayToSelectItem(data: any[]): SelectItem[] {
    return data.map((t) => ({ label: t, value: t }));
  }

  public static markInvalidFieldsAsDirty(
    formGroup: UntypedFormGroup,
  ): UntypedFormGroup {
    Object.keys(formGroup.controls).forEach((control: string) => {
      if (formGroup.controls[control] instanceof UntypedFormControl) {
        formGroup.controls[control].markAsDirty();
      } else if (formGroup.controls[control] instanceof UntypedFormArray) {
        (formGroup.controls[control] as UntypedFormArray).controls.forEach(
          (fgrp: UntypedFormGroup) => {
            this.markInvalidFieldsAsDirty(fgrp);
          },
        );
      }
    });

    return formGroup;
  }

  public static getFormattedDateTime(d: string, separator?: string) {
    const sep = separator ? separator : "/";

    const date = new Date(d);
    return (
      date.getDate() + sep + (date.getMonth() + 1) + sep + date.getFullYear()
    );
  }

  public static showLastChanged(v: string) {
    return this.getFormattedTime(
      new Date().getTime() - new Date(v).getTime(),
      "vor",
    );
  }

  public static showTimeWithCustomText(date: string, text: string) {
    return Utils.getFormattedTime(
      Math.abs(new Date().getTime() - new Date(date).getTime()),
      text,
    );
  }

  public static showValidUntil(date: string): string {
    let preText = "in";
    if (!date) {
      return "-";
    }

    const diff = new Date().getTime() - new Date(date).getTime();

    if (diff > 0) {
      preText = "vor: ";
    }

    return Utils.getFormattedTime(Math.abs(diff), preText);
  }

  public static showDateAt(date: string): string {
    if (!date) {
      return "-";
    }

    return "am " + Utils.getFormattedDateTime(date.toString(), ".");
  }

  public static isInPast(date: any) {
    if (date) {
      return new Date(date).getTime() - new Date().getTime() < 0;
    } else {
      return false;
    }
  }

  public static validateEmail(mail: string) {
    return mail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
  }

  public static createCSV(data: any[], filename: string) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(","),
    );
    csv.unshift(header.join(","));
    const csvArray = csv.join("\r\n");

    const blob = new Blob([csvArray], { type: "text/csv" });
    FileSaver.saveAs(blob, filename);
  }

  private static getFormattedTime(diff: number, preText): string {
    if (diff < 60000) {
      return preText + " " + Math.round(diff / 1000) + " Sekunden";
    } else if (diff < 3600000) {
      return preText + " " + Math.round(diff / 60000) + " Minuten";
    } else if (diff < 86400000) {
      return preText + " " + Math.round(diff / 3600000) + " Stunden";
    } else if (diff < 2629746000) {
      return preText + " " + Math.round(diff / 86400000) + " Tagen";
    } else if (diff < 31556952000) {
      return preText + " " + Math.round(diff / 2629746000) + " Monaten";
    } else {
      return preText + " " + Math.round(diff / 31556952000) + " Jahren";
    }
  }
}
