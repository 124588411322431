import { Injectable } from "@angular/core";
import { Message } from "primeng/api";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class NotificationService {
  private _message$ = new Subject<Message>();
  public get message$() {
    return this._message$.asObservable();
  }

  public constructor() {}

  public notifySuccess(content: string, header?: string, sticky = false) {
    this._message$.next({
      severity: "success",
      summary: header,
      detail: content,
      sticky: sticky,
    });
  }

  public notifyError(content: string, header?: string, sticky = false) {
    this._message$.next({
      severity: "error",
      summary: header,
      detail: content,
      sticky: sticky,
    });
  }

  public notifyInfo(content: string, header?: string, sticky = false) {
    this._message$.next({
      severity: "info",
      summary: header,
      detail: content,
      sticky: sticky,
    });
  }

  public notifyWarning(content: string, header?: string, sticky = false) {
    this._message$.next({
      severity: "warn",
      summary: header,
      detail: content,
      sticky: sticky,
    });
  }
}
