import { Component } from "@angular/core";
import {
  ComponentOutletConfig,
  DataTableFilterComponent,
} from "@incert/incert-gui";
import { ProcessedDataTableFilterDefinition } from "../interfaces/processed-data-table-filter-definition.interface";

interface FilterOverlayComponent {
  instance?: DataTableFilterComponent;
  componentConfig: ComponentOutletConfig<DataTableFilterComponent>;
  header: string;
}

@Component({
  selector: "incert-data-table-filter-overlay",
  templateUrl: "./data-table-filter-overlay.component.html",
  styleUrls: ["./data-table-filter-overlay.component.css"],
})
export class DataTableFilterOverlayComponent {
  components: FilterOverlayComponent[] = [];

  set filters(definitions: ProcessedDataTableFilterDefinition[]) {
    this.components = definitions.map((d) => {
      const config: FilterOverlayComponent = {
        header: d.header,
        componentConfig: {
          component: d.component.component,
          injector: d.component.injector,
          init: (c) => {
            config.instance = c;
            c.setValue(d.value);
          },
        },
      };
      return config;
    });
  }

  get instances(): (DataTableFilterComponent | undefined)[] {
    return this.components.map((c) => c.instance);
  }
}
