import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from "@angular/forms";

export function markAsDirty(control: UntypedFormGroup | AbstractControl) {
  control.markAsDirty();
  if (control instanceof UntypedFormGroup) {
    for (const key of Object.keys(control.controls)) {
      markAsDirty(control.controls[key]);
    }
  }
  if (control instanceof UntypedFormArray) {
    for (const key of Object.keys(control.controls)) {
      markAsDirty(control.controls[key]);
    }
  }
  return;
}
