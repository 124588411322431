import Quill from "quill";
import Embed from "quill/blots/embed";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export interface QuillTagBlotConfigInterface {
  blotName?: string;
  tagName?: string;
  className?: string;
  begin?: string;
  end?: string;
}

export function insertTagBlot(quill: Quill, blotName: string, content: string) {
  const range = quill.getSelection(true);
  quill.insertEmbed(range.index, blotName, content, Quill.sources.USER);
  quill.setSelection(range.index + 1, Quill.sources.SILENT);
}

export function createQuillTagBlot(
  quill: typeof Quill,
  {
    blotName = "tag",
    tagName = "span",
    className = "incert-tag",
    begin = "{{",
    end = "}}",
  }: QuillTagBlotConfigInterface,
) {
  const embed = quill.import("blots/embed") as typeof Embed;
  const keywordRegex = new RegExp(
    escapeRegExp(begin) + "(.*?)" + escapeRegExp(end),
    "g",
  );

  class TagBlot extends embed {
    static blotName = blotName;
    static tagName = tagName;
    static className = className;

    static create(value) {
      const node = super.create() as HTMLElement;
      node.innerText = begin + value + end;
      return node;
    }

    static value(node) {
      const text = node.innerText.trim();
      return text.substring(begin.length, text.length - end.length);
    }

    static prepareInput(el: Element) {
      el.innerHTML = el.innerHTML.replace(
        keywordRegex,
        `<${tagName} class="${className}">\uFEFF<span contenteditable="false">${begin}$1${end}</span>\uFEFF</${tagName}>`,
      );
    }

    static prepareOutput(el: Element) {
      for (const child of Array.from(el.getElementsByTagName(this.tagName))) {
        if (child.classList.contains(this.className)) {
          const keyword = keywordRegex.exec(child.innerHTML);
          keywordRegex.lastIndex = 0;

          if (keyword) {
            child.parentNode.replaceChild(
              el.ownerDocument.createTextNode(begin + keyword[1] + end),
              child,
            );
          }
        }
      }
    }
  }

  return TagBlot;
}
