import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { IconType } from "@incert/incert-core";

@Component({
  selector: "incert-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.css"],
})
export class IconComponent implements OnInit, OnChanges {
  @Input() public iconType: keyof typeof IconType; //Pre-defined Icon Type (basic Icons)
  @Input() public iconClass = ""; //Individual Icons, that are not defined in IconType
  @Input() public weight = "fal"; //Font Awesome Font weight
  @Input() public label = ""; //Label, which is either shown next to the icon, or only used for accessibility reasons
  @Input() public showLabel = false; //Show the label next to the icon or only use it for screen readers?

  public finalIconClass = "";

  constructor() {}

  ngOnInit() {
    this.setFinalIconClass();
  }

  private setFinalIconClass() {
    if (this.iconType) {
      //Use default Icons
      this.finalIconClass = IconType[this.iconType];
    } else {
      this.finalIconClass = this.weight + " " + this.iconClass;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFinalIconClass();
  }
}
