<div
  *ngIf="config.text.length > config.maxLengthWrap">
  <div>
    <span [innerHTML]="getComment()"></span>
    <span
      class="pointer"
      (click)="showCommentOverlay()"
      [innerHTML]="'<u> Mehr anzeigen </u>'">
    </span>
  </div>
</div>
<div *ngIf="config.text.length <= config.maxLengthWrap">
  <div>
    <div><p [innerHTML]="config.text"></p></div>
  </div>
</div>
