import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormFieldComponent } from "./form-field/form-field.component";
import { FormSubmitDirective } from "./directives/form-submit.directive";
import { IfControllEnabledDirective } from "./directives/if-control-enabled.directive";
import { IfControllValidDirective } from "./directives/if-control-valid.directive";
import { LanguageFormComponent } from "./language-form/language-form.component";
import { LanguageFormGroupNamesDirective } from "./language-form/language-form-group-names.directive";
import { IconButtonModule } from "../icon-button/icon-button.module";

@NgModule({
  imports: [CommonModule, IconButtonModule],
  declarations: [
    FormFieldComponent,
    FormSubmitDirective,
    IfControllEnabledDirective,
    IfControllValidDirective,
    LanguageFormComponent,
    LanguageFormGroupNamesDirective,
  ],
  exports: [
    FormFieldComponent,
    FormSubmitDirective,
    IfControllEnabledDirective,
    IfControllValidDirective,
    LanguageFormComponent,
    LanguageFormGroupNamesDirective,
  ],
})
export class FormExtensionModule {}
