import { NgModule } from "@angular/core";
import { DataTableTagComponent } from "./data-table-tag.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [DataTableTagComponent],
  imports: [CommonModule],
  exports: [DataTableTagComponent],
})
export class DataTableTagModule {}
