import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel";
import {
  DataTableComponent,
  DataTableConfig,
  ROW_DATA,
} from "../../data-table";
import { LoadingService } from "@incert/incert-core";

@Component({
  selector: "incert-data-table-hover-table",
  templateUrl: "./data-table-hover-table.component.html",
})
export class DataTableHoverTableComponent implements OnInit {
  @Input() public dtConfig: DataTableConfig<any> | null;
  @Input() public hoverLabel: string;
  @Input() public helpText: string;
  data: any[];

  @ViewChild(DataTableComponent)
  public dataTable: DataTableComponent;

  constructor(
    @Inject(ROW_DATA) public row_data: any,
    private loadingService: LoadingService,
  ) {}

  async ngOnInit() {
    this.dtConfig = this.setDtConfig(this.row_data);
    // only assign by setter if value wasn't set directly
    if (!this.hoverLabel) {
      this.hoverLabel = this.setHoverLabel(this.row_data);
    }
    if (!this.helpText) {
      this.helpText = this.setHelpText(this.row_data);
    }
  }

  public async fix(event: MouseEvent, op: OverlayPanel) {
    if (op && event) {
      await this.loadingService.load(async () => {
        op.toggle(event);
      });
    }
  }

  public setDtConfig: (row: any) => DataTableConfig<any> | null = () => null;

  public setHoverLabel: (row: any) => string | null = () => null;

  public setHelpText: (row: any) => string | null = () => null;
}
