import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import {
  AccountSystem,
  AccountSystemPropertyMetadata,
} from "../../../core/models/account-system.interface";
import {
  ConfigProperty,
  ConfigValue,
} from "../key-value-panel/key-value.interface";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";

@Injectable({
  providedIn: "root",
})
export class SystemValueManagerService {
  private _dataHasChanged$ = new Subject<Array<AccountSystem>>();

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getSystemConfigMetadata(): Promise<AccountSystemPropertyMetadata> {
    return this.httpService.get<AccountSystemPropertyMetadata>(
      this.settingsService.baseUrl + "accountSystemPropertyValue/meta",
    );
  }

  public getValuesOfPropertiesForAccountSystem(
    property: string,
    accountSystemId: number,
  ) {
    return this.httpService.get<any>(
      this.settingsService.baseUrl +
        "accountSystemPropertyValue?property=" +
        property +
        "&acs=" +
        accountSystemId,
    );
  }

  public getProperties(): Promise<Array<ConfigProperty>> {
    return this.httpService.get<Array<ConfigProperty>>(
      this.settingsService.baseUrl + "properties",
    );
  }

  public getValuesOfProperty(property: string): Promise<Array<ConfigValue>> {
    return this.httpService.get<Array<ConfigValue>>(
      this.settingsService.baseUrl + "properties/" + property + "/values",
    );
  }

  public get dataHasChanged$() {
    return this._dataHasChanged$;
  }

  public getFilterProperties(): Promise<Array<ConfigProperty>> {
    return this.httpService.get<Array<ConfigProperty>>(
      this.settingsService.baseUrl + "account-system-filter/properties",
    );
  }
}
