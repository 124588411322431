import { NgModule } from "@angular/core";
import { UserDetailComponent } from "./user-detail";
import { UserOverviewComponent } from "./user-overview";
import { UserDetailEditComponent } from "./user-detail-edit/user-detail-edit.component";
import { UserDetailCheckboxComponent } from "./user-detail-edit/user-detail-checkbox/user-detail-checkbox.component";
import { UserAddAccountSystemComponent } from "./user-add-account-system/user-add-account-system.component";
import { UserAddComponent } from "./user-add/user-add.component";
import { CustomerListDataTableModule } from "../../core/data-table-components/customer-list-data-table.module";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
    FormExtensionModule,
  ],
  declarations: [
    UserDetailComponent,
    UserOverviewComponent,
    UserDetailEditComponent,
    UserDetailCheckboxComponent,
    UserAddAccountSystemComponent,
    UserAddComponent,
  ],
  exports: [
    UserDetailComponent,
    UserOverviewComponent,
    UserDetailEditComponent,
    UserDetailCheckboxComponent,
  ],
})
export class UserModule {}
