import {
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormGroup,
} from "@angular/forms";
import { FormLanguage } from "./form-language.model";
import { FormUtil } from "../util/form.util";
import {
  LFB_INJECTION_WRAPPER,
  LfbInjectionWrapper,
} from "./lfb-injection-wrapper.token";
import { LanguageFormGroup } from "./language-form-group.class";

@Component({
  selector: "language-form",
  templateUrl: "./language-form.component.html",
})
export class LanguageFormComponent implements OnInit {
  @Input()
  public formGroupName: string;

  @Input()
  set languageId(val: number) {
    this._languageId = Number(val);
  }

  get languageId() {
    return this._languageId ? this._languageId : Number(this.formGroupName);
  }

  get activeLanguageId(): number | null {
    return this.activeLanguage ? Number(this.activeLanguage.id) : null;
  }

  private activeLanguage?: FormLanguage;
  private _formGroup: UntypedFormGroup;
  private _languageId?: number;

  @ViewChild(TemplateRef, { static: true })
  template: TemplateRef<LanguageFormComponent>;

  constructor(
    private controlContainer: ControlContainer,
    @Inject(LFB_INJECTION_WRAPPER) private wrapper: LfbInjectionWrapper,
  ) {}

  ngOnInit() {
    if (this.controlContainer) {
      this._formGroup = this.controlContainer.control as UntypedFormGroup;
    } else {
      throw new Error("can't find controlContainer");
    }
    this.activeLanguage = this.wrapper.languageFormService.activeLanguage;
    this.wrapper.languageFormService.onActiveLanguageChange.subscribe(
      (lang: FormLanguage) => (this.activeLanguage = lang),
    );

    const languageId = Number(this.wrapper.lfb.defaultLanguage.id);
    if (languageId !== this.languageId) {
      if (this._formGroup.parent instanceof LanguageFormGroup) {
        FormUtil.removeRequired(this._formGroup);
      } else {
        this.removeRequiredFromLanguageForm(this._formGroup);
      }
    }
  }

  private removeRequiredFromLanguageForm(control: AbstractControl) {
    if (control instanceof LanguageFormGroup) {
      if (this.languageId in control.controls) {
        FormUtil.removeRequired(control.controls[this.languageId]);
      }
    } else if (control instanceof UntypedFormGroup) {
      for (const key of Object.keys(control.controls)) {
        this.removeRequiredFromLanguageForm(control.controls[key]);
      }
    }
  }
}
