import { InjectionToken } from "@angular/core";
import { incertGUII18n } from "./incert-gui-i18n.const";
export type IncertGUII18n = typeof incertGUII18n;

export const INCERT_GUI_I18N = new InjectionToken<IncertGUII18n>(
  "INCERT_GUI_I18N",
  {
    providedIn: "root",
    factory: () => {
      return incertGUII18n;
    },
  },
);
