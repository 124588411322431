<p-accordion>
  <div *ngFor="let ag of accessGroups">
    <p-accordionTab *ngIf="!ag.parent" [ngClass]="{'p-accordion-tab-non-toggleable': !ag.children}">
      <ng-template pTemplate="header">
        <span class="p-panel-header-label">{{ag.name}}</span>
        <div *ngIf="parentGroups" class="access-profile-add-tags">
          <incert-tag
            *ngIf="aps.isMatchElement(ag, parentGroups)"
            label="vererbt">
          </incert-tag>
        </div>
        <div *ngIf="ag.children" class="access-profile-add-tags">
          <incert-tag
            *ngIf="getActiveChildrenCount(ag)"
            [label]="getActiveChildrenCount(ag).toString()">
          </incert-tag>
        </div>
        <div class="access-profile-add-button">
          <p-inputSwitch
            (onChange)="aps.registerChanges(ag, accessGroupChanges)"
            [ngModel]="hasAccessElement(ag, accessGroupChanges, accessProfile?.accessGroups)"
          ></p-inputSwitch>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="inc-accordion-non-toggle-row" *ngFor="let accessGroupChild of ag.children">
          <span class="p-panel-header-label">{{accessGroupChild.name}}</span>
          <div *ngIf="parentGroups" class="access-profile-add-tags">
            <incert-tag
              *ngIf="aps.isMatchElement(accessGroupChild, parentGroups)"
              label="vererbt">
            </incert-tag>
          </div>
          <div class="access-profile-add-button">
            <p-inputSwitch
              *ngIf="!hasAccessElement(ag, accessGroupChanges, accessProfile?.accessGroups)"
              (onChange)="aps.registerChanges(accessGroupChild, accessGroupChanges)"
              [ngModel]="hasAccessElement(accessGroupChild, accessGroupChanges, accessProfile?.accessGroups)"
              [disabled]="hasAccessElement(ag, accessGroupChanges, accessProfile?.accessGroups)">
            </p-inputSwitch>
            <p-inputSwitch
              *ngIf="hasAccessElement(ag, accessGroupChanges, accessProfile?.accessGroups)"
              [ngModel]="true"
              [disabled]="true">
            </p-inputSwitch>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  </div>
</p-accordion>
