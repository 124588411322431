import { Injector, ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { I18N_CONFIG, I18nConfigInterface } from "./i18n.config.interface";
import { I18nService } from "./i18n.service";
import { I18nPipe } from "./i18n.pipe";

export function i18nFactory(config: I18nConfigInterface, injector: Injector) {
  return new I18nService(injector.get(config.loader));
}

@NgModule({
  imports: [CommonModule],
  declarations: [I18nPipe],
  exports: [I18nPipe],
})
export class I18nModule {
  static forRoot(config: I18nConfigInterface): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        {
          provide: I18N_CONFIG,
          useValue: config,
        },
        {
          provide: I18nService,
          useFactory: i18nFactory,
          deps: [I18N_CONFIG, Injector],
        },
      ],
    };
  }
}
