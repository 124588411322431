import { inject, InjectionToken } from "@angular/core";
import { HttpAccess } from "@incert/incert-core";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "../environment.token";

export const FRONTEND_ENGINE_API_HTTP_ACCESS = new InjectionToken<HttpAccess>(
  "FRONTEND_ENGINE_API_HTTP_ACCESS",
  {
    providedIn: "root",
    factory: () => {
      const httpAccess = new HttpAccess(inject(HttpClient));
      const environment = inject(ENVIRONMENT);
      httpAccess.baseHref = environment.isDevSystem
        ? "https://dev.frontend-engine-api.incertapi.com/"
        : "https://frontend-engine-api.incert.at/";
      return httpAccess;
    },
  },
);
