import { DataConversion, Optional, Required } from "@incert/data-conversion";

@DataConversion()
export class RedemptionOriginRestriction {
  @Required()
  public id: number;
  @Required()
  public incomingInterface?: number;
  @Required()
  public outgoingInterface?: number;
  @Required()
  public manufacturerId?: number;
  @Optional()
  public created: string;
  @Required()
  public inheritSubManufacturer?: boolean;
}
