import { Component, forwardRef, Input, OnDestroy } from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { CurrencyFormatPipe } from "../currency-format-pipe/currency-format.pipe";
import { Subscription } from "rxjs";
import { isNumeric, NotificationService } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";

@Component({
  selector: "incert-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor, OnDestroy {
  private readonly POINT_SEPARATOR = ".";
  private readonly COMMA_SEPARATOR = ",";

  @Input()
  public placeholder = "";

  @Input()
  public decimals: number;

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public set prefix(v: string) {
    this._prefix = v + " ";
  }

  @Input()
  public set postfix(v: string) {
    this._postfix = " " + v;
  }

  @Input()
  public set isCurrency(v: boolean) {
    if (v) {
      this.decimals = 2;
    }
    this._isCurrency = v;
  }

  @Input()
  public set currencyCode(c: string) {
    if (c) {
      this._currencyCode = c;
    }
  }

  @Input()
  allowZeroValue = false;

  private _prefix = "";
  private _postfix = "";
  private _isCurrency = false;
  private _currencyCode = "";
  private subscription: Subscription;

  public fc = new UntypedFormControl();
  private _onChange = (_: any) => {};

  constructor(
    private currencyFormatPipe: CurrencyFormatPipe,
    private notificationService: NotificationService,
    private i18n: I18nService,
  ) {
    this.subscription = this.fc.valueChanges
      .pipe(debounceTime(250))
      .subscribe((v) => {
        if (v) {
          this._onChange(this.validateNumber(v));
        } else {
          this._onChange(undefined);
        }
      });
    this.i18n.load("core/notifications").then((r) => r);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private get regex() {
    let regex = "-?\\d+";
    if (this.decimals !== 0) {
      const decimalPlaces =
        this.decimals !== undefined ? "{1," + this.decimals + "}" : "+";
      regex += "(.\\d" + decimalPlaces + ")?";
    }
    return new RegExp(regex, "gm");
  }

  private validateNumber(v: string): number {
    v = v.toString();
    const separator = this.getSeparator(v);
    let replacingSeparator = this.COMMA_SEPARATOR;
    if (separator === this.COMMA_SEPARATOR) {
      replacingSeparator = "\\" + this.POINT_SEPARATOR;
    }

    v = v.replace(new RegExp(replacingSeparator, "g"), "").replace(/,/g, ".");

    if (isNumeric(v) === false && v !== "") {
      this.notificationService.notifyWarning(
        this.i18n.instant(
          "core.notifications.numberNotification.alphabeticalsAreDeleted",
        ),
        this.i18n.instant(
          "core.notifications.numberNotification.onlyNumbersAllowed",
        ),
        false,
      );
    }

    const match = this.regex.exec(v);
    if (!match) {
      return undefined;
    } else {
      const number = parseFloat(match[0]);
      if (
        (this.min !== undefined && number < this.min) ||
        (this.max !== undefined && number > this.max)
      ) {
        return undefined;
      }
      return number;
    }
  }

  public correctDisplayValue(): void {
    const separator = this.getSeparator(this.fc.value ?? "");
    const number = this.validateNumber(this.fc.value ?? "");

    if (number == undefined) {
      this.fc.patchValue(undefined, { emitEvent: false });
    } else if (number === 0 && !this.allowZeroValue) {
      this.fc.patchValue(undefined, { emitEvent: false });
    } else {
      let displayValue = number
        .toString()
        .replace(this.POINT_SEPARATOR, separator);
      if (this._isCurrency) {
        displayValue = this.currencyFormatPipe.transform(
          number,
          false,
          this._currencyCode,
        );
      }
      this.fc.patchValue(this._prefix + displayValue + this._postfix, {
        emitEvent: false,
      });
    }
  }

  private getSeparator(v: string): string {
    v = v.toString();
    const result = v.match(/[.,]/gm);
    return result ? result.pop() : this.POINT_SEPARATOR;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.fc.patchValue(obj, { emitEvent: false });
    this.correctDisplayValue();
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.fc.disable({ emitEvent: false });
    } else {
      this.fc.enable({ emitEvent: false });
    }
  }
}
