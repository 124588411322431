<div class="loading-wrapper-container" [ngClass]="{'loading': loadingService.loading$|async}">
    <ng-content [ngClass]="{'loading-content':loadingService.loading$|async}"></ng-content>

    <div class="loading-wrapper" *ngIf="loadingService.loading$|async">
        <div class="loading-overlay"></div>
        <div class="loading-indicator-wrapper">
            <div class="loading-indicator"></div>
        </div>
    </div>
</div>
