import { Component, OnInit } from "@angular/core";
import { IconType } from "@incert/incert-core";

export interface Tool {
  name: string;
  icon: keyof typeof IconType;
  route: string;
}

@Component({
  selector: "incert-internal-tools-tools-overview",
  templateUrl: "./tools-overview.component.html",
  styleUrls: ["./tools-overview.component.css"],
})
export class ToolsOverviewComponent implements OnInit {
  public toolConfig: Array<Tool>;

  constructor() {}

  ngOnInit(): void {
    this.toolConfig = [
      {
        name: "Wissensnotizen",
        icon: "shoppingcart",
        route: "memo-overview",
      },
      {
        name: "Notifications",
        icon: "shoppingcart",
        route: "notification-overview",
      },
      {
        name: "Systemwerte",
        icon: "shoppingcart",
        route: "key-value-overview",
      },
    ];
  }
}
