import { InjectionToken, Injector } from "@angular/core";
import { formExtensionI18n } from "./form-extension-i18n.const";

export type FormExtensionI18n = typeof formExtensionI18n;

export const FORM_EXTENSION_I18N = new InjectionToken<FormExtensionI18n>(
  "FORM_EXTENSION_I18N",
  {
    providedIn: "root",
    factory: () => formExtensionI18n,
  },
);
