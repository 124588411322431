import { Component, Inject, Input, OnInit } from "@angular/core";
import { IconType } from "@incert/incert-core";
import { ROW_DATA } from "@incert/incert-gui";

export enum LinkDisplayOptions {
  onlyIcon,
  onlyText,
  standard,
}

@Component({
  selector: "incert-link-icon",
  templateUrl: "./data-table-link.component.html",
  styleUrls: ["./data-table-link.component.scss"],
})
export class DataTableLinkComponent implements OnInit {
  @Input() public icon: keyof typeof IconType;
  @Input() public displayStyle: LinkDisplayOptions;
  @Input() public uri: string[] = [];
  @Input() public hidden: boolean;

  public resolveRowData: (row: any) => any = () => "";

  constructor(@Inject(ROW_DATA) private column_data: any) {}

  ngOnInit() {
    this.validate(this.resolveRowData(this.column_data));
    this.filterLinks();
  }

  public validate(data: string[] | string): void {
    if (data) {
      if (!Array.isArray(data)) {
        this.uri.push(data);
      } else {
        this.uri = data;
      }
    }
  }

  public filterLinks(): void {
    this.uri = this.uri.filter((el: string) => el !== null);
  }

  public get LinkDisplayOptions() {
    return LinkDisplayOptions;
  }
}
