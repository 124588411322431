<div class="col">
  <div class="col p-m-3">
        <div class="ui-widget-header p-d-inline-flex col-12 p-p-0">
          <div class="ui-inputgroup p-justify-center col-11">
          </div>
          <div class="col-1 p-d-inline-flex">
          </div>
        </div>

        <data-table *ngIf="historyDataConfig" [config]="historyDataConfig"></data-table>
  </div>
</div>
