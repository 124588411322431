import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { allowEmpty } from "../allow-empty.function";

export class NumberValidators {
  static ensure(decimals: number = null): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      const validationErrors: ValidationErrors = {};
      const value = Number(control.value);
      if (isNaN(value) || !isFinite(value)) {
        validationErrors["numberEnsure"] = {};
        return validationErrors;
      }

      if (decimals !== null) {
        if (NumberValidators.countDecimals(control.value) > decimals) {
          if (decimals === 0) {
            validationErrors["numberNoDecimal"] = {};
          } else {
            validationErrors["numberMaxDecimals"] = { decimals: decimals };
          }
          return validationErrors;
        }
      }

      return null;
    });
  }

  public static between(
    min: number,
    max: number,
    decimals: number = null,
  ): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      const ensureFn = NumberValidators.ensure(decimals);
      let validationErrors: ValidationErrors = ensureFn(control);

      if (validationErrors !== null) {
        return validationErrors;
      } else {
        validationErrors = {};
      }

      if (control.value < min || control.value > max) {
        validationErrors["numberBetween"] = { min: min, max: max };
        return validationErrors;
      }
      return null;
    });
  }

  public static min(min: number, decimals: number = null): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      const ensureFn = NumberValidators.ensure(decimals);
      let validationErrors: ValidationErrors = ensureFn(control);

      if (validationErrors !== null) {
        return validationErrors;
      } else {
        validationErrors = {};
      }
      if (control.value < min) {
        validationErrors["numberMin"] = { min: min };
        return validationErrors;
      }
      return null;
    });
  }

  public static max(
    max: number | (() => number),
    decimals: number = null,
  ): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      const ensureFn = NumberValidators.ensure(decimals);
      let validationErrors: ValidationErrors = ensureFn(control);

      let value = max;
      if (typeof max === "function") {
        value = max();
      }
      if (validationErrors !== null) {
        return validationErrors;
      } else {
        validationErrors = {};
      }
      if (control.value > value) {
        validationErrors["numberMax"] = { max: value };
        return validationErrors;
      }
      return null;
    });
  }

  public static lessThan(control2: AbstractControl): ValidatorFn {
    return allowEmpty((control: AbstractControl) => {
      if (parseInt(control.value, 10) >= parseInt(control2.value, 10)) {
        console.log(control2.value);
        return {
          numberLessThan: { number: control2.value },
        };
      }
      return null;
    });
  }

  private static countDecimals(value: number) {
    let decimals;
    if ((decimals = value.toString().split(".")).length === 2) {
      return decimals[1].length;
    }
    return 0;
  }
}
