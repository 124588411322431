import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BarcodeScannerService } from "../barcode-scanner.service";

@Component({
  selector: "incert-barcode-scanner-button",
  templateUrl: "./barcode-scanner-button.component.html",
  styleUrls: ["./barcode-scanner-button.component.css"],
})
export class BarcodeScannerButtonComponent implements OnInit {
  public scannerAvailable;

  @Output()
  public code = new EventEmitter<string>();

  constructor(private barcodeScannerService: BarcodeScannerService) {}

  async ngOnInit() {
    this.scannerAvailable =
      await this.barcodeScannerService.isScannerAvailable();
  }
  async scan() {
    const result = await this.barcodeScannerService.scan();
    if (result) {
      this.code.emit(result);
    }
  }
}
