import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberFormatPipe } from "./number-format.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [NumberFormatPipe],
  exports: [NumberFormatPipe],
  providers: [NumberFormatPipe],
})
export class NumberFormatPipeModule {}
