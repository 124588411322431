import { Component } from "@angular/core";
import { ArrayDataSource, DataTableConfig } from "@incert/incert-gui";
import {Server} from "../../../../core/models/server.model";
import {AccountSystem} from "../../../../core/models/account-system.interface";
import {AccountSystemManager} from "../../account-system-manager.service";

@Component({
  selector: "account-system-server",
  templateUrl: "./account-system-server.component.html",
  styleUrls: ["./account-system-server.component.css"],
})
export class AccountSystemServerComponent {
  constructor(private accountSystemManager: AccountSystemManager) {}

  servers: Server[];
  accountSystem: AccountSystem;
  dtConfig: DataTableConfig<Server>;

  async ngOnInit() {
    this.servers = await this.accountSystemManager.getAccountSystemServers(this.accountSystem);
    this.createDtConfig();
  }

  createDtConfig() {
    this.dtConfig = {
      data: new ArrayDataSource(this.servers),
      rows: 50,
      columns: [
        {
          header: "Portal Name",
          property: (v) => v.get("portalName"),
        },
        {
          header: "Cluster",
          property: (v) => v.get("cluster"),
        },
        {
          header: "Typ",
          property: (v) => v.get("type"),
        },
        {
          header: "Prefix",
          property: (v) => v.get("prefix"),
        },
        {
          header: "IP lokal",
          property: (v) => v.get("localIpAddress"),
        },
        {
          header: "IP öffentlich",
          property: (v) => v.get("publicIpAddress"),
        },
        {
          header: "Hostname",
          property: (v) => v.get("hostname"),
        },
      ],
    };
  }
}
