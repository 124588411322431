import { Component, Input, OnInit, OnDestroy, forwardRef } from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { I18nService } from "@incert/i18n";

@Component({
  selector: "grouped-multi-select",
  templateUrl: "./grouped-multi-select.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupedMultiSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GroupedMultiSelectComponent),
      multi: true,
    },
  ],
})
export class GroupedMultiSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  private subscription: Subscription;

  private _onChange: (_: any) => void;
  private _onTouched: (_: any) => void;

  private _selectedOptions: any[] = [];
  private _touched = false;

  @Input()
  public placeholder: string = this.i18n.instant(
    "core.multiSelect.defaultLabel",
  );

  @Input()
  public options: any;

  public fc = new UntypedFormControl([]);

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private i18n: I18nService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.fc.valueChanges.subscribe((v) => {
      this._selectedOptions = v;
      this.change();
    });

    const formDefinition = {};
    for (const group of this.options) {
      formDefinition[group.value] = [false];
    }
    this.form = this.formBuilder.group(formDefinition);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  writeValue(arr: any[]): void {
    if (Array.isArray(arr)) {
      this._selectedOptions = arr;
      this.fc.setValue(arr, { emitEvent: false });
    }
  }

  onGroupToggle(arr: any[], mode: boolean) {
    this.markAsTouched();
    arr.forEach((element) => {
      if (
        mode &&
        !this._selectedOptions.find((value) => value === element.value)
      ) {
        this._selectedOptions.push(element.value);
      } else if (
        !mode &&
        this._selectedOptions.find((value) => value === element.value)
      ) {
        this._selectedOptions.splice(
          this._selectedOptions.findIndex((value) => value === element.value),
          1,
        );
      }
    });

    this.writeValue(this._selectedOptions);
    this.change();
  }

  private change() {
    if (this._onChange) {
      this._onChange(this._selectedOptions);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.fc.disable();
      this.form.disable();
    } else {
      this.fc.enable();
      this.form.enable();
    }
  }

  markAsTouched() {
    if (!this._touched) {
      if (this._onTouched) this._onTouched(this._touched);
      this._touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  checkGroupCheckboxes() {
    for (const option of this.options) {
      if (
        option.items.every((item) => this._selectedOptions.includes(item.value))
      ) {
        this.form.controls[option.value].setValue(true);
      } else {
        this.form.controls[option.value].setValue(false);
      }
    }
  }
}
