import { AbstractControl, ValidationErrors } from "@angular/forms";

export function ensureJson() {
  return (control: AbstractControl) => {
    const validationErrors: ValidationErrors = {};
    try {
      JSON.parse(control.value);
    } catch (e) {
      validationErrors["jsonEnsure"] = { jsonInvalid: true };
      return validationErrors;
    }
    return null;
  };
}
