import { Utils } from "./utils";
import { SelectItem } from "primeng/api";

export class Constants {
  static countryOptions(): SelectItem[] {
    return Utils.mapIdToSelectItem([
      "International",
      "Deutschland",
      "Österreich",
      "Schweiz",
      "Italien",
    ]);
  }

  static languageOptions(): SelectItem[] {
    return [
      { label: "Deutsch", value: "de" },
      { label: "Englisch", value: "en" },
    ];
  }

  static currencyOptions(): SelectItem[] {
    return Utils.mapIdToSelectItem(["EUR", "CHF"]);
  }
}
