import { NgModule } from "@angular/core";
import { I18nModule } from "@incert/i18n";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { StepsModule } from "primeng/steps";
import { WizardComponent } from "./wizard.component";
import { WizardStepComponent } from "./wizard-step/wizard-step.component";
import { WizardNavButtonsComponent } from "./wizard-nav-buttons/wizard-nav-buttons.component";
import { WizardSummaryComponent } from "./wizard-summary/wizard-summary.component";
import { WizardSummaryStepComponent } from "./wizard-summary/wizard-summary-step/wizard-summary-step.component";
import { WizardAddVoucherComponent } from "./wizard-sub-components/wizard-add-voucher/wizard-add-voucher.component";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormExtensionModule } from "../form-extension";
import { TabViewModule } from "primeng/tabview";
import { InputSwitchModule } from "primeng/inputswitch";
import { MultiSelectModule } from "primeng/multiselect";

@NgModule({
  declarations: [
    WizardComponent,
    WizardStepComponent,
    WizardNavButtonsComponent,
    WizardSummaryComponent,
    WizardSummaryStepComponent,
    WizardAddVoucherComponent,
  ],
  exports: [
    WizardComponent,
    WizardStepComponent,
    WizardNavButtonsComponent,
    WizardSummaryComponent,
    WizardSummaryStepComponent,
    WizardAddVoucherComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    StepsModule,
    ButtonModule,
    I18nModule,
    DropdownModule,
    FormExtensionModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    InputSwitchModule,
    MultiSelectModule,
  ],
})
export class WizardModule {}
