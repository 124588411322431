import { NgModule } from "@angular/core";
import { ReportComponent } from "./report.component";
import { ReportUpsertComponent } from "./report-upsert/report-upsert.component";
import {
  DataTableModule,
  FormExtensionModule,
  IconButtonModule,
} from "@incert/incert-gui";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { MultiSelectModule } from "primeng/multiselect";
import { NgForOf, NgIf } from "@angular/common";
import { PaginatorModule } from "primeng/paginator";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";

@NgModule({
  imports: [
    DataTableModule,
    DropdownModule,
    FormExtensionModule,
    IconButtonModule,
    InputSwitchModule,
    MultiSelectModule,
    NgForOf,
    PaginatorModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    NgIf,
  ],
  declarations: [ReportComponent, ReportUpsertComponent],
  exports: [ReportComponent],
})
export class ReportModule {}
