<div *ngIf="visible" class="wizard-summary-block">
  <div class="wizard-summary-headline">
    <h3 *ngIf="headline">{{headline}}</h3>
    <h6 *ngIf="subHeadline">{{subHeadline}}</h6>
    <!--<incert-language-item langId="en" small="true"></incert-language-item>-->
  </div>
  <div class="wizard-summary-block-content" (click)="onSummaryStepClick()" [ngClass]="{'wizard-summary-block-clickable': isClickable()}">
    <div *ngIf="contentText" [innerHTML]="contentText"></div>
    <ng-content select="[content]"></ng-content>
  </div>
</div>
