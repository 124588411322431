import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "../../icon/icon.module";
import { BrowserModule } from "@angular/platform-browser";
import { DataTableToggleButtonComponent } from "./data-table-toggle-button.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToggleButtonModule } from "primeng/togglebutton";
import { InputSwitchModule } from "primeng/inputswitch";

@NgModule({
  declarations: [DataTableToggleButtonComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IconModule,
    ToggleButtonModule,
    FormsModule,
    InputSwitchModule,
    ReactiveFormsModule,
  ],
  exports: [DataTableToggleButtonComponent],
})
export class DataTableToggleButtonModule {}
