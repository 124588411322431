import { Component, Input, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";

export interface MenuItemNestedList extends MenuItem {
  contextMenu?: MenuItem[];
}

@Component({
  selector: "incert-nested-list",
  templateUrl: "./nested-list.component.html",
  styleUrls: ["./nested-list.component.scss"],
})
export class NestedListComponent implements OnInit {
  public selectedMenuItem: { vi: number; hi: number } = { vi: null, hi: 0 };

  @Input()
  menuItems: MenuItemNestedList[];

  @Input()
  index = 0;

  constructor() {}

  public selectedMenuItemIndex(value: number, object: MenuItemNestedList) {
    object.command(null);
    this.selectedMenuItem.vi = value;
    object.expanded = !object.expanded;
    this.menuItems.map((i) => {
      if (i !== object) i.expanded = false;
    });
  }

  ngOnInit(): void {
    this.selectedMenuItem.hi = this.index;
  }
}
