declare const Quill: any;

let delta = null;

function useDelta() {
  if (!delta) {
    delta = Quill.import("delta");
  }
  return delta;
}

/**
 * converts normal characters that were treated with modifiers into special characters
 * Example: MS-Word does not use UMLAUTs but instead takes a normal character and adds a combining diaeresis modifier.
 * @param str string containing the html
 * @returns string
 */
function convertModifying(str: any) {
  if (typeof str !== "string") return str;
  // all char codes taken from <http://www.fileformat.info/info/charset/UTF-8/list.htm>
  let dict;
  // umlaut dictionary
  dict = {
    A: "Ä",
    E: "Ë",
    I: "Ï",
    O: "Ö",
    U: "Ü",
    a: "ä",
    e: "ë",
    i: "ï",
    o: "ö",
    u: "ü",
    y: "ÿ",
    Y: "Ÿ",
  };
  str = str.replace(/[A-z]\u0308/g, function (m) {
    return dict[m[0]] || m[0];
  });
  // accent dictionary
  dict = {
    A: "Á",
    E: "É",
    I: "Í",
    O: "Ó",
    U: "Ú",
    Y: "Ý",
    a: "á",
    e: "é",
    i: "í",
    o: "ó",
    u: "ú",
    y: "ý",
    C: "Ć",
    c: "ć",
    L: "Ĺ",
    l: "ĺ",
    N: "Ń",
    n: "ń",
    R: "Ŕ",
    r: "ŕ",
    S: "Ś",
    s: "ś",
    Z: "Ź",
    z: "ź",
    G: "Ǵ",
    g: "ǵ",
  };
  str = str.replace(/[A-z]\u0301/g, function (m) {
    return dict[m[0]] || m[0];
  });
  return str;
}

function matchMsWordList(node, delta: any): any {
  let newDelta;
  try {
    // Clone the operations
    const ops = delta.ops.map((op) => Object.assign({}, op));

    // Trim the front of the first op to remove the bullet/number
    const bulletOp = ops.find((op) => op.insert && op.insert.trim().length);
    if (!bulletOp) newDelta = delta;

    bulletOp.insert = bulletOp.insert.trimLeft();
    const listPrefix =
      bulletOp.insert.match(/^.*?(^·|\.)/) || bulletOp.insert[0];
    bulletOp.insert = convertModifying(
      bulletOp.insert.substring(listPrefix[0].length, bulletOp.insert.length),
    );

    // Trim the newline off the last op
    const last = ops[ops.length - 1];
    last.insert = last.insert.substring(0, last.insert.length - 1);

    // Determine the list type
    const listType = listPrefix[0].length === 1 ? "bullet" : "ordered";

    // Determine the list indent
    const style = node.getAttribute("style").replace(/\n+/g, "");
    const levelMatch = style.match(/level(\d+)/);
    const indent = levelMatch ? levelMatch[1] - 1 : 0;

    // Add the list attribute
    ops.push({ insert: "\n", attributes: { list: listType, indent } });

    newDelta = useDelta()(ops);
  } catch (e) {
    console.error(e);
  }
  return newDelta ?? delta;
}

function matchMsTitle(node, delta): any {
  try {
    if (delta && delta.hasOwnProperty("ops")) {
      // add the attribute header-1 and all other style attributes to each operation
      return {
        ops: delta.ops.map((op) => {
          if (op.hasOwnProperty("attributes")) {
            op.attributes = { ...op.attributes, header: 1 };
          } else {
            op.attributes = { header: 1 };
          }
          return op;
        }),
      };
    }
  } catch (e) {
    console.error(e);
  }
  return delta;
}

function maybeMatchMsWordList(node, delta) {
  let newDelta;
  try {
    if (delta.ops[0].insert.trimLeft()[0] === "·") {
      newDelta = matchMsWordList(node, delta);
    }
  } catch (e) {
    console.error(e);
  }
  return newDelta ?? delta;
}

/**
 * All lists use <ol> instead of both <ul> and <ol>.
 * ref: https://github.com/quilljs/quill/blob/develop/docs/guides/upgrading-to-2-0.md
 *
 * This function converts all bullet lists back to an <ul>.
 * @param str
 */
function convertBulletToUnorderedList(str) {
  if (str && typeof str === "string" && str.length) {
    const el = document.createElement("div");
    el.innerHTML = str;
    const orderedLists = el.querySelectorAll("ol");

    if (orderedLists) {
      orderedLists.forEach((ol) => {
        const firstLiElement = ol.querySelectorAll("li")[0];

        if (firstLiElement) {
          // check the first list HTML element
          const isUnorderedListEl =
            firstLiElement.hasAttribute("data-list") &&
            firstLiElement.getAttribute("data-list") === "bullet";

          if (isUnorderedListEl) {
            // replace <ol> with <ul> HTML element
            const ul = document.createElement("ul");
            ul.innerHTML = ol.innerHTML;
            ol.parentNode.replaceChild(ul, ol);
          }
        }
      });
    }
    return el.innerHTML;
  }
  return "";
}

/**
 * Removes the leading &lt;p&gt;&lt;br&gt;&lt;/p&gt; tag from the given string, if present.
 * Issue: https://incert-jira.atlassian.net/browse/BACKENDGMS-1116
 */
function removeLeadingEmptyParagraphTag(str) {
  if (str && typeof str === "string") {
    const regex = /^<p><br><\/p>/;
    return str.replace(regex, "");
  }
  return str;
}

function validateQuillEditorValue(value: string) {
  value = convertBulletToUnorderedList(value);
  value = removeLeadingEmptyParagraphTag(value);
  return value;
}

export {
  convertBulletToUnorderedList,
  removeLeadingEmptyParagraphTag,
  validateQuillEditorValue,
  maybeMatchMsWordList,
  convertModifying,
  matchMsWordList,
  matchMsTitle,
};
