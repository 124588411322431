import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpAccessInterface } from "./http-access.interface";
import { DataConverter, ObjectOrArrayType } from "@incert/data-conversion";
import { DateUtil } from "../../utils/date.util";
import { isObject } from "../../functions";

@Injectable()
export class HttpAccess implements HttpAccessInterface {
  public normalizeTimeZoneOffset = true;
  private _baseHref = "";

  public constructor(protected httpClient: HttpClient) {}

  set baseHref(value: string) {
    this._baseHref = value;
  }

  public async get(url: string): Promise<any>;
  public async get<T>(url: string, type: ObjectOrArrayType<T>): Promise<T>;
  public async get<T extends any>(
    url: string,
    type?: ObjectOrArrayType<T>,
  ): Promise<any> {
    {
      if (type) {
        return await DataConverter.convert<T>(type, await this.get(url));
      } else {
        return this.httpClient.get(this.resolveUrl(url)).toPromise();
      }
    }
  }

  public async post(url: string, payload: any): Promise<any>;
  public async post<T extends any>(
    url: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  public async post<T extends any>(
    url: string,
    payload: any = null,
    type?: ObjectOrArrayType<T>,
  ): Promise<any> {
    if (this.normalizeTimeZoneOffset) {
      payload = this.normalize(payload);
    }

    if (type) {
      return await DataConverter.convert<T>(
        type,
        await this.post(url, payload),
      );
    } else {
      return this.httpClient.post(this.resolveUrl(url), payload).toPromise();
    }
  }

  public async delete(url: string): Promise<any>;
  public async delete<T extends any>(
    url: string,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  public async delete<T extends any>(
    url: string,
    type?: ObjectOrArrayType<T>,
  ): Promise<any> {
    {
      if (type) {
        return await DataConverter.convert<T>(type, await this.delete(url));
      } else {
        return this.httpClient.delete(this.resolveUrl(url)).toPromise();
      }
    }
  }

  public async put(url: string, payload: any): Promise<any>;
  public async put<T extends any>(
    url: string,
    payload: any,
    type: ObjectOrArrayType<T>,
  ): Promise<T>;
  public async put<T extends any>(
    url: string,
    payload: any = null,
    type?: ObjectOrArrayType<T>,
  ): Promise<any> {
    if (this.normalizeTimeZoneOffset) {
      payload = this.normalize(payload);
    }

    if (type) {
      return await DataConverter.convert<T>(type, await this.put(url, payload));
    } else {
      return this.httpClient.put(this.resolveUrl(url), payload).toPromise();
    }
  }

  private normalize(payload: any) {
    if (payload instanceof FormData) {
      return payload;
    }
    if (payload instanceof Date) {
      return DateUtil.normalizeTimeZoneOffset(payload);
    }
    if (Array.isArray(payload)) {
      return payload.map((item) => this.normalize(item));
    }
    if (isObject(payload)) {
      payload = { ...payload };
      for (const i of Object.keys(payload)) {
        payload[i] = this.normalize(payload[i]);
      }
    }
    return payload;
  }

  private resolveUrl(url: string) {
    return this._baseHref + url;
  }
}
