import { Component, Input, OnInit } from "@angular/core";
import { SlideContent } from "../slide-content";

@Component({
  selector: "incert-default-accordion",
  templateUrl: "./default-accordion.component.html",
})
export class DefaultAccordionComponent implements OnInit {
  @Input()
  public accordionContent: SlideContent[];

  constructor() {}

  ngOnInit() {}
}
