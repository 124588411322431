import { Inject, Pipe, PipeTransform } from "@angular/core";
import { Iso8601Converter } from "./iso8601-converter";
import { DatePipe } from "@angular/common";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

type TransformArguments = {
  includeSeconds?: boolean;
};

@Pipe({
  name: "dateTimeFormat",
})
export class DateTimeFormatPipe implements PipeTransform {
  public constructor(
    private datePipe: DatePipe,
    @Inject(INCERT_GUI_I18N) private config: IncertGUII18n,
  ) {}

  transform(value: any, args?: TransformArguments): any {
    try {
      const format = Iso8601Converter.toDatePipeFormat(
        this.config.dateFormat +
          " " +
          (args && args.includeSeconds
            ? this.config.timeFormatIncludeSeconds
            : this.config.timeFormat),
      );
      return this.datePipe.transform(value, format);
    } catch (e) {
      return String(value);
    }
  }
}
