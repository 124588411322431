import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GoBackActionComponent } from "./go-back-action.component";
import { PageModule } from "../page/page.module";

@NgModule({
  imports: [CommonModule, PageModule],
  exports: [GoBackActionComponent],
  declarations: [GoBackActionComponent],
})
export class GoBackActionModule {}
