<form-field [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <p-autoComplete [suggestions]="autocompleteSuggestions"
                  (completeMethod)="handleSearch($event)"
                  [delay]="config.searchDelay"
                  [dropdown]="config.dropdownEnabled"
                  [multiple]="config.multipleEnabled"
                  [formControl]="form.get('filter')"
                  [showClear]="!!form?.get('filter')?.value?.label"
                  (onClear)="handleUnselect()"
                  (onSelect)="filterExplicit()"
                  [forceSelection]="!form.get('filter').value && config.forceSelection"
                  [placeholder]="floatingLabel ? '' : config.placeholder"
                  appendTo="body"
                  field="label">
  </p-autoComplete>
</form-field>
