import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { I18nService } from "./i18n.service";

@Injectable()
@Pipe({
  name: "i18n",
  pure: false,
})
export class I18nPipe implements PipeTransform {
  public constructor(private i18n: I18nService) {}

  transform(value: any, ...args: any[]): any {
    return this.i18n.instant(value, args[0] ? args[0] : undefined);
  }
}
