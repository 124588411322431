import { Component, OnInit } from "@angular/core";
import { SystemValueManagerService } from "../tools/system-value/system-value.manager.service";

@Component({
  selector: "incert-internal-tools-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  public systemValues: any[] = [];
  public activeSystemValues: string[] = [];
  public specialFilter: boolean = null;

  constructor(private systemValueManagerService: SystemValueManagerService) {}

  async ngOnInit() {
    this.systemValues = (
      await this.systemValueManagerService.getProperties()
    ).map((p) => p.name);
  }
}
