import { Component, Inject, Input, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table";

@Component({
  selector: "incert-data-table-toggle-button",
  templateUrl: "./data-table-toggle-button.component.html",
  styleUrls: ["./data-table-toggle-button.component.css"],
})
export class DataTableToggleButtonComponent implements OnInit {
  @Input() public checked = false;
  @Input() public disabled = false;
  @Input() public onShow: (row: any) => boolean = () => true;
  @Input() public onClick: (row: any, checked: boolean) => any = () => null;

  constructor(@Inject(ROW_DATA) public row_data: any) {}

  public show(): boolean {
    if (!this.onShow) {
      return true;
    }
    return this.onShow(this.row_data);
  }

  public isChecked: (row: any) => any = () => "";
  public isDisabled: (row: any) => any = () => "";

  ngOnInit(): void {
    this.checked = !!this.isChecked(this.row_data);
    this.disabled = !!this.isDisabled(this.row_data);
  }
}
