import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const validationMessageKey = "validationMessage";

let idCount = 0;

export function validationMessage(msg: string, vFn: ValidatorFn): ValidatorFn {
  const validatorIdentifier = idCount++;

  return (control: AbstractControl) => {
    const result = vFn(control);
    if (result) {
      const validationErrors: ValidationErrors = {};
      validationErrors[validationMessageKey + validatorIdentifier] = msg;
      return validationErrors;
    } else {
      return null;
    }
  };
}
