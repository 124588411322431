import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

export interface InputConfig {
  name: string;
  title: string;
  description?: string;
  placeholder?: string;
  initialValue: string;
  control: UntypedFormControl;
  group?: UntypedFormGroup;
  options: InputConfigOptions;
  groupId?: number;
  subShopId?: number;
}

export interface InputConfigOptions {
  displayType: InputDisplayType;
  emptyAllowed?: string;
  emptyValue?: string;
  items?: [];
  rawData?: any;
  separator?: "";
}

export type InputDisplayType =
  | "text"
  | "string"
  | "multiselect"
  | "expressfonts"
  | "stripe_webhooks"
  | "stripe_connect_handles"
  | "color"
  | "shipping_rate"
  | "keyfile"
  | "radio"
  | "switch"
  | "pulldown"
  | "textarea"
  | "multiadd"
  | "years_days"
  | "languageinput"
  | "checkbox"
  | "ip_list"
  | "template_snippets"
  | "link_button"
  | "code";
