import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IncSliderComponent } from "./inc-slider.component";
import { SliderModule } from "primeng/slider";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { I18nModule } from "@incert/i18n";

@NgModule({
  declarations: [IncSliderComponent],
  imports: [
    CommonModule,
    SliderModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
  ],
  exports: [IncSliderComponent],
})
export class IncSliderModule {}
