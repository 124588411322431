<p-messages [closable]="closable" [severity]="status" *ngIf="!closed"
            [styleClass]="{ 'alert-message-custom-color': backgroundColor, 'alert-message-dark-color': backgroundColor && darkFont }"
            [style]="{ background: backgroundColor }">
  <ng-template pTemplate>
    <span class="p-inline-message-icon"></span>
    <div class="alert-message" >
      <strong *ngIf="header">{{header}}</strong>
      <div [innerHTML]="content"></div>
      <div *ngIf="button">
        <p-button [label]="button.label" (click)="button.onClick()" styleClass="reset-button-to-link"></p-button>
      </div>
    </div>
    <icon-button iconOnly="true" iconType="close" (click)="close()" class="alert-message-close" *ngIf="closable"></icon-button>
  </ng-template>
</p-messages>

