import { NgModule } from "@angular/core";
import { RoleOverviewComponent } from "./role-overview/role-overview.component";
import { RoleConfirmDialogComponent } from "./role-confirm-dialog/role-confirm-dialog.component";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  declarations: [RoleOverviewComponent, RoleConfirmDialogComponent],
  imports: [
    CustomerListPrimeModule,
    CustomerListSharedModule,
    FormExtensionModule,
  ],
  exports: [RoleOverviewComponent, RoleConfirmDialogComponent],
})
export class RoleModule {}
