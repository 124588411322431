<form [formGroup]="form">
  <p *ngIf="!isTicket">{{'core.wizard.productSelection.introText' | i18n}}</p>
  <p *ngIf="isTicket">{{'core.wizard.productSelection.ticket.introText' | i18n}}</p>
  <div class="grid wizard-add-voucher-row">
    <div *ngIf="!onlyExisting" class="col-5">
      <h4>{{'core.wizard.productSelection.createNew' | i18n}}</h4>
      <form-field *ngIf="types.length" [label]="'core.wizard.productSelection.chooseType' | i18n">
        <p-dropdown
          placeholder="{{'core.wizard.productSelection.chooseType' | i18n}}"
          [showClear]="true"
          [options]="types"
          optionValue="id"
          optionLabel="name"
          formControlName="type">
        </p-dropdown>
      </form-field>
      <p-button *ngIf="!types.length"
                [label]="(isTicket ? 'core.wizard.productSelection.ticket.create' : 'core.wizard.productSelection.create') | i18n"
                (onClick)="onNew.emit()"></p-button>
    </div>

    <div *ngIf="!onlyExisting" class="col-2">
        {{'core.wizard.productSelection.or' | i18n}}
    </div>

    <div class="col-5">
      <h4 *ngIf="!isTicket">{{'core.wizard.productSelection.useExisting' | i18n}}</h4>
      <h4 *ngIf="isTicket">{{'core.wizard.productSelection.ticket.useExisting' | i18n}}</h4>
      <form-field [label]="(isTicket ? 'core.wizard.productSelection.ticket.choose' : 'core.wizard.productSelection.choose') | i18n">
        <p-multiSelect
          pMultiSelectI18n
          [placeholder]="(isTicket ? 'core.wizard.productSelection.ticket.choose' : 'core.wizard.productSelection.choose') | i18n"
          [showClear]="true"
          [filter]="true"
          [options]="products"
          optionValue="id"
          optionLabel="name"
          formControlName="product">
        </p-multiSelect>
      </form-field>
    </div>
  </div>
</form>
