import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OverlayOutletComponent } from "./overlay-outlet.component";
import { ConfirmTemplateComponent } from "./confirm-template.component";
import { IconButtonModule } from "../../icon-button/icon-button.module";
import { OverlayContentHostComponent } from "./overlay-content-host.component";
import { DialogModule } from "primeng/dialog";

@NgModule({
  imports: [CommonModule, DialogModule, IconButtonModule],
  declarations: [
    OverlayOutletComponent,
    ConfirmTemplateComponent,
    OverlayContentHostComponent,
  ],
  exports: [OverlayOutletComponent],
})
export class OverlayOutletModule {}
