import { Component, Input } from "@angular/core";
import { Tag } from "@incert/gms-core";
import { IncertTagComponent } from "../tag.component";

@Component({
  selector: "incert-tags",
  templateUrl: "./tags.component.html",
})
export class TagsComponent extends IncertTagComponent {
  @Input() public tags: Tag[] = [];
}
