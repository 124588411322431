import { Component, Inject, OnInit } from "@angular/core";
import { OverlayService, OverlaySize } from "@incert/incert-core";
import { DataTableShowCommentOverlayComponent } from "../data-table-show-comment-overlay/data-table-show-comment-overlay.component";
import { ROW_DATA } from "@incert/incert-gui";
import { User } from "../../../pages/user";
import { Utils } from "../../utils";

export interface CommentAreaConfig<T> {
  header: string;
  text: string | (keyof T & string);
  maxLengthWrap: number;
  createdAt?: string;
  createdBy?: User;
}

@Component({
  selector: "incert-internal-tools-data-table-comment-area",
  templateUrl: "./data-table-comment-area.component.html",
  styleUrls: ["./data-table-comment-area.component.scss"],
})
export class DataTableCommentAreaComponent<T> implements OnInit {
  public config: CommentAreaConfig<T>;
  public setTextAreaData: (row: T) => CommentAreaConfig<any> = () => null;

  shortenedComment = "";

  constructor(
    @Inject(ROW_DATA) private column_data: any,
    private overlayService: OverlayService,
  ) {}

  ngOnInit(): void {
    this.config = this.setTextAreaData(this.column_data);
  }

  public getComment() {
    const p = this.config.text.replace(/<p>/g, "<div>");
    this.shortenedComment = p.replace(/<\/p>/g, "</div>");
    return this.shortenedComment.slice(0, this.config.maxLengthWrap);
  }

  public async showCommentOverlay() {
    await this.overlayService.show<DataTableShowCommentOverlayComponent>({
      header: this.getMemoCommentText(),
      type: DataTableShowCommentOverlayComponent,
      size: OverlaySize.medium,
      init: (c) => {
        c.comment = this.shortenedComment;
      },
      actions: [
        {
          displayAsLink: true,
          label: "Schließen",
          action: () => true,
        },
      ],
    });
  }

  public getMemoCommentText() {
    if (this.config.createdAt && this.config.createdBy) {
      return (
        this.config.createdBy.firstName +
        " " +
        this.config.createdBy.lastName +
        " - " +
        Utils.showLastChanged(this.config.createdAt) +
        ""
      );
    } else {
      return null;
    }
  }
}
