export function buildQueryString(params: any) {
  const buildParams = [];
  for (const key of Object.keys(params)) {
    const value = params[key];
    if (value !== null && value !== undefined) {
      if (value instanceof Array) {
        for (const entry of value) {
          buildParams.push(getUrlParam(key + "[]", entry));
        }
      } else {
        buildParams.push(getUrlParam(key, value));
      }
    }
  }
  return buildParams.join("&");
}

function getUrlParam(key: string, value: any) {
  let urlParam = escape(key) + "=";
  if (typeof value === "object" && value instanceof Date) {
    urlParam += escape(value.toISOString());
  } else {
    urlParam += escape(value.toString());
  }
  return urlParam;
}
