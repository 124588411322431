import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SeverityIconComponent } from "./severity-icon.component";
import { SharedModule } from "primeng/api";
import { IconModule } from "../icon.module";

@NgModule({
  declarations: [SeverityIconComponent],
  exports: [SeverityIconComponent],
  imports: [CommonModule, SharedModule, IconModule],
})
export class SeverityIconModule {}
