<form [formGroup]="form">
  <form-field label="Typ">
    <p-dropdown
      [options]="typeSI"
      [formControlName]="nameofForm('type')"
      appendTo="body"
      placeholder="Typ"
      [showClear]="true">
    </p-dropdown>
  </form-field>
  <form-field label="Bezeichnung">
    <input pInputText type="text" [formControlName]="nameofForm('name')" placeholder="Bezeichnung">
  </form-field>
  <form-field label="Rollen">
    <p-multiSelect
      [options]="contactRoles"
      [formControlName]="nameofForm('contactRoles')"
      placeholder="Rollen"
      appendTo="body">
    </p-multiSelect>
  </form-field>
  <form-field label="Systemgruppe">
    <p-dropdown
      [options]="accountSystemGroups"
      [formControlName]="nameofForm('accountSystemGroup')"
      placeholder="Systemgruppe"
      appendTo="body"
      [showClear]="true">
    </p-dropdown>
  </form-field>
  <div *ngIf="isType('report')">
    <h3>Report</h3>
    <form-field label="Dashboards">
      <p-multiSelect
        [options]="reportDashboards"
        [formControlName]="nameofForm('dashboardIds')"
        placeholder="Report Dashboards"
        appendTo="body">
      </p-multiSelect>
    </form-field>
    <div class="grid">
      <div class="col-6">
        <form-field label="Start Datum">
          <p-calendar
            showIcon="true" showButtonBar="true" appendTo="body" dateFormat="dd.mm.yy"
            [formControlName]="nameofForm('startDate')"
            placeholder="Start Datum"
            selectionMode="single">
          </p-calendar>
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="End Datum">
          <p-calendar
            showIcon="true" showButtonBar="true" appendTo="body" dateFormat="dd.mm.yy"
            [formControlName]="nameofForm('endDate')"
            placeholder="End Datum"
            selectionMode="single">
          </p-calendar>
        </form-field>
      </div>
      <div class="col-6">
        <form-field label="Frequenz">
          <p-dropdown
            [options]="frequencySI"
            [formControlName]="nameofForm('frequency')"
            placeholder="Frequenz"
            appendTo="body">
          </p-dropdown>
        </form-field>
      </div>
      <div *ngIf="frequencySI" class="col-6">
        <form-field *ngIf="isFrequency(ContactGroupFrequency.DAILY)" label="Wiederholt sich alle X Tage">
          <input placeholder="Wiederholt sich alle X Tage" type="text"
                 [formControlName]="nameofForm('frequencyDetail')"/>
        </form-field>
        <form-field *ngIf="isFrequency(ContactGroupFrequency.WEEKLY)" label="Tag">
          <p-dropdown
            [options]="weekdaySI"
            [formControlName]="nameofForm('frequencyDetail')"
            placeholder="Tag"
            appendTo="body">
          </p-dropdown>
        </form-field>
        <form-field *ngIf="isFrequency(ContactGroupFrequency.MONTHLY)" label="Jeden X im Monat">
          <input placeholder="Jeden X im Monat" type="text" [formControlName]="nameofForm('frequencyDetail')"/>
        </form-field>
      </div>
    </div>
  </div>
</form>

