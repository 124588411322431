import { Component, Inject, OnInit } from "@angular/core";
import { ROW_DATA } from "../../data-table";

@Component({
  selector: "incert-data-table-password-field",
  templateUrl: "./data-table-password-field.component.html",
  styleUrls: ["./data-table-password-field.component.css"],
})
export class DataTablePasswordFieldComponent implements OnInit {
  /**
   * p-passwordField is unavailable in PrimeNG v9
   * so this is a temp workaround component
   * https://primefaces.org/primeng/showcase/#/password
   */
  public showPassword = false;

  constructor(@Inject(ROW_DATA) public row_data: any) {}

  public password: string;

  resolveRowData: (row: any) => any = () => "";

  ngOnInit(): void {
    this.password = this.resolveRowData(this.row_data);
  }

  public toggle() {
    this.showPassword = !this.showPassword;
  }
}
