import { FilterDefinition } from "./column-definition.interface";
import { PropertyPathAccessor } from "@incert/incert-core";

export interface DataTableFilterDefinition<T> {
  header: string;
  property?: PropertyPathAccessor<T>;
  externalProperty?: string;
  filter: FilterDefinition<any>;
  tooltip?: string;
}
