import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageComponent } from "./image.component";
import { IconModule } from "../icon/icon.module";
import { ImagePreviewComponent } from "./image-preview/image-preview.component";
import { TooltipModule } from "../tooltip/tooltip.module";

@NgModule({
  declarations: [ImageComponent, ImagePreviewComponent],
  imports: [CommonModule, IconModule, TooltipModule],
  exports: [ImageComponent],
})
export class ImageModule {}
