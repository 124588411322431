<div class='grid edited p-align-center'>
  <div class="p-inputgroup col-7">
    <p-autoComplete
      [placeholder]=" (accountSystems?.length) + ' Kundensysteme durchsuchen'"
      #autoComplete
      optionLabel="display"
      [(ngModel)]='searchString'
      [suggestions]='suggestions'
      (onSelect)='onElementChosen(autoComplete, $event.value)'
      (onDropdownClick)='searchEngine.onDropDownClick()'
      (keydown.delete)='clearResults()'
      (keydown.backspace)='clearResults()'
      (completeMethod)='search($event)'>
      <ng-template let-item pTemplate='item'>
        <div *ngIf='item'>
          <ng-container *ngIf="item.type === SearchEngineMode.GENERAL">
            <p class='hint hint-filter'>{{ item.additionalInformation }}</p>
          </ng-container>
          <ng-container *ngIf="item.type === SearchEngineMode.TEASER">
            <p class='hint hint-teaser'>{{ item.additionalInformation }}</p>
          </ng-container>
          <span>{{ item.display | readableLogic }}</span>
        </div>
      </ng-template>
    </p-autoComplete>
    <div *ngIf='searchString'>
      <icon-button
        iconOnly='true'
        displayIconButtonAsButton='true'
        iconType='error'
        (click)='clearResults()'>
      </icon-button>
    </div>
  </div>
  <div class="col align-left">
    <icon-button
      *ngIf='searchString'
      (click)='saveFilterOverlay()'
      iconType='save'
      iconOnly='true'
      displayIconButtonAsButton='true'
      tooltipPosition='top'
      incTooltip='Filter als Vorlage speichern'>
    </icon-button>
  </div>
</div>
