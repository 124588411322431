import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineLabelTextComponent } from "./inline-label-text.component";

@NgModule({
  imports: [CommonModule],
  exports: [InlineLabelTextComponent],
  declarations: [InlineLabelTextComponent],
})
export class InlineLabelTextModule {}
