import { Injectable } from "@angular/core";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";
import { ContactEmail } from "./contact-email.model";

@Injectable({
  providedIn: "root",
})
export class ContactEmailManagerService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public async getContactEmails() {
    const response = await this.httpService.get<any>(
      this.settingsService.baseUrl + "contact-email",
    );
    for (const r of response) {
      r["contactRole"] = {
        label: r["contactRole"]["title"],
        value: r["contactRole"]["id"],
      };
    }
    return response;
  }

  public async getContactRoles() {
    const response = await this.httpService.get<any>(
      this.settingsService.baseUrl + "contact-email/all-roles",
    );
    return Object.values(response).map((r: any) => ({
      label: r.title,
      value: r.id,
    }));
  }

  public async getAccountSystemsSI() {
    const response = await this.httpService.get<any>(
      this.settingsService.baseUrl + "contact-email/systems",
    );
    return Object.values(response).map((r: any) => ({
      label: r.name,
      value: r.short,
    }));
  }

  public async importFallbackEmails() {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "contact-email/fallback",
    );
  }

  public async postContactData(contact: ContactEmail) {
    return await this.httpService.post<any>(
      this.settingsService.baseUrl + "contact-email",
      contact,
    );
  }

  public async deleteContactData(id: number) {
    return await this.httpService.delete<any>(
      this.settingsService.baseUrl + "contact-email/" + id,
    );
  }
}
