import { Type } from "@angular/core";
import { ButtonType } from "../../enums/button-type.enum";
import { OverlaySize } from "./overlay-size.enum";
import { IconType } from "../../enums/icon-type.enum";

export type OverlayCallback<T> = (component: T) => boolean | Promise<boolean>;

export interface OverlayAction<T> {
  label: string;
  iconType?: keyof typeof IconType;
  buttonType?: keyof typeof ButtonType;
  action: OverlayCallback<T>;
  displayAsLink?: boolean;
  disableCondition?: boolean;
  hidden?: boolean;
  focus?: boolean;
  id?: string;
}

export interface OverlayModel<T> {
  type: Type<T>;
  header?: string;
  headerDescription?: string;
  init?: (type: T) => void;
  actions?: OverlayAction<T>[];
  closable?: boolean;
  size?: OverlaySize;
  displayAsSidebar?: boolean;
  styleClass?: string;
  dismissableMask?: boolean;
}
