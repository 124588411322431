export function removeFromList(
  list: string,
  valueToRemove: string,
  separator = ";",
): string {
  return list
    .split(separator)
    .filter((v) => v !== valueToRemove)
    .join(separator);
}
