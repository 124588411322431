export function hexToRGB(hex, alpha) {
  if (Array.isArray(hex)) {
    const rgbaArray = [];
    for (const hexElem of hex) {
      const r = parseInt(hexElem.slice(1, 3), 16),
        g = parseInt(hexElem.slice(3, 5), 16),
        b = parseInt(hexElem.slice(5, 7), 16);
      if (alpha) {
        rgbaArray.push("rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")");
      } else {
        rgbaArray.push("rgb(" + r + ", " + g + ", " + b + ")");
      }
    }
    return rgbaArray;
  } else {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
}
