import { Component, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DataTableTagComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AuthManager } from "../../../core/auth";
import { Report } from "./report.model";
import { ReportService } from "./report.service";
import { ReportUpsertComponent } from "./report-upsert/report-upsert.component";
import { SelectItem } from "primeng/api";
import { AccountSystem } from "../../../core/models/account-system.interface";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { DiscrEnum } from "../../../core/enums/discr.enum";
import { InstallationStatusEnum } from "../../../core/enums/installation-status.enum";
import { Utils } from "../../../core/utils";
import { ReportStatus } from "./report.enum";

@Component({
  selector: "incert-report",
  templateUrl: "./report.component.html",
  styleUrl: "./report.component.css",
})
export class ReportComponent implements OnInit {
  dtConfig: DataTableConfig<Report>;
  reports: Report[] = [];
  dashboardSI: SelectItem[] = [];
  accountSystems: AccountSystem[] = [];

  constructor(
    private overlayService: OverlayService,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private authManager: AuthManager,
    private reportService: ReportService,
    private accountSystemManager: AccountSystemManager,
  ) {}

  async ngOnInit() {
    this.dashboardSI = await this.reportService.getDashboards();
    await this.loadingService.load(async () => {
      await this.init();
    });
    this.accountSystems =
      await this.accountSystemManager.getAccountSystemsGeneral(
        "?discr[]=gms&discr[]=" +
          DiscrEnum.ecom +
          "&installationStatus[]=" +
          InstallationStatusEnum.active +
          "&installationStatus[]=" +
          InstallationStatusEnum.productive,
      );
  }

  private async init() {
    this.reports = await this.reportService.getReports();
    await this.createDtConfig();
  }

  private async createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.reports);
    this.dtConfig = {
      mode: "pagination",
      rows: 15,
      data: arrayDataSource,
      filterArea: {
        filters: [
          {
            header: "Status",
            property: (v) => v.get("status"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: [
                {
                  label: "Custom live",
                  value: ReportStatus.CUSTOM_LIVE,
                },
                {
                  label: "Offline",
                  value: ReportStatus.OFFLINE,
                },
                {
                  label: "Hubspot synced",
                  value: ReportStatus.HUB_LIVE,
                },
                {
                  label: "Hubspot unsynced",
                  value: ReportStatus.HUB_UNSYNCED,
                },
              ],
            }),
          },
          {
            header: "UUID",
            property: (v) => v.get("uuid"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Kürzel",
            property: (v) => v.get("partId"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Dashboard",
            property: (v) => v.get("dashboardId"),
            filter: createFilterDefinition(SelectFilterComponent, {
              options: this.dashboardSI,
            }),
          },
        ],
      },
      columns: [
        {
          header: "Status",
          property: (v) => v.get("status"),
          component: createComponentConfiguration(DataTableTagComponent, {
            resolveRowData: (row: Report) => {
              return [
                {
                  name: row.status,
                  bgColor:
                    row.status === ReportStatus.CUSTOM_LIVE ||
                    row.status === ReportStatus.HUB_LIVE
                      ? "#009711"
                      : row.status === ReportStatus.OFFLINE
                        ? "#ff6127"
                        : "#fec601",
                },
              ];
            },
          }),
          width: "150px",
          sort: true,
        },
        {
          header: "UUID",
          property: (v) => v.get("uuid"),
          sort: true,
        },
        {
          header: "Kürzel",
          property: (v) => v.get("partId"),
          sort: true,
        },
        {
          header: "Dashboard",
          property: (v) => v.get("dashboardId"),
          transform: (v) => this.dashboardSI.find((d) => d.value === v)?.label,
          sort: true,
        },
        {
          header: "Statistiken ab",
          property: (v) => v.get("dateFrom"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Statistiken bis",
          property: (v) => v.get("dateTo"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Aktiv bis",
          property: (v) => v.get("ttl"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.readReport,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  hidden: !this.writeReport,
                  icon: "edit",
                  onClick: (row: Report) => this.overlayUpsert(row),
                  tooltip: "Bearbeiten",
                },
                {
                  hidden: !this.executeReport,
                  icon: "delete",
                  onClick: (row: Report) => this.delete(row),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.writeReport,
              label: "Report",
              icon: "plus",
              onClick: () => this.overlayUpsert(),
            },
          ],
        }),
      ],
    };
  }

  public async overlayUpsert(report: Report = null) {
    await this.overlayService.show<ReportUpsertComponent>({
      type: ReportUpsertComponent,
      header: report ? "Report bearbeiten" : "Report hinzufügen",
      size: OverlaySize.medium,
      displayAsSidebar: true,
      init: async (component) => {
        if (report) {
          component.report = report;
        }
        component.dashboardSI = this.dashboardSI;
        component.accountSystems = this.accountSystems;
      },
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: async (component) => {
            if (await component.upsertReport()) {
              await this.init();
            }
            return true;
          },
        },
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async delete(report: Report) {
    const response = await this.confirmService.confirmError(
      "",
      "Report löschen",
    );
    if (response) {
      await this.reportService.deleteReports(report.uuid);
      await this.ngOnInit();
    }
  }

  public get readReport(): boolean {
    return this.authManager.hasPermission("readReport");
  }

  public get writeReport(): boolean {
    return this.authManager.hasPermission("writeReport");
  }

  public get executeReport(): boolean {
    return this.authManager.hasPermission("executeReport");
  }
}
