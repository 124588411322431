import {Component, Input} from "@angular/core";
import {AccountSystem} from "../../../../core/models/account-system.interface";
import {Account} from "../../../../core/models/account.model";

@Component({
  selector: "account-system-details",
  templateUrl: "./account-system-show-details.component.html",
  styleUrls: ["./account-system-show-details.component.scss"],
})
export class AccountSystemShowDetailsComponent {
  @Input() public accountSystem: AccountSystem;
  @Input() public account: Account;

  constructor() {
  }
}
