import { NgModule } from "@angular/core";
import { CustomerListModule } from "../../customer-list.module";
import { SettingsComponent } from "./settings.component";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CustomerListModule,
    CustomerListPrimeModule,
    CustomerListSharedModule,
    FormExtensionModule,
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
