import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberInputComponent } from "./number-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [NumberInputComponent],
  imports: [CommonModule, InputTextModule, ReactiveFormsModule],
  exports: [NumberInputComponent],
})
export class NumberInputModule {}
