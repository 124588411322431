<div class="inct-file-upload" [ngClass]="{'reset-styles': resetStyles}">
  <ng-template [ngIf]="selectedFiles.length > 0">
    <div class="inct-fu-files">
      <div class="inct-fu-file" *ngFor="let file of selectedFiles; let i = index">
        <img *ngIf="selectedFileInfo[i].isImage" #preview [src]=" sanitizer.bypassSecurityTrustUrl(selectedFileInfo[i].objectUrl) " [alt]="file.name">
        <video *ngIf="selectedFileInfo[i].isVideo" #preview controls>
          <source [src]="sanitizer.bypassSecurityTrustUrl(selectedFileInfo[i].objectUrl)">
        </video>
        <div *ngIf="!selectedFileInfo[i].isImage && !selectedFileInfo[i].isVideo" class="inct-fu-filetype">
          <p class="inct-fu-filetype-name">{{file.name}}</p>
        </div>
        <div class="inct-file-option-buttons">
          <div [class]="'inct-file-option-button inct-file-option-button--'+icon.type" *ngFor="let icon of icons">
            <icon-button
              (click)="actionFile(file, icon.type)"
              [iconType]="icon.type"
              [label]="icon.label"
              iconOnly="true"
              displayIconButtonAsButton="true"
            ></icon-button>
          </div>
          <div class="inct-file-option-button inct-file-option-button--delete" *ngIf="showDelete">
            <icon-button
              (click)="deleteFile(i)"
              iconType="delete"
              label="Löschen"
              iconOnly="true"
              displayIconButtonAsButton="true"
            ></icon-button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <p-fileUpload
    #form
    (onSelect)="showSelectedFiles($event, form)"

    [customUpload]="true"
    [showCancelButton]="false"
    [showUploadButton]="false"
    class="inct-p-file-upload"
    [multiple]="allowMultiple"
    name="file"
    *ngIf="selectedFiles.length < maxNumberOfFiles"
  >
    <ng-template pTemplate="content">
      <div class="inct-fu-drag-and-drop">
        <!-- Makes drag and drop field clickable -->
        <p-fileUpload
          #invisibleForm
          (onSelect)="showSelectedFiles($event, invisibleForm)"

          [multiple]="allowMultiple"
          [showCancelButton]="false"
          [showUploadButton]="false"
          class="invisible-file-upload">
        </p-fileUpload>
          <ng-container [ngSwitch]="fileType">
              <ng-container *ngSwitchCase="'image/*'">
                  <span>{{'core.media.image.upload.dragDrop' | i18n}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'video/*'">
                  <span>{{'core.media.video.upload.dragDrop' | i18n}}</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                  <span>{{'core.media.file.upload.dragDrop' | i18n}}</span>
              </ng-container>
          </ng-container>
      </div>
    </ng-template>

  </p-fileUpload>
</div>



