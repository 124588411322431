export enum Action {
  "agree" = "agree",
  "disagree" = "disagree",

  "add" = "add",
  "remove" = "remove",

  "next" = "next",
  "previous" = "previous",

  "login" = "login",
  "logout" = "logout",

  "yes" = "yes",
  "no" = "no",

  "save" = "save",
  "load" = "load",
  "edit" = "edit",
  "preview" = "preview",

  "ok" = "ok",
  "abort" = "abort",
  "cancel" = "cancel",

  "create" = "create",
  "delete" = "delete",
  "update" = "update",
  "refresh" = "refresh",
}
