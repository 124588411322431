<div>
  <data-table-filter-area
    *ngIf="config.filterArea && config.persistence && persistence"
    [persistence]="persistence"
    [persistenceService]="config.persistence?.persistenceService"
    [filters]="config.filterArea.filters"
    (setFilter)="setFilterValues($event)">
  </data-table-filter-area>
  <data-table-filter-area
    *ngIf="config.filterArea && !config.persistence"
    [filters]="config.filterArea.filters"
    (setFilter)="setFilterValues($event)">
  </data-table-filter-area>
  <div class="data-table-filter-row clearfix separator-bottom"
       *ngIf="config.export === true || config.columnEdit === true">
    <div class="button-links-group">
      <p-button
        *ngIf="config.export === true"
        label="{{'core.dataTable.export' | i18n}}"
        (click)="overlayExport()"
        styleClass="reset-button-to-link">
      </p-button>
      <p-button
        *ngIf="config.columnEdit === true"
        label="{{'core.dataTable.configureColumns' | i18n}}"
        (click)="columnOverlay()"
        styleClass="reset-button-to-link">
      </p-button>
    </div>
  </div>
  <div class="data-table-filter-row clearfix separator-bottom"
       *ngIf="config.additionalHeaderComponents">
    <ng-container *ngFor="let headerComponent of config.additionalHeaderComponents">
      <column-host [component]="headerComponent" [expandedRows]="expandedRows"></column-host>
    </ng-container>
  </div>

  <p-table
    #tt
    [totalRecords]="totalRows"
    sortMode="multiple"
    [value]="data"
    [rows]="config.rows"
    [lazy]="true"
    (onLazyLoad)="load$.next(undefined)"
    [paginator]="config.mode ==='pagination' && data.length > 0 &&  (totalRows > config.rows || config?.alwaysShowPagination)"
    [showCurrentPageReport]="config.mode === 'pagination'"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [scrollable]="config.mode==='scroll'"
    [scrollHeight]="config.mode==='scroll' ? scrollHeight : undefined"
    [dataKey]="'key'"
    [autoLayout]="true"
    [rowExpandMode]="config?.expansion?.expandSingle ? 'single': 'multiple'"
    [expandedRowKeys]="expandedRows"
    [currentPageReportTemplate]="'core.dataTable.resultsOf' | i18n: {first: getFirstOfTable(), last: getLastOfTable(), total: totalRows}"
    [class]="getClassList()"
    [(selection)]="selectedRows"
    (onRowReorder)="onRowReorder($event)">
    <ng-template pTemplate="header" let-columns>
      <!-- col header -->
      <tr *ngFor="let columns of columnHelper?.columnsByLevel">
        <th *ngIf="config.dragDropList"></th>
        <th *ngIf="config.selectionMode === 'multiple' || config.selectionMode === 'single'">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of columns">
          <th
            *ngIf="col.sort !== true"
            [hidden]="col.hidden== true"
            [rowSpan]="col.rowSpan"
            [colSpan]="col.colSpan">
            <div *ngIf="isString(col.header)">
              <span>
                {{col.header}}
                <incert-icon *ngIf="col.description" iconType="info" [incTooltip]="col.description"></incert-icon>
              </span>
            </div>
            <div *ngIf="!isString(col.header)">{{col.header.label}}
              <column-host [component]="col.header.component" [expandedRows]="expandedRows"></column-host>
            </div>
          </th>
          <th
            *ngIf="col.sort === true"
            [pSortableColumn]="getPropertyPathJson(col)"
            [hidden]="col.hidden== true"
            [rowSpan]="col.rowSpan"
            [colSpan]="col.colSpan">
            <div *ngIf="isString(col.header)">{{col.header}}
              <p-sortIcon [field]="getPropertyPathJson(col)"></p-sortIcon>
            </div>
            <div *ngIf="!isString(col.header)">{{col.header.label}}
              <column-host [component]="col.header" [expandedRows]="expandedRows"></column-host>
              <p-sortIcon [field]="getPropertyPathJson(col)"></p-sortIcon>
            </div>
          </th>
        </ng-container>
        <th *ngIf="config.multipleSelectionItems">
          <div id="data-table-multiple-selection">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            <icon-button iconOnly="true" iconType="closeAccordion"
                         (click)="multipleSelectionMenu.toggle($event)"></icon-button>
            <p-menu #multipleSelectionMenu [popup]="true" [model]="config.multipleSelectionItems"
                    [appendTo]="'body'"></p-menu>
          </div>
        </th>
        <th *ngIf="config.expansion"></th>
      </tr>
      <!-- filter -->
      <tr #filterSection *ngIf="columnHelper?.hasFilter">
        <th *ngIf="config.dragDropList"></th>
        <th *ngIf="config.selectionMode === 'multiple'"></th>
        <th *ngFor="let col of columnHelper?.flattedColumns" [hidden]="col.hidden == true">
          <ng-container *ngIf="col.filter">
            <ng-template #filterHost></ng-template>
          </ng-container>
        </th>
        <th *ngIf="config.multipleSelectionItems"></th>
        <th *ngIf="config.expansion"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-entry let-index="rowIndex" let-expanded="expanded">
      <tr [pReorderableRow]="index" [pReorderableRowDisabled]="!config.dragDropList"
          [ngClass]="{'dtrow-has-additional-info-row-text':entry.additionalInfoRowText}">
        <td *ngIf="config.dragDropList">
          <span [pReorderableRowHandle]="index" [class]="IconType.bars"></span>
        </td>
        <td *ngIf="config.selectionMode === 'multiple' || config.selectionMode === 'single'">
          <p-tableCheckbox [value]="entry" [disabled]="config.selectionDisabled && config.selectionDisabled(entry.row)"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let col of columnHelper?.flattedColumns; let i = index;">
          <td
            *ngIf="!col.hidden"
            [class]="entry.columns[i].class"
            [style]="'width: ' + entry.columns[i].width"
            [ngClass]="{'align-right': col.align === 'right', 'align-center': col.align=== 'center'}"
            [style.background-color]="entry.columns[i].color" [style.color]="entry.columns[i].fontColor">
            <ng-container *ngIf="entry.columns[i].value && !col.component && !col.template">
              <span [innerHTML]="entry.columns[i].value" [ngClass]="{'nowrap': entry.columns[i].nowrap}"></span>
            </ng-container>
            <column-host
              *ngIf="entry.columns[i].component"
              (refresh)="load$.next()" [data]="entry"
              [component]="entry.columns[i].component"
              [expandedRows]="expandedRows"
            ></column-host>
            <ng-container
              *ngIf="col.template"
              [ngTemplateOutlet]="col.template"
              [ngTemplateOutletContext]="{'$implicit': entry.row}"
            ></ng-container>
          </td>
        </ng-container>
        <td *ngIf="config.multipleSelectionItems">
          <p-tableCheckbox [value]="entry"></p-tableCheckbox>
        </td>
        <td *ngIf="config.expansion" class="data-table-toggle-icon-column">
          <span
            *ngIf="entry.canExpand"
            [pRowToggler]="entry"
            (click)="cdr.detectChanges()"
            class="data-table-toggle-icon"
            [ngClass]="expanded ? 'data-table-toggle-icon-expanded' : ''">
          </span>
        </td>
        <td *ngIf="entry.additionalInfoRowText" class="dtrow-additional-info-row-text">
          <!-- Sub Elements, position absolute to match with design. Don't judge, i know it's a crime-->
          {{entry.additionalInfoRowText}}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-entry let-columns="columns">
      <tr class="dtrow-expansion" *ngIf="entry.canExpand">
        <td [attr.colspan]="20">
          <div class="dtrow-expansion-inner" [ngClass]="{'dtrow-expansion-has-padding': config.expansion?.hasPadding}">
            <ng-container *ngIf="config.expansion?.component">
              <column-host (refresh)="load$.next()" [data]="entry"
                           [component]="config.expansion.component"
                           [expandedRows]="expandedRows"></column-host>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="config.aggregateData">
        <ng-container *ngFor="let col of aggregateResult; let i = index;">
          <td class="aggregation-row">
            <span *ngIf="col?.result">{{col.result?.operation | aggregatePipe}}: </span>{{(col.result?.value | number)}}
          </td>
        </ng-container>
      </tr>
      <tr *ngIf="config.summaryData">
        <ng-container *ngFor="let v of config.summaryData">
          <td>
            <label [innerHTML]="v"></label>
          </td>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody" let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div class="loading-text"></div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div [ngClass]="{'separator-top': !config.dragDropList}" *ngIf="!data.length && !tt.loading && initialLoaded">
    <no-data-info [message]="config.emptyMessage ? config.emptyMessage : 'core.searchEmpty'|i18n"></no-data-info>
  </div>
</div>
