<div class="p-grid p-jc-center p-ai-center">
  <div class="p-col">
    <div class="input-with-icon">
      <span class="icon" aria-hidden="true"><incert-icon iconType="search"></incert-icon></span>
      <input
        [(ngModel)]="searchString" (keyup)="onGlobalSearch()"
        class="search"
        pInputText size="50" type="text" [placeholder]="placeholder ? placeholder : 'Globale Suche'" aria-label="">
    </div>
  </div>
</div>
