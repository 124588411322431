import { Component, EventEmitter, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  DataSourceFilter,
  FILTER_CONFIG,
  FilterConfig,
} from "../../data-table";
import { DataTableFilterComponent } from "../../data-table";
import { PropertyPathAccessor } from "@incert/incert-core";
import { DataTableFilterService } from "../data-table-filter-service";

interface Config extends FilterConfig {
  type?: "contains" | "eq" | "in" | "inLike";
  separator?: string;
  properties?: PropertyPathAccessor<any>[];
}

@Component({
  selector: "text-filter",
  templateUrl: "./text-filter.component.html",
  styleUrls: ["./text-filter.component.scss"],
})
export class TextFilterComponent implements DataTableFilterComponent {
  config: Config = {
    header: "",
    defaultValue: "",
    type: "contains",
  };
  change = new EventEmitter<DataSourceFilter>();
  form: UntypedFormGroup;
  floatingLabel = false;
  prevValue = null;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(FILTER_CONFIG) config: Config,
    private dataTableFilterService: DataTableFilterService,
  ) {
    this.config = Object.assign(this.config, config);
    this.form = this.fb.group({ filter: [this.config.defaultValue || null] });
  }

  //filter updates when called; method can get called, sending value as parameter
  async getFilter() {
    if (this.config.method) {
      await this.config.method(this.form.get("filter").value);
      return null;
    }
    return this.buildFilter(this.form.get("filter").value);
  }

  setValue(val) {
    this.form.get("filter").patchValue(val);
  }

  setFloatingLabel(val) {
    this.floatingLabel = val;
  }

  reset(): void {
    this.form.get("filter").reset();
  }

  buildFilter(v): DataSourceFilter | null {
    if (v === null || v === "" || v === undefined) {
      return this.dataTableFilterService.getDataTableFilter(this.config, null);
    }
    if (this.config.type === "in" || this.config.type === "inLike") {
      const separator = this.config.separator ?? ",";
      if (typeof v === "string" && v.includes(separator)) {
        v = v
          .split(separator)
          .map((entry) => entry.trim())
          .filter((entry) => entry.length > 0);
      } else {
        v = [v];
      }
    }
    return this.dataTableFilterService.getDataTableFilter(this.config, v);
  }

  filterExplicit() {
    if (this.prevValue !== this.form.get("filter").value) {
      this.change.emit(this.buildFilter(this.form.get("filter").value));
    }
    this.prevValue = this.form.get("filter").value;
  }
}
