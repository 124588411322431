import { Injectable } from "@angular/core";
import { Role } from "./role";
import { Permission } from "./permission";
import { Subject } from "rxjs";
import { APIService } from "../../core/api.service";
import { User } from "../user";
import { HttpService } from "../../core";

@Injectable({ providedIn: "root" })
export class RoleManager {
  public roleHasChanged = new Subject();

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getRoles(): Promise<Array<Role>> {
    return this.httpService.get<Array<Role>>(
      this.settingsService.baseUrl + "roles",
    );
  }

  public getRole(roleId: number): Promise<Role> {
    return this.httpService.get<Role>(
      this.settingsService.baseUrl + "roles/" + roleId,
    );
  }

  public getPermissions(): Promise<Array<Permission>> {
    return this.httpService.get<Array<Permission>>(
      this.settingsService.baseUrl + "permissions",
    );
  }

  public getPermissionsByRoleId(roleId: number): Promise<Array<Permission>> {
    return this.httpService.get<Array<Permission>>(
      this.settingsService.baseUrl + "roles/" + roleId + "/allpermissions",
    );
  }

  public createRole(role: Role): Promise<Role> {
    return this.httpService.post<Role>(
      this.settingsService.baseUrl + "roles",
      role,
    );
  }

  public updateRole(role: Role): Promise<boolean> {
    return this.httpService.put<boolean>(
      this.settingsService.baseUrl + "roles",
      role,
    );
  }

  public deleteRole(roleId: number): Promise<boolean> {
    return this.httpService.delete<boolean>(
      this.settingsService.baseUrl + "roles/" + roleId,
    );
  }

  public deleteRolePermission(
    roleId: number,
    permissionId: number,
  ): Promise<boolean> {
    return this.httpService.delete<boolean>(
      this.settingsService.baseUrl +
        "roles/" +
        roleId +
        "/remove/" +
        permissionId,
    );
  }

  public getUsersByRoleId(roleId: number): Promise<Array<User>> {
    return this.httpService.get<Array<User>>(
      this.settingsService.baseUrl + "roles/" + roleId + "/user",
    );
  }

  public markForChange() {
    this.roleHasChanged.next(true);
  }
}
