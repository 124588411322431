import {
  Component,
  Injector,
  Input,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import { OverlayOutletParam } from "./overlay-outlet-param.model";
import { LoadingService } from "@incert/incert-core";

@Component({
  selector: "overlay-content-host",
  template: ``,
})
export class OverlayContentHostComponent implements OnInit {
  @Input()
  overlayParam: OverlayOutletParam;

  public constructor(
    private viewContainerRef: ViewContainerRef,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(
      this.overlayParam.overlayModel.type,
      {
        injector: Injector.create({
          parent: this.overlayParam.injector,
          providers: [
            {
              provide: LoadingService,
              useValue: this.loadingService,
            },
          ],
        }),
      },
    );
    this.overlayParam.componentInstance = componentRef.instance;
    if (this.overlayParam.overlayModel.init) {
      this.overlayParam.overlayModel.init(componentRef.instance);
    }
  }
}
