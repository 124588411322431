import { AbstractControl, ValidatorFn } from "@angular/forms";

export class PasswordGroupValidator {
  /**
   * Checks if the passwords in control1 and control2 are equal
   * @param control1
   * @param control2
   */
  public static equal(
    control1: AbstractControl,
    control2: AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let validationErrors = control2.errors ? control2.errors : {};
      if (control1.value !== control2.value) {
        validationErrors["passwordEqual"] = {};
      } else {
        delete validationErrors["passwordEqual"];
        if (Object.keys(validationErrors).length === 0) {
          validationErrors = null;
        }
      }
      control2.setErrors(validationErrors);

      return null;
    };
  }
}
