import { Injector } from "@angular/core";
import { COLUMN_DATA } from "./column-data.token";
import { DataTableRefresh, DT_REFRESH } from "../data-table-refresh.token";
import { ROW_DATA } from "./row-data.token";

export class ColumnHostInjector implements Injector {
  constructor(
    private parent: Injector,
    private data: any,
    private refreshFn: DataTableRefresh,
  ) {}

  /* tslint:disable-next-line */
  get(token: any, notFoundValue?: any): any {
    if (token === COLUMN_DATA) {
      return this.data;
    }
    if (token === ROW_DATA) {
      return this.data;
    }
    if (token === DT_REFRESH) {
      return this.refreshFn;
    }

    /* tslint:disable-next-line */
    return this.parent.get(token, notFoundValue);
  }
}
