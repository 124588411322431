import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "readableLogic",
})
export class ReadableLogicPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]) {
    switch (value) {
      case "=":
        return "Gleich";
      case ">":
        return "Größer";
      case "<":
        return "Kleiner";
      case "<=":
        return "Kleiner Gleich";
      case ">=":
        return "Größer Gleich";
      case "!=":
        return "Ungleich";
      default:
        return value;
    }
  }
}
