import { Component, Input, Inject, OnInit } from "@angular/core";
import { NotificationService } from "@incert/incert-core";
import { Router } from "@angular/router";

import { ErrorResponseI } from "../errorResponseI";
import { ChangeUserPassword, IUserManager } from "../../pages/user";
import { AuthManager } from "../auth";

@Component({
  selector: "sac-pw-change-dialog",
  templateUrl: "./pw-change-dialog.component.html",
  styleUrls: ["./pw-change-dialog.component.scss"],
})
export class PwChangeDialogComponent implements OnInit {
  @Input() public userId: number;
  @Input() public firstLogin: string;
  public newPassword: string;
  public newPasswordConfirm: string;
  public loggedInUserId: number;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    @Inject("UserManager") private userManager: IUserManager,
    @Inject("AuthManager") private authManager: AuthManager,
  ) {}

  ngOnInit(): void {
    this.loggedInUserId = parseInt(localStorage.getItem("USER_ID"), 10);
  }

  public async cancel() {
    if (this.firstLogin) {
      await this.router.navigate(["login"]);
    }
    return true;
  }

  public async save() {
    try {
      if (!this.newPassword || !this.newPasswordConfirm) {
        this.notificationService.notifyInfo(
          "Eingabefelder sind nicht richtig ausgefüllt.",
          "Passwort nicht ausgefüllt",
        );
        return false;
      } else if (this.newPassword !== this.newPasswordConfirm) {
        this.notificationService.notifyInfo(
          "Feld neues Passwort und neues Passwort wiederholen müssen gleich sein.",
          "Passwöter stiummen nicht überein",
        );
        return false;
      } else {
        const changeUserPassword: ChangeUserPassword = {
          id: this.userId,
          oldPassword: null,
          newPassword: this.newPassword,
          creationUserId: this.loggedInUserId,
        };

        await this.userManager.changePassword(changeUserPassword);
        this.notificationService.notifySuccess(
          "Passwort von wurde erfolgreich geändert",
          "Passwort ändern",
        );
        if (this.firstLogin) {
          this.authManager.authModel.firstLogin = Date.now();
        }
      }

      if (!this.firstLogin) {
        await this.router.navigate(["user-overview"]);
      } else {
        await this.router.navigate(["account-system-overview"]);
      }
      return true;
    } catch (e) {
      if (e.status === 400) {
        const errorResponse = new ErrorResponseI(e._body);
        this.notificationService.notifyError(
          "Passwort ändern",
          errorResponse.message,
        );
      } else {
        alert(e);
        this.notificationService.notifyError(
          "Passwort ändern",
          "Es ist leider etwas schief gelaufen. Bitte versuchen Sie es erneut.",
        );
      }
      await this.authManager.logout();
      await this.router.navigate(["login"]);

      return false;
    }
  }
}
