import { LazyDataSourceSort } from "./lazy-data-source-sort.enum";
import { LazyDataSourceFilter } from "./lazy-data-source-filter.enum";

export interface LazyDataSourceInputSort {
  sort: LazyDataSourceSort;
  field: string;
}

export interface LazyDataSourceInputFilter {
  filter: LazyDataSourceFilter;
  field: string;
  value: string;
}

export interface LazyDataSourceInput {
  sorts?: Array<LazyDataSourceInputSort>;
  filters?: Array<LazyDataSourceInputFilter>;
  page?: number;
  limit?: number;
}
