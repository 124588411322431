<ng-container *ngIf="text?.length <= charLimit; else showExpandButton">
  {{ text }}
</ng-container>
<ng-template #showExpandButton>
  <ng-container *ngIf="showExpandedText; else hideExpandedText">
    {{ text }}
    <p-button styleClass="reset-button-to-link"
              (onClick)="toggleShowExpandedText()">
      {{ "core.dataTable.showLess" | i18n }}
    </p-button>
  </ng-container>
  <ng-template #hideExpandedText>
    {{ text.substring(0, charLimit - 1) }} ...
    <p-button styleClass="reset-button-to-link"
              (onClick)="toggleShowExpandedText()">
      {{ "core.dataTable.showMore" | i18n }}
    </p-button>
  </ng-template>
</ng-template>

