import { Component, Input, OnInit, Optional } from "@angular/core";
import { SlideContent } from "../slide-content";
import { OverlayHandle } from "@incert/incert-core";
import { I18nService } from "@incert/i18n";

@Component({
  selector: "incert-default-carousel",
  templateUrl: "./default-carousel.component.html",
})
export class DefaultCarouselComponent implements OnInit {
  @Input()
  public carouselPages: SlideContent[];

  @Input()
  textAlign = "align-center";

  displayFinishButton = false;

  @Input()
  onFinish: () => Promise<void> = null;

  constructor(
    i18nService: I18nService,
    @Optional() private overlayHandle: OverlayHandle,
  ) {}

  ngOnInit() {
    if (this.overlayHandle) {
      this.onPageChange(0);
    }
  }

  onPageChange(pageNumber: number) {
    if (this.overlayHandle) {
      this.overlayHandle.header = this.carouselPages[pageNumber].headline;
      this.overlayHandle.headerDescription =
        this.carouselPages[pageNumber].text;
      if (
        this.onFinish !== null &&
        pageNumber == this.carouselPages.length - 1
      ) {
        this.displayFinishButton = true;
      } else this.displayFinishButton = false;
    }
  }

  async onClickFinish() {
    if (this.onFinish) {
      await this.onFinish();
    }
    if (this.overlayHandle) {
      this.overlayHandle.close();
    }
  }
}
