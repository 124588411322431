<div *ngIf="formGroup">
  <form class="grid" [formGroup]="formGroup">
    <div class="col-4">
      <form-field label="Bezeichnung">
          <input formControlName="title" type="text" aria-label="" placeholder="Bezeichnung">
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Kundensystem(e)">
          <p-multiSelect
            [options]="accountSystemDropdown"
            appendTo="body"
            formControlName="accountSystems"
            [showToggleAll]="false"
            placeholder="Kundensystem(e)">
          </p-multiSelect>
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Kategorie">
          <p-dropdown
            [options]="categories"
            formControlName="category"
            placeholder="Kategorie"
            optionLabel="name"
            appendTo="body"
            styleClass="ui-column-filter"></p-dropdown>
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Tags">
          <p-multiSelect [options]="tags" optionLabel="name" formControlName="tags" placeholder="Tags"></p-multiSelect>
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Ansprechperson(en)">
          <p-multiSelect [options]="usersDropdown" formControlName="speakTo" placeholder="Ansprechperson(en)"></p-multiSelect>
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Gültig bis">
          <p-calendar
            showIcon="true"
            formControlName="validUntil"
            showTime="true"
            hourFormat="24"
            placeholder="Ablaufdatum"
            dateFormat="dd/mm/yy">
          </p-calendar>
      </form-field>
    </div>
    <div class="col-4">
      <form-field label="Meilenstein Datum">
          <p-calendar
            showIcon="true"
            formControlName="milestone"
            placeholder="Meilenstein Datum"
            dateFormat="dd/mm/yy">
          </p-calendar>
      </form-field>
    </div>
    <div class="col-12">
      <form-field label="Kommentar">
        <incert-quill-editor
          formControlName="comment">
        </incert-quill-editor>
      </form-field>
      <span class="text-small"> Noch {{lettersLeft}} Zeichen verfügbar</span>
    </div>
    <form-field class="col-12" label="Allgemeine Wissensnotiz">
      <p-inputSwitch
        formControlName="universal">
      </p-inputSwitch>
    </form-field>
    <form-field class="col-12" label="Wissensnotiz anpinnen">
      <p-inputSwitch
        formControlName="pinned">
      </p-inputSwitch>
    </form-field>
    <form-field class="col-12" label="Zendesk Ticket bei Ablauf erstellen">
      <p-inputSwitch
        formControlName="createZendeskTicket">
      </p-inputSwitch>
    </form-field>
  </form>
</div>
