import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PermissionChangelist } from "../permission-changelist";
import { RoleManager } from "../role-manager.service";
import { NotificationService } from "@incert/incert-core";
import { Permission } from "../permission";

@Component({
  selector: "incert-cl-confirm-dialog",
  templateUrl: "./role-confirm-dialog.component.html",
  styleUrls: ["./role-confirm-dialog.component.scss"],
})
export class RoleConfirmDialogComponent {
  @Input() changeList: PermissionChangelist;
  @Input() isCreate: boolean;
  @Input() toggled: boolean;
  @Output() toggledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private roleManager: RoleManager,
    private notificationService: NotificationService,
  ) {}

  public dismissDialog() {
    this.toggled = !this.toggled;
    this.toggledChange.emit(this.toggled);
    return true;
  }

  async saveNewRolePermissions() {
    try {
      await this.createOrUpdate(this.isCreate);
      await this.deletePermissions();

      this.dismissDialog();
      this.resetChanges();
    } catch (e) {
      this.notificationService.notifyError(
        "Die Änderungen konnten nicht vorgenommen werden",
        "Fehler" + e.message,
      );
      return false;
    }
    return true;
  }

  private resetChanges() {
    this.changeList.addedPermissions = [];
    this.changeList.removedPermissions = [];
  }

  private async createOrUpdate(isCreateRequest: boolean) {
    if (isCreateRequest) {
      await this.createRole();

      this.notificationService.notifySuccess(
        this.getChangeCounter() + " Änderungen wurden vorgenommen",
        "Rolle erzeugt: " + this.changeList.name,
      );
    } else {
      await this.updateRole();

      this.notificationService.notifySuccess(
        this.getChangeCounter() + " Änderungen wurden vorgenommen",
        "Update: " + this.changeList.name,
      );
    }

    this.dataChange.emit(true);
  }

  private async updateRole() {
    await this.roleManager.updateRole({
      id: this.changeList.id,
      name: this.changeList.name,
      description: this.changeList.description,
      permissions: this.changeList.addedPermissions,
    });
  }

  private async createRole() {
    await this.roleManager.createRole({
      id: -1,
      name: this.changeList.name,
      description: this.changeList.description,
      permissions: this.changeList.addedPermissions,
    });
  }

  private async deletePermissions() {
    this.changeList.removedPermissions.forEach((permission: Permission) => {
      this.roleManager.deleteRolePermission(this.changeList.id, permission.id);
    });
  }

  public deletePermissionChange(permission: Permission) {
    if (this.changeList.addedPermissions.includes(permission)) {
      this.changeList.addedPermissions =
        this.changeList.addedPermissions.filter(
          (el) => el.id !== permission.id,
        );
    } else {
      this.changeList.removedPermissions =
        this.changeList.removedPermissions.filter(
          (el) => el.id !== permission.id,
        );
    }

    if (this.getChangeCounter() === 0) {
      this.dismissDialog();
    }
  }

  public getChangeCounter() {
    return (
      this.changeList.addedPermissions.length +
      this.changeList.removedPermissions.length
    );
  }
}
