<div class="wizard-nav-buttons">
  <p-button
    *ngIf="wizardComponent.activeIndex > 0 && !isEdit"
    (onClick)="onClickPrev();"
    [label]="onPrevLabel || ('core.action.previous' | i18n)"
    styleClass="p-button-secondary">
  </p-button>
  <p-button
    *ngIf="wizardComponent.goBackRoute.length > 0 && wizardComponent.activeIndex == 0 && !isEdit"
    (onClick)="onClickGoBack();"
    [label]="onPrevLabel || ('core.action.previous' | i18n)"
    styleClass="p-button-secondary">
  </p-button>
  <p-button
    *ngIf="wizardComponent.activeIndex < wizardComponent.wizardSteps.length - 1 && !isEdit"
    (onClick)="onClickNext()"
    [disabled]="!isNavValid()"
    [label]="onNextLabel || ('core.action.next' | i18n)">
  </p-button>
  <p-button
    *ngIf="wizardComponent.activeIndex == wizardComponent.wizardSteps.length - 1 && !isEdit"
    (onClick)="onClickSubmit()"
    [disabled]="!isNavValid()"
    [label]="onNextLabel || ('core.action.finalise' | i18n)">
  </p-button>
  <p-button
    *ngIf="isEdit"
    (onClick)="onClickSubmit()"
    [label]="onNextLabel || ('core.action.update' | i18n)">
  </p-button>
</div>
