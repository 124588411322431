<div *ngFor="let error of errors">
  <div class="info-entry separator-bottom">
    <div class="p-p-3">
      <h3 class="clear-separator-bottom clear-separator-top">{{ error.name }}</h3>
      <div>{{ error.description }}</div>
    </div>
  </div>
</div>

<div *ngIf="init ?? errors.length <= 0">
  <div *ngIf="accountSystem.installationStatus === InstallationStatusEnum.added">
    <icon-button
      (click)='installSystem()'
      iconType='branch'
      label='System installieren'
      fullWidth="true">
    </icon-button>
  </div>

  <div *ngIf="accountSystem.installationStatus === InstallationStatusEnum.installed">
    <icon-button
      (click)='toggleActiveAccountSystem()'
      iconType='branch'
      label='System aktivieren'
      fullWidth="true">
    </icon-button>
  </div>

  <div *ngIf="accountSystem.installationStatus === InstallationStatusEnum.active">
    <div class="info-entry separator-bottom">
      <div class="p-p-3">
        <h3 class="clear-separator-bottom clear-separator-top">Laufzeitdaten löschen</h3>
        <div>Es muss sichergestellt werden, dass die gewünschten Laufzeitdaten, vor der Produktivstellung durch die Entwicklungsabteilung gelöscht
          wurden.
        </div>
      </div>
    </div>
    <div class="grid">
      <icon-button
        class="col-6"
        (click)='toggleActiveAccountSystem()'
        iconType='branch'
        label='System deaktivieren'
        fullWidth="true">
      </icon-button>
      <icon-button
        class="col-6"
        (click)='toggleProductiveAccountSystem()'
        iconType='branch'
        label='System produktiv stellen'
        fullWidth="true">
      </icon-button>
    </div>
  </div>

  <div *ngIf="accountSystem.installationStatus === InstallationStatusEnum.productive">
    <icon-button
      (click)='toggleProductiveAccountSystem()'
      iconType='branch'
      label='System Shutdown'
      fullWidth="true">
    </icon-button>
  </div>
</div>
