import { Component } from "@angular/core";

@Component({
  selector: "incert-language-items",
  templateUrl: "./language-items.component.html",
  styleUrls: ["./language-items.component.scss"],
})
export class LanguageItemsComponent {
  public language: null | any[] = null;
}
