import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  Input,
  OnInit,
  QueryList,
} from "@angular/core";
import {
  LanguageFormBuilder,
  LanguageFormComponent,
  LanguageFormService,
  LFB_INJECTION_WRAPPER,
  LfbInjectionWrapper,
  lfbInjectionWrapperFactory,
} from "../form-extension";

@Component({
  selector: "language-tabs",
  templateUrl: "./language-tabs.component.html",
  providers: [
    {
      provide: LFB_INJECTION_WRAPPER,
      useFactory: lfbInjectionWrapperFactory,
    },
  ],
})
export class LanguageTabsComponent implements OnInit, AfterViewInit {
  public index = 0;

  @ContentChildren(LanguageFormComponent, { descendants: true })
  public languageFormEntries = new QueryList<LanguageFormComponent>();

  @Input()
  public lfb: LanguageFormBuilder;

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(LFB_INJECTION_WRAPPER)
    public lfbInjectionWrapper: LfbInjectionWrapper,
  ) {}

  ngOnInit(): void {
    if (this.lfb) {
      this.lfbInjectionWrapper.lfb = this.lfb;
      this.lfbInjectionWrapper.languageFormService = new LanguageFormService(
        this.lfb,
      );
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public indexChanged(index: number) {
    this.index = index;
    this.lfbInjectionWrapper.languageFormService.activeLanguage =
      this.lfbInjectionWrapper.lfb.languages[this.index];
  }
}
