<form-field [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <p-dropdown
    *ngIf="config.type==='eq'"
    [formControl]="form.get('filter')"
    [options]="options"
    (onChange)="filterExplicit()"
    [showClear]="true"
    [filter]="config.filter"
    [placeholder]="floatingLabel ? ' ' : config.header"
    appendTo="body">
  </p-dropdown>
  <p-multiSelect
    *ngIf="config.type==='in'"
    [formControl]="form.get('filter')"
    [options]="options"
    (onChange)="filterExplicit()"
    [maxSelectedLabels]="3"
    [selectedItemsLabel]="'core.multiSelect.selectedItemsLabel' | i18n"
    [placeholder]="floatingLabel ? ' ' : config.header"
    appendTo="body">
  </p-multiSelect>
</form-field>
