import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  Injector,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from "@angular/core";
import { GridsterConfig, GridsterItem } from "angular-gridster2";
import { WidgetModel } from "./shared/widget.model";
import { DashboardConfig } from "./shared/dashboard-config.model";
import { DashboardFilterService } from "./shared/dashboard-filter.service";
import {
  ConfirmService,
  LoadingService,
  NotificationService,
} from "@incert/incert-core";
import { WidgetHostComponent } from "./widget-host/widget-host.component";
import { DashboardConfigService } from "./shared/dashboard-config.service";
import { DASHBOARD_I18N, DashboardI18n } from "./dashboard-i18n.token";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [DashboardFilterService, DashboardConfigService],
})
export class DashboardComponent implements OnInit, AfterContentInit {
  @Input()
  dashboardConfig: DashboardConfig;

  @ViewChildren(WidgetHostComponent)
  private widgetHosts = new QueryList<WidgetHostComponent>();

  @ContentChildren("filter", { read: TemplateRef })
  filterQueryList = new QueryList<TemplateRef<any>>();

  filters: TemplateRef<any>[] = null;

  public loaded = false;

  public displaySaveBtn = false;

  public config: GridsterConfig = {
    gridType: "verticalFixed",
    minCols: 8, // fixed col width for gridType: 'fixed'
    minRows: 4,
    fixedColWidth: 80,
    fixedRowHeight: 130,
    swapWhileDragging: true,
    mobileBreakpoint: 768,
    draggable: {
      enabled: true,
    },
    resizable: {
      enabled: true,
    },
    itemChangeCallback: async () => await this.dashboardChanged(),
    itemResizeCallback: async ($event) => await this.resizeWidget($event),
  };

  public widgets: WidgetModel[] = [];
  public displayAddWidget = false;
  public isEtrackerActive = true;

  constructor(
    private injector: Injector,
    public loadingService: LoadingService,
    private dashboardConfigService: DashboardConfigService,
    private cdr: ChangeDetectorRef,
    private confirmService: ConfirmService,
    @Inject(DASHBOARD_I18N) public dashboardI18n: DashboardI18n,
  ) {}

  ngAfterContentInit(): void {
    this.filters = this.filterQueryList.toArray().filter((v) => v);
  }

  async ngOnInit() {
    if (!this.dashboardConfig) {
      throw Error("Error: dashboardConfig not set");
    }

    this.dashboardConfigService.init(this.dashboardConfig);

    await this.loadingService.load(async () => {
      this.isEtrackerActive =
        await this.dashboardConfigService.isEtrackerActive();
      await this.loadWidgets();
    });
  }

  async addWidget(componentName: string) {
    const defaultSize = this.dashboardConfigService.getWidgetSize(
      componentName,
      "default",
    );
    const minSize = this.dashboardConfigService.getWidgetSize(
      componentName,
      "min",
    );
    const maxSize = this.dashboardConfigService.getWidgetSize(
      componentName,
      "max",
    );
    this.widgets.push({
      componentName: componentName,
      position: {
        x: 0,
        y: 0,
        rows: defaultSize?.rows ?? 1,
        cols: defaultSize?.cols ?? 1,
        minItemRows: minSize?.rows,
        minItemCols: minSize?.cols,
        maxItemRows: maxSize?.rows,
        maxItemCols: maxSize?.cols,
        resizeEnabled: true,
        dragEnabled: true,
      },
    });
    await this.dashboardChanged();
  }

  async removeWidget(widget: WidgetModel) {
    this.widgets.splice(this.widgets.indexOf(widget), 1);
    await this.dashboardChanged();
  }

  async resizeWidget(item: GridsterItem) {
    this.widgetHosts.forEach((v) => {
      const searchItem = <GridsterItem>v.widget.position;
      // find item position
      if (searchItem.x === item.x && searchItem.y === item.y) {
        v.resize();
        return;
      }
    });
  }

  async dashboardChanged() {
    this.displaySaveBtn =
      this.loaded && this.dashboardConfigService.persistor.delayed;
    if (!this.dashboardConfigService.persistor.delayed) {
      await this.saveWidgets();
    } else {
      this.cdr.markForCheck();
    }
  }

  async saveWidgets() {
    await this.dashboardConfigService.persistor.save(
      this.dashboardConfig.id,
      this.widgets,
    );
    this.displaySaveBtn = false;
  }

  async loadWidgets() {
    let widgets = await this.dashboardConfigService.persistor.load(
      this.dashboardConfig.id,
    );
    if (this.dashboardConfig.hideMissing) {
      // @ts-ignore
      widgets = widgets.filter((v) =>
        this.dashboardConfigService.getWidgetIds().includes(v.componentName),
      );
    }
    for (const widget of widgets) {
      if (!widget.position) {
        this.addWidget(widget.componentName);
      } else {
        this.widgets.push(widget);
      }
    }
    this.loaded = true;
  }

  public async setGlobalFilter() {
    const result = await this.confirmService.confirmInfo(
      "Sind Sie sicher?",
      "Globaler Filter setzen",
    );
    if (result) {
      for (const widgetHost of this.widgetHosts.toArray()) {
        widgetHost.setGlobalFilter(false);
      }
    }
  }

  public async export() {
    window.print();
  }
}
