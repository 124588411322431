import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CONFIRM_SUBJECT } from "./confirm-subject.token";
import { ConfirmParam } from "./confirm.param";
import { Severity } from "../../enums/severity.enum";
import { sleep } from "../../functions/sleep.function";

@Injectable({
  providedIn: "root",
})
export class ConfirmService {
  public constructor(
    @Inject(CONFIRM_SUBJECT) private confirmSubject: Subject<ConfirmParam>,
  ) {}

  public async confirmSuccess(
    content?: string,
    header?: string,
  ): Promise<boolean> {
    return this.confirm(Severity.success, content, header);
  }

  public async confirmError(
    content?: string,
    header?: string,
  ): Promise<boolean> {
    return this.confirm(Severity.error, content, header);
  }

  public async confirmInfo(
    content?: string,
    header?: string,
  ): Promise<boolean> {
    return this.confirm(Severity.info, content, header);
  }

  public async confirmWarning(
    content?: string,
    header?: string,
  ): Promise<boolean> {
    return this.confirm(Severity.warning, content, header);
  }

  private async confirm(
    severity: Severity,
    content?: string,
    header?: string,
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this.confirmSubject.next({
        content: content,
        header: header,
        severity: severity,
        resolve: resolve,
      });
    });
  }
}
