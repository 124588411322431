import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { APIService } from "../../core/api.service";
import { HttpService } from "../../core";
import { Account } from "../../core/models/account.model";

@Injectable({ providedIn: "root" })
export class AccountManager {
  public accountCreated = new Subject<Account>();
  public accountDataChanged = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getAccountsAssocWithSystems(): Promise<Array<Account>> {
    return this.httpService.get<Array<Account>>(
      this.settingsService.baseUrl + "account/all",
    );
  }

  public getAccount(accountId: number): Promise<Account> {
    return this.httpService.get<Account>(
      this.settingsService.baseUrl + "account/" + accountId,
    );
  }

  public updateAccount(account: Account): Promise<Account> {
    return this.httpService.put<Account>(
      this.settingsService.baseUrl + "account",
      account,
    );
  }

  public deleteAccount(accountId: number): Promise<boolean> {
    return this.httpService.delete<boolean>(
      this.settingsService.baseUrl + "account/" + accountId,
    );
  }

  public insertAccount(newAccount: Account): Promise<any> {
    return this.httpService.post<Account>(
      this.settingsService.baseUrl + "account",
      newAccount,
    );
  }
}
