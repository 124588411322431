import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessGroupAssignComponent } from "./access-group-assign.component";
import { FormExtensionModule, IncertTagModule } from "@incert/incert-gui";
import { AccordionModule } from "primeng/accordion";
import { InputSwitchModule } from "primeng/inputswitch";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [AccessGroupAssignComponent],
  exports: [AccessGroupAssignComponent],
  imports: [
    CommonModule,
    FormExtensionModule,
    AccordionModule,
    IncertTagModule,
    InputSwitchModule,
    FormsModule,
  ],
})
export class AccessGroupAssignModule {}
