<div class="box">
  <loading-wrapper>
    <sac-header></sac-header>
    <overlay-outlet></overlay-outlet>
    <toast-outlet></toast-outlet>
    <router-outlet></router-outlet>
    <sac-footer></sac-footer>
    <clipboard-outlet></clipboard-outlet>
  </loading-wrapper>
</div>
