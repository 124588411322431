import { Component, Input } from "@angular/core";

@Component({
  selector: "incert-internal-tools-infos-entry",
  templateUrl: "./infos-entry.component.html",
  styleUrls: ["./infos-entry.component.css"],
})
export class InfosEntryComponent {
  @Input()
  public header = "Header";
  @Input()
  public description = "Description";
  @Input()
  public version = "";
  @Input()
  public date = "";
  @Input()
  public classEntry = "info-entry";
}
