<p-carousel [value]="this.carouselPages" [styleClass]="'overlay-carousel'" (onPage)="onPageChange($event.page)">
    <ng-template let-page pTemplate="item">
        <div [class]="textAlign">
            <ng-container *ngIf="!overlayHandle">
                <h3>{{page.headline}}</h3>
                <p *ngIf="page.text" [innerHTML]="page.text"></p>
            </ng-container>
            <p *ngIf="page.body" [innerHTML]="page.body"></p>
            <img *ngIf="page.image" src="{{page.image}}" alt="{{page.headline}}">
            <div *ngIf="displayFinishButton" class="separator-top">
                <p-button
                    label="{{'gms.helpDialog.finishButtonLabel' | i18n}}"
                    styleClass="p-button-primary"
                    (click)="onClickFinish()">
                </p-button>
            </div>
        </div>
    </ng-template>
</p-carousel>
