import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HelpComponent } from "./help.component";
import { I18nModule } from "@incert/i18n";
import { IconModule } from "../icon/icon.module";
import { DefaultSlideModule } from "../default-slide-components/default-slide.module";
import { TooltipModule } from "../tooltip/tooltip.module";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    TooltipModule,
    DefaultSlideModule,
  ],
  exports: [HelpComponent],
  declarations: [HelpComponent],
})
export class HelpModule {}
