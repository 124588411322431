<div>
  <form-field [label]="selectionHeader">
    <p-multiSelect
      appendTo="body"
      [formControl]="formControl"
      [options]="items"
      (onChange)="onChange()"
      name="Selection">
    </p-multiSelect>
  </form-field>
  <div *ngIf="formControl.getRawValue() && formControl.getRawValue().length > 0">
    <data-table [config]="dtConfig"></data-table>
  </div>
</div>


