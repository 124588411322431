import {
  Component,
  forwardRef,
  Injector,
  Input,
  Type,
  ViewContainerRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from "@angular/forms";

export interface ComponentOutletConfig<T> {
  component: Type<T>;
  init?: (instance: T) => void;
  injector?: Injector;
}

@Component({
  selector: "component-outlet",
  template: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComponentOutletComponent),
      multi: true,
    },
  ],
})
export class ComponentOutletComponent<T> {
  instance: T;
  @Input()
  public set config(config: ComponentOutletConfig<T>) {
    let injector = config.injector || this.injector;
    const control = injector.get(NgControl, null);
    if (control) {
      injector = Injector.create({
        providers: [
          {
            provide: NgControl,
            useValue: control,
          },
        ],
        parent: injector,
      });
    }
    const component = this.vcr.createComponent(config.component, {
      injector: injector,
    });
    this.instance = component.instance;
    if (control) {
      control.valueAccessor = component.instance as ControlValueAccessor;
    }
    if (config.init) {
      config.init(component.instance);
    }
  }
  public constructor(
    private vcr: ViewContainerRef,
    private injector: Injector,
  ) {}

  getInstance() {
    return this.instance;
  }
}
