import { Injectable } from "@angular/core";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";
import { Marketplace } from "./marketplace.model";

@Injectable({
  providedIn: "root",
})
export class MarketplaceService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  async getMarketplaces() {
    return await this.httpService.get<Marketplace[]>(
      this.settingsService.baseUrl + "marketplace",
    );
  }

  async getSelectItems() {
    return await this.httpService.get<any[]>(
      this.settingsService.baseUrl + "marketplace/si",
    );
  }

  async upsertMarketplace(marketplace: Marketplace) {
    return await this.httpService.post<any>(
      this.settingsService.baseUrl + "marketplace",
      marketplace,
    );
  }

  public async deleteMarketplace(marketplaceId: number) {
    return await this.httpService.delete<any>(
      this.settingsService.baseUrl + "marketplace/" + marketplaceId,
    );
  }
}
