import { Component, OnInit } from "@angular/core";
import { AccessProfileManager } from "../access-profile.manager.service";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AccessProfile } from "../access-profile.model";
import { SelectItem } from "primeng/api";
import { ArrayDataSource, markAsDirty, modelToForm } from "@incert/incert-gui";
import { AccessProfileComponent } from "../access-profile.component";

@Component({
  selector: "incert-internal-tools-access-profile-legacy-add",
  templateUrl: "./access-profile-legacy-add.component.html",
})
export class AccessProfileLegacyAddComponent implements OnInit {
  constructor(
    public accessProfileManager: AccessProfileManager,
    private fb: UntypedFormBuilder,
  ) {
    this.form = this.fb.group(
      modelToForm<AccessProfile>({
        title: ["", [Validators.required]],
        description: ["", [Validators.required]],
        interfaceType: ["", [Validators.required]],
      }),
    );
  }

  form: UntypedFormGroup;
  formControl = new UntypedFormControl();
  selectItems: SelectItem[] = [];

  public arrayDataSource: ArrayDataSource<any>;
  accessProfile: AccessProfile;
  accessProfileComp: AccessProfileComponent;
  interfaceTypes: any;

  async ngOnInit() {
    for (const entry of this.interfaceTypes) {
      this.selectItems.push({ label: entry.type, value: entry.type });
    }
    this.form.patchValue(this.accessProfile);
  }

  async insertAccessProfile() {
    markAsDirty(this.form);
    if (this.form.valid) {
      await this.accessProfileManager.upsertAccessProfile({
        id: this.accessProfile ? this.accessProfile.id : null,
        title: this.form.get("title").value,
        description: this.form.get("description").value,
        parent: null,
        interfaceType: this.form.get("interfaceType").value,
        accessGroups: [],
        accessLevels: [],
      });
      await this.accessProfileComp.ngOnInit();
      return true;
    }
    return false;
  }
}
