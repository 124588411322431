import { Injectable } from "@angular/core";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";

@Injectable({
  providedIn: "root",
})
export class ContactGroupManagerService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public async getContactGroups() {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "contact-group",
    );
  }

  public async getContactGroupEmails(id: number) {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "contact-group/email/" + id,
    );
  }

  public async upsertContactGroup(newGroup: any) {
    return await this.httpService.post<any>(
      this.settingsService.baseUrl + "contact-group",
      newGroup,
    );
  }

  public async deleteContactGroup(id: any) {
    return await this.httpService.delete<any>(
      this.settingsService.baseUrl + "contact-group/" + id,
    );
  }
}
