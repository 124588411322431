import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastOutletComponent } from "./toast-outlet.component";
import { ToastModule } from "primeng/toast";

@NgModule({
  imports: [CommonModule, ToastModule],
  declarations: [ToastOutletComponent],
  exports: [ToastOutletComponent],
})
export class ToastOutletModule {}
