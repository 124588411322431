import { Component, OnInit } from "@angular/core";
import { Report } from "../../report/report.model";
import { SelectItem } from "primeng/api";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  createNameof,
  DateUtil,
  NotificationService,
} from "@incert/incert-core";
import { ReportService } from "../../report/report.service";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { AccountSystemGroup } from "../../../account-system-group/account-system-group";
import { ReportStatus } from "../../report/report.enum";

@Component({
  selector: "incert-report-batch-insert",
  templateUrl: "./report-batch-insert.component.html",
  styleUrl: "./report-batch-insert.component.css",
})
export class ReportBatchInsertComponent implements OnInit {
  dashboardSI: SelectItem[];
  reportStatusSI: SelectItem[] = [
    { label: "Hubspot", value: ReportStatus.HUB_UNSYNCED },
    { label: "Custom", value: ReportStatus.CUSTOM_LIVE },
  ];
  formGroup: UntypedFormGroup;
  nameofForm = createNameof<Report>();
  accountSystemGroup: AccountSystemGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private reportService: ReportService,
    private notificationService: NotificationService,
  ) {
    this.formGroup = this.fb.group(
      modelToForm<Partial<Report>>({
        dashboardId: this.fb.control(null, Validators.required),
        dateFrom: this.fb.control(null, Validators.required),
        dateTo: this.fb.control(null, Validators.required),
        ttl: this.fb.control(null, Validators.required),
        status: this.fb.control(ReportStatus.HUB_UNSYNCED, Validators.required),
      }),
    );
  }

  async ngOnInit() {
    this.dashboardSI = await this.reportService.getDashboards();
  }

  async insertReportBatch() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      try {
        const reportBatch: any = {
          ...this.formGroup.value,
          dateFrom: DateUtil.normalizeTimeZoneOffset(
            this.formGroup.get(this.nameofForm("dateFrom")).value,
          ),
          dateTo: DateUtil.normalizeTimeZoneOffset(
            this.formGroup.get(this.nameofForm("dateTo")).value,
          ),
          ttl: DateUtil.normalizeTimeZoneOffset(
            this.formGroup.get(this.nameofForm("ttl")).value,
          ),
          accountSystemGroupId: this.accountSystemGroup.id,
        };
        await this.reportService.insertReportBatch(reportBatch);
      } catch (e) {
        this.notificationService.notifyError(e, "Hinzufügen nicht möglich");
        return false;
      }
      return true;
    }
    return false;
  }
}
