import { Component, Input, OnInit } from "@angular/core";
import {
  ArrayDataSource,
  ColumnDefinition,
  createComponentConfiguration,
  DataTableConfig,
  DataTableToggleButtonComponent,
  isPropertyColumn,
} from "@incert/incert-gui";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "incert-data-table-column-overlay",
  templateUrl: "./data-table-column-overlay.component.html",
  styleUrls: ["./data-table-column-overlay.component.css"],
})
export class DataTableColumnOverlayComponent implements OnInit {
  @Input()
  config: DataTableConfig<any>;
  @Input()
  columns: ColumnDefinition<any>[];
  arrayDataSource: ArrayDataSource<DataTableConfig<any>> = null;
  dtConfig: DataTableConfig<any>;
  toggleAll = new UntypedFormControl();

  async ngOnInit() {
    //either set columns directly or set valid config
    if (!this.columns) {
      this.columns = this.config.columns
        .filter((v) => isPropertyColumn(v))
        .map((x) => Object.assign({}, x));
    }
    this.updateToggleAll();
    this.createDtConfig();
  }

  private createDtConfig() {
    this.arrayDataSource = new ArrayDataSource<any>(this.columns);
    this.dtConfig = {
      data: this.arrayDataSource,
      rows: Number.MAX_SAFE_INTEGER,
      dragDropList: true,
      noHeader: true,
      onRowReorder: (rows) => this.onRowReorder(rows),
      columns: [
        {
          header: "",
          property: (v) => v.get("header"),
        },
        {
          header: "",
          component: createComponentConfiguration(
            DataTableToggleButtonComponent,
            {
              onClick: (row) => {
                row.hidden = !row.hidden;
                this.updateToggleAll();
              },
              isChecked: (row) => !row.hidden,
            },
          ),
        },
      ],
    };
  }

  public getNewColumns() {
    const propertyColumns: ColumnDefinition<any>[] = this.columns;
    for (const c of this.config.columns) {
      if (!isPropertyColumn(c)) {
        propertyColumns.push(c);
      }
    }
    return propertyColumns;
  }

  async onRowReorder(rows: any[]) {
    this.columns = rows.map((v) => v.row);
  }

  toggleAllColumns() {
    if (this.isAllColumns(true)) {
      this.setAllColumns(false);
      return;
    }
    this.setAllColumns(true);
  }

  updateToggleAll() {
    this.toggleAll.patchValue(!this.isAllColumns(true));
  }

  setAllColumns(bool: boolean) {
    this.columns.map((v) => (v.hidden = bool));
    this.arrayDataSource.refreshDataTableSource();
  }

  isAllColumns(bool: boolean) {
    return !!this.columns.find((v) => v.hidden === bool);
  }
}
