import { Component } from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "incert-image-preview",
  templateUrl: "./image-preview.component.html",
  styleUrls: ["./image-preview.component.scss"],
})
export class ImagePreviewComponent {
  src: SafeUrl;
  alt: string;
}
