import { Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  Notification,
  NotificationPermission,
  NotificationTranslation,
} from "../notification.interface";
import { NotificationManagerService } from "../notification-manager.service";
import {
  createNameof,
  LoadingService,
  NotificationService,
} from "@incert/incert-core";
import { AccountSystem } from "../../../../core/models/account-system.interface";
import { SelectItem } from "primeng/api";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { Constants } from "../../../../core/constants";

@Component({
  selector: "incert-internal-tools-add-new-notification",
  templateUrl: "./add-notification.component.html",
  styleUrls: ["./add-notification.component.scss"],
})
export class AddNotificationComponent implements OnInit {
  public notificationCategoriesSI: Array<SelectItem>;
  public notificationPermissions: Array<NotificationPermission>;
  public notificationBehaviors: Array<SelectItem>;
  public languageSI: Array<SelectItem>;
  public accountSystems: Array<AccountSystem>;
  public notification: Notification;
  public formGroup: UntypedFormGroup;
  nameofFormTranslation = createNameof<NotificationTranslation>();

  constructor(
    private notificationManager: NotificationManagerService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private fb: UntypedFormBuilder,
  ) {
    this.createFormGroup();
    this.addTranslation();
  }

  async ngOnInit() {
    this.accountSystems.map((v) => {
      return (v.displayName = v.name + " | " + v.short);
    });

    if (this.notification) {
      for (let i = 0; i < this.notification.translations.length - 1; i++) {
        this.addTranslation();
      }
      this.formGroup.patchValue(this.notification);
      this.formGroup
        .get("accountSystems")
        .patchValue(
          this.accountSystems.filter(
            (a) =>
              this.notification.accountSystems
                .map((na) => na.id)
                .indexOf(a.id) !== -1,
          ),
        );
      this.formGroup
        .get("category")
        .patchValue(this.notification.category.severity);
      this.formGroup
        .get("startDate")
        .patchValue(
          this.notification.startDate
            ? new Date(this.notification.startDate)
            : null,
        );
      this.formGroup
        .get("endDate")
        .patchValue(
          this.notification.endDate
            ? new Date(this.notification.endDate)
            : null,
        );
    }
    this.languageSI = Constants.languageOptions();
  }

  private createFormGroup() {
    this.formGroup = this.fb.group(
      modelToForm<Notification>({
        id: this.fb.control(0),
        category: this.fb.control("", Validators.required),
        behavior: this.fb.control("", Validators.required),
        accountSystems: this.fb.control("", Validators.required),
        translations: this.fb.array([], Validators.required),
        endDate: this.fb.control("", Validators.required),
        uri: this.fb.control(""),
        startDate: this.fb.control(""),
        permissions: this.fb.control(""),
      }),
    );
  }

  addTranslation() {
    this.translations.push(
      this.fb.group(
        modelToForm<NotificationTranslation>({
          language: this.fb.control(null, Validators.required),
          title: this.fb.control("", Validators.required),
          shortDescription: new UntypedFormControl(""),
          text: new UntypedFormControl(""),
          cta: new UntypedFormControl(""),
        }),
      ),
    );
  }

  deleteTranslation(index: number) {
    if (this.translations.length === 1) {
      this.notificationService.notifyInfo(
        "Mindestens eine Übersetzung muss angegeben werden",
        "Löschen nicht möglich",
      );
    } else {
      try {
        this.translations.removeAt(index);
      } catch (e) {
        this.notificationService.notifyError(e);
      }
    }
  }

  async upsertNotification() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      await this.loadingService.load(async () => {
        try {
          await this.notificationManager.upsertNotification(
            this.formGroup.getRawValue(),
          );
          this.notificationService.notifySuccess(
            "Benachrichtigung wurde erfolgreich aktualisiert!",
            "Benachrichtigung aktualisiert!",
          );
        } catch (e) {
          this.notificationService.notifyError(
            "Fehler bei der Aktualisierung!",
          );
        }
      });
      return true;
    }
    return false;
  }

  get translations() {
    return this.formGroup.get("translations") as UntypedFormArray;
  }
}
