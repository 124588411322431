import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LazyDataSourceInput } from "./model/lazy-data-source-input.model";
import { LazyDataSourceOutput } from "./model/lazy-data-source-output.model";
import { LazyDataSourceSort } from "./model/lazy-data-source-sort.enum";
import { LazyDataSourceFilter } from "./model/lazy-data-source-filter.enum";
import { LazyDataSourceDispatcher } from "./lazy-data-source-dispatcher.interface";
import { DataConverter } from "@incert/data-conversion";

@Injectable()
export class ApiLazyDataSourceDispatcher implements LazyDataSourceDispatcher {
  public constructor(private http: HttpClient) {}

  async dispatch(
    input: LazyDataSourceInput,
    path: string,
    type?: any,
  ): Promise<LazyDataSourceOutput<any>> {
    let params = "";
    let first = true;

    if (input.sorts) {
      for (const sort of input.sorts) {
        if (first) {
          params += "?";
          first = false;
        } else {
          params += "&";
        }

        switch (sort.sort) {
          case LazyDataSourceSort.ASCENDING:
            params += "sort[" + sort.field + "]=" + "asc";
            break;
          case LazyDataSourceSort.DESCENDING:
            params += "sort[" + sort.field + "]=" + "desc";
            break;
        }
      }
    }

    if (input.filters) {
      for (const filter of input.filters) {
        if (first) {
          params += "?";
          first = false;
        } else {
          params += "&";
        }

        params += "filter[";

        switch (filter.filter) {
          case LazyDataSourceFilter.CONTAINS:
            params += "*";
            break;
          case LazyDataSourceFilter.EQ:
            params += "eq";
            break;
          case LazyDataSourceFilter.GT:
            params += "gt";
            break;
          case LazyDataSourceFilter.IN:
            params += "in";
            break;
          case LazyDataSourceFilter.LT:
            params += "lt";
            break;
        }
        params += "][" + filter.field + "]=" + filter.value;
      }
    }

    if (input.limit) {
      if (first) {
        params += "?";
        first = false;
      } else {
        params += "&";
      }
      params += "limit=" + input.limit;
    }

    if (input.page) {
      if (first) {
        params += "?";
        first = false;
      } else {
        params += "&";
      }
      params += "offset=" + input.page;
    }

    const headers = new HttpHeaders();
    headers.append("Access-Control-Request-Headers", "Pagination-Count");

    let count: number = null;
    let page: number = null;
    let limit: number = null;
    let data: any = null;

    const response = await this.http
      .get(path + params, {
        headers: headers,
        observe: "response",
      })
      .toPromise()
      .then((res) => {
        count = res.headers.has("Pagination-Count")
          ? Number(res.headers.get("Pagination-Count"))
          : null;
        limit = res.headers.has("Pagination-Limit")
          ? Number(res.headers.get("Pagination-Limit"))
          : null;
        page = res.headers.has("Pagination-Page")
          ? Number(res.headers.get("Pagination-Page"))
          : null;
        return res.body;
      });

    if (type) {
      data = await DataConverter.convert<any[]>(type, response);
    } else {
      data = response;
    }
    return { data: data, limit: limit, totalCount: count, page: page };
  }
}
