export class PropertyPathProxy<T> {
  get<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    K5 extends keyof T[K1][K2][K3][K4],
    K6 extends keyof T[K1][K2][K3][K4][K5],
    K7 extends keyof T[K1][K2][K3][K4][K5][K6],
    K8 extends keyof T[K1][K2][K3][K4][K5][K6][K7],
    K9 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8],
    K10 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9],
    K11 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9][K10],
    K12 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9][K10][K11],
    K13 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9][K10][K11][K12],
    K14 extends keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9][K10][K11][K12][K13],
    K15 extends
      keyof T[K1][K2][K3][K4][K5][K6][K7][K8][K9][K10][K11][K12][K13][K14],
  >(
    a: K1,
    b?: K2,
    c?: K3,
    d?: K4,
    e?: K5,
    f?: K6,
    g?: K7,
    h?: K8,
    i?: K9,
    j?: K10,
    k?: K11,
    l?: K12,
    m?: K13,
    n?: K14,
    o?: K15,
  ): PropertyKey[];
  get(...args: PropertyKey[]): PropertyKey[] {
    return args;
  }
}

// only once initialized
const propertyPathProxy = new PropertyPathProxy<any>();

export type PropertyPathAccessor<T> = (
  v: PropertyPathProxy<T>,
) => PropertyKey[];

export function getPropertyPath<T>(
  accessor: PropertyPathAccessor<T>,
): PropertyKey[] {
  try {
    return accessor(propertyPathProxy);
  } catch (e) {
    return [];
  }
}

export function getPropertyPathValue(
  val: object,
  propertyPath: PropertyPathAccessor<any> | PropertyKey[] | PropertyKey,
): any {
  let keys: PropertyKey[] = null;

  if (Array.isArray(propertyPath)) {
    keys = propertyPath;
  } else if (typeof propertyPath === "function") {
    keys = getPropertyPath<any>(propertyPath);
  } else {
    keys = [propertyPath];
  }

  let res = val;

  for (let i = 0; i < keys.length; ++i) {
    if (res) {
      res = res[keys[i]];
    }
  }

  return res === undefined || res === null ? "" : res;
}
