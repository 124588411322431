import { NgModule } from "@angular/core";
import { BooleanFilterComponent } from "./boolean-filter.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";
import { FormExtensionModule } from "../../form-extension";

@NgModule({
  declarations: [BooleanFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    FormExtensionModule,
  ],
})
export class BooleanFilterModule {}
