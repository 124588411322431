import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContactEmailComponent } from "./contact-email.component";
import { ContactEmailUpsertComponent } from "./contact-email-upsert/contact-email-upsert.component";
import { CustomerListSharedModule } from "../../../customer-list-shared.module";
import { CustomerListPrimeModule } from "../../../customer-list-prime.module";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  declarations: [ContactEmailComponent, ContactEmailUpsertComponent],
  imports: [
    CommonModule,
    CustomerListSharedModule,
    CustomerListPrimeModule,
    FormExtensionModule,
  ],
})
export class ContactEmailModule {}
