export class ErrorResponseI {
  message: string;

  constructor(jsonStr: string) {
    const jsonObj: any = JSON.parse(jsonStr);
    for (const prop in jsonObj) {
      this[prop] = jsonObj[prop];
    }
  }
}
