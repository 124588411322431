import { Component, forwardRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Slider } from "primeng/slider";

@Component({
  selector: "inc-slider",
  templateUrl: "./inc-slider.html",
  styleUrls: ["./inc-slider.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IncSliderComponent),
      multi: true,
    },
  ],
})
export class IncSliderComponent implements ControlValueAccessor {
  @Input() public showSliderValueInHandle = false;
  @Input() public showSliderLabels = false;
  @Input() public showSliderInput = false;
  private _min = 0;
  private _max = 100;

  @Input()
  set min(val: number) {
    if (this._min !== val) {
      this._min = val;

      if (this.slider) {
        this.slider.min = val;
        this.slider.updateHandleValue();
      }
    }
  }

  get min() {
    return this._min;
  }

  @Input()
  set max(val: number) {
    if (this._max !== val) {
      this._max = val;

      if (this.slider) {
        this.slider.max = val;
        this.slider.updateHandleValue();
      }
    }
  }

  get max() {
    return this._max;
  }

  @Input() orientation = "horizontal";
  @Input() step = 1;
  @Input() range = false;
  @Input() disabled = false;

  @ViewChild(Slider)
  private slider?: Slider;

  val = 0;

  onChange = (_: number) => {};

  public calculatePositionVal() {
    return (
      "left: " +
      Math.max(0, Math.min(100, (100 / (this.max - this.min)) * this.val)) +
      "%"
    );
  }

  writeValue(obj: any): void {
    if (typeof obj === "number") {
      this.val = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
