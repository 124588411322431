import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessProfileComponent } from "./access-profile.component";
import { I18nModule } from "@incert/i18n";
import {
  DataTableModule,
  FormExtensionModule,
  IconButtonModule,
  IconModule,
  IncertTagModule,
  PageModule,
} from "@incert/incert-gui";
import { ButtonModule } from "primeng/button";
import { AccessProfileAddComponent } from "./access-profile-add/access-profile-add.component";
import { MultiSelectModule } from "primeng/multiselect";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { PanelModule } from "primeng/panel";
import { AccessProfileDetailComponent } from "./access-profile-detail/access-profile-detail.component";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TableModule } from "primeng/table";
import { AccessProfileLegacyAddComponent } from "./access-profile-legacy-add/access-profile-legacy-add.component";
import { MultiselectWithTableModule } from "../../../../../../../shared-libs/incert-gui/src/lib/multiselect-with-table/multiselect-with-table.module";
import { DropdownModule } from "primeng/dropdown";
import { TabViewModule } from "primeng/tabview";
import { InputSwitchModule } from "primeng/inputswitch";
import { AccessLevelAssignModule } from "../../../../../../../shared-libs/incert-gui/src/lib/customer-list/access-level-assign/access-level-assign.module";
import { AccessGroupAssignModule } from "../../../../../../../shared-libs/incert-gui/src/lib/customer-list/access-group-assign/access-group-assign.module";

@NgModule({
  declarations: [
    AccessProfileComponent,
    AccessProfileAddComponent,
    AccessProfileDetailComponent,
    AccessProfileLegacyAddComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconButtonModule,
    ButtonModule,
    PageModule,
    DataTableModule,
    MultiSelectModule,
    ReactiveFormsModule,
    AccordionModule,
    PanelModule,
    IconModule,
    OverlayPanelModule,
    TableModule,
    IncertTagModule,
    MultiselectWithTableModule,
    DropdownModule,
    FormsModule,
    TabViewModule,
    InputSwitchModule,
    AccessLevelAssignModule,
    AccessGroupAssignModule,
    FormExtensionModule,
  ],
})
export class AccessProfileModule {}
