import { Injectable } from "@angular/core";
import { IUserManager, User, UpdateUser, ChangeUserPassword } from "./";
import { AccountSystem } from "../../core/models/account-system.interface";
import { Subject } from "rxjs";
import { APIService } from "../../core/api.service";
import { HttpService } from "../../core";

@Injectable({ providedIn: "root" })
export class UserManager implements IUserManager {
  private _userData$ = new Subject<any>();

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getUsers(): Promise<Array<User>> {
    return this.httpService.get<Array<User>>(
      this.settingsService.baseUrl + "user",
    );
  }

  public getUser(id: number): Promise<User> {
    return this.httpService.get<User>(
      this.settingsService.baseUrl + "user/" + id,
    );
  }

  public createUser(user: User): Promise<User> {
    return this.httpService.post<User>(
      this.settingsService.baseUrl + "user",
      user,
    );
  }

  public updateUser(user: UpdateUser): Promise<Array<User>> {
    return this.httpService.put<Array<User>>(
      this.settingsService.baseUrl + "user",
      user,
    );
  }

  public deleteUser(userId: number): Promise<any> {
    return this.httpService.delete<any>(
      this.settingsService.baseUrl +
        "user/" +
        userId +
        "/" +
        localStorage.getItem("USER_ID"),
    );
  }

  public getUsersAssignedToCSG(): Promise<Array<User>> {
    return this.httpService.get<Array<User>>(
      this.settingsService.baseUrl + "user",
    );
  }

  public getUsersAccountSystems(userId: number): Promise<Array<AccountSystem>> {
    return this.httpService.get<Array<AccountSystem>>(
      this.settingsService.baseUrl + "user/" + userId + "/accountsystems",
    );
  }

  public changePassword(
    changeUserPassword: ChangeUserPassword,
  ): Promise<number> {
    return this.httpService.put<number>(
      this.settingsService.baseUrl + "user/changePassword",
      changeUserPassword,
    );
  }

  public getNotAssignedAccountSystemsByUserId(
    userId: number,
  ): Promise<Array<AccountSystem>> {
    return this.httpService.get<Array<AccountSystem>>(
      this.settingsService.baseUrl + "user/" + userId + "/unrefereedsystems",
    );
  }

  get userData$(): Subject<any> {
    return this._userData$;
  }
}
