import {Component, OnInit} from '@angular/core';
import {NotificationService} from "@incert/incert-core";

import {AccountSystemInstallationService} from "./account-system-installation.service";
import {AccountSystem} from "../../../../core/models/account-system.interface";
import {InstallationStatusEnum} from "../../../../core/enums/installation-status.enum";

export class InstallationStatusError {
  name: string = ""
  description: string = ""
}

@Component({
  selector: 'incert-internal-tools-account-system-installation-status',
  templateUrl: './account-system-installation-status.component.html',
  styleUrls: ['./account-system-installation-status.component.css']
})
export class AccountSystemInstallationStatusComponent implements OnInit {
  accountSystem: AccountSystem;
  errors: InstallationStatusError[] = [];
  init: boolean = false;
  protected readonly InstallationStatusEnum = InstallationStatusEnum;

  constructor(
    private accountSystemInstallationService: AccountSystemInstallationService,
    private notificationService: NotificationService,
  ) {
  }

  async ngOnInit() {
    this.errors = await this.accountSystemInstallationService.getInstallationStatusErrors(this.accountSystem);
    this.init = true;
  }

  async installSystem() {
    try {
      await this.accountSystemInstallationService.installAccountSystem(this.accountSystem);
      this.refresh();
    } catch (e) {
      this.notificationService.notifyError(
        "Kundensystem installieren",
        "Es ist leider ein Fehler aufgetreten",
      );
    }
  }

  async toggleActiveAccountSystem() {
    try {
      await this.accountSystemInstallationService.toggleActiveAccountSystem(this.accountSystem);
      this.refresh();
    } catch (e) {
      this.notificationService.notifyError(
        "Kundensystem aktivieren",
        "Es ist leider ein Fehler aufgetreten",
      );
    }
  }

  async toggleProductiveAccountSystem() {
    try {
      await this.accountSystemInstallationService.toggleProductiveAccountSystem(this.accountSystem);
      this.refresh();
    } catch (e) {
      this.notificationService.notifyError(
        "Kundensystem aktivieren",
        "Es ist leider ein Fehler aufgetreten",
      );
    }
  }

  refresh(): void {
    window.location.reload();
  }

}
