import { Component, Inject, OnInit } from "@angular/core";
import { ROW_DATA } from "@incert/incert-gui";

@Component({
  selector: "incert-data-table-expand-text",
  templateUrl: "./data-table-expand-text.component.html",
})
export class DataTableExpandTextComponent implements OnInit {
  public text: string;
  public charLimit = 150;

  protected showExpandedText = false;

  public resolveRowText: (row: any) => any = () => null;

  constructor(@Inject(ROW_DATA) private row_data: any) {}

  ngOnInit(): void {
    this.text = this.text || this.resolveRowText(this.row_data);
  }

  protected toggleShowExpandedText() {
    this.showExpandedText = !this.showExpandedText;
  }
}
