import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { GroupedMultiSelectComponent } from "./grouped-multi-select.component";
import { MultiSelectModule } from "primeng/multiselect";
import { CheckboxModule } from "primeng/checkbox";
import { I18nModule } from "@incert/i18n";

@NgModule({
  declarations: [GroupedMultiSelectComponent],
  exports: [GroupedMultiSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CheckboxModule,
    I18nModule,
  ],
})
export class GroupedMultiSelectModule {}
