import { Injectable } from "@angular/core";
import { TeaserEngine } from "./engines/teaser.engine";
import { GeneralEngine } from "./engines/general-engine";
import {
  SearchEngine,
  SearchEngineMode,
  SearchEngineResult,
} from "./search-engine.interface";

@Injectable({
  providedIn: "root",
})
export class AutoCompleteService {
  private _engine: SearchEngine;

  constructor(
    private generalEngine: GeneralEngine,
    private teaserEngine: TeaserEngine,
  ) {}

  public setSearchEngine(mode: SearchEngineMode) {
    switch (mode) {
      case SearchEngineMode.GENERAL: {
        this._engine = this.generalEngine;
        break;
      }
      case SearchEngineMode.TEASER: {
        this._engine = this.teaserEngine;
        break;
      }
    }
    return this._engine;
  }

  public async getSuggestions(
    searchString: string,
    config: any,
  ): Promise<SearchEngineResult[]> {
    return await this._engine.suggest(searchString, config);
  }
}
