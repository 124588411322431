<p-toolbar>
  <div class="p-toolbar-group-left">
    <ul class='navigation-list'>
      <li class='navigation-logo'>
        <a href="javascript:void(0)" (click)='navigateHome()'>
          <img src='incert-logo.png' alt='Kundenliste' width='115px'>
        </a>
      </li>
      <li *ngIf='readAccount'>
        <a [routerLink]='[RouteDef.accountOverview]'
           [routerLinkActive]="'active-router'">
            <span class="icon-text">
              <incert-icon [iconClass]="IconType.community"></incert-icon>Kunden
            </span>
        </a>
      </li>
      <li *ngIf='readSystem || readSystemGroup' class='dropdown'>
        <a href="javascript:void(0)">
            <span class="icon-text">
               <incert-icon [iconClass]="IconType.community"></incert-icon>Kundensysteme
            </span>
        </a>
        <div class='dropdown-content' [routerLinkActive]="'active-router'">
          <a *ngIf='readSystem'
             [routerLink]='[RouteDef.accountSystemOverview]'
             [queryParams]='{ discr: DiscrEnum.ecom }'
             [routerLinkActive]="'active-router'">ECOM</a>
          <a
            *ngIf='readSystem'
            [routerLink]='[RouteDef.accountSystemOverview]'
            [queryParams]='{ discr: DiscrEnum.loy }'
            [routerLinkActive]="'active-router'">LOY</a>
          <a
            *ngIf='readSystemGroup'
            [routerLink]='[RouteDef.accountSystemGroup]'
            [routerLinkActive]="'active-router'">Systemgruppen</a>
        </div>
      </li>
      <li
        *ngIf='readMemo'
        class='dropdown'>
        <a href="javascript:void(0)">
            <span class="icon-text">
               <incert-icon [iconClass]="IconType.workInProgress"></incert-icon>Tools
            </span>
        </a>
        <div class='dropdown-content'>
          <a
            *ngIf='readMemo'
            [routerLink]='[RouteDef.memo]'
            [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.memo"></incert-icon>Wissensnotizen
              </span>
          </a>
          <a
            *ngIf='readNotification'
            [routerLink]='[RouteDef.notification]'
            [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.notification"></incert-icon>Notifications
              </span>
          </a>
          <a
            *ngIf="readSystemValue"
            [routerLink]='[RouteDef.systemValue]'
            [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.branch"></incert-icon>Systemwerte
              </span>
          </a>
          <a *ngIf="readContact"
             [routerLink]='[RouteDef.contact]'
             [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.user"></incert-icon>Kontakte
              </span>
          </a>
          <a *ngIf="readContactGroup"
             [routerLink]='[RouteDef.contactGroup]'
             [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.users"></incert-icon>Kontaktgruppen
              </span>
          </a>
          <a *ngIf="readReport"
             [routerLink]='[RouteDef.report]'
             [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.file"></incert-icon>Reports
              </span>
          </a>
          <a *ngIf="readAccessProfile"
             [routerLink]='[RouteDef.accessProfile]'
             [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.bars"></incert-icon>Access Profile
              </span>
          </a>
          <a *ngIf="readMarketplace"
             [routerLink]='[RouteDef.marketplace]'
             [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.shoppingbag"></incert-icon>Marketplace
              </span>
          </a>
          <a *ngIf="readResource"
             [routerLink]='[RouteDef.resource]'
             [routerLinkActive]="'active-router'">
              <span class="icon-text">
                <incert-icon [iconClass]="IconType.file"></incert-icon>Ressourcen
              </span>
          </a>
        </div>
      </li>
      <li class='dropdown'
          *ngIf='readInfo || readLoginProtocol || readUserProtocol || readInstallationProtocol || readAccessProtocol || readRunnerProtocol'>
        <a href="javascript: void(0)">
            <span class="icon-text">
              <incert-icon [iconClass]="IconType.file"></incert-icon>Bibliothek
            </span>
        </a>
        <div class='dropdown-content'>
          <a
            *ngIf="readInfo"
            [routerLink]='[RouteDef.info]'
            [routerLinkActive]="'active-router'">Allgemein</a>
          <a
            *ngIf="readLoginProtocol"
            [routerLink]='[RouteDef.historyLoginProtocol]'
            [queryParams]="{ type: 'login' }"
            [routerLinkActive]="'active-router'">Loginprotokoll</a>
          <a
            *ngIf="readUserProtocol"
            [routerLink]='[RouteDef.historyChangeProtocol]'
            [queryParams]="{ type: 'change' }"
            [routerLinkActive]="'active-router'">Userprotokoll</a>
          <a
            *ngIf="readInstallationProtocol"
            [routerLink]='[RouteDef.protocolInstallationHistory]'
            [queryParams]="{ type: 'key-value' }"
            [routerLinkActive]="'active-router'">Installationsprotokoll</a>
          <a
            *ngIf="readAccessProtocol"
            [routerLink]='[RouteDef.historyAccessProtocol]'
            [queryParams]="{ type: 'access' }"
            [routerLinkActive]="'active-router'">Zugriffsprotokoll</a>
          <a
            *ngIf="readRunnerProtocol"
            [routerLink]='[RouteDef.historyServiceProtocol]'
            [queryParams]="{ type: 'key-value' }"
            [routerLinkActive]="'active-router'">Runnerprotokoll</a>
        </div>
      </li>
      <li class='dropdown' *ngIf='readRole || readUser'>
        <a href="javascript: void(0)">
            <span class="icon-text">
              <incert-icon [iconClass]="IconType.user"></incert-icon>Admin
            </span>
        </a>
        <div class='dropdown-content'>
          <a
            *ngIf="readUser"
            [routerLink]='[RouteDef.userOverview]'
            [routerLinkActive]="'active-router'">Benutzer</a>
          <a
            *ngIf="readRole"
            [routerLink]='[RouteDef.role]'
            [routerLinkActive]="'active-router'">Rollen</a>
        </div>
      </li>
    </ul>
  </div>

  <div class="p-toolbar-group-right">
    <icon-button *ngIf='hasUserName()'
                 iconType="user"
                 label="{{userName}}"
                 buttonType="secondary">
    </icon-button>
    <icon-button *ngIf='isAdminLoggedIn()'
                 (click)='relogin()'
                 iconType="logout"
                 label="Zurück"
                 buttonType="secondary">
    </icon-button>
    <icon-button (click)='logout()'
                 *ngIf='showNavbar()'
                 iconType="logout"
                 label="Logout">
    </icon-button>
  </div>
</p-toolbar>
