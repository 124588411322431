import { Directive, TemplateRef, ViewContainerRef, Input } from "@angular/core";
import { LanguageFormBuilder } from "./language-form-builder.service";

@Directive({
  selector: "[languageFormGroupNames]",
})
export class LanguageFormGroupNamesDirective {
  private _changed = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private lfb: LanguageFormBuilder,
  ) {}

  @Input("languageFormGroupNames")
  set loop(empty: any) {
    for (const language of this.lfb.languages) {
      this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: language.id,
      });
    }
  }
}
