export function DataConversion() {
  /* tslint:disable:only-arrow-functions */
  return function (target: any) {
    Object.defineProperty(target.prototype, "__dataConversionEnabled", {
      value: true,
      writable: false,
      enumerable: false,
    });
    return target;
  };
}
