import { Component, Input, OnInit } from "@angular/core";
import { SlideContent } from "../slide-content";

@Component({
  selector: "incert-default-tabs",
  templateUrl: "./default-tabs.component.html",
})
export class DefaultTabsComponent implements OnInit {
  @Input()
  public tabsContent: SlideContent[];

  constructor() {}

  ngOnInit(): void {}
}
