import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "incert-language-item",
  templateUrl: "./language-item.component.html",
  styleUrls: ["./language-item.component.scss"],
})
export class LanguageItemComponent implements OnInit {
  @Input() langCode: string;
  @Input() small = false;

  constructor() {}

  ngOnInit(): void {}

  getImageSrc() {
    return "assets/images/flags/" + this.langCode + ".svg";
  }
}
