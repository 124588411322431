import { Weekday } from "../enums/weekday.enum";

export type DateRangeFilter = (date: Date) => boolean;
export class DateRangeUtil {
  public static filterWeekdays(weekdays: Weekday[]): DateRangeFilter {
    return (date: Date) => {
      const noWeekday = date.getDay();

      if (noWeekday === 0 && weekdays.find((t) => t === Weekday.sunday)) {
        return true;
      }
      if (noWeekday === 1 && weekdays.find((t) => t === Weekday.monday)) {
        return true;
      }
      if (noWeekday === 2 && weekdays.find((t) => t === Weekday.tuesday)) {
        return true;
      }
      if (noWeekday === 3 && weekdays.find((t) => t === Weekday.wednesday)) {
        return true;
      }
      if (noWeekday === 4 && weekdays.find((t) => t === Weekday.thursday)) {
        return true;
      }
      if (noWeekday === 5 && weekdays.find((t) => t === Weekday.friday)) {
        return true;
      }
      if (noWeekday === 6 && weekdays.find((t) => t === Weekday.saturday)) {
        return true;
      }
      return false;
    };
  }

  public static generateDateRange(
    startDate: Date,
    endDate: Date,
    interval = 1,
    filter: DateRangeFilter,
  ): Date[] {
    interval = interval || 1;

    const retVal = [];
    const current = new Date(startDate) as Date;

    while (current <= endDate) {
      if (filter) {
        if (filter(current)) {
          retVal.push(new Date(current));
        }
      } else {
        retVal.push(new Date(current));
      }
      current.setDate(current.getDate() + interval);
    }
    return retVal;
  }
}
