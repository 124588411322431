import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { loadScript, resolveAsset } from "@incert/incert-core";

declare const monaco: any;
type CodeEditorLanguage = "html" | "json" | "css" | "php" | "xml" | "scss";

const vsPath = "assets/monaco-editor/vs";
let isInit = false;

export function validateCodeEditor(c: CodeEditorComponent) {
  return () => {
    if (!c.isValid()) {
      return { codeEditor: true };
    }
    return null;
  };
}

@Component({
  selector: "code-editor",
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeEditorComponent),
      multi: true,
    },
  ],
})
export class CodeEditorComponent
  implements AfterViewInit, ControlValueAccessor
{
  @Input()
  language: CodeEditorLanguage = "json";

  @Input()
  height = "500px";

  @Input()
  width = "100%";

  private _value = "";

  private _isValid = false;

  private onTouched = () => {};
  private propagateChange = (_: any) => {};
  @ViewChild("editor")
  editorElement: ElementRef<HTMLElement>;
  private editor: any;

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    if (value !== "") {
      this._value = value;
      if (this.editor) {
        this.editor.setValue(value);
      }
    }
  }

  async ngAfterViewInit() {
    await this.init();
  }

  public async init() {
    if (!isInit) {
      // is needed for plugins dynamic loading
      const newScript = document.createElement("script");
      const inlineScript = document.createTextNode(
        "var require = { paths: { vs: '" + resolveAsset(vsPath) + "' } };",
      );
      newScript.appendChild(inlineScript);
      document.body.appendChild(newScript);
      isInit = true;
    }
    await loadScript(resolveAsset(vsPath + "/editor/editor.main.css"), {
      "data-name": "vs/editor/editor.main",
    });
    await loadScript(resolveAsset(vsPath + "/loader.js"));
    await loadScript(resolveAsset(vsPath + "/editor/editor.main.nls.js"));
    await loadScript(resolveAsset(vsPath + "/editor/editor.main.js"));
    this.editor = monaco.editor.create(this.editorElement.nativeElement, {
      value: this._value,
      language: this.language,
      theme: "vs-dark",
    });
    this.editor.onDidChangeModelContent((e: any) => {
      this.propagateChange(this.editor.getValue());
    });
    monaco.editor.onDidChangeMarkers(() => {
      const markers = monaco.editor.getModelMarkers({
        resource: this.editor.getModel().uri,
      });
      let isValid = true;
      for (const marker of markers) {
        if (marker["severity"] === monaco.MarkerSeverity.Error) {
          isValid = false;
        }
      }
      const changed = this._isValid !== isValid;
      this._isValid = isValid;
      if (changed) {
        // trigger change so Validator gets updated
        this.propagateChange(this.editor.getValue());
      }
    });
    this.formatDocument();
  }

  public formatDocument() {
    setTimeout(() => {
      this.editor.getAction("editor.action.formatDocument").run();
    }, 200);
  }

  public isValid() {
    return this._isValid;
  }
}
