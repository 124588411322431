import { Injectable, Inject } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { IAuthManager } from "./auth";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    private router: Router,
  ) {}

  public async canActivate() {
    if (this.authManager.isUserLoggedIn) {
      return true;
    } else {
      await this.router.navigate(["login"]);
    }
  }
}
