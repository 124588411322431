import { inject, InjectionToken } from "@angular/core";
import { LanguageFormBuilder } from "./language-form-builder.service";
import { LanguageFormService } from "./language-form.service";
export interface LfbInjectionWrapper {
  lfb: LanguageFormBuilder;
  languageFormService: LanguageFormService;
}
export function lfbInjectionWrapperFactory(): LfbInjectionWrapper {
  return {
    lfb: inject(LanguageFormBuilder),
    languageFormService: new LanguageFormService(inject(LanguageFormBuilder)),
  };
}
export const LFB_INJECTION_WRAPPER = new InjectionToken<LfbInjectionWrapper>(
  "LFB_INJECTION_WRAPPER",
  {
    providedIn: "root",
    factory: lfbInjectionWrapperFactory,
  },
);
