<form-field [label]="config.header" [floatingLabel]="floatingLabel" [clearSeparatorBottom]="floatingLabel">
  <input *ngIf="config.type === 'between'"
         type="text" pInputText readonly="readonly"
         (click)='toggle($event, op)'
         [value]="(form.get('inputLow').value !== null && form.get('inputHigh').value !== null)
         ? (form.get('inputLow').value + ' - ' + form.get('inputHigh').value) : ''"
         [placeholder]="floatingLabel ? '' : config?.header">
  <p-overlayPanel #op appendTo="body" (onHide)="filterExplicit()">
    <form-field label="{{'core.dateTime.from' | i18n}}">
      <input
        type="number"
        [formControl]="form.get('inputLow')">
    </form-field>
    <form-field label="{{'core.dateTime.to' | i18n}}">
      <input type="number"
             [formControl]="form.get('inputHigh')">
    </form-field>
  </p-overlayPanel>
</form-field>
