import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiselectWithTableComponent } from "./multiselect-with-table.component";
import { DataTableModule } from "../data-table/data-table.module";
import { MultiSelectModule } from "primeng/multiselect";
import { ReactiveFormsModule } from "@angular/forms";
import { PageModule } from "../page/page.module";
import { FormExtensionModule } from "@incert/incert-gui";

@NgModule({
  declarations: [MultiselectWithTableComponent],
  exports: [MultiselectWithTableComponent],
  imports: [
    CommonModule,
    PageModule,
    MultiSelectModule,
    ReactiveFormsModule,
    DataTableModule,
    FormExtensionModule,
  ],
})
export class MultiselectWithTableModule {}
