import { nameof, PropertyPathAccessor } from "@incert/incert-core";
import { BehaviorSubject, Observable } from "rxjs";

export function isDataSource(ds: any): ds is DataSource<any> {
  return ds && nameof<DataSource<any>>("load") in ds.__proto__;
}

export type DataSourceSortOrder = "asc" | "desc";
export type DataSourceFilterType =
  | "or"
  | "and"
  | "eq"
  | "gt"
  | "lt"
  | "startsWith"
  | "in"
  | "contains"
  | "between"
  | "ge"
  | "le";

export interface DataSourceSort {
  property: PropertyKey | PropertyKey[] | PropertyPathAccessor<any>;
  sortOrder: DataSourceSortOrder;
}

export interface DataSourceFilterBinaryExpression {
  property?: PropertyKey | PropertyKey[] | PropertyPathAccessor<any>;
  externalProperty?: string;
  type:
    | "eq"
    | "gt"
    | "lt"
    | "startsWith"
    | "in"
    | "inLike"
    | "contains"
    | "between"
    | "ge"
    | "le";
  value: any;
}

export function isDataSourceFilterBinaryExpression(
  filter: DataSourceFilter,
): filter is DataSourceFilterBinaryExpression {
  return filter.type !== "or" && filter.type !== "and";
}

export interface DataSourceFilterLogicalExpression {
  type: "or" | "and";
  filters: DataSourceFilter[];
}

export function isDataSourceFilterLogicalExpression(
  filter: DataSourceFilter,
): filter is DataSourceFilterLogicalExpression {
  return filter.type && (filter.type === "or" || filter.type === "and");
}

export type DataSourceFilter =
  | DataSourceFilterBinaryExpression
  | DataSourceFilterLogicalExpression;

export interface DataSourceLoadParameter {
  limit?: number;
  offset?: number;
  sorts?: DataSourceSort[];
  filters?: DataSourceFilter[];
}

export interface DataSource<T> {
  /**
   * Total number of filtered rows
   */
  get total(): number;

  /**
   * Filtered, sorted and limited rows
   */
  get data(): T[];

  /**
   * Triggered when data source is refreshed, for example when the data has changed
   */
  get refreshDatasource$(): Observable<this>;

  /**
   * Update total and data properties based on settings passed in DataSourceLoadParameter
   */
  load(param: DataSourceLoadParameter): Promise<T[]>;

  /**
   * Export all rows based on current filter and sort settings from previous "load" call
   */
  export(): Promise<T[]>;

  /**
   * Optional
   * Triggers a change detection check when its value gets updated to a truthy value
   */
  checkForChanges$?: BehaviorSubject<boolean>;
}
