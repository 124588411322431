import { NgModule } from "@angular/core";
import { HeaderComponent } from "./header";
import { FooterComponent } from "./footer";
import { CustomerListModule } from "../../customer-list.module";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { ToolbarModule } from "primeng/toolbar";

@NgModule({
  imports: [
    CustomerListModule,
    CustomerListSharedModule,
    CustomerListPrimeModule,
    ToolbarModule,
  ],
  declarations: [HeaderComponent, FooterComponent],
  exports: [HeaderComponent, FooterComponent],
})
export class PageModule {}
