import { of } from "rxjs";

export interface GlobalFilterOptions<T> {
  readonly data: T[];
  maxArgs?: 1 | 2 | 3 | 4 | 5;
}

export class GlobalArrayFilter<T> {
  private filterOptions: GlobalFilterOptions<T>;
  private filteredArrayData: T[] = [];
  private topSearchResults: T[] = [];
  private data: T[] = [];
  private currentRow: any;
  private searchString = "";

  public filter(searchString: string, options: GlobalFilterOptions<T>) {
    this.filterOptions = options;
    this.searchString = searchString;
    this.topSearchResults = [];
    this.globalFilter();

    return of(this.filteredArrayData);
  }

  public filterDirect(searchString: string, options: GlobalFilterOptions<T>) {
    this.filterOptions = options;
    this.searchString = searchString;
    this.topSearchResults = [];
    this.globalFilter();

    return this.filteredArrayData;
  }

  private globalFilter(data?: T[], isRecursive?: boolean): any {
    if (!isRecursive) {
      this.filteredArrayData = [];
      this.data = this.filterOptions.data;
    } else {
      this.data = data;
    }

    this.data.forEach((row: T) => {
      if (!isRecursive) {
        this.currentRow = row;
      }
      for (const property of Object.keys(row)) {
        if (
          (row[property] && !isNaN(+row[property])) ||
          typeof row[property] === "string"
        ) {
          if (
            this.searchString.toString().length >= 3 &&
            row[property].toString().toLowerCase() ===
              this.searchString.toString().toLowerCase()
          ) {
            if (this.topSearchResults.indexOf(this.currentRow) === -1) {
              this.topSearchResults.push(this.currentRow);
            }
          } else {
            if (
              row[property]
                .toString()
                .toLowerCase()
                .includes(this.searchString.toString().toLowerCase()) &&
              this.filteredArrayData.indexOf(this.currentRow) === -1 &&
              this.topSearchResults.indexOf(this.currentRow) === -1
            ) {
              this.filteredArrayData.push(this.currentRow);
            }
          }
        } else if (Array.isArray(row[property])) {
          this.globalFilter(row[property], true);
        } else if (row[property] && typeof row[property] === "object") {
          for (const prop of Object.keys(row[property])) {
            if (Array.isArray(row[property][prop])) {
              this.globalFilter(row[property][prop], true);
            } else if (row[property] && typeof prop === "string") {
              if (
                this.searchString.toString().length > 3 &&
                row[property][prop] &&
                row[property][prop].toString().toLowerCase() ===
                  this.searchString.toString().toLowerCase()
              ) {
                if (this.topSearchResults.indexOf(this.currentRow) === -1) {
                  this.topSearchResults.push(this.currentRow);
                }
              } else {
                if (
                  row[property][prop] &&
                  row[property][prop]
                    .toString()
                    .toLowerCase()
                    .includes(this.searchString.toString().toLowerCase()) &&
                  this.filteredArrayData.indexOf(this.currentRow) === -1 &&
                  this.topSearchResults.indexOf(this.currentRow) === -1
                ) {
                  this.filteredArrayData.push(this.currentRow);
                }
              }
            }
          }
        }
      }
    });

    this.addTopSearchResults();
  }

  private addTopSearchResults() {
    this.topSearchResults.forEach((topRow: any) => {
      if (this.filteredArrayData.indexOf(topRow) === -1) {
        this.filteredArrayData.unshift(topRow);
      }
    });
  }
}
