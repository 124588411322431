import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderedMultiSelectComponent } from "./ordered-multi-select.component";
import { MultiSelectModule } from "primeng/multiselect";
import { ReactiveFormsModule } from "@angular/forms";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { CheckboxModule } from "primeng/checkbox";
import { DragDropModule } from "primeng/dragdrop";
import { IconModule } from "../icon/icon.module";
import { FormExtensionModule } from "../form-extension";
import { TooltipModule } from "../tooltip/tooltip.module";

@NgModule({
  declarations: [OrderedMultiSelectComponent],
  exports: [OrderedMultiSelectComponent],
  imports: [
    CommonModule,
    MultiSelectModule,
    ReactiveFormsModule,
    IconButtonModule,
    CheckboxModule,
    TooltipModule,
    DragDropModule,
    IconModule,
    FormExtensionModule,
  ],
})
export class OrderedMultiSelectModule {}
