declare let document: any;
const loadedScripts: { [script: string]: Promise<void> } = {};

export async function loadScript(
  src: string,
  attributes: any = {},
): Promise<void> {
  if (!(src in loadedScripts)) {
    loadedScripts[src] = new Promise<void>((resolve, reject) => {
      let el: any;

      if (src.endsWith(".css")) {
        el = document.createElement("link");
        el.rel = "stylesheet";
        el.href = src;
      } else {
        el = document.createElement("script");
        el.src = src;
        el.type = "text/javascript";
      }
      for (const key of Object.keys(attributes)) {
        el.setAttribute(key, attributes[key]);
      }
      if (el.readyState) {
        //IE
        el.onreadystatechange = () => {
          if (el.readyState === "loaded" || el.readyState === "complete") {
            el.onreadystatechange = null;
            resolve();
          }
        };
      } else {
        //Others
        el.onload = () => {
          resolve();
        };
      }
      el.onerror = () => reject();
      document.getElementsByTagName("head")[0].appendChild(el);
    });
  }
  return loadedScripts[src];
}
