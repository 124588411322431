<div>
  <div [formGroup]="formGroup">
      <form-field label="Vorname">
        <input formControlName="firstName" type="text" pInputText placeholder="Vorname">
      </form-field>
      <form-field label="Nachname">
        <input formControlName="lastName" type="text" pInputText placeholder="Nachname">
      </form-field>
      <form-field label="E-mail">
        <input formControlName="email" type="email" pInputText placeholder="E-Mail">
      </form-field>
      <form-field label="Passwort">
        <input formControlName="password" type="password" pInputText placeholder="Passwort">
      </form-field>
      <form-field label="Passwort bestätigen">
        <input formControlName="passwordConfirm" type="password" pInputText placeholder="Passwort bestätigen">
      </form-field>
      <form-field label="Rolle">
        <p-dropdown formControlName="role" placeholder="Rolle" [options]="roles" optionLabel="name" appendTo="body"></p-dropdown>
      </form-field>
  </div>
</div>
