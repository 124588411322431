<div class='p-m-5'>
  <div class="grid">
    <div class="col-3">
    </div>
    <div class="col-9" *ngIf="localPersistence?.config?.specialFilter">
      <account-system-config-filter
        class="align-center"
        [accountSystemDS]='arrayDataSource'
        [accountSystems]='accountSystems'>
      </account-system-config-filter>
    </div>
  </div>
  <data-table [config]="accountSystemConfig"></data-table>
</div>

