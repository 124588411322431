<div class="inc-accordion-non-toggle-row" *ngFor="let al of accessLevels">
  <span class="p-panel-header-label">{{al?.name}}</span>
  <div *ngIf="parentLevels" class="access-profile-add-tags">
    <incert-tag
      *ngIf="aps.isMatchElement(al, parentLevels)"
      label="vererbt">
    </incert-tag>
  </div>
  <div class="access-profile-add-button">
    <p-inputSwitch
      (onChange)="aps.registerChanges(al, accessLevelChanges)"
      [ngModel]="hasAccessElement(al, accessLevelChanges, accessProfile?.accessLevels)">
    </p-inputSwitch>
  </div>
</div>
