import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IconType } from "@incert/incert-core";

@Component({
  selector: "incert-input-with-icon",
  templateUrl: "./input-with-icon.component.html",
})
export class InputWithIconComponent implements OnInit {
  @Input() iconType: IconType;
  @Input() clickableIcon: boolean;

  @Output() iconClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onIconClick() {
    this.iconClick.emit();
  }
}
