import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertMessageComponent } from "./alert-message.component";
import { MessagesModule } from "primeng/messages";
import { SharedModule } from "primeng/api";
import { IconButtonModule } from "../..";
import { ButtonModule } from "primeng/button";

@NgModule({
  declarations: [AlertMessageComponent],
  exports: [AlertMessageComponent],
  imports: [
    CommonModule,
    MessagesModule,
    SharedModule,
    IconButtonModule,
    ButtonModule,
  ],
})
export class AlertMessageModule {}
