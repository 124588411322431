import { Injectable, Type } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataSourceMapping } from "./data-source-mapping.interface";
import { RqlDatasource } from "./rql-datasource";

@Injectable()
export class RqlDatasourceCreatorService {
  constructor(private http: HttpClient) {}

  public create<T>(
    limit: number,
    mapping: DataSourceMapping<T>[],
    type: Type<T>,
  ): RqlDatasource<T> {
    return new RqlDatasource<T>(limit, mapping, this.http, type);
  }
}
