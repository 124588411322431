/**
 * Checks deeply if two objects are equal.
 * @param obj1: {}
 * @param obj2: {}
 */
export function objectsDeeplyEqual(obj1: any, obj2: any) {
  if (obj1 && obj2 && typeof obj1 === "object" && typeof obj2 === "object") {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length === keys2.length) {
      return keys1.every((key) => objectsDeeplyEqual(obj1[key], obj2[key]));
    }
  }
  if (typeof obj1 === "string" && typeof obj2 === "string") {
    return obj1.replace(/['"\\\/]/gm, "") === obj2.replace(/['"\\\/]/gm, "");
  }
  return obj1 === obj2;
}
