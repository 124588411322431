import { Component, Input, OnInit } from "@angular/core";
import { AccessProfileService } from "../access-profile.service";

@Component({
  selector: "incert-access-level-assign",
  templateUrl: "./access-level-assign.component.html",
  styleUrls: ["./access-level-assign.component.css"],
})
export class AccessLevelAssignComponent implements OnInit {
  @Input()
  accessLevels: any[] = [];
  @Input()
  parentLevels: any;
  @Input()
  accessProfile: any;
  @Input()
  accessLevelChanges: any[] = [];
  @Input()
  withAccessProfiles = false;

  constructor(public aps: AccessProfileService) {}

  ngOnInit() {}

  public hasAccessElement(element: any, changeList: any, type: any): boolean {
    if (this.withAccessProfiles) {
      return this.aps.hasAccessElementAP(
        element,
        changeList,
        type,
        this.accessProfile,
      );
    } else {
      return this.aps.hasAccessElement(element, changeList);
    }
  }
}
