<p-toast [style]="{marginTop: '80px'}">
  <!-- Snippet copied from primeng source and adapted, so detail can be raw HTML -->
  <ng-template let-message pTemplate="message">
    <span class="p-toast-icon pi"
          [ngClass]="{'pi-info-circle': message.severity === 'info', 'pi-exclamation-triangle': message.severity === 'warn',
          'pi-times-circle': message.severity === 'error', 'pi-check' :message.severity === 'success'}"></span>
    <div class="p-toast-message-text-content">
      <div class="p-toast-summary">{{message.summary}}</div>
      <div class="p-toast-detail" [innerHtml]="message.detail"></div>
    </div>
  </ng-template>
</p-toast>
