import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  ViewContainerRef,
} from "@angular/core";

@Component({
  selector: "page-content",
  templateUrl: "./page-content.component.html",
  styleUrls: ["./page-content.component.scss"],
})
export class PageContentComponent implements OnInit {
  @Input() public header: string;

  @Input() public clearSpacingTop: false; //Inner Spacing (Padding)
  @Input() public clearSpacingBottom: false; //Inner Spacing (Padding)
  @Input() public clearSeparatorTop: false;
  @Input() public helpInfoText: string;

  constructor() {}

  ngOnInit() {}
}
