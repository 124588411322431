import Quill from "quill";
import { ClassAttributor, Registry } from "parchment";
import { FontFamilyModel } from "shared-libs/gms-core/src/lib/models/font-family.model";
import { FontFaceModel } from "shared-libs/gms-core/src/lib/models/font-face.model";

const installedFontClasses: {
  [key: string]: { fontFamilies: string[]; fontFaces: FontFaceModel[] };
} = {};
let stylesheets: WeakRef<HTMLElement>[] = [];

export class QuillCustomFontModule {
  public format: ClassAttributor;

  constructor(quill: typeof Quill) {
    const parchment = quill.import("parchment");

    class HookedClassAttributor extends parchment.ClassAttributor {
      constructor(
        private fontModule: QuillCustomFontModule,
        originalFormat: ClassAttributor,
      ) {
        super(originalFormat.attrName, originalFormat.keyName, {
          scope: originalFormat.scope,
          whitelist: [],
        });
      }

      add(node, value) {
        this.fontModule.updateFontClasses(node);
        return super.add(node, value);
      }

      remove(node) {
        this.fontModule.updateFontClasses(node);
        return super.remove(node);
      }

      value(node) {
        this.fontModule.updateFontClasses(node);
        return super.value(node);
      }
    }

    this.format = new HookedClassAttributor(
      this,
      quill.import("formats/font") as ClassAttributor,
    );
  }

  registerFontFamily(
    font: string | string[],
    fontFaces?: FontFaceModel[],
  ): string {
    if (typeof font === "string") {
      font = [font];
    }

    const id = this.idFromFontFamily(font);

    this.format.whitelist.push(id);

    if (!(id in installedFontClasses)) {
      installedFontClasses[id] = {
        fontFamilies: font,
        fontFaces: fontFaces ?? [],
      };

      for (const stylesheet of stylesheets) {
        const elem = stylesheet.deref();
        if (elem) {
          elem.innerText = this.getStylesheet();
        }
      }

      stylesheets = stylesheets.filter((s) => s.deref());
    }
    return id;
  }

  registerFontFamilyAndFaces(fontFamily: FontFamilyModel): string {
    return this.registerFontFamily(fontFamily.name, fontFamily.fonts);
  }

  install(registry: Registry) {
    registry.register(this.format);
  }

  prepareInput(el: HTMLElement) {
    for (const child of Array.from(el.querySelectorAll("[style]"))) {
      const fontFamily = this.parseFontFamily(
        (child as HTMLElement).style.fontFamily,
      );

      if (fontFamily.length) {
        const id = this.idFromFontFamily(fontFamily);

        let selectedId = null;
        if (id && id in installedFontClasses) {
          selectedId = id;
        } else {
          // Try to find next best font family by searching for first font
          for (const key in installedFontClasses) {
            if (
              installedFontClasses[key][0].toLowerCase() ===
              fontFamily[0].toLowerCase()
            ) {
              selectedId = key;
              break;
            }
          }
        }

        if (selectedId) {
          child.classList.add(this.format.keyName + "-" + selectedId);

          // Font family must be reset otherwise quill won't use the font class
          (child as HTMLElement).style.fontFamily = "";
        }
      }
    }
  }

  prepareOutput(el: HTMLElement) {
    for (const id in installedFontClasses) {
      for (const child of Array.from(
        el.getElementsByClassName(this.format.keyName + "-" + id),
      )) {
        (child as HTMLElement).style.fontFamily =
          "'" + installedFontClasses[id].fontFamilies.join("','") + "'";
        child.classList.remove(this.format.keyName + "-" + id);
        if (!child.classList.length) {
          child.removeAttribute("class");
        }
      }
    }
  }

  private updateFontClasses(node: HTMLElement) {
    const doc = node.ownerDocument;
    if (!doc.getElementById("quill-custom-fonts")) {
      const style = doc.createElement("style");
      style.id = "quill-custom-fonts";
      style.innerText = this.getStylesheet();
      doc.head.appendChild(style);
      stylesheets.push(new WeakRef(style));
    }
  }

  private getStylesheet(): string {
    return Object.keys(installedFontClasses)
      .map((f) => {
        const font = installedFontClasses[f];
        let style = `.${this.format.keyName}-${f} { font-family: '${font.fontFamilies.join("','")}'; }\n`;

        for (const face of font.fontFaces) {
          style += "@font-face {";
          style += `font-family: "${font.fontFamilies[0]}";`;
          style += `src: url("${face.url}")`;
          if (face.format) {
            style += ` format("${face.format}")`;
          }
          style += ";";
          style += `font-style: ${face.style};`;
          style += `font-weight: ${face.weight};`;
          if (face.range) {
            style += `unicode-range: "${face.range}";`;
          }
          style += "}\n";
        }

        return style;
      })
      .join("\n");
  }

  private parseFontFamily(family: string): string[] {
    return family
      .split(",")
      .map((f) => f.replace(/^[\s"']+/, "").replace(/[\s"']+$/, ""))
      .filter((f) => f);
  }

  private idFromFontFamily(family: string[]) {
    return family
      .join("")
      .toLowerCase()
      .replace(/[^a-z_]/g, "");
  }
}
