import { Component, Input } from "@angular/core";

@Component({
  selector: "incert-date-list-box",
  templateUrl: "./date-list-box.component.html",
  styleUrls: ["./date-list-box.component.scss"],
})
export class DateListBoxComponent {
  @Input() day: number | string;
  @Input() month: string;
  @Input() time: string;
  @Input() headline: string;
  @Input() tagLabel = "";
  @Input() tagColor = "#ffffff";
}
