<div>
  <table class="table">
    <tr>
      <th>Id</th>
      <th>Änderung</th>
      <th>Beschreibung</th>
      <th>Änderungen verwerfen</th>
    </tr>
    <tr *ngFor="let permission of changeList.addedPermissions">
      <td>{{permission.id}}</td>
      <td>
        <incert-icon
          iconType="success"></incert-icon>
      </td>
      <td class="">{{permission.name}}</td>
      <td>
        <icon-button
          (click)="deletePermissionChange(permission)"
          iconType="delete">
        </icon-button>
      </td>
    </tr>
    <tr *ngFor="let permission of changeList.removedPermissions">
      <td>{{permission.id}}</td>
      <td>
        <incert-icon
          iconType="error"></incert-icon>
      </td>
      <td>{{permission.name}}</td>
      <td>
        <icon-button
          (click)="deletePermissionChange(permission)"
          iconType="delete"></icon-button>
      </td>
    </tr>
  </table>
</div>
