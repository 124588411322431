<form [formGroup]="form">
  <form-field label="Typ">
    <p-dropdown
      [options]="typeSI"
      [formControlName]="nameofForm('type')"
      appendTo="body"
      placeholder="Typ"
      [showClear]="true">
    </p-dropdown>
  </form-field>
  <form-field label="Bezeichnung">
    <input pInputText type="text" [formControlName]="nameofForm('name')" placeholder="Bezeichnung">
  </form-field>
  <form-field label="Systemgruppe">
    <p-dropdown
      [options]="accountSystemGroups"
      [formControlName]="nameofForm('accountSystemGroup')"
      placeholder="Systemgruppe"
      appendTo="body"
      [showClear]="true">
    </p-dropdown>
  </form-field>
  <form-field *ngIf="isType('general')" label="Rollen">
    <p-multiSelect
      [options]="contactRoles"
      [formControlName]="nameofForm('contactRoles')"
      placeholder="Rollen"
      appendTo="body">
    </p-multiSelect>
  </form-field>
</form>

