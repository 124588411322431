import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  ArrayDataSource,
  createFilterDefinition,
  DataTableConfig,
  TextFilterComponent,
} from "@incert/incert-gui";
import { SelectItem } from "primeng/api";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "incert-multiselect-with-table",
  templateUrl: "./multiselect-with-table.component.html",
  styleUrls: ["./multiselect-with-table.component.css"],
})
export class MultiselectWithTableComponent implements OnInit, OnChanges {
  @Input()
  selectionHeader: any = "header";
  @Input()
  tableHeader = "";
  @Input()
  items: SelectItem[] = [];
  @Input()
  selectedItems: any[] = [];
  @Output()
  updateItems = new EventEmitter();

  formControl = new UntypedFormControl();
  dtConfig: DataTableConfig<any>;

  constructor() {}

  async ngOnInit() {}

  public onChange() {
    this.selectedItems = this.items.filter((i) =>
      this.formControl.value.includes(i.value),
    );
    this.configTable(this.selectedItems);
    this.updateItems.emit(this.selectedItems);
  }

  configTable(manu: any[]) {
    this.dtConfig = {
      data: new ArrayDataSource<any>(manu),
      mode: "pagination",
      rows: 5,
      displayAsNewIncList: true,
      columns: [
        {
          header: this.tableHeader,
          property: (v) => v.get("label"),
          filter: createFilterDefinition(TextFilterComponent, {
            property: (v) => v.get("label"),
          }),
          //sort: true
        },
      ],
    };
  }

  async ngOnChanges(changes: any) {
    if (this.selectedItems) {
      this.formControl.patchValue(this.selectedItems.map((x) => x.value));
      this.configTable(this.selectedItems);
    }
  }
}
