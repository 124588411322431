export function completeSequence<T, K extends keyof T>(
  array: T[],
  property: K,
  sequence: T[K][],
  factory: (sequenceEntry: T[K]) => T,
) {
  for (const sequenceEntry of sequence) {
    if (!array.find((v) => v[property] === sequenceEntry)) {
      array.push(factory(sequenceEntry));
    }
  }
  return array;
}
