import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NestedListComponent } from "./nested-list.component";
import { ContextMenuModule } from "primeng/contextmenu";

@NgModule({
  imports: [CommonModule, ContextMenuModule],
  declarations: [NestedListComponent],
  exports: [NestedListComponent],
})
export class NestedListModule {}
