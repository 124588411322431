import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { DateFormatPipe } from "./date-format.pipe";
import { DateTimeFormatPipe } from "./date-time-format.pipe";
import { TimeFormatPipe } from "./time-format.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [DateFormatPipe, DateTimeFormatPipe, TimeFormatPipe],
  exports: [DateFormatPipe, DateTimeFormatPipe, TimeFormatPipe],
  providers: [DatePipe, DateFormatPipe, TimeFormatPipe, DateTimeFormatPipe],
})
export class DateTimeFormatPipeModule {}
