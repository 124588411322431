import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from "@angular/core";

@Component({
  selector: "static-filter-host",
  templateUrl: "./static-filter-host.component.html",
  styleUrls: ["./static-filter-host.component.css"],
})
export class StaticFilterHostComponent implements AfterViewInit {
  @ViewChild("host", { static: true, read: ViewContainerRef })
  ref: ViewContainerRef;

  @Input()
  viewRef: ViewRef;

  @Input()
  header: string;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.ref.insert(this.viewRef);
  }
}
