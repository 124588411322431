import {
  Expression,
  SearchEngine,
  SearchEngineMode,
  SearchEngineResult,
  TeaserEngineState,
} from "../search-engine.interface";
import { Injectable } from "@angular/core";
import { ConstantsManager, Logic } from "../../constants-manager.service";
import {
  ConfigProperty,
  ConfigValue,
} from "../../../pages/tools/key-value-panel/key-value.interface";
import { SearchEngineService } from "../search-engine-service";
import { SystemValueManagerService } from "../../../pages/tools/system-value/system-value.manager.service";

@Injectable({
  providedIn: "root",
})
export class TeaserEngine implements SearchEngine {
  public stack = [];
  public latestPropertyToQuery: string;
  public resultExpression: Expression = {
    expressions: [],
  };

  constructor(
    private systemValueManagerService: SystemValueManagerService,
    private constantsManager: ConstantsManager,
    private searchEngineService: SearchEngineService,
  ) {}

  public async suggest(searchString: string): Promise<any> {
    return await this.getTeaserDataPool(this.getLatestState()).then((data) =>
      this.createSuggestions(data, searchString),
    );
  }

  public createSuggestions(data: Array<any> = [], searchString: string) {
    const resultSet: SearchEngineResult[] = [];
    const propertiesSeekable = ["name", "value"];

    if (TeaserEngine.validateSearchPool(data)) {
      data.forEach((item) => {
        propertiesSeekable.forEach((property) => {
          if (item.hasOwnProperty(property)) {
            if (
              item[property]
                .toLowerCase()
                .indexOf(searchString.toLocaleLowerCase()) !== -1
            ) {
              if (!resultSet.find((r) => r.display === item[property])) {
                resultSet.push({
                  display: item[property],
                  additionalInformation: this.getCurrentState(),
                  type: SearchEngineMode.TEASER,
                });
              }
            }
          } else if (!Array.isArray(item)) {
            const res = TeaserEngine.traversePrimitive(item, searchString);
            if (res && !resultSet.find((r) => r.display === res)) {
              resultSet.push({
                display: item[property],
                additionalInformation: this.getCurrentState(),
                type: SearchEngineMode.TEASER,
              });
            }
          }
        });
      });
    }
    return resultSet;
  }

  private static validateSearchPool(data: Array<any>) {
    return !!(Array.isArray(data) && data.length);
  }

  private static traversePrimitive(item: string | number, key) {
    if (typeof item === "string") {
      if (item.toLowerCase() === key.toLowerCase()) {
        return item;
      }
    } else if (isNaN(+item)) {
      // TODO this might not work
      if (item === key) {
        return item;
      }
    }
  }

  private async getTeaserDataPool(
    latestState: string,
  ): Promise<ConfigProperty[] | Logic[] | ConfigValue[]> {
    switch (latestState) {
      case TeaserEngineState.NONE:
        return await this.systemValueManagerService.getFilterProperties();
      case TeaserEngineState.KEY:
        return await this.constantsManager.getKeyValueConstants();
      case TeaserEngineState.LOGIC:
        return await this.systemValueManagerService.getValuesOfProperty(
          this.latestPropertyToQuery,
        );
      case TeaserEngineState.VALUE:
        return await this.constantsManager.getKeyValueConcats();
      case TeaserEngineState.CONCAT:
        return await this.systemValueManagerService.getFilterProperties();
    }
  }

  private getCurrentState(): string {
    switch (this.getLatestState()) {
      case TeaserEngineState.NONE:
      case TeaserEngineState.CONCAT:
        return TeaserEngineState.KEY;
      case TeaserEngineState.KEY:
        return TeaserEngineState.LOGIC;
      case TeaserEngineState.LOGIC:
        return TeaserEngineState.VALUE;
      case TeaserEngineState.VALUE:
        return TeaserEngineState.CONCAT;
    }
  }

  private getLatestState(): string {
    if (this.stack.length) {
      return this.stack[this.stack.length - 1];
    } else {
      return TeaserEngineState.NONE;
    }
  }

  public push(choosenElement: string) {
    if (
      choosenElement &&
      (this.getLatestState() === TeaserEngineState.NONE ||
        this.getLatestState() === TeaserEngineState.CONCAT)
    ) {
      this.latestPropertyToQuery = choosenElement;
      this.stack.push(TeaserEngineState.KEY);
    } else if (
      choosenElement &&
      this.getLatestState() === TeaserEngineState.KEY
    ) {
      this.stack.push(TeaserEngineState.LOGIC);
    } else if (
      choosenElement &&
      this.getLatestState() === TeaserEngineState.VALUE
    ) {
      this.stack.push(TeaserEngineState.CONCAT);
    } else if (
      choosenElement &&
      this.getLatestState() === TeaserEngineState.LOGIC
    ) {
      this.stack.push(TeaserEngineState.VALUE);
    }
  }

  public async onElementChosen(autoComplete: any, element: SearchEngineResult) {
    this.resultExpression.expressions.push(element.display);
    this.push(element.display);
    setTimeout(() => autoComplete.handleDropdownClick(element), 350);
  }

  public async onDropDownClick() {
    return await this.searchEngineService.processSingleMulti(this);
  }

  public async onInputWritten(element: string) {}

  cleanUp() {
    this.stack = [];
    this.latestPropertyToQuery = null;
    this.resultExpression.expressions = [];
  }

  public getMode(): string {
    return SearchEngineMode.TEASER;
  }
}
