import { Inject, Injectable, Pipe, PipeTransform } from "@angular/core";
import { parseFloatOrZero } from "@incert/incert-core";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

@Injectable({
  providedIn: "root",
})
@Pipe({
  name: "currencyFormat",
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(@Inject(INCERT_GUI_I18N) private i18n: IncertGUII18n) {}

  transform(
    inputValue: string | number,
    hideCurrencyIcon?: boolean,
    currencyCode?: string,
  ): string {
    if (inputValue == undefined) {
      return "";
    }

    currencyCode = currencyCode ?? this.i18n.currency;
    const currency: IncertGUII18n["currencyByCode"]["EUR"] = this.i18n
      .currencyByCode[currencyCode] ?? {
      decimalPoint: ",",
      symbolLeft: "",
      symbolRight: currencyCode,
      decimalPlaces: 2,
      thousandsPoint: ".",
    };
    const symbolLeft =
      currency.symbolLeft && !hideCurrencyIcon ? currency.symbolLeft + " " : "";
    const symbolRight =
      currency.symbolRight && !hideCurrencyIcon
        ? " " + currency.symbolRight
        : "";
    let formattedValue = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: currency.decimalPlaces,
      maximumFractionDigits: currency.decimalPlaces,
    })
      .format(parseFloatOrZero(inputValue.toString()))
      .replace(/\./g, "-")
      .replace(/,/g, currency.thousandsPoint)
      .replace(/-/g, currency.decimalPoint);

    if (formattedValue.charAt(0) === ",") {
      formattedValue = "-" + formattedValue.slice(1);
    }

    return symbolLeft + formattedValue + symbolRight;
  }
}
