<div class='p-m-5'>
  <p-tabView>
    <ng-container *ngFor="let category of categories">
      <p-tabPanel [header]="category.label">
        <ng-container *ngFor="let directory of category.children">
          <ng-container *ngIf="directory.children">
            <incert-internal-tools-infos-entry
              [header]="directory.label"
              [description]="directory.description"
              (click)="directory.isActive = !directory.isActive">
            </incert-internal-tools-infos-entry>
            <div *ngIf="directory.isActive">
              <ng-container *ngFor="let entry of directory.children">
                <incert-internal-tools-infos-entry
                  [header]="entry.label"
                  [description]="entry.description"
                  [classEntry]="'info-entry-sub'"
                  (click)="overlay(entry.label, entry.url, entry.textType)">
                </incert-internal-tools-infos-entry>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!directory.children">
            <incert-internal-tools-infos-entry
              [header]="directory.label"
              [description]="directory.description"
              (click)="overlay(directory.label, directory.url, directory.textType)">
            </incert-internal-tools-infos-entry>
          </ng-container>
        </ng-container>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</div>
