import { Component, OnInit } from "@angular/core";
import { ArrayDataSource, DataTableConfig } from "@incert/incert-gui";
import { AccountSystem } from "../../../../core/models/account-system.interface";
import { SystemValueManagerService } from "../../../tools/system-value/system-value.manager.service";

@Component({
  selector: "incert-internal-tools-account-system-system-value",
  templateUrl: "./account-system-system-value.component.html",
  styleUrls: ["./account-system-system-value.component.css"],
})
export class AccountSystemSystemValueComponent implements OnInit {
  systemValueKey: string;
  accountSystem: AccountSystem;
  systemValues: any[] = [];
  dtConfig: DataTableConfig<any>;

  constructor(private systemValueManagerService: SystemValueManagerService) {}

  async ngOnInit() {
    this.systemValues =
      await this.systemValueManagerService.getValuesOfPropertiesForAccountSystem(
        this.systemValueKey,
        this.accountSystem.id,
      );
    this.systemValues =
      this.systemValues["propertyValues"][this.systemValueKey];
    this.createDtConfig();
  }

  createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.systemValues);
    this.dtConfig = {
      data: arrayDataSource,
      rows: 50,
      columns: [
        {
          header: "Wert",
          property: (v) => v.get("value"),
        },
      ],
    };
  }
}
