import {Component, OnInit} from '@angular/core';
import {
  DataTableFilterProfileEntry
} from "../../../shared/interfaces/data-table-persistence.interface";
import {
  ArrayDataSource,
  DataTableConfig,
} from "@incert/incert-gui";
import {I18nService} from "@incert/i18n";

@Component({
  selector: 'incert-data-table-filter-profile-expansion',
  templateUrl: './data-table-filter-profile-expansion.component.html',
  styleUrls: ['./data-table-filter-profile-expansion.component.css']
})
export class DataTableFilterProfileExpansionComponent implements OnInit {
  filterProfileEntries: DataTableFilterProfileEntry[] = [];
  dtConfig: DataTableConfig<DataTableFilterProfileEntry> = null;
  arrayDataSource: ArrayDataSource<DataTableFilterProfileEntry> = null;

  constructor(private i18n: I18nService) {
  }

  ngOnInit(): void {
    this.createDtConfig();
  }

  private createDtConfig() {
    this.arrayDataSource = new ArrayDataSource<DataTableFilterProfileEntry>(this.filterProfileEntries);
    this.dtConfig = {
      data: this.arrayDataSource,
      rows: Number.MAX_SAFE_INTEGER,
      columns: [
        {
          header: this.i18n.instant("core.dataTable.filter"),
          property: (v) => v.get("name"),
        },
        {
          header: this.i18n.instant("core.dataTable.selectedValue"),
          property: (v) => v.get("value"),
        }
      ]
    };
  }
}
