import { Component, OnInit } from "@angular/core";
import { AccountSystemManager } from "../../account-system-manager.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NotificationService } from "@incert/incert-core";
import { markAsDirty } from "@incert/incert-gui";
import { SearchEngineService } from "../../../../core/autocomplete/search-engine-service";
import { SearchEngineResult } from "../../../../core/autocomplete/search-engine.interface";

@Component({
  selector: "incert-internal-tools-account-system-config-filter-save",
  templateUrl: "./account-system-config-filter-save.component.html",
})
export class AccountSystemConfigFilterSaveComponent implements OnInit {
  constructor(
    private accountSystemManager: AccountSystemManager,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private searchEngineService: SearchEngineService,
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      filter: ["", [Validators.required]],
    });
  }

  searchString: SearchEngineResult = null;
  form: UntypedFormGroup;

  ngOnInit() {
    const filterString = this.searchEngineService.filterToString(
      this.searchString,
    );
    this.form.get("filter").patchValue(filterString);
    this.form.get("filter").disable();
  }

  async saveFilter(userId: number = null) {
    markAsDirty(this.form);
    if (this.form.valid) {
      const response =
        await this.accountSystemManager.insertAccountSystemFilter({
          name: this.form.get("name").value,
          filter: this.searchString?.display
            ? this.searchString.display
            : this.searchString,
          userId: userId,
        });
      if (response) {
        this.form.get("name").patchValue(response);
        this.notificationService.notifyInfo("Dieser Filter existiert bereits");
        return false;
      } else {
        this.notificationService.notifySuccess("Filter hinzugefügt!");
        return true;
      }
    }
  }
}
