import { Component, OnInit } from "@angular/core";
import {
  Bundle,
  Marketplace,
  MarketplaceAssociation,
  MarketplaceTranslation,
  MarketplaceType,
} from "../marketplace.model";
import { createNameof, NotificationService } from "@incert/incert-core";
import { MarketplaceService } from "../marketplace.service";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SelectItem } from "primeng/api";
import { AccessProfile } from "../../access-profile/access-profile.model";
import { Resource } from "../../resource/shared/resource.model";
import { Constants } from "../../../../core/constants";

@Component({
  selector: "incert-marketplace-upsert",
  templateUrl: "./marketplace-upsert.component.html",
  styleUrls: ["./marketplace-upsert.component.scss"],
})
export class MarketplaceUpsertComponent implements OnInit {
  marketplace: Marketplace;
  marketplaceTypeSI: SelectItem<MarketplaceType>[];
  accessProfileSI: SelectItem<AccessProfile>[];
  bundleSI: SelectItem<Bundle>[];
  resourceSI: SelectItem<Resource>[];
  languageSI: SelectItem[];
  formGroup: UntypedFormGroup;
  nameofForm = createNameof<Marketplace>();
  nameofFormResource = createNameof<Resource>();
  nameofFormTranslation = createNameof<MarketplaceTranslation>();

  constructor(
    private marketplaceService: MarketplaceService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder,
  ) {
    this.createFormGroup();
    this.addTranslation();
  }

  async ngOnInit() {
    if (this.marketplace) {
      for (let i = 0; i < this.marketplace.translations.length - 1; i++) {
        this.addTranslation();
      }
      for (let i = 0; i < this.marketplace.resources.length; i++) {
        this.addResource();
      }
      this.formGroup.patchValue(this.marketplace);
      const marketplaceTypes = [];
      for (const type of this.marketplace.types) {
        marketplaceTypes.push(type.id);
      }
      this.formGroup.get("types").patchValue(marketplaceTypes);
      const accessProfiles = [];
      const bundles = [];
      for (const association of this.marketplace.associations) {
        if (association.discr === "bundle") {
          bundles.push(association.bundle.identifier);
        } else if (association.discr === "access_profile") {
          accessProfiles.push(association.accessProfile.id);
        }
      }
      this.formGroup.get("bundles").patchValue(bundles);
      this.formGroup.get("accessProfiles").patchValue(accessProfiles);
    }
    this.languageSI = Constants.languageOptions();
  }

  private createFormGroup() {
    this.formGroup = this.fb.group({
      id: this.fb.control(0),
      published: this.fb.control(false),
      installable: this.fb.control(false),
      types: this.fb.control([], Validators.required),
      associations: this.fb.control([]),
      bundles: this.fb.control([]),
      accessProfiles: this.fb.control([]),
      resources: this.fb.array([]),
      translations: this.fb.array([]),
    });
  }

  public addResource() {
    this.resources.push(
      this.fb.group(
        modelToForm<Resource>({
          id: this.fb.control(0),
          short: this.fb.control(""),
        }),
      ),
    );
  }

  public deleteResource(index: number) {
    try {
      this.resources.removeAt(index);
    } catch (e) {
      this.notificationService.notifyError(e);
    }
  }

  addTranslation() {
    this.translations.push(
      this.fb.group(
        modelToForm<MarketplaceTranslation>({
          language: this.fb.control("de", Validators.required),
          name: this.fb.control("", Validators.required),
        }),
      ),
    );
  }

  deleteTranslation(index: number) {
    if (this.translations.length === 1) {
      this.notificationService.notifyInfo(
        "Mindestens eine Übersetzung muss angegeben werden",
        "Löschen nicht möglich",
      );
    } else {
      try {
        this.translations.removeAt(index);
      } catch (e) {
        this.notificationService.notifyError(e);
      }
    }
  }

  public async upsertMarketplace() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      try {
        const marketplace: Marketplace = new Marketplace();
        marketplace.id = this.formGroup.get("id").value;
        marketplace.published = this.formGroup.get("published").value;
        marketplace.installable = this.formGroup.get("installable").value;
        marketplace.resources = this.formGroup.get("resources").value;
        marketplace.translations = this.formGroup.get("translations").value;
        const types: MarketplaceType[] = [];
        for (const id of this.formGroup.get("types").value) {
          types.push({
            id: id,
          });
        }
        marketplace.types = types;
        const associations: MarketplaceAssociation[] = [];
        for (const bundleIdentifier of this.formGroup.get("bundles").value) {
          associations.push({
            id: null,
            discr: "bundle",
            bundle: {
              identifier: bundleIdentifier,
            },
          });
        }
        for (const accessProfileId of this.formGroup.get("accessProfiles")
          .value) {
          associations.push({
            id: null,
            discr: "access_profile",
            accessProfile: {
              id: accessProfileId,
            },
          });
        }
        marketplace.associations = associations;

        await this.marketplaceService.upsertMarketplace(marketplace);
      } catch (e) {
        this.notificationService.notifyError(e, "Hinzufügen nicht möglich");
        return false;
      }
      return true;
    }
    return false;
  }

  get resources() {
    return this.formGroup.get("resources") as UntypedFormArray;
  }

  get translations() {
    return this.formGroup.get("translations") as UntypedFormArray;
  }
}
